import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { t } from '../../../../../../../types/translation/Translator';
import { ScanRule } from '../../../../../../../types/scanRule';
import Dropdown from '../../../../../Common/Dropdown';
import { ScanConfigurationType, scanConfigurationTypeToString } from '../../../../../../../types/company';
import TextInput from '../../../../../Common/TextInput';
import ModalPane from '../../../../../../../VentoryUI/components/common/Modal/components/ModalPane';

interface ScanRuleInfoPaneProps {
  rule: ScanRule;
  footer: (rule: ScanRule) => JSX.Element;
}

export default function ScanRuleInfoPane({ rule, footer }: ScanRuleInfoPaneProps) {
  const [ruleInput, setRuleInput] = useState<ScanRule>(new ScanRule(rule));

  return (
    <ModalPane footer={footer(ruleInput)}>
      <Grid container height={'300px'} columnSpacing={2} alignContent={'space-between'}>
        <Grid item xs={6}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Dropdown
                mandatory
                label={t().scanRuleField.singular.label}
                selectedValue={ruleInput.configurationValues[0]?.field || null}
                values={[...Object.keys(ScanConfigurationType)]}
                toText={item => scanConfigurationTypeToString(item as ScanConfigurationType)}
                onChange={item => setRuleInput(ruleInput.withConfigurationField(item as ScanConfigurationType))}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <TextInput
                mandatory
                onChange={item => setRuleInput(ruleInput.withConfigurationValue(item))}
                value={ruleInput.configurationValues[0]?.stringValue || ''}
                label={t().scanRuleStringValue.singular.label}
                placeholder={t().scanRuleStringValue.singular.label}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
