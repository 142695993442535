import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { CompanyRoleAssignmentContext } from '../../../../../context/CompanyRoleAssignmentContext';
import { UserContext } from '../../../../../context/UserContext';
import {
  CompanyRoleAssignmentMutations,
  DeleteCompanyRoleAssignmentResponse,
  DeleteCompanyRoleAssignmentVariables,
  LeaveCompanyResponse,
  LeaveCompanyVariables,
} from '../../../../../graphql/companyRoleAssignment.graphql';
import DeleteModal from '../../../../../VentoryUI/components/common/Modal/DeleteModal';

interface DeleteUserModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  ids: Set<string>;
}

export default function DeleteUserModal({ open, setOpen, ids }: DeleteUserModalProps) {
  const navigate = useNavigate();

  const { companyRoles, setCompanyRoles } = useContext(CompanyRoleAssignmentContext);
  const { currentCompany } = useContext(CompanyContext);
  const { currentUser, signOut } = useContext(UserContext);

  const [error, setError] = useState<string>('');

  const userCompanyRole = companyRoles.get([...ids.values()][0])?.find(r => r.companyId === currentCompany.id);

  const [remove, { loading: removeLoading }] = useMutation<
    DeleteCompanyRoleAssignmentResponse,
    DeleteCompanyRoleAssignmentVariables
  >(CompanyRoleAssignmentMutations.remove, {
    onCompleted: res => {
      const deletedRole = res.deleteCompanyRoleAssignment;
      const currentRoles = companyRoles.get(deletedRole.userId);
      if (!currentRoles) return;

      const idx = (currentRoles || []).findIndex(r => r.companyId === deletedRole.companyId);
      if (idx > -1) currentRoles?.splice(idx, 1);

      companyRoles.set(deletedRole.userId, currentRoles);
      setCompanyRoles(companyRoles);
      navigate('/settings/users');
    },
    onError: err => setError(err.message),
  });

  const [leave, { loading: leaveLoading }] = useMutation<LeaveCompanyResponse, LeaveCompanyVariables>(
    CompanyRoleAssignmentMutations.leave,
    {
      onCompleted: res => {
        signOut();
      },
      onError: err => setError(err.message),
    },
  );

  const handleDelete = async () => {
    try {
      if (!userCompanyRole) return;

      await remove({
        variables: {
          companyRoleAssignment: userCompanyRole.forDelete(),
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleLeave = async () => {
    try {
      if (!userCompanyRole) return;

      await leave({
        variables: {
          companyId: userCompanyRole.companyId,
          roleId: userCompanyRole.id,
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <DeleteModal
      open={open}
      error={error}
      onClose={handleClose}
      loading={removeLoading || leaveLoading}
      onConfirm={() => (currentUser?.userId === userCompanyRole?.userId ? handleLeave() : handleDelete())}
      text={
        currentUser?.userId === userCompanyRole?.userId
          ? t().verifyLeaveCompany.singular.upper
          : t().verifyRemoveUserFromCompany.singular.upper
      }
    />
  );
}
