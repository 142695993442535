import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { TableSettingsColumn } from './tableSettings';

import { t } from './translation/Translator';

export enum ProductTransactionColumn {
  createdAt = 'createdAt',
  createdBy = 'createdBy',
  processedAt = 'processedAt',
  processedBy = 'processedBy',
  updatedAt = 'updatedAt',
  updatedBy = 'updatedBy',
  quantity = 'quantity',
  processedQuantity = 'processedQuantity',
  status = 'status',
  type = 'type',
  productNumber = 'productNumber',
  productName = 'productName',
  sourceBin = 'sourceBin',
  destinationBin = 'destinationBin',
  sourceStockLocation = 'sourceStockLocation',
  destinationStockLocation = 'destinationStockLocation',
  lot = 'lot',
  serialNbr = 'serialNbr',
  lpn = 'lpn',
  countryOfOrigin = 'countryOfOrigin',
  destinationContainer = 'destinationContainer',
  sourceContainer = 'sourceContainer',
}

export enum ProductTransactionTableType {
  orderTransaction = 'orderTransaction',
}

export class ProductTransactionTableSettings extends CompanyEntity {
  columns: ProductTransactionTableColumn[] = defaultOrderTransactionTableColumns;
  type: ProductTransactionTableType = ProductTransactionTableType.orderTransaction;
  userId?: string;

  constructor(obj: any, type?: ProductTransactionTableType) {
    if (!obj.companyId) return;
    super(obj.companyId);
    if (type) this.type = type;
    Object.assign(this, cloneDeep(obj));
  }

  static possibleFieldsForType(type: ProductTransactionTableType) {
    switch (type) {
      case ProductTransactionTableType.orderTransaction:
        return new Set([
          ProductTransactionColumn.processedAt,
          ProductTransactionColumn.processedBy,
          ProductTransactionColumn.quantity,
          ProductTransactionColumn.processedQuantity,
          ProductTransactionColumn.status,
          ProductTransactionColumn.productNumber,
          ProductTransactionColumn.productName,
          ProductTransactionColumn.sourceBin,
          ProductTransactionColumn.destinationBin,
          ProductTransactionColumn.lot,
          ProductTransactionColumn.serialNbr,
          ProductTransactionColumn.lpn,
          ProductTransactionColumn.countryOfOrigin,
          ProductTransactionColumn.destinationContainer,
          ProductTransactionColumn.sourceContainer,
        ]);
      default:
        return new Set();
    }
  }

  forUpdate(): UpdateProductTransactionTableSettingsInput {
    return UpdateProductTransactionTableSettingsInput.from(this, UpdateProductTransactionTableSettingsInput);
  }

  forDelete(): DeleteProductTransactionTableSettingsInput {
    return DeleteProductTransactionTableSettingsInput.from(this, DeleteProductTransactionTableSettingsInput);
  }

  validate() {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof ProductTransactionTableSettings)[],
      (field: keyof ProductTransactionTableSettings) => {
        return null;
      },
    );
  }
}

export class CreateProductTransactionTableSettingsInput extends forCreate(ProductTransactionTableSettings) {}

export class UpdateProductTransactionTableSettingsInput extends forUpdate(ProductTransactionTableSettings) {}

export class DeleteProductTransactionTableSettingsInput extends forDelete(ProductTransactionTableSettings) {}

export class ProductTransactionTableColumn implements TableSettingsColumn<ProductTransactionColumn> {
  index: number;
  column: string;
  label: string;

  constructor(index: number, column: string, label: string) {
    this.index = index;
    this.column = column;
    this.label = label;
  }
}

export const defaultOrderTransactionTableColumns: ProductTransactionTableColumn[] = [
  {
    index: 0,
    column: ProductTransactionColumn.productName,
    label: 'Name',
  },
  {
    index: 1,
    column: ProductTransactionColumn.productNumber,
    label: 'Number',
  },
  {
    index: 2,
    column: ProductTransactionColumn.processedAt,
    label: 'Processed At',
  },
  {
    index: 3,
    column: ProductTransactionColumn.processedBy,
    label: 'Processed By',
  },
  {
    index: 4,
    column: ProductTransactionColumn.destinationContainer,
    label: 'Destination Container',
  },
  {
    index: 5,
    column: ProductTransactionColumn.sourceContainer,
    label: 'Source Container',
  },
  {
    index: 6,
    column: ProductTransactionColumn.quantity,
    label: 'Quantity',
  },
  {
    index: 7,
    column: ProductTransactionColumn.status,
    label: 'Status',
  },
];

export const defaultProductTransactionTableColumns: ProductTransactionTableColumn[] = [
  {
    index: 0,
    column: ProductTransactionColumn.processedBy,
    label: 'Processed By',
  },
  {
    index: 1,
    column: ProductTransactionColumn.processedAt,
    label: 'Processed At',
  },
  {
    index: 2,
    column: ProductTransactionColumn.productNumber,
    label: 'Product Number',
  },
  {
    index: 3,
    column: ProductTransactionColumn.quantity,
    label: 'Quantity',
  },
  {
    index: 4,
    column: ProductTransactionColumn.status,
    label: 'Status',
  },
];

export function productTransactionColumnToString(col: string): string {
  switch (col) {
    case ProductTransactionColumn.createdAt:
      return t().createdAt.singular.label;
    case ProductTransactionColumn.createdBy:
      return t().createdBy.singular.label;
    case ProductTransactionColumn.processedAt:
      return t().processedAt.singular.label;
    case ProductTransactionColumn.processedBy:
      return t().processedBy.singular.label;
    case ProductTransactionColumn.updatedAt:
      return t().updatedAt.singular.label;
    case ProductTransactionColumn.updatedBy:
      return t().updatedBy.singular.label;
    case ProductTransactionColumn.quantity:
      return t().quantity.singular.label;
    case ProductTransactionColumn.processedQuantity:
      return t().processQuantity.singular.label;
    case ProductTransactionColumn.status:
      return t().status.singular.label;
    case ProductTransactionColumn.type:
      return t().type.singular.label;
    case ProductTransactionColumn.productNumber:
      return t().productNumber.singular.label;
    case ProductTransactionColumn.productName:
      return t().productName.singular.label;
    case ProductTransactionColumn.sourceBin:
      return t().sourceBin.singular.label;
    case ProductTransactionColumn.destinationBin:
      return t().destinationBin.singular.label;
    case ProductTransactionColumn.sourceStockLocation:
      return t().sourceStockLocation.singular.label;
    case ProductTransactionColumn.destinationStockLocation:
      return t().destinationStockLocation.singular.label;
    case ProductTransactionColumn.lot:
      return t().lotNumber.singular.label;
    case ProductTransactionColumn.lpn:
      return t().lpn.singular.label;
    case ProductTransactionColumn.serialNbr:
      return t().serialNumber.singular.label;
    case ProductTransactionColumn.countryOfOrigin:
      return t().countryOfOrigin.singular.label;
    case ProductTransactionColumn.sourceContainer:
      return t().sourceContainer.singular.label;
    case ProductTransactionColumn.destinationContainer:
      return t().destinationContainer.singular.label;
  }
  return col;
}

export function stringToProductTransactionColumn(col: string): string {
  switch (col) {
    case t().createdAt.singular.label:
      return ProductTransactionColumn.createdAt;
    case t().createdBy.singular.label:
      return ProductTransactionColumn.createdBy;
    case t().processedAt.singular.label:
      return ProductTransactionColumn.processedAt;
    case t().processedBy.singular.label:
      return ProductTransactionColumn.processedBy;
    case t().updatedAt.singular.label:
      return ProductTransactionColumn.updatedAt;
    case t().updatedBy.singular.label:
      return ProductTransactionColumn.updatedBy;
    case t().quantity.singular.label:
      return ProductTransactionColumn.quantity;
    case t().processQuantity.singular.label:
      return ProductTransactionColumn.processedQuantity;
    case t().status.singular.label:
      return ProductTransactionColumn.status;
    case t().type.singular.label:
      return ProductTransactionColumn.type;
    case t().productNumber.singular.label:
      return ProductTransactionColumn.productNumber;
    case t().productName.singular.label:
      return ProductTransactionColumn.productName;
    case t().sourceBin.singular.label:
      return ProductTransactionColumn.sourceBin;
    case t().destinationBin.singular.label:
      return ProductTransactionColumn.destinationBin;
    case t().sourceStockLocation.singular.label:
      return ProductTransactionColumn.sourceStockLocation;
    case t().destinationStockLocation.singular.label:
      return ProductTransactionColumn.destinationStockLocation;
    case t().lotNumber.singular.label:
      return ProductTransactionColumn.lot;
    case t().lpn.singular.label:
      return ProductTransactionColumn.lpn;
    case t().serialNumber.singular.label:
      return ProductTransactionColumn.serialNbr;
    case t().countryOfOrigin.singular.label:
      return ProductTransactionColumn.countryOfOrigin;
    case t().destinationContainer.singular.label:
      return ProductTransactionColumn.destinationContainer;
    case t().sourceContainer.singular.label:
      return ProductTransactionColumn.sourceContainer;
  }
  return col;
}
