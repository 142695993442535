import React, { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { LotContext } from '../../../../../context/LotContext';
import InnerFilterContent, { FilterInnerContentProps } from '../../common/DropdownContent/DynamicFilterInnerContent';
import { Lot } from '../../../../../types/lot';

export default function LotFilterInnerContent({ filter, filterProps, setFilterProps }: FilterInnerContentProps<Lot>) {
  const { lots } = useContext(LotContext);

  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.lot || [])]}
      text={item => lots.get(item)?.number || t().unknownLot.singular.label}
      onRemove={i => {
        filterProps.lot?.delete(i);
        if (!filterProps.lot?.size) filterProps.lot = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
