import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { BinContext } from '../../../../../../../context/BinContext';
import { CompanyContext } from '../../../../../../../context/CompanyContext';
import { ContainerContext } from '../../../../../../../context/ContainerContext';
import { ContainerTypeContext } from '../../../../../../../context/ContainerTypeContext';
import { CustomFieldContext } from '../../../../../../../context/CustomFieldContext';
import {
  CreateContainerResponse,
  CreateContainerVariables,
  ContainerMutations,
} from '../../../../../../../graphql/container.graphql';
import { SystemBin } from '../../../../../../../types/bin';
import { Container } from '../../../../../../../types/container';
import { CustomFieldEntityType } from '../../../../../../../types/customField';
import { SystemStockLocation } from '../../../../../../../types/stockLocation';
import { t } from '../../../../../../../types/translation/Translator';
import { CancelButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import { CreateButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import DropdownSelect from '../../../../../../../VentoryUI/components/common/DropdownSelect/DropdownSelect';
import Modal from '../../../../../../../VentoryUI/components/common/Modal/Modal';
import { ModalPane } from '../../../../../../../VentoryUI/components/common/Modal/components';
import CustomFieldInput from '../../../../../Common/CustomFieldInput';
import TextInput from '../../../../../Common/TextInput';

interface CreateOrderContainerModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onCreated: (container: Container) => void;
}

export default function CreateOrderCreateContainerModal({ open, setOpen, onCreated }: CreateOrderContainerModalProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { bins } = useContext(BinContext);
  const { containerTypes } = useContext(ContainerTypeContext);
  const { containers, setContainers } = useContext(ContainerContext);
  const { customFields } = useContext(CustomFieldContext);

  const containerCustomFields = useMemo(() => {
    return [...customFields.values()].filter(cf => cf.entityType === CustomFieldEntityType.container);
  }, [customFields]);

  const [error, setError] = useState('');
  const [container, setContainer] = useState(
    new Container({ companyId: currentCompany.id, stockLocationId: SystemStockLocation.id, binId: SystemBin.id }),
  );

  const [create, { loading }] = useMutation<CreateContainerResponse, CreateContainerVariables>(
    ContainerMutations.create,
    {
      onCompleted: data => {
        data.createContainer.forEach(container => containers.set(container.id, new Container(container)));
        if (data.createContainer.length) onCreated(data.createContainer[0]);
        setContainers(new Map(containers));
        handleClose();
      },
      onError: error => {
        setError(error.message);
      },
    },
  );

  const handleClose = () => {
    setError('');
    setContainer(
      new Container({ companyId: currentCompany.id, stockLocationId: SystemStockLocation.id, binId: SystemBin.id }),
    );
    setOpen(false);
  };

  const handleCreate = async () => {
    await create({
      variables: { containers: [{ ...container, customFields: container.customFieldValues() as any } as any] },
    });
  };

  return (
    <Modal
      error={error}
      open={open}
      onClose={() => setOpen(false)}
      title={t().createContainer.singular.label}
      width='500px'
    >
      <ModalPane
        footerButtons={[
          CancelButtonTemplate(handleClose, { disabled: loading }),
          CreateButtonTemplate(handleCreate, { loading, disabled: !!container.validate(containerCustomFields).length }),
        ]}
      >
        <Grid container gap={2}>
          <Grid item xs={12}>
            <TextInput
              label={t().identifier.singular.label}
              placeholder={t().identifier.singular.label}
              value={container.identifier}
              onChange={value => setContainer(container.withIdentifier(value))}
            />
          </Grid>
          <Grid item xs={12}>
            <DropdownSelect
              selected={container.containerTypeId}
              values={new Set([...containerTypes.values()].map(ct => ct.id))}
              onSelect={id => setContainer(container.withContainerTypeId(id || ''))}
              placeholder={t().selectContainerType.singular.label}
              toText={id => containerTypes.get(id)?.name || t().unknownContainerType.singular.label}
            />
          </Grid>
          {containerCustomFields.length ? (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {containerCustomFields.map(cf => (
                  <Grid item xs={12}>
                    <CustomFieldInput
                      item={cf}
                      value={container.customFields.get(cf.id)?.value}
                      onChange={setContainer}
                      entity={container}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </ModalPane>
    </Modal>
  );
}
