import React from 'react';
import { ShippingLocation } from '../../../../../types/contact';
import { t } from '../../../../../types/translation/Translator';
import { Grid } from '@mui/material';
import ShippingLocationInfoPane from '../Panes/ShippingLocationInfoPane';
import { testIds } from '../../../../../util/identifiers/identifiers.util';

import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import Button from '../../../../../VentoryUI/components/common/Button';
import DeleteButton from '../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';

interface UpdateShippingLocationModalProps {
  open: boolean;
  setOpen: (value: boolean, shippingLocation?: ShippingLocation | null) => void;
  shippingLocation: ShippingLocation;
  disabled?: boolean;
}

export default function UpdateShippingLocationModal({
  open,
  setOpen,
  shippingLocation,
  disabled = false,
}: UpdateShippingLocationModalProps) {
  const footer = (loc: ShippingLocation) => {
    return (
      <Grid container>
        <Grid item>
          <DeleteButton onClick={() => setOpen(false, null)} />
        </Grid>
        <Grid item flexGrow={1}>
          <Grid container columnSpacing={1} justifyContent={'flex-end'}>
            <Grid item>
              <Button onClick={() => setOpen(false)} testId={testIds.cancel} text={t().cancel.singular.label} />
            </Grid>
            <Grid item>
              <Button
                disabled={!loc.name}
                onClick={() => setOpen(false, loc)}
                testId={testIds.save}
                style='secondary'
                text={t().save.singular.label}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal
      open={open}
      width='1000px'
      onClose={() => setOpen(false)}
      testId={testIds.updateShippingLocationModal}
      title={t().updateShippingLocation.singular.label}
    >
      <ShippingLocationInfoPane disabled={disabled} location={shippingLocation} footer={footer} />
    </Modal>
  );
}
