import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { t } from './translation/Translator';

export enum EntityType {
  lot = 'lot',
  bin = 'bin',
  pmd = 'pmd',
  serial = 'serial',
  task = 'task',
  order = 'order',
  product = 'product',
  stockLocation = 'stockLocation',
  container = 'container',
}

enum CommentValidationError {}

export class Comment extends CompanyEntity {
  entityId!: string;
  parentId?: string;
  type!: EntityType;
  content!: string;
  userId!: string;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  forUpdate(): UpdateCommentInput {
    return UpdateCommentInput.from(this, UpdateCommentInput);
  }

  forDelete(): DeleteCommentInput {
    return DeleteCommentInput.from(this, DeleteCommentInput);
  }

  validate() {
    return this.validateEntity(Object.getOwnPropertyNames(this) as (keyof Comment)[], (field: keyof Comment) => {
      return null;
    });
  }
}

export class CreateCommentInput extends forCreate(Comment) {}

export class UpdateCommentInput extends forUpdate(Comment) {}

export class DeleteCommentInput extends forDelete(Comment) {}

export function entityTypeToString(type: EntityType) {
  switch (type) {
    case EntityType.lot:
      return t().lot.singular.label;
    case EntityType.bin:
      return t().bin.singular.label;
    case EntityType.pmd:
      return t().productMasterData.singular.label;
    case EntityType.serial:
      return t().serial.singular.label;
    case EntityType.task:
      return t().task.singular.label;
    case EntityType.order:
      return t().order.singular.label;
    case EntityType.product:
      return t().product.singular.label;
    case EntityType.stockLocation:
      return t().stockLocation.singular.label;
    case EntityType.container:
      return t().container.singular.label;
  }
}
