import React, { useContext } from 'react';
import { CompanyRoleAssignment } from '../../../types/companyRoleAssignment';
import { Grid } from '@mui/material';
import { CompanyContext } from '../../../context/CompanyContext';
import { t } from '../../../types/translation/Translator';
import { useMutation } from '@apollo/client';
import {
  AcceptCompanyRoleAssignmentResponse,
  AcceptCompanyRoleAssignmentVariables,
  CompanyRoleAssignmentMutations,
  DeclineCompanyRoleAssignmentResponse,
  DeclineCompanyRoleAssignmentVariables,
} from '../../../graphql/companyRoleAssignment.graphql';
import { UserContext } from '../../../context/UserContext';

import Button from '../../../VentoryUI/components/common/Button';

interface AcceptCompanyInviteScreenProps {
  role: CompanyRoleAssignment;
}

export default function AcceptCompanyInviteScreen({ role }: AcceptCompanyInviteScreenProps) {
  const { companies } = useContext(CompanyContext);
  const { currentUser, signOut } = useContext(UserContext);

  if (!currentUser) return null;

  const [acceptInvite, { loading: acceptLoading }] = useMutation<
    AcceptCompanyRoleAssignmentResponse,
    AcceptCompanyRoleAssignmentVariables
  >(CompanyRoleAssignmentMutations.accept, {
    onCompleted: res => {
      window.location.reload();
    },
  });

  const [declineInvite, { loading: declineLoading }] = useMutation<
    DeclineCompanyRoleAssignmentResponse,
    DeclineCompanyRoleAssignmentVariables
  >(CompanyRoleAssignmentMutations.decline, {
    onCompleted: res => {
      signOut();
    },
  });

  const handleAccept = async () => {
    await acceptInvite({ variables: { roleId: role.id, companyId: role.companyId } });
  };

  const handleDecline = async () => {
    await declineInvite({ variables: { roleId: role.id, companyId: role.companyId } });
  };

  return (
    <Grid container direction='column' alignItems='center' justifyContent='center' sx={{ height: '100vh' }}>
      <Grid container paddingX={3} paddingY={2} justifyContent={'center'} rowSpacing={1} className='bg-white'>
        <Grid item xs={12} textAlign={'center'} justifyItems={'center'}>
          <p className='text-xl font-bold'>{"You've been invited to" + ` ${companies.get(role.companyId)?.name}`}</p>
        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={1} justifyContent={'center'}>
            <Grid item width={'150px'}>
              <Button
                disabled={acceptLoading}
                loading={declineLoading}
                onClick={handleDecline}
                text={t().decline.singular.label}
              />
            </Grid>
            <Grid item width={'150px'}>
              <Button
                disabled={declineLoading}
                loading={acceptLoading}
                style='secondary'
                onClick={handleAccept}
                text={t().accept.singular.label}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
