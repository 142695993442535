import React from 'react';
import { StringUtil } from '../../../../../util/string.util';
import InnerFilterContent, { FilterInnerContentProps } from '../../common/DropdownContent/DynamicFilterInnerContent';
import { FileTypeCategory } from '../../../../../types/file';

export default function FileTypeCategoryFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<FileTypeCategory>) {
  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.fileTypeCategory || [])]}
      text={item => StringUtil.upperCaseFirstCharacter(item)}
      onRemove={i => {
        filterProps.fileTypeCategory?.delete(i);
        if (!filterProps.fileTypeCategory?.size) filterProps.fileTypeCategory = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
