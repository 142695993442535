import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as VentoryWhiteLogo } from '../../../assets/img/Ventory-WhiteNoBack.svg';
import { t } from '../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import Agreements from '../../../assets/html/agreements';
import { useMutation } from '@apollo/client';
import {
  AcceptLicenseAgreementResponse,
  AcceptLicenseAgreementVariables,
  AcceptPrivacyPolicyResponse,
  AcceptPrivacyPolicyVariables,
  AcceptTermsOfUseResponse,
  AcceptTermsOfUseVariables,
  UserMutations,
} from '../../../graphql/user.graphql';
import { UserContext } from '../../../context/UserContext';
import { User } from '../../../types/user';
import PrivacyPolicy from '../../../assets/html/privacyPolicy';
import TermsOfUse from '../../../assets/html/termsOfUse';

import Button from '../../../VentoryUI/components/common/Button';

interface AcceptAgreementsScreenProps {}

enum AgreementPane {
  agreements,
  privacyPolicy,
  terms,
}

export default function AcceptAgreementsScreen({}: AcceptAgreementsScreenProps) {
  const navigate = useNavigate();

  const { currentUser, setCurrentUser } = useContext(UserContext);

  const determinePane = () => {
    if (!currentUser?.acceptedAgreements) {
      return AgreementPane.agreements;
    } else if (!currentUser?.acceptedPrivacyPolicy) {
      return AgreementPane.privacyPolicy;
    } else {
      return AgreementPane.terms;
    }
  };

  const [pane, setPane] = useState<AgreementPane>(determinePane());

  const [error, setError] = useState<string>('');

  const [acceptLicense, { loading: licenseLoading }] = useMutation<
    AcceptLicenseAgreementResponse,
    AcceptLicenseAgreementVariables
  >(UserMutations.acceptLicenseAgreement, {
    onCompleted: res => {
      setCurrentUser(new User(res.acceptLicenseAgreement));
    },
    onError: err => setError(err.message),
  });

  const [acceptPrivacyPolicy, { loading: privacyLoading }] = useMutation<
    AcceptPrivacyPolicyResponse,
    AcceptPrivacyPolicyVariables
  >(UserMutations.acceptPrivacyPolicy, {
    onCompleted: res => {
      setCurrentUser(new User(res.acceptPrivacyPolicy));
    },
    onError: err => setError(err.message),
  });

  const [acceptTermsOfUse, { loading: termsLoading }] = useMutation<
    AcceptTermsOfUseResponse,
    AcceptTermsOfUseVariables
  >(UserMutations.acceptTermsOfUse, {
    onCompleted: res => {
      setCurrentUser(new User(res.acceptTermsOfUse));
      navigate('/dashboard');
    },
    onError: err => setError(err.message),
  });

  const handleAccept = async () => {
    setError('');
    switch (pane) {
      case AgreementPane.agreements:
        return await acceptLicense();
      case AgreementPane.privacyPolicy:
        return await acceptPrivacyPolicy();
      case AgreementPane.terms:
        return await acceptTermsOfUse();
    }
  };

  const content = () => {
    switch (pane) {
      case AgreementPane.agreements:
        return Agreements();
      case AgreementPane.privacyPolicy:
        return PrivacyPolicy();
      case AgreementPane.terms:
        return TermsOfUse();
    }
  };

  const title = () => {
    switch (pane) {
      case AgreementPane.agreements:
        return t().licenseAgreement.singular.label;
      case AgreementPane.privacyPolicy:
        return t().privacyPolicy.singular.label;
      case AgreementPane.terms:
        return t().termsOfUse.singular.label;
    }
  };

  useEffect(() => {
    setPane(determinePane());
  }, [currentUser]);

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{ height: '100vh' }}
      className='bg-ventory-blue-900'
    >
      <Grid container width={'calc(100vw/8)'} mb={2}>
        <Grid item xs={12}>
          <VentoryWhiteLogo />
        </Grid>
      </Grid>

      <Grid
        width={'1200px'}
        container
        paddingX={3}
        paddingY={2}
        justifyContent={'center'}
        rowSpacing={1}
        className='bg-white'
      >
        <Grid item xs={12} textAlign={'center'} justifyItems={'center'}>
          <p className='text-2xl font-bold'>{title()}</p>
        </Grid>
        {error ? (
          <Grid textAlign={'center'} item xs={12} marginX={'auto'}>
            <p className='border-red-400 border-2 text-sm rounded-sm bg-red-100 py-1 text-red-500'>{error}</p>
          </Grid>
        ) : null}
        <Grid item xs={12} overflow={'auto'} maxHeight={'70vh'}>
          {content()}
        </Grid>
        <Grid item xs={12}>
          <Button
            style='secondary'
            loading={licenseLoading || privacyLoading || termsLoading}
            onClick={handleAccept}
            text={t().accept.singular.label}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
