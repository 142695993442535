import { cloneDeep } from '@apollo/client/utilities';
import { EntityInput, forCreate, forDelete, forUpdate } from '../common/entity';
import { Order, OrderType } from './order';
import { ShippingLocation } from '../contact';
import { CustomFieldValue } from '../customField';
import { ProductTransactionSpecifiers } from '../productTransaction';
import { OrderUtil } from './order.util';

export class OrderInput extends Order implements EntityInput<Order> {
  forCreate(): CreateOrderInput {
    delete (this as any).authorization;
    return {
      ...this,
      customFields: this.customFieldValues(),
      products: [...this.products.values()].map(p => {
        delete p.id;
        return { ...p, customFields: p.customFieldValues() };
      }),
    };
  }

  forUpdate(): UpdateOrderInput {
    delete (this as any).authorization;
    return {
      ...UpdateOrderInput.from(this, UpdateOrderInput),
      products: undefined,
      customFields: this.customFieldValues(),
    } as any;
  }

  forDelete(): DeleteOrderInput {
    delete (this as any).authorization;
    return DeleteOrderInput.from(this, DeleteOrderInput);
  }

  validate() {
    return this.validateEntity(Object.getOwnPropertyNames(this) as (keyof Order)[], (field: keyof Order) => {
      return null;
    });
  }

  withStockLocationId(stockLocationId: string | undefined) {
    this.products = new Map();
    return super.withStockLocationId(stockLocationId);
  }

  withType(type: OrderType) {
    this.type = type;
    this.products = new Map();
    return cloneDeep(this);
  }

  withExternalReferenceId(id: string) {
    this.externalReferenceId = id;
    return this;
  }

  withContactId(contactId?: string) {
    this.contactId = contactId;
    this.contactLocation = undefined;
    return cloneDeep(this);
  }

  withContactLocation(location?: ShippingLocation | null) {
    this.contactLocation = location || undefined;
    return cloneDeep(this);
  }

  withAssignedUsers(ids: string[]) {
    this.assignedTo = ids;
    return cloneDeep(this);
  }

  withEstimatedTimeOfArrival(eta?: Date) {
    this.estimatedTimeOfArrival = eta;
    return cloneDeep(this);
  }

  withProduct(pmdId: string, customFieldValue?: CustomFieldValue, toContainerId?: string) {
    const specifier = new ProductTransactionSpecifiers({
      pmdId: pmdId,
      id: pmdId,
      quantity: 1,
      toStockLocationId: this.type === OrderType.inbound ? this.stockLocationId : undefined,
      fromStockLocationId: this.type === OrderType.inbound ? undefined : this.stockLocationId,
      toContainerId,
      customFields: customFieldValue ? [customFieldValue] : [],
    });
    this.products.set(OrderUtil.orderSpecifierHash(specifier), specifier);
    return cloneDeep(this);
  }

  withContainer(containerId: string, customFieldValue?: CustomFieldValue) {
    const specifier = new ProductTransactionSpecifiers({
      containerId: containerId,
      id: containerId,
      quantity: 1,
      toStockLocationId: this.type === OrderType.inbound ? this.stockLocationId : undefined,
      fromStockLocationId: this.type === OrderType.inbound ? undefined : this.stockLocationId,
      customFields: customFieldValue ? [customFieldValue] : [],
    });
    this.products.set(OrderUtil.orderSpecifierHash(specifier), specifier);
    return cloneDeep(this);
  }

  withSpecifierCustomField(specifierHash: string, customField: CustomFieldValue) {
    const existing = this.products.get(specifierHash);
    if (!existing) return cloneDeep(this);

    this.products.set(specifierHash, existing.withCustomField(customField));
    return cloneDeep(this);
  }

  withPurchaseOrderNumber(purchaseOrderNumber?: string) {
    this.purchaseOrderNumber = purchaseOrderNumber;
    return cloneDeep(this);
  }

  removeProducts(hashes: Set<string>) {
    hashes.forEach(hash => this.products.delete(hash));
    return cloneDeep(this);
  }
}

export class CreateOrderInput extends forCreate(OrderInput) {}

export class UpdateOrderInput extends forUpdate(OrderInput) {}

export class DeleteOrderInput extends forDelete(OrderInput) {}
