import React from 'react';
import {
  Currency,
  LengthUnit,
  lengthUnitToString,
  ProductMasterData,
  WeightUnit,
  weightUnitToString,
} from '../../../../../types/productMasterData';
import { Grid } from '@mui/material';
import TextInput from '../../../Common/TextInput';
import { t } from '../../../../../types/translation/Translator';
import Dropdown from '../../../Common/Dropdown';

import Paper from '../../../../../VentoryUI/components/common/Paper';
import FlexPane from '../../../../../VentoryUI/components/common/FlexPane';

interface ProductMasterDataAttributePaneInputProps {
  footer: (input: ProductMasterData) => JSX.Element;
  productMasterDataInput: ProductMasterData;
  setProductMasterDataInput: (pmd: ProductMasterData) => void;
}

export default function ProductMasterDataAttributePane({
  footer,
  productMasterDataInput,
  setProductMasterDataInput,
}: ProductMasterDataAttributePaneInputProps) {
  return (
    <FlexPane
      content={
        <Paper>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {/* Column 1 */}
            <Grid item xs={4}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    value={String(productMasterDataInput.dimensions?.height || '')}
                    label={t().height.singular.label}
                    placeholder={t().height.singular.label}
                    onChange={v => productMasterDataInput.withHeight(v)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    value={String(productMasterDataInput.dimensions?.width || '')}
                    label={t().width.singular.label}
                    placeholder={t().width.singular.label}
                    onChange={v => productMasterDataInput.withWidth(v)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    value={String(productMasterDataInput.dimensions?.depth || '')}
                    label={t().depth.singular.label}
                    placeholder={t().depth.singular.label}
                    onChange={v => productMasterDataInput.withDepth(v)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    maxHeight='200px'
                    label={t().unit.singular.label}
                    values={[...Object.keys(LengthUnit)] as LengthUnit[]}
                    selectedValue={productMasterDataInput.dimensions?.unit || LengthUnit.meter}
                    toText={item => lengthUnitToString(item)}
                    onChange={item => setProductMasterDataInput(productMasterDataInput.withLengthUnit(item))}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Column 2 */}
            <Grid item xs={4}>
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={6}>
                  <TextInput
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    value={String(productMasterDataInput.netWeight?.value || '')}
                    label={t().netWeight.singular.label}
                    placeholder={t().netWeight.singular.label}
                    onChange={v => productMasterDataInput.withNetWeightValue(v)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    maxHeight='200px'
                    label={t().unit.singular.label}
                    values={[...Object.keys(WeightUnit)] as WeightUnit[]}
                    selectedValue={productMasterDataInput.netWeight?.unit || WeightUnit.gram}
                    toText={item => weightUnitToString(item)}
                    onChange={item => setProductMasterDataInput(productMasterDataInput.withNetWeightUnit(item))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    value={String(productMasterDataInput.grossWeight?.value || '')}
                    label={t().grossWeight.singular.label}
                    placeholder={t().grossWeight.singular.label}
                    onChange={v => productMasterDataInput.withGrossWeightValue(v)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    maxHeight='200px'
                    label={t().unit.singular.label}
                    values={[...Object.keys(WeightUnit)] as WeightUnit[]}
                    selectedValue={productMasterDataInput.grossWeight?.unit || WeightUnit.gram}
                    toText={item => weightUnitToString(item)}
                    onChange={item => setProductMasterDataInput(productMasterDataInput.withGrossWeightUnit(item))}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Column 3 */}
            <Grid item xs={4}>
              <Grid container rowSpacing={1} columnSpacing={1}>
                <Grid item xs={6}>
                  <TextInput
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    value={String(productMasterDataInput.purchasePrice?.value || '')}
                    label={t().purchasePrice.singular.label}
                    placeholder={t().purchasePrice.singular.label}
                    onChange={v => productMasterDataInput.withPurchasePriceValue(v)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    maxHeight='200px'
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    label={t().currency.singular.label}
                    values={[...Object.keys(Currency)] as Currency[]}
                    selectedValue={productMasterDataInput.purchasePrice?.currency || Currency.EUR}
                    toText={item => String(item).charAt(0).toUpperCase() + String(item).slice(1)}
                    onChange={item => setProductMasterDataInput(productMasterDataInput.withPurchasePriceCurrency(item))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    value={String(productMasterDataInput.sellingPrice?.value || '')}
                    label={t().sellingPrice.singular.label}
                    placeholder={t().sellingPrice.singular.label}
                    onChange={v => productMasterDataInput.withSellingPriceValue(v)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    maxHeight='200px'
                    label={t().currency.singular.label}
                    values={[...Object.keys(Currency)] as Currency[]}
                    selectedValue={productMasterDataInput.sellingPrice?.currency || Currency.EUR}
                    toText={item => String(item).charAt(0).toUpperCase() + String(item).slice(1)}
                    onChange={item => setProductMasterDataInput(productMasterDataInput.withSellingPriceCurrency(item))}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      }
      footer={
        <Grid container>
          <Grid item xs={12} alignContent={'flex-end'}>
            {footer(productMasterDataInput)}
          </Grid>
        </Grid>
      }
    />
  );
}
