import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { StaticTimeFilterType } from './StaticTimeFilterType';
import { FilterProps } from '../common/filter.util';

import { CompanyContext } from '../../../../context/CompanyContext';
import { Colors } from '../../../util/color.util';

interface StaticTimeFilterProps {
  filter: FilterProps;
  setFilter: (filter: FilterProps) => void;
}

export function StaticTimeFilter({ filter, setFilter }: StaticTimeFilterProps) {
  const { currentCompany } = useContext(CompanyContext);
  const color = Colors.getPrimaryColor(currentCompany);

  const items = StaticTimeFilterType.keys();

  const handleClick = (item: string) => {
    filter.staticTimeFrame = item;
    filter.timeFrame = StaticTimeFilterType.getTimeFrame(item);
    setFilter({ ...filter });
  };

  const backgroundColor = (item: string) => {
    if (filter.staticTimeFrame === item) {
      return Colors.companyLightBackground(currentCompany);
    }
    return '#FFFFFF';
  };

  const textColor = (item: string) => {
    if (filter.staticTimeFrame === item) {
      return Colors.companyDarkText(currentCompany);
    }

    return '#697586';
  };

  const borderColor = (item: string) => {
    if (filter.staticTimeFrame === item) return backgroundColor(item);
    return '#F2F4F7';
  };

  const borderClass = (idx: number) => {
    if (idx === 0) return 'border-ventory-grey-100 border-l border-t border-b';
    if (idx === items.length - 1) return 'border-ventory-grey-100 border-r border-t border-b';
    return 'border-t border-b';
  };

  return (
    <div className='h-[28px] mb-2 w-fit'>
      <Grid container className='h-full'>
        {items.map((item, idx) => {
          return (
            <Grid
              key={item}
              item
              onClick={() => handleClick(item)}
              bgcolor={backgroundColor(item)}
              borderColor={borderColor(item)}
              className={`cursor-pointer h-full my-auto flex ${borderClass(idx)}`}
            >
              <p
                style={{ color: textColor(item) }}
                className='px-3 font-[500] text-[13px] cursor-pointer select-none my-auto'
              >
                {StaticTimeFilterType.toLocalizedString(item)}
              </p>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
