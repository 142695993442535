import React from 'react';
import { StockLocation } from '../../../../types/stockLocation';
import { TagRelation } from '../../../../types/tagRelation';
import { EntityType } from '../../../../types/comment';
import { t } from '../../../../types/translation/Translator';
import { LocationMarkerIcon } from '../../../icons/LocationMarker/LocationMarkerIcon';
import { toFilterString } from '../../../../util/string.util';

import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { FilterProps, FilterStorageKey } from '../common/filter.util';
import { AddressFilter } from '../AddressFilter';
import { TagFilter } from '../TagFilter';
import { StockLocationFilterInnerContent, StockLocationFilterDropdownContent } from './DropdownContent';

export class StockLocationFilter extends BaseFilter<StockLocation> {
  tagRelations: Map<string, TagRelation>;

  constructor(tagRelations: Map<string, TagRelation>) {
    super(FilterStorageKey.STOCK_LOCATION);
    this.tagRelations = tagRelations;
  }

  filterOptions(): BaseFilter<any>[] {
    return [new TagFilter()];
  }

  toLabel(): string {
    return t().stockLocation.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.stockLocation?.size || 0;
  }

  toIcon(): JSX.Element {
    return <LocationMarkerIcon className={this.className} />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <StockLocationFilterDropdownContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <StockLocationFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  search(item: StockLocation, query: string) {
    if (
      toFilterString(item.name).includes(query) ||
      toFilterString(item.identifier).includes(query) ||
      (item.address && new AddressFilter().search(item.address, query))
    ) {
      return true;
    }

    return false;
  }

  filteredItems(items: StockLocation[], query: string, filterProps: FilterProps) {
    const filteredStockLocations = TagFilter.filterByTag(this.tagRelations, EntityType.stockLocation, filterProps);

    if (this.tagRelations && filteredStockLocations.size === 0 && filterProps.stockLocation?.size) return [];

    const result = items.filter(item => {
      if (filteredStockLocations.size && !filteredStockLocations.has(item.id)) {
        return false;
      }

      return this.search(item, query);
    });

    return result;
  }
}
