import React from 'react';
import { ScanConfiguration } from '../../../../types/company';
import { toFilterString } from '../../../../util/string.util';
import { BaseFilter } from '../common/BaseFilter';
import { t } from '../../../../types/translation/Translator';

export class ScanConfigurationFilter extends BaseFilter<ScanConfiguration> {
  toLabel(): string {
    return t().scan.singular.label;
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <></>;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  search(item: ScanConfiguration, textFilter: string) {
    if (
      toFilterString(item.barcodeFormat).includes(textFilter) ||
      toFilterString(item.regex).includes(textFilter) ||
      toFilterString(item.type).includes(textFilter)
    ) {
      return true;
    }

    return false;
  }
}
