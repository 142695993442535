import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProductOverviewPane from './Product/ProductOverviewPane';
import ProductHistoryOverviewPane from './History/ProductHistoryOverviewPane';
import { CompanyContext } from '../../../context/CompanyContext';
import BinOverviewPane from './Bin/BinOverviewPane';
import { StockLocationContext } from '../../../context/StockLocationContext';
import { StockLocationRoleAssignmentContext } from '../../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../../types/stockLocationRoleAssignment';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { t } from '../../../types/translation/Translator';
import Pane from '../../../VentoryUI/components/common/Pane';
import ContainerOverviewPane from './Container/ContainerOverviewPane';

export default function StockScreen() {
  const { currentCompany } = useContext(CompanyContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { stockLocations } = useContext(StockLocationContext);

  const tabs = [
    {
      text: t().product.plural.label,
      path: '/stock/products',
      key: 'products',
    },
    {
      text: t().bin.plural.label,
      path: '/stock/bins',
      key: 'bins',
    },
    {
      text: t().container.plural.label,
      path: '/stock/containers',
      key: 'containers',
    },
    {
      text: t().history.singular.label,
      path: '/stock/history',
      key: 'history',
    },
  ];

  const filteredTabs = tabs.filter(item => {
    switch (item.key) {
      case 'history':
        if (!currentCompany.settings.featureToggles.productTransactions.web) return false;
        if (
          ![...stockLocations.values()].filter(sl =>
            hasStockLocationRole(currentCompany.id, sl.id, StockLocationRole.STOCK_LOCATION_MANAGER),
          ).length
        )
          return false;
        break;
      case 'containers':
        if (!currentCompany.settings.featureToggles.containers.containers) return false;
        break;
    }

    return true;
  });

  return (
    <Pane tabs={filteredTabs} testId={testIds.stockScreen}>
      <Routes>
        <Route path='products' element={<ProductOverviewPane />} />
        <Route path='bins' element={<BinOverviewPane />} />
        {currentCompany.settings.featureToggles.containers.containers ? (
          <Route path='containers' element={<ContainerOverviewPane />} />
        ) : null}
        <Route path='history' element={<ProductHistoryOverviewPane />} />
      </Routes>
    </Pane>
  );
}
