import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { OrderContext } from '../../../../../context/OrderContext';
import { t } from '../../../../../types/translation/Translator';
import LoadingPackage from '../../../Common/LoadingPackage';
import OrderInfoPane from './OrderInfoPane';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import OrderDetailPane from './OrderDetailPane';
import { useMutation } from '@apollo/client';
import {
  UpdateOrderResponse,
  UpdateOrderVariables,
  OrderMutations,
  CompleteOrderResponse,
  CompleteOrderVariables,
  CancelOrderResponse,
  CancelOrderVariables,
} from '../../../../../graphql/order.graphql';
import ValidationModal from '../../../Common/ValidationModal';
import AddOrderProductsModal from '../Modals/AddOrderProductsModal';
import AssignOrderUserModal from '../Modals/AssignOrderUserModal';
import OrderUserPane from './OrderUserPane';
import Pane from '../../../../../VentoryUI/components/common/Pane';
import UpdateOrderPaneFooter from './Footer/UpdateOrderPaneFooter';
import { Order, OrderStatus, OrderType, OrderInput } from '../../../../../types/order';
import { ProductTransactionContext } from '../../../../../context/ProductTransactionContext';
import { ProductTransaction, ProductTransactionStatus } from '../../../../../types/productTransaction';

export default function UpdateOrderPane() {
  const navigate = useNavigate();

  const id = useParams()['id'] || '';
  if (!id) return null; // TODO: Entity not found

  const { orders, setOrders, ordersLoading } = useContext(OrderContext);
  const [orderInput, setOrderInput] = useState<OrderInput | undefined>(undefined);
  const { productTransactions } = useContext(ProductTransactionContext);

  const [openAddProductModal, setOpenAddProductModal] = useState<boolean>(false);
  const [openAssignOrderUserModal, setOpenAssignOrderUserModal] = useState<boolean>(false);
  const [openCompleteModal, setOpenCompleteModal] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);

  const [error, setError] = useState<string>('');

  const [update, { loading: updateLoading }] = useMutation<UpdateOrderResponse, UpdateOrderVariables>(
    OrderMutations.update,
    {
      onCompleted: res => {
        const o = res.updateOrder[0];
        orders.set(o.id, new Order(o));
        setOrders(new Map(orders));
        navigate('/operations/orders');
      },
    },
  );

  const [complete, { loading: completeLoading }] = useMutation<CompleteOrderResponse, CompleteOrderVariables>(
    OrderMutations.completeOrder,
    {
      onCompleted: res => {
        const o = res.completeOrder;
        orders.set(o.id, new Order(o));
        setOrders(new Map(orders));
        navigate('/operations/orders');
      },
    },
  );

  const [cancel, { loading: cancelLoading }] = useMutation<CancelOrderResponse, CancelOrderVariables>(
    OrderMutations.cancelOrder,
    {
      onCompleted: res => {
        orders.set(res.cancelOrder.id, new Order(res.cancelOrder));
        setOrders(new Map(orders));
        navigate('/operations/orders');
      },
    },
  );

  const handleUpdate = async () => {
    try {
      const input = orderInput?.forUpdate();
      if (!input) return;

      await update({
        variables: {
          orders: [input],
        },
      });
    } catch (e: any) {
      setError(String(e.message));
    }
  };

  const handleCancel = async () => {
    if (!orderInput) return;

    try {
      await cancel({
        variables: {
          orderId: orderInput.id,
          companyId: orderInput.companyId,
        },
      });
    } catch (e: any) {
      setError(String(e.message));
    }
  };

  const handleComplete = async () => {
    if (!orderInput) return;

    try {
      await complete({
        variables: {
          orderId: orderInput.id,
          companyId: orderInput.companyId,
        },
      });
    } catch (e: any) {
      setError(String(e.message));
    }
  };

  const completeText =
    orderInput?.externalIdentifier?.settingsId === '001' && orderInput.type === OrderType.outbound
      ? orderInput?.status === OrderStatus.pickingComplete
        ? t().issueGoods.singular.label
        : t().pickingComplete.singular.label
      : t().completeOrder.singular.label;

  const footer = (canUpdate: boolean = false) => {
    if (!orderInput) return <></>;

    const hasOpenTransactions = [...productTransactions.values()].filter(
      (pt: ProductTransaction) => pt.parentId === orderInput.id && pt.status === ProductTransactionStatus.created,
    ).length;

    return (
      <UpdateOrderPaneFooter
        order={orderInput}
        loading={{
          cancel: cancelLoading,
          update: updateLoading,
          complete: completeLoading,
        }}
        actions={{
          onBack: () => navigate('/operations/orders'),
          onComplete: () => (!hasOpenTransactions ? handleComplete() : setOpenCompleteModal(true)),
          onCancel: () => setOpenCancelModal(true),
          onAssigned: () => setOpenAssignOrderUserModal(true),
          onAddProduct: () => setOpenAddProductModal(true),
          onUpdate: () => handleUpdate(),
        }}
      />
    );
  };

  const tabs = [
    {
      text: t().order.singular.label,
      path: `detail`,
      key: 'detail',
    },
    {
      text: t().user.plural.label,
      path: 'users',
      key: 'users',
    },
    {
      text: t().info.singular.label,
      path: `info`,
      key: 'info',
    },
  ];

  useEffect(() => {
    if (orders.has(id) && !orderInput) {
      setOrderInput(new OrderInput(orders.get(id)));
    }
  }, [ordersLoading]);

  const content =
    (ordersLoading && !orders.get(id)) || !orderInput ? (
      <div className='h-full grid justify-center items-center'>
        <LoadingPackage />
      </div>
    ) : (
      <>
        <Routes>
          <Route
            path='detail'
            element={<OrderDetailPane order={orderInput} setOrder={setOrderInput} footer={footer} />}
          />
          <Route path='users' element={<OrderUserPane order={orderInput} setOrder={setOrderInput} footer={footer} />} />
          <Route path='info' element={<OrderInfoPane order={orderInput} setOrder={setOrderInput} footer={footer} />} />
          <Route path='/' element={<Navigate to='detail' replace={true} />} />
        </Routes>
        <AddOrderProductsModal open={openAddProductModal} setOpen={v => setOpenAddProductModal(v)} order={orderInput} />
        <AssignOrderUserModal
          open={openAssignOrderUserModal}
          setOpen={v => setOpenAssignOrderUserModal(v)}
          order={orderInput}
          setOrder={setOrderInput}
        />
        <ValidationModal
          open={openCompleteModal}
          setOpen={setOpenCompleteModal}
          onYes={() => handleComplete()}
          onNo={() => setOpenCompleteModal(false)}
          loading={completeLoading}
          title={completeText}
          content={t().completeOrderValidation.singular.upper}
          subContent={'This will cancel all transactions that have not been processed'}
        />
        <ValidationModal
          open={openCancelModal}
          setOpen={setOpenCancelModal}
          onYes={() => handleCancel()}
          onNo={() => setOpenCancelModal(false)}
          loading={cancelLoading}
          title={t().cancelOrder.singular.upper}
          content={t().cancelOrderValidation.singular.upper}
          subContent={'This will cancel all transactions that have not been processed'}
        />
      </>
    );

  return (
    <Pane tabs={tabs} testId={testIds.updateOrderPane} error={error}>
      {content}
    </Pane>
  );
}
