import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function PackageIcon({ className, height = 16, width = 16 }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        fill='none'
        stroke='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M27.3333 9.70369L16 16.0001M16 16.0001L4.66663 9.70369M16 16.0001V28.6667M28 21.4114V10.5886C28 10.1318 28 9.90335 27.9327 9.69961C27.8732 9.51937 27.7757 9.35393 27.6472 9.21435C27.5017 9.05656 27.3021 8.94563 26.9027 8.72376L17.036 3.24228C16.6579 3.0322 16.4688 2.92716 16.2687 2.88597C16.0913 2.84953 15.9087 2.84953 15.7315 2.88597C15.5312 2.92716 15.3421 3.0322 14.964 3.24228L5.09729 8.72376C4.69793 8.94563 4.49825 9.05656 4.35284 9.21435C4.22421 9.35393 4.12687 9.51937 4.06731 9.69961C4 9.90335 4 10.1318 4 10.5886V21.4114C4 21.8683 4 22.0967 4.06731 22.3005C4.12687 22.4807 4.22421 22.6462 4.35284 22.7857C4.49825 22.9435 4.69793 23.0545 5.09729 23.2763L14.964 28.7578C15.3421 28.9679 15.5312 29.0729 15.7315 29.1141C15.9087 29.1506 16.0913 29.1506 16.2687 29.1141C16.4688 29.0729 16.6579 28.9679 17.036 28.7578L26.9027 23.2763C27.3021 23.0545 27.5017 22.9435 27.6472 22.7857C27.7757 22.6462 27.8732 22.4807 27.9327 22.3005C28 22.0967 28 21.8683 28 21.4114Z'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M22 12.6667L10 6' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </Icon>
  );
}
