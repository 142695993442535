import React from 'react';

interface ErrorBoxProps {
  warning?: string;
  onClick?: () => void;
}

export default function WarningBox({ warning, onClick }: ErrorBoxProps) {
  if (!warning) return null;

  return (
    <div className={`text-center mb-2 ${onClick ? 'cursor-pointer' : ''}`} onClick={e => (onClick ? onClick() : null)}>
      <p className='border-yellow-400 border-2 rounded-sm bg-yellow-100 py-1 text-yellow-500 text-sm'>{warning}</p>
    </div>
  );
}
