import React from 'react';
import { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { BinContext } from '../../../../../context/BinContext';
import InnerFilterContent, { FilterInnerContentProps } from '../../common/DropdownContent/DynamicFilterInnerContent';
import { Bin } from '../../../../../types/bin';

export default function BinFilterInnerContent({ filter, filterProps, setFilterProps }: FilterInnerContentProps<Bin>) {
  const { bins } = useContext(BinContext);

  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.bin || [])]}
      text={item => bins.get(item)?.name || t().unknownBin.singular.label}
      onRemove={i => {
        filterProps.bin?.delete(i);
        if (!filterProps.bin?.size) filterProps.bin = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
