import { useMutation } from '@apollo/client';
import React, { useContext, useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CompanyContext } from '../../../../../../../context/CompanyContext';
import { CustomFieldContext } from '../../../../../../../context/CustomFieldContext';
import { OrderTableSettingsContext } from '../../../../../../../context/OrderTableSettingsContext';
import {
  CreateOrderTableSettingsResponse,
  CreateOrderTableSettingsVariables,
  OrderTableSettingsMutations,
  UpdateOrderTableSettingsResponse,
  UpdateOrderTableSettingsVariables,
} from '../../../../../../../graphql/orderTableSettings.graphql';
import { CustomFieldEntityType } from '../../../../../../../types/customField';
import {
  OrderTableSettings,
  defaultOrderTableColumn,
  OrderColumn,
  orderColumnToString,
  OrderTableColumn,
  stringToOrderColumn,
} from '../../../../../../../types/orderTableSettings';
import { DraggableList } from '../../../../../Common/DraggableList';
import DropdownSelect from '../../../../../Common/DropdownSelect';
import LoadingPackage from '../../../../../Common/LoadingPackage';
import TextInput from '../../../../../Common/TextInput';
import { DraggableItemData } from '../../../../../Common/DraggableItem';
import { t } from '../../../../../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ForwardIcon from '@mui/icons-material/Forward';
import { Grid } from '@mui/material';

import BackButton from '../../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import FlexPane from '../../../../../../../VentoryUI/components/common/FlexPane';
import Paper from '../../../../../../../VentoryUI/components/common/Paper';

interface DraggableOrderTableField extends DraggableItemData, OrderTableColumn {}

interface OrderTableSettingsViewProps {
  setError: (error: string) => void;
  offset?: number;
}

export function OrderTableSettingsView({ setError, offset = 64 }: OrderTableSettingsViewProps) {
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { customFields } = useContext(CustomFieldContext);
  const { orderTableSettings, setOrderTableSettings, orderTableSettingsLoading } =
    useContext(OrderTableSettingsContext);

  const [input, setInput] = useState<OrderTableSettings>(
    new OrderTableSettings({ companyId: currentCompany.id, columns: defaultOrderTableColumn }),
  );
  const [items, setItems] = useState<DraggableOrderTableField[]>([]);

  const dropdownItems = [
    ...Object.keys(OrderColumn)
      .filter(i => items.findIndex(item => item.column === i) === -1)
      .map(k => orderColumnToString(k as OrderColumn)),
    ...[...customFields.values()]
      .filter(
        i => i.entityType === CustomFieldEntityType.order && items.findIndex(item => item.column === i.name) === -1,
      )
      .map(i => i.name),
  ];

  const handleDelete = (id: number) => {
    const index = items.findIndex(item => item.id === id);
    if (index === -1) return;
    items.splice(index, 1);
    setItems([...items]);
  };

  const handleAdd = (item: DraggableOrderTableField) => {
    setItems([...items, item]);
  };

  const [create, { loading: createLoading }] = useMutation<
    CreateOrderTableSettingsResponse,
    CreateOrderTableSettingsVariables
  >(OrderTableSettingsMutations.create, {
    onCompleted: res => {
      const setting = res.createOrderTableSettings[0];
      orderTableSettings.set(setting.id, new OrderTableSettings(setting));
      setOrderTableSettings(new Map(orderTableSettings));
      navigate('/operations/orders');
    },
    onError: err => setError(err.message),
  });

  const [update, { loading: updateLoading }] = useMutation<
    UpdateOrderTableSettingsResponse,
    UpdateOrderTableSettingsVariables
  >(OrderTableSettingsMutations.update, {
    onCompleted: res => {
      const setting = res.updateOrderTableSettings[0];
      orderTableSettings.set(setting.id, new OrderTableSettings(setting));
      setOrderTableSettings(new Map(orderTableSettings));
      navigate('/operations/orders');
    },
    onError: err => setError(err.message),
  });

  useEffect(() => {
    if (orderTableSettings.size) {
      const settings = [...orderTableSettings.values()][0];
      setInput(new OrderTableSettings(settings));
      setItems(
        settings.columns.map((col, id) => {
          return { ...col, key: `${col.index}${col.label}`, id };
        }),
      );
    } else {
      setItems(
        input.columns.map((col, id) => {
          return { ...col, key: `${col.index}${col.label}`, id };
        }),
      );
    }
  }, [orderTableSettings]);

  const handleSave = async () => {
    try {
      input.columns = items.map((i, idx) => new OrderTableColumn(idx, i.column, i.label));

      if (!input.id) {
        return await create({
          variables: {
            orderTableSettings: [input],
          },
        });
      }
      return await update({
        variables: {
          orderTableSettings: [input.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  if (orderTableSettingsLoading) {
    return (
      <Grid container height={'100%'} alignContent={'center'}>
        <Grid item className='fill-gray-300 ' marginX={'auto'}>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  const orderReportFieldItem = (item: DraggableOrderTableField) => {
    return (
      <Grid key={`parent-${item.id}`} container display='flex' marginY='auto' columnSpacing={2} alignItems={'center'}>
        <Grid item>
          <ImportExportIcon />
        </Grid>
        <Grid item xs={3}>
          <p style={{ marginBottom: '0px' }}>{orderColumnToString(item.column)}</p>
        </Grid>
        <Grid item>
          <ForwardIcon />
        </Grid>
        <Grid item flexGrow={1}>
          <TextInput
            disabled={updateLoading || createLoading}
            dynamicUpdate
            draggable={true}
            onDragStart={event => event.preventDefault()}
            value={item.label}
            onChange={v => {
              item.label = v;
              setItems([...items]);
            }}
            placeholder={orderColumnToString(item.column)}
          />
        </Grid>
        <Grid item style={{ cursor: 'pointer' }}>
          <DeleteOutlineIcon color='error' onClick={() => handleDelete(item.id)} />
        </Grid>
      </Grid>
    );
  };

  return (
    <FlexPane
      content={
        <Paper>
          <FlexPane
            header={
              <DropdownSelect
                selectOnly
                label={t().addOrderColumn.singular.upper}
                values={dropdownItems}
                disabled={items.length > 12 || !dropdownItems.length || createLoading || updateLoading}
                selectedValue={null}
                toText={item => item || 'Add'}
                onChange={item => {
                  if (!item) return;
                  handleAdd({
                    label: item,
                    column: stringToOrderColumn(item) as OrderColumn,
                    id: items.length,
                    index: items.length,
                    key: `${stringToOrderColumn(item)}${items.length}`,
                  });
                }}
                placeholder={t().addOrderColumn.singular.upper}
              />
            }
            content={
              <div className='overflow-auto max-h-full'>
                <DndProvider backend={HTML5Backend}>
                  <DraggableList displayFn={orderReportFieldItem} items={items} setItems={setItems} />
                </DndProvider>
              </div>
            }
          />
          <Grid container columnSpacing={1}>
            <Grid item xs={6} mb={2}></Grid>
            <Grid item xs={12}>
              <Grid item xs={12} xl={6} maxHeight={'calc(100vh - 400px)'} overflow={'auto'}></Grid>
            </Grid>
          </Grid>
        </Paper>
      }
      footer={
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <BackButton disabled={createLoading || updateLoading} onClick={() => navigate('/operations/orders')} />
          </Grid>
          <Grid item>
            <SaveButton onClick={handleSave} loading={createLoading || updateLoading} />
          </Grid>
        </Grid>
      }
    />
  );
}
