import { Grid } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { CompanyContext } from '../../../../context/CompanyContext';
import { ChevronDownIcon } from '../../../icons/ChevronDown/ChevronDownIcon';
import CloseIcon from '../../../icons/Close/CloseIcon';
import { Colors } from '../../../util/color.util';
import { DropdownButton } from '../DropdownButton/DropdownButton';

interface DropdownSelectProps {
  selected?: string;
  values: Set<string>;
  toText: (item: string) => string | React.ReactNode;
  onSelect: (item?: string) => void;
  placeholder: string;
  disabled?: boolean;
}

export default function DropdownSelect({
  selected,
  values,
  toText,
  onSelect,
  placeholder,
  disabled = false,
}: DropdownSelectProps) {
  return (
    <DropdownButton
      width='100%'
      disabled={disabled || !!selected}
      content={selected ? toText(selected) : placeholder}
      endIcon={
        selected ? (
          <CloseIcon height={12} width={12} onClick={() => (!disabled ? onSelect(undefined) : null)} />
        ) : (
          <ChevronDownIcon />
        )
      }
      startIcon={null}
      dropdownContent={open =>
        selected ? (
          <></>
        ) : (
          <Grid container className='p-2'>
            {[...values].map((value, index) => (
              <DropdownItem key={index} value={value} toText={toText} onSelect={onSelect} />
            ))}
          </Grid>
        )
      }
    />
  );
}

interface DropdownItemProps {
  value: string;
  toText: (item: string) => string | React.ReactNode;
  onSelect: (item: string) => void;
}

function DropdownItem({ value, toText, onSelect }: DropdownItemProps) {
  const { currentCompany } = useContext(CompanyContext);

  const [hovered, setHovered] = useState(false);

  const style = useMemo(() => {
    if (hovered) {
      return {
        backgroundColor: Colors.companyLightBackground(currentCompany),
        color: Colors.companyDarkText(currentCompany),
      };
    }
  }, [hovered]);

  return (
    <Grid
      item
      xs={12}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => onSelect(value)}
      style={style}
      className='cursor-pointer px-2 py-1 flex items-center mb-1 rounded-3'
    >
      {toText(value)}
    </Grid>
  );
}
