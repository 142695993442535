import React from 'react';
import InnerFilterContent, { FilterInnerContentProps } from '../../common/DropdownContent/DynamicFilterInnerContent';

export default function FileTypeFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<string>) {
  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.fileType || [])]}
      text={item => item}
      onRemove={i => {
        filterProps.fileType?.delete(i);
        if (!filterProps.fileType?.size) filterProps.fileType = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
