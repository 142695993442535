import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { t } from '../../../../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { CompanyContext } from '../../../../../../context/CompanyContext';
import { OrderSettings } from '../../../../../../types/orderSettings';
import { useMutation, useQuery } from '@apollo/client';
import {
  CreateOrderSettingsResponse,
  CreateOrderSettingsVariables,
  GetOrderSettingsResponse,
  GetOrderSettingsVariables,
  OrderSettingsMutations,
  OrderSettingsQueries,
  UpdateOrderSettingsResponse,
  UpdateOrderSettingsVariables,
} from '../../../../../../graphql/orderSettings.graphql';
import LoadingPackage from '../../../../Common/LoadingPackage';
import TextInput from '../../../../Common/TextInput';
import { OrderType } from '../../../../../../types/order/order';
import Checkbox from '../../../../../../VentoryUI/components/common/Checkbox/Checkbox';

import BackButton from '../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Paper from '../../../../../../VentoryUI/components/common/Paper';
import FlexPane from '../../../../../../VentoryUI/components/common/FlexPane';

interface OrderSettingsPaneProps {
  setError: (error: string) => void;
}

export default function OrderSettingsPane({ setError }: OrderSettingsPaneProps) {
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);

  const [inboundOrderSettingsInput, setInboundOrderSettingsInput] = useState<OrderSettings>(
    new OrderSettings({ companyId: currentCompany.id, type: OrderType.inbound }),
  );
  const [outboundOrderSettingsInput, setOutboundOrderSettingsInput] = useState<OrderSettings>(
    new OrderSettings({ companyId: currentCompany.id, type: OrderType.outbound }),
  );

  const [create, { loading: createLoading }] = useMutation<CreateOrderSettingsResponse, CreateOrderSettingsVariables>(
    OrderSettingsMutations.create,
    {
      onCompleted: res => navigate('/operations/orders'),
      onError: err => setError(err.message),
    },
  );

  const [update, { loading: updateLoading }] = useMutation<UpdateOrderSettingsResponse, UpdateOrderSettingsVariables>(
    OrderSettingsMutations.update,
    {
      onCompleted: res => navigate('/operations/orders'),
      onError: err => setError(err.message),
    },
  );

  const { loading } = useQuery<GetOrderSettingsResponse, GetOrderSettingsVariables>(OrderSettingsQueries.get, {
    variables: {
      companyId: currentCompany.id,
    },
    onCompleted: res => {
      const inbound = res.orderSettings.data.find(item => item.type === OrderType.inbound);
      const outbound = res.orderSettings.data.find(item => item.type === OrderType.outbound);

      if (inbound) setInboundOrderSettingsInput(new OrderSettings(inbound));
      if (outbound) setOutboundOrderSettingsInput(new OrderSettings(outbound));
    },
    onError: res => setError(res.message),
  });

  const handleSave = async () => {
    try {
      if (!inboundOrderSettingsInput.id) {
        return await create({
          variables: {
            orderSettings: [inboundOrderSettingsInput, outboundOrderSettingsInput],
          },
        });
      }
      return await update({
        variables: {
          orderSettings: [inboundOrderSettingsInput.forUpdate(), outboundOrderSettingsInput.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  if (loading) {
    return (
      <Paper>
        <Grid container height={'100%'} alignContent={'center'}>
          <Grid item className='fill-gray-300 ' marginX={'auto'}>
            <LoadingPackage />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <FlexPane
      overflow='visible'
      content={
        <Paper overflow='auto'>
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <p className='text-base font-semibold'>{t().inboundOrder.plural.label}</p>
                </Grid>
                <Grid item xs={12}>
                  <p className='underline'>{t().orderNumbers.singular.label}</p>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    dynamicUpdate
                    value={inboundOrderSettingsInput.prefix}
                    label={t().prefix.singular.label}
                    placeholder={t().prefix.singular.label}
                    onChange={v => setInboundOrderSettingsInput(inboundOrderSettingsInput.withPrefix(v))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    dynamicUpdate
                    value={inboundOrderSettingsInput.suffix}
                    label={t().suffix.singular.label}
                    placeholder={t().suffix.singular.label}
                    onChange={v => setInboundOrderSettingsInput(inboundOrderSettingsInput.withSuffix(v))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    type='number'
                    mandatory
                    dynamicUpdate
                    value={inboundOrderSettingsInput.digits?.toString() || ''}
                    label={t().nrOfDigits.singular.label}
                    placeholder={t().nrOfDigits.singular.label}
                    min={3}
                    onChange={v => setInboundOrderSettingsInput(inboundOrderSettingsInput.withDigits(v))}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <p className='underline'>{t().additionalSettings.singular.label}</p>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label={t().sendPushNotificationToAssignees.singular.label}
                    onChange={value =>
                      setInboundOrderSettingsInput(inboundOrderSettingsInput.withSendPushNotificationToAssignees(value))
                    }
                    value={inboundOrderSettingsInput.sendPushNotificationToAssignees}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <p className='text-base font-semibold'>{t().outboundOrder.plural.label}</p>
                </Grid>
                <Grid item xs={12}>
                  <p className='underline'>{t().orderNumbers.singular.label}</p>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    dynamicUpdate
                    value={outboundOrderSettingsInput.prefix}
                    label={t().prefix.singular.label}
                    placeholder={t().prefix.singular.label}
                    onChange={v => setOutboundOrderSettingsInput(outboundOrderSettingsInput.withPrefix(v))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    dynamicUpdate
                    value={outboundOrderSettingsInput.suffix}
                    label={t().suffix.singular.label}
                    placeholder={t().suffix.singular.label}
                    onChange={v => setOutboundOrderSettingsInput(outboundOrderSettingsInput.withSuffix(v))}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    type='number'
                    mandatory
                    dynamicUpdate
                    value={outboundOrderSettingsInput.digits?.toString() || ''}
                    label={t().nrOfDigits.singular.label}
                    placeholder={t().nrOfDigits.singular.label}
                    min={3}
                    onChange={v => setOutboundOrderSettingsInput(outboundOrderSettingsInput.withDigits(v))}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <p className='underline'>{t().additionalSettings.singular.label}</p>
                </Grid>
                <Grid item xs={12}>
                  <Checkbox
                    label={t().sendPushNotificationToAssignees.singular.upper}
                    onChange={value =>
                      setOutboundOrderSettingsInput(
                        outboundOrderSettingsInput.withSendPushNotificationToAssignees(value),
                      )
                    }
                    value={outboundOrderSettingsInput.sendPushNotificationToAssignees}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      }
      footer={
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <BackButton disabled={createLoading || updateLoading} onClick={() => navigate('/operations/orders')} />
          </Grid>
          <Grid item>
            <SaveButton onClick={handleSave} loading={createLoading || updateLoading} />
          </Grid>
        </Grid>
      }
    />
  );
}
