import { CompanyRole } from '../companyRoleAssignment';
import { StockLocationRole } from '../stockLocationRoleAssignment';
import { StockLocationEntity } from './entity';

export class EntityAuthorization<T extends StockLocationEntity> {
  forCreate = (userId: string): CompanyRole | StockLocationRole => StockLocationRole.STOCK_LOCATION_VIEWER;
  forUpdate = (userId: string): CompanyRole | StockLocationRole => StockLocationRole.STOCK_LOCATION_VIEWER;
  forDelete = (userId: string): CompanyRole | StockLocationRole => StockLocationRole.STOCK_LOCATION_VIEWER;
  forGet = (userId: string): CompanyRole | StockLocationRole => StockLocationRole.STOCK_LOCATION_VIEWER;
}
