import React from 'react';
import { JSX } from 'react';
import { BinStatus } from '../../../../types/binStatus';
import { t } from '../../../../types/translation/Translator';
import { BinStatusFilterDropdownContent, BinStatusFilterInnerContent } from './DropdownContent';
import { BinIcon } from '../../../icons/Bin/BinIcon';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { toFilterString } from '../../../../util/string.util';

export class BinStatusFilter extends BaseFilter<BinStatus> {
  toLabel(): string {
    return t().binStatus.singular.label;
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <BinIcon />;
  }

  search(item: BinStatus, textFilter: string) {
    if (toFilterString(item.status).includes(textFilter)) {
      return true;
    }

    return false;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <BinStatusFilterDropdownContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <BinStatusFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }
}
