import { gql } from '@apollo/client';
import {
  ContainerType,
  CreateContainerTypeInput,
  DeleteContainerTypeInput,
  UpdateContainerTypeInput,
} from '../types/containerType';
import { IdArray } from '../types/common/entity';
import { forPaginated } from './common/paginated.graphql';

const containerType = gql`
  fragment ContainerType on ContainerType {
    id
    version
    companyId
    name
  }
`;

const get = gql`
  query ContainerTypes($companyId: String!, $page: Float) {
    containerTypes(companyId: $companyId, page: $page) {
      data {
        ...ContainerType
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${containerType}
`;

export interface GetContainerTypesVariables {
  companyId: string;
  page?: number;
}

export interface GetContainerTypesResponse {
  containerTypes: PaginatedContainerTypes;
}

class PaginatedContainerTypes extends forPaginated<ContainerType>() {}

const create = gql`
  mutation CreateContainerType($containerTypes: [CreateContainerTypeInput!]!) {
    createContainerType(containerTypes: $containerTypes) {
      ...ContainerType
    }
  }
  ${containerType}
`;

export interface CreateContainerTypeVariables {
  containerTypes: CreateContainerTypeInput[];
}

export interface CreateContainerTypeResponse {
  createContainerType: ContainerType[];
}

const update = gql`
  mutation UpdateContainerType($containerTypes: [UpdateContainerTypeInput!]!) {
    updateContainerType(containerTypes: $containerTypes) {
      ...ContainerType
    }
  }
  ${containerType}
`;

export interface UpdateContainerTypeVariables {
  containerTypes: UpdateContainerTypeInput[];
}

export interface UpdateContainerTypeResponse {
  updateContainerType: ContainerType[];
}

const remove = gql`
  mutation DeleteContainerType($containerTypes: [DeleteContainerTypeInput!]!) {
    deleteContainerType(containerTypes: $containerTypes) {
      ...ContainerType
    }
  }
  ${containerType}
`;

export interface DeleteContainerTypeVariables {
  containerTypes: DeleteContainerTypeInput[];
}

export interface DeleteContainerTypeResponse {
  deleteContainerType: IdArray;
}

export const ContainerTypeQueries = {
  get,
};

export const ContainerTypeMutations = {
  create,
  update,
  remove,
};

export const ContainerTypeFragment = {
  containerType,
};
