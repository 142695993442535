import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import apolloClient from '../../apollo';
import Agreements from '../../assets/html/agreements';
import NotFound from '../../assets/html/notFound';
import PrivacyPolicy from '../../assets/html/privacyPolicy';
import TermsOfUse from '../../assets/html/termsOfUse';
import App from './App';
import LoadingScreen from './Common/LoadingScreen';

export default function MainRouter() {
  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <ApolloProvider client={apolloClient}>
        <Router>
          <Routes>
            <Route path='/static/privacy_policy' element={<PrivacyPolicy />} />
            <Route path='/static/terms' element={<TermsOfUse />} />
            <Route path='/static/agreements' element={<Agreements />} />
            <Route path='/static/*' element={<NotFound />} />
            <Route path='/*' element={<App />} />
          </Routes>
        </Router>
      </ApolloProvider>
    </React.Suspense>
  );
}
