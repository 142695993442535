import React from 'react';
import MainRouter from './ui/pages/MainRouter';
import './index.css';
import { createRoot } from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material';

const container = document.getElementById('root');
const root = createRoot(container!);

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 1280,
      md: 1360,
      lg: 1920,
      xl: 2560,
    },
  },
});

const appId = 'dG9rOmI1ZmMxNjcwXzAzNTZfNDcyNl85NzY4X2Y0MGNkOTBlMzNmMToxOjA=';

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MainRouter />
    </ThemeProvider>
  </React.StrictMode>,
);
