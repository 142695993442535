import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../../context/CompanyContext';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CustomerQueries, GetCustomersResponse, GetCustomersVariables } from '../../../../graphql/customer.graphql';
import { Company } from '../../../../types/company';
import { toMap } from '../../../../util/map.util';
import LoadingPackage from '../../Common/LoadingPackage';
import CompanySelectFrame from '../../Common/CompanySelectFrame';
import { removeDiacritics, toFilterString } from '../../../../util/string.util';
import SearchBar from '../../../../VentoryUI/components/common/SearchBar';
import Paper from '../../../../VentoryUI/components/common/Paper';
import FlexPane from '../../../../VentoryUI/components/common/FlexPane';
import { VentorySubscriptionState, VentorySubscriptionType } from '../../../../types/billing';

export default function AdministratorCustomerPane() {
  const navigate = useNavigate();
  const { refreshCompany } = useContext(CompanyContext);

  const [customers, setCustomers] = useState<Map<string, Company>>(new Map());
  const [filter, setFilter] = useState<string>('');

  const { loading } = useQuery<GetCustomersResponse, GetCustomersVariables>(CustomerQueries.get, {
    onCompleted: res => {
      setCustomers(new Map(toMap(res.customers.data, 'id')));
    },
  });

  const handledCustomers = new Set();

  const activeCustomers = () => {
    return [...customers.values()]
      .filter(
        c =>
          c.status?.state === VentorySubscriptionState.active &&
          c.status?.type !== VentorySubscriptionType.demo &&
          c.status?.type !== VentorySubscriptionType.trial &&
          c.status?.type !== VentorySubscriptionType.poc &&
          removeDiacritics(toFilterString(c.name)).includes(filter),
      )
      .map(item => {
        handledCustomers.add(item.id);

        return (
          <Grid item>
            <CompanySelectFrame
              key={item.id}
              showBorderColor={true}
              company={item}
              onClick={async () => {
                await refreshCompany(item.id);
                navigate('/dashboard');
              }}
            />
          </Grid>
        );
      });
  };

  const pocCustomers = () => {
    return [...customers.values()]
      .filter(
        c =>
          c.status?.state === VentorySubscriptionState.active &&
          c.status?.type === VentorySubscriptionType.poc &&
          removeDiacritics(toFilterString(c.name)).includes(filter),
      )
      .map(item => {
        handledCustomers.add(item.id);

        return (
          <Grid item>
            <CompanySelectFrame
              key={item.id}
              showBorderColor={true}
              company={item}
              onClick={async () => {
                await refreshCompany(item.id);
                navigate('/dashboard');
              }}
            />
          </Grid>
        );
      });
  };

  const demoCustomers = () => {
    return [...customers.values()]
      .filter(
        c =>
          c.status?.state === VentorySubscriptionState.active &&
          c.status?.type === VentorySubscriptionType.demo &&
          removeDiacritics(toFilterString(c.name)).includes(filter),
      )
      .map(item => {
        handledCustomers.add(item.id);

        return (
          <Grid item>
            <CompanySelectFrame
              key={item.id}
              showBorderColor={true}
              company={item}
              onClick={async () => {
                await refreshCompany(item.id);
                navigate('/dashboard');
              }}
            />
          </Grid>
        );
      });
  };

  const expiredTrialCustomers = () => {
    return [...customers.values()]
      .filter(
        c =>
          c.status?.state === VentorySubscriptionState.expired &&
          c.status?.type === VentorySubscriptionType.trial &&
          removeDiacritics(toFilterString(c.name)).includes(filter),
      )
      .map(item => {
        handledCustomers.add(item.id);

        return (
          <Grid item>
            <CompanySelectFrame
              key={item.id}
              showBorderColor={true}
              company={item}
              onClick={async () => {
                await refreshCompany(item.id);
                navigate('/dashboard');
              }}
            />
          </Grid>
        );
      });
  };

  const otherCustomers = () => {
    return [...customers.values()]
      .filter(c => removeDiacritics(toFilterString(c.name)).includes(filter) && !handledCustomers.has(c.id))
      .map(item => (
        <Grid item>
          <CompanySelectFrame
            key={item.id}
            showBorderColor={true}
            company={item}
            onClick={async () => {
              await refreshCompany(item.id);
              navigate('/dashboard');
            }}
          />
        </Grid>
      ));
  };

  if (loading) {
    return (
      <Paper>
        <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
          <Grid item className='fill-gray-300'>
            <LoadingPackage />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <FlexPane
      wrapContent={false}
      header={
        <Grid container>
          <Grid item flexGrow={1}>
            <SearchBar placeholder='Filter Customers' onChange={v => setFilter(v)} />
          </Grid>
        </Grid>
      }
      content={
        <Paper overflow='auto'>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <p className='font-bold p-1'>{'Active customers'}</p>
                </Grid>
                <Grid item xs={12} flexDirection={'column'}>
                  <Grid container>{activeCustomers()}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <p className='font-bold p-1'>{'PoC customers'}</p>
                </Grid>
                <Grid item xs={12} flexDirection={'column'}>
                  <Grid container>{pocCustomers()}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <p className='font-bold p-1'>{'Trial customers (expired)'}</p>
                </Grid>
                <Grid item xs={12} flexDirection={'column'}>
                  <Grid container>{expiredTrialCustomers()}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <p className='font-bold p-1'>{'Demo customers'}</p>
                </Grid>
                <Grid item xs={12} flexDirection={'column'}>
                  <Grid container>{demoCustomers()}</Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <p className='font-bold p-1'>{'Other customers'}</p>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>{otherCustomers()}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      }
    />
  );
}
