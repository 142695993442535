import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';

export class ContainerType extends CompanyEntity {
  name!: string;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  forUpdate(): UpdateContainerTypeInput {
    this.validate();
    return UpdateContainerTypeInput.from(this, UpdateContainerTypeInput);
  }

  forDelete(): DeleteContainerTypeInput {
    this.validate();
    return DeleteContainerTypeInput.from(this, DeleteContainerTypeInput);
  }

  validate() {}

  withName(name: string) {
    this.name = name;
    return cloneDeep(this);
  }
}

export class CreateContainerTypeInput extends forCreate(ContainerType) {}

export class UpdateContainerTypeInput extends forUpdate(ContainerType) {}

export class DeleteContainerTypeInput extends forDelete(ContainerType) {}
