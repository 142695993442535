import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../../../../../context/CompanyContext';
import { ContainerTypeContext } from '../../../../../../../context/ContainerTypeContext';
import {
  ContainerTypeMutations,
  CreateContainerTypeResponse,
  CreateContainerTypeVariables,
} from '../../../../../../../graphql/containerType.graphql';
import { ContainerType } from '../../../../../../../types/containerType';
import { t } from '../../../../../../../types/translation/Translator';
import { CancelButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import { CreateButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import Modal from '../../../../../../../VentoryUI/components/common/Modal/Modal';
import { ModalPane } from '../../../../../../../VentoryUI/components/common/Modal/components';
import ContainerTypeItem from './ContainerTypeItem';

interface CreateContainerTypeModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function CreateContainerTypeModal({ open, setOpen }: CreateContainerTypeModalProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { containerTypes, setContainerTypes } = useContext(ContainerTypeContext);

  const [error, setError] = useState('');
  const [containerType, setContainerType] = useState(new ContainerType({ companyId: currentCompany.companyId }));

  const [create, { loading }] = useMutation<CreateContainerTypeResponse, CreateContainerTypeVariables>(
    ContainerTypeMutations.create,
    {
      variables: { containerTypes: [containerType] },
      onCompleted: data => {
        data.createContainerType.forEach(ct => containerTypes.set(ct.id, new ContainerType(ct)));
        setContainerTypes(new Map(containerTypes));
        setOpen(false);
      },
      onError: error => {
        setError(error.graphQLErrors[0]?.message);
      },
    },
  );

  const handleCreate = () => {
    create();
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      title={t().createContainerType.singular.upper}
      error={error}
      width='500px'
    >
      <ModalPane
        footerButtons={[
          {
            ...CancelButtonTemplate(() => {
              setError('');
              setContainerType(new ContainerType({ companyId: currentCompany.companyId }));
              setOpen(false);
            }),
            disabled: loading,
          },
          { ...CreateButtonTemplate(handleCreate), loading },
        ]}
      >
        <ContainerTypeItem containerType={containerType} setContainerType={setContainerType} />
      </ModalPane>
    </Modal>
  );
}
