import dayjs from 'dayjs';
import { EntityTimeFrame } from '../common/filter.util';
import { t } from '../../../../types/translation/Translator';

export namespace StaticTimeFilterType {
  export const MAX = 'MAX';
  export const YEAR = 'YEAR';
  export const QUARTER = 'QUARTER';
  export const MONTH = 'MONTH';
  export const CURRENT_MONTH = 'CURRENT_MONTH';
  export const WEEK = 'WEEK';

  export function keys() {
    return [MAX, CURRENT_MONTH, YEAR, QUARTER, MONTH, WEEK];
  }

  export function toString(item: string): string {
    switch (item) {
      case MAX:
        return 'Max';
      case YEAR:
        return '12 Months';
      case QUARTER:
        return '3 Months';
      case MONTH:
        return '30 Days';
      case CURRENT_MONTH:
        return 'Current month';
      case WEEK:
        return '7 Days';
      default:
        return '';
    }
  }

  export function toLocalizedString(item: string): string {
    switch (item) {
      case MAX:
        return t().max.singular.label;
      case YEAR:
        return t().twelveMonths.singular.label;
      case QUARTER:
        return t().threeMonths.singular.label;
      case MONTH:
        return t().thirtyDays.singular.label;
      case CURRENT_MONTH:
        return t().currentMonth.singular.label;
      case WEEK:
        return t().sevenDays.singular.label;
      default:
        return '';
    }
  }

  export function getTimeFrame(item: string): EntityTimeFrame {
    switch (item) {
      case MAX:
        return { start: dayjs(0).toDate(), end: dayjs().startOf('day').toDate() };
      case YEAR:
        return {
          start: dayjs().subtract(1, 'year').endOf('month').add(1, 'day').startOf('day').toDate(),
          end: dayjs().endOf('day').toDate(),
        };
      case QUARTER:
        return {
          start: dayjs().subtract(3, 'months').endOf('month').add(1, 'day').startOf('day').toDate(),
          end: dayjs().endOf('day').toDate(),
        };
      case MONTH:
        return {
          start: dayjs().subtract(29, 'days').startOf('day').toDate(),
          end: dayjs().endOf('day').toDate(),
        };
      case WEEK:
        return {
          start: dayjs().subtract(6, 'days').startOf('day').toDate(),
          end: dayjs().endOf('day').toDate(),
        };
      case CURRENT_MONTH:
        return {
          start: dayjs().startOf('month').toDate(),
          end: dayjs().endOf('day').toDate(),
        };
    }
    return { start: dayjs().toDate(), end: dayjs().toDate() };
  }
}
