import React, { useContext } from 'react';
import { ReorderRule } from '../../../../../types/reorderRule';
import { Grid } from '@mui/material';
import { CompanyRole, companyRoleToString } from '../../../../../types/companyRoleAssignment';
import { StockLocationRole, stockLocationRoleToString } from '../../../../../types/stockLocationRoleAssignment';
import Selector from '../../Selector';
import { t } from '../../../../../types/translation/Translator';
import { UserContext } from '../../../../../context/UserContext';

import Paper from '../../../../../VentoryUI/components/common/Paper';

interface ReorderRuleUserPaneProps {
  rule: ReorderRule;
  setRule: (rule: ReorderRule) => void;
}

export default function ReorderRuleUserPane({ rule, setRule }: ReorderRuleUserPaneProps) {
  const { companyUsers } = useContext(UserContext);

  return (
    <Paper>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <p className='text-sm'>{t().selectCompanyRolesThatShouldReceiveNotification.singular.upper}</p>
            <Selector
              showSearch={false}
              checkedValues={rule.notificationCompanyRoles}
              values={Object.values(CompanyRole)}
              onChange={value => setRule(rule.withNotificationCompanyRoles(value))}
              toText={value => companyRoleToString(value)}
            />
          </Grid>
          {rule.notificationCompanyRoles.includes(CompanyRole.employee) ? (
            <Grid item xs={12}>
              <p className='text-sm'>{t().selectStockLocationRolesThatShouldReceiveNotification.singular.upper}</p>
              <Selector
                showSearch={false}
                checkedValues={rule.notificationStockLocationRoles}
                values={Object.values(StockLocationRole)}
                onChange={value => setRule(rule.withNotificationStockLocationRoles(value))}
                toText={value => stockLocationRoleToString(value)}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <p className='text-sm mb-1'>{t().selectUsersThatShouldReceiveANotification.singular.upper}</p>
              <Selector
                placeholder={t().filterUsers.singular.label}
                values={[...companyUsers.values()].map(cu => cu.userId)}
                checkedValues={rule.notificationUserIds}
                filterFn={(item, filter) => companyUsers.get(item)?.filter(filter) || false}
                toText={item => companyUsers.get(item)?.email || 'Unknown user'}
                onChange={checked => setRule(rule.withNotificationUsers(checked))}
                toElement={item => (
                  <Grid container columnSpacing={1}>
                    <Grid item xs={12}>
                      <p className='text-normal font-normal'>{`${companyUsers.get(item)?.firstName} ${
                        companyUsers.get(item)?.lastName
                      }`}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <p className='text-sm text-gray-400'>{`${companyUsers.get(item)?.email}`}</p>
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
