import { VentoryColor } from '../../VentoryUI/util/color.util';
import { CustomFieldEntitySubType } from '../customField';
import { ProductTransactionSpecifiers } from '../productTransaction';
import { t } from '../translation/Translator';
import { OrderStatus, OrderType } from './order';

export namespace OrderUtil {
  export function orderSpecifierHash(specifier: ProductTransactionSpecifiers) {
    return orderSpecifierHashByValue(specifier.pmdId, specifier.containerId || specifier.toContainerId);
  }

  export function orderSpecifierHashByValue(pmdId?: string, containerId?: string) {
    return `${pmdId || ''}|${containerId || ''}`;
  }

  export const typeToString = (type: OrderType) => {
    switch (type) {
      case OrderType.inbound:
        return t().inbound.singular.label || 'Inbound';
      case OrderType.outbound:
        return t().outbound.singular.label || 'Outbound';
      case OrderType.replenish:
        return t().replenish.singular.label || 'Replenish';
      case OrderType.move:
        return t().move.singular.label || 'Move';
    }
  };

  export const typeToEntitySubType = (type: OrderType): CustomFieldEntitySubType => {
    switch (type) {
      case OrderType.inbound:
        return CustomFieldEntitySubType.inbound;
      case OrderType.outbound:
        return CustomFieldEntitySubType.outbound;
      case OrderType.replenish:
        return CustomFieldEntitySubType.replenish;
      case OrderType.move:
        return CustomFieldEntitySubType.move;
    }
  };

  export const statusToString = (status: OrderStatus): string => {
    switch (status) {
      case OrderStatus.open:
        return t().open.singular.label;
      case OrderStatus.created:
        return t().created.singular.label;
      case OrderStatus.inProgress:
        return t().inProgress.singular.label;
      case OrderStatus.complete:
        return t().completed.singular.label;
      case OrderStatus.shipped:
        return t().shipped.singular.label;
      case OrderStatus.arrived:
        return t().arrived.singular.label;
      case OrderStatus.cancelled:
        return t().cancelled.singular.label;
      case OrderStatus.failed:
        return t().failed.singular.label;
      case OrderStatus.released:
        return t().released.singular.label;
      case OrderStatus.disabled:
        return t().disabled.singular.label;
      case OrderStatus.pickingComplete:
        return t().pickingComplete.singular.label;
    }
  };

  export const statusToColorStyle = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.open:
      case OrderStatus.created:
        return {
          color: VentoryColor.green700,
          backgroundColor: VentoryColor.green50,
        };
      case OrderStatus.released:
        return {
          color: VentoryColor.blue700,
          backgroundColor: VentoryColor.blue50,
        };
      case OrderStatus.inProgress:
        return {
          color: VentoryColor.yellow700,
          backgroundColor: VentoryColor.yellow50,
        };
      case OrderStatus.complete:
        return {
          color: VentoryColor.green500,
          backgroundColor: VentoryColor.green50,
        };
      case OrderStatus.cancelled:
        return {
          color: VentoryColor.red700,
          backgroundColor: VentoryColor.red50,
        };
      case OrderStatus.disabled:
        return {
          color: VentoryColor.grey700,
          backgroundColor: VentoryColor.grey50,
        };
      case OrderStatus.pickingComplete:
        return {
          color: VentoryColor.blue700,
          backgroundColor: VentoryColor.blue50,
        };
      default:
        return {
          color: VentoryColor.grey700,
          backgroundColor: VentoryColor.grey50,
        };
    }
  };
}
