import { Grid } from '@mui/material';
import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import FileViewLoader from '../FileViewLoader';
import ModalPane from '../../Modal/components/ModalPane';
import WarningIcon from '@mui/icons-material/Warning';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { t } from '../../../../../types/translation/Translator';

interface ImagePickerModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  ids: string[];
  onSelected: (selected: string) => void;
}

export default function ImagePickerModal({ open, setOpen, ids, onSelected }: ImagePickerModalProps) {
  const [selected, setSelected] = useState('');

  const handleConfirm = () => {
    onSelected(selected);
    setOpen(false);
    setSelected('');
  };

  const style = (id: string) => {
    if (id === selected) {
      return {
        border: '1px solid blue',
      };
    }
  };

  return (
    <Modal
      title={'Select an Image'}
      onClose={() => setOpen(false)}
      open={open}
      width='1000px'
      height='calc(90vh - 100px)'
    >
      {!ids.length ? (
        <div className='h-full w-full flex flex-col items-center justify-center' data-testid={testIds.noItemsFound}>
          <WarningIcon sx={{ fontSize: '50px' }} className='text-gray-300' />
          <p className='select-none font-semibold text-gray-300 text-xl'>{t().noItemsFound.singular.label}</p>
        </div>
      ) : (
        <ModalPane
          footerButtons={[{ text: t().select.singular.label, onClick: handleConfirm, style: 'secondary' }]}
          overflow='auto'
        >
          <Grid container spacing={1}>
            {ids.map(id => (
              <Grid
                key={id}
                item
                xs={6}
                onClick={() => setSelected(id)}
                height={'200px'}
                width={'300px'}
                display='flex'
                alignItems={'center'}
                justifyContent={'center'}
                style={style(id)}
                className='cursor-pointer'
              >
                <FileViewLoader className={'p-2'} fileId={id} />
              </Grid>
            ))}
          </Grid>
        </ModalPane>
      )}
    </Modal>
  );
}
