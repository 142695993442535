import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import ProductMasterDataInfoPane from './Panes/ProductMasterDataInfoPane';
import { ProductMasterData } from '../../../../types/productMasterData';
import { CompanyContext } from '../../../../context/CompanyContext';
import { useMutation } from '@apollo/client';
import {
  CreateProductMasterDataResponse,
  CreateProductMasterDataVariables,
  ProductMasterDataMutations,
} from '../../../../graphql/productMasterData.graphql';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import { testIds } from '../../../../util/identifiers/identifiers.util';

import CreateButton from '../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import BackButton from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import Pane from '../../../../VentoryUI/components/common/Pane';

export default function CreateProductMasterDataPane({}) {
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { productMasterData, setProductMasterData } = useContext(ProductMasterDataContext);

  const [error, setError] = useState<string>('');
  const [productMasterDataInput, setProductMasterDataInput] = useState(
    new ProductMasterData({ companyId: currentCompany.id }),
  );

  const [create, { loading }] = useMutation<CreateProductMasterDataResponse, CreateProductMasterDataVariables>(
    ProductMasterDataMutations.create,
    {
      onCompleted: res => {
        const pmd = res.createProductMasterData[0];
        setProductMasterData(new Map(productMasterData).set(pmd.id, new ProductMasterData(pmd)));
        navigate('/reference_data/products');
      },
      onError: res => setError(res.message),
    },
  );

  const handleCreate = async (pmd: ProductMasterData) => {
    try {
      const pmdInput = pmd.forCreate();

      await create({
        variables: {
          productMasterData: [pmdInput],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const footer = (pmd: ProductMasterData) => (
    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <BackButton disabled={loading} onClick={() => navigate('/reference_data/products')} />
      </Grid>
      <Grid item>
        <CreateButton
          loading={loading}
          onClick={() => handleCreate(pmd)}
          disabled={!productMasterDataInput.productName || !productMasterDataInput.productNumber}
        />
      </Grid>
    </Grid>
  );

  return (
    <Pane error={error} testId={testIds.createProductMasterDataPane}>
      <ProductMasterDataInfoPane
        footer={footer}
        setError={setError}
        title={t().createProductMasterData.singular.label}
        productMasterDataInput={productMasterDataInput}
        setProductMasterDataInput={setProductMasterDataInput}
      />
    </Pane>
  );
}
