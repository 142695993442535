import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CompanyContext } from '../../../../../../../../context/CompanyContext';
import { ProductExportConfigurationContext } from '../../../../../../../../context/ProductExportConfigurationContext';
import {
  ProductExportConfiguration,
  ProductExportConfigurationMapping,
} from '../../../../../../../../types/productExportConfiguration';
import {
  CreateProductExportConfigurationResponse,
  CreateProductExportConfigurationVariables,
  ProductExportConfigurationMutations,
} from '../../../../../../../../graphql/productExportConfiguration.graphql';
import ProductExportConfigurationInput, {
  DraggableProductExportFieldIdentifier,
} from '../Common/ProductExportConfigurationInput';
import { Grid } from '@mui/material';
import BackButton from '../../../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import CreateButton from '../../../../../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import Pane from '../../../../../../../../VentoryUI/components/common/Pane';

interface CreateProductExportConfigurationPaneProps {}

export default function CreateProductExportConfigurationPane({}: CreateProductExportConfigurationPaneProps) {
  const navigate = useNavigate();
  const { currentCompany } = useContext(CompanyContext);
  const { productExportConfigurations, setProductExportConfigurations } = useContext(ProductExportConfigurationContext);

  const [productExportConfigurationInput, setProductExportConfigurationInput] = useState<ProductExportConfiguration>(
    new ProductExportConfiguration({ companyId: currentCompany.id }),
  );
  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<
    CreateProductExportConfigurationResponse,
    CreateProductExportConfigurationVariables
  >(ProductExportConfigurationMutations.create, {
    onCompleted: res => {
      const config = res.createProductExportConfiguration[0];
      productExportConfigurations.set(config.id, new ProductExportConfiguration(config));
      setProductExportConfigurations(new Map(productExportConfigurations));
      navigate('/stock/products/settings/report');
    },
    onError: err => setError(err.message),
  });

  const handleCreate = async (
    configuration: ProductExportConfiguration,
    items: DraggableProductExportFieldIdentifier[],
  ) => {
    try {
      configuration.fieldMapping = items.map(
        (i, idx) => new ProductExportConfigurationMapping(i.customField, idx, i.field),
      );
      await create({
        variables: {
          productExportConfigurations: [configuration],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const createFooter = (configuration: ProductExportConfiguration, items: DraggableProductExportFieldIdentifier[]) => {
    return (
      <Grid container columnSpacing={1} justifyContent={'flex-end'}>
        <Grid item>
          <BackButton disabled={loading} onClick={() => navigate('/stock/products/settings/report')} />
        </Grid>
        <Grid item>
          <CreateButton
            loading={loading}
            disabled={!configuration.filename || !configuration.delimiter || !configuration.name}
            onClick={() => handleCreate(configuration, items)}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    // TODO: ERROR
    <Pane>
      <ProductExportConfigurationInput
        configuration={productExportConfigurationInput}
        setConfiguration={setProductExportConfigurationInput}
        footer={createFooter}
      />
    </Pane>
  );
}
