import { ApolloClient, createHttpLink, DefaultOptions, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { promiseToObservable } from './util/observable.util';
import {
  getAccessToken,
  getCurrentCompanyId,
  getRefreshToken,
  storeAccessToken,
  storeRefreshToken,
} from './util/token.util';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { RefreshJwtTokenResponse, RefreshJwtTokenVariables, UserQueries } from './graphql/user.graphql';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // Return headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'X-Frame-Options': 'sameorigin',
      'X-Content-Type-Options': 'nosniff',
      'Permissions-Policy': '*',
      authorization: `Bearer ${getAccessToken()}`,
      'X-Company': getCurrentCompanyId(),
    },
  };
});

let activeSocket: any, timedOut: NodeJS.Timeout;
const wsLink = new GraphQLWsLink(
  createClient({
    url: `${process.env.REACT_APP_WS_BASE_URL}/graphql`,
    keepAlive: 30000, // 30s
    on: {
      connected: socket => {
        activeSocket = socket;
      },
      ping: received => {
        // Give backend 5s to answer
        if (!received) {
          timedOut = setTimeout(() => {
            if (activeSocket.readyState === WebSocket.OPEN) {
              activeSocket.close(4408, 'Request Timeout');
              console.info('Ping Request Timeout');
            }
          }, 5000);
        }
      },
      pong: received => {
        if (received) clearTimeout(timedOut);
      },
    },
    connectionParams: () => {
      return {
        Authorization: `Bearer ${getAccessToken()}`,
      };
    },
  }),
);

const getRefreshedAccessToken = async (refreshToken: string) => {
  const response = await apolloClient.query<RefreshJwtTokenResponse, RefreshJwtTokenVariables>({
    query: UserQueries.refreshJwtToken,
    variables: { refreshToken },
  });

  const tokens = response.data.refreshJwtToken;
  if (tokens.accessToken) storeAccessToken(tokens.accessToken);
  if (tokens.refreshToken) storeRefreshToken(tokens.refreshToken);

  return tokens.accessToken;
};

const authErrorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  console.error(
    operation.operationName,
    graphQLErrors?.map(e => e.message),
    { graphQLErrors, networkError, operation },
  );
  if (graphQLErrors?.some(error => error?.extensions && error?.extensions['code'] === 'VENTORY_UNAUTHENTICATED')) {
    const refreshToken = getRefreshToken();
    if (operation.operationName === 'RefreshJwtToken' || !refreshToken) {
      window.location.href = '/login';
      return;
    }

    return promiseToObservable(getRefreshedAccessToken(refreshToken)).flatMap((accessToken: unknown) => {
      if (typeof accessToken === 'string') {
        const oldHeaders = operation.getContext().headers;
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${accessToken}`,
          },
        });
      }
      return forward(operation);
    });
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'none',
  },
};

const cache = new InMemoryCache({
  resultCaching: true,
  addTypename: false,
});

const apolloClient = new ApolloClient({
  link: authErrorLink.concat(authLink).concat(splitLink),
  defaultOptions,
  cache,
});

export default apolloClient;
