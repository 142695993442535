import { Grid } from '@mui/material';
import React from 'react';
import { Container } from '../../../../../types/container';
import { Product } from '../../../../../types/product';
import { ProductMasterData } from '../../../../../types/productMasterData';
import { t } from '../../../../../types/translation/Translator';
import BoxIcon from '../../../../icons/Box/BoxIcon';
import PackageIcon from '../../../../icons/Package/PackagIcon';

export function ProductOrContainerNameColumn(
  item: Product | Container,
  productMasterData: Map<string, ProductMasterData>,
) {
  const name = _Name(item, productMasterData);

  return (
    <Grid container display='flex' maxHeight={'18px'} flexWrap='nowrap'>
      <Grid item className='pr-2'>
        {_Icon(item)}
      </Grid>
      <Grid item className='content-center'>
        <span className='text-[12px] text-ventory-grey-900 font-[400] text-ellipsis' title={name}>
          {name}
        </span>
      </Grid>
    </Grid>
  );
}

function _Name(item: Product | Container, productMasterData: Map<string, ProductMasterData>) {
  if (item instanceof Product) {
    return productMasterData.get(item.productMasterDataId)?.productName ?? t().unknownProductReference.singular.label;
  }

  if (item instanceof Container) {
    return item.identifier;
  }

  return '';
}

function _Icon(item: Product | Container) {
  if (item instanceof Product) {
    return <PackageIcon />;
  }

  if (item instanceof Container) {
    return <BoxIcon />;
  }

  return <></>;
}

export function ProductOrContainerContainerColumn(item: Product | Container, containers: Map<string, Container>) {
  if (item instanceof Product) {
    if (!item.containerId) return '';
    return containers.get(item.containerId)?.identifier || t().unknownContainer.singular.label;
  }

  if (item instanceof Container) {
    if (!item.parentId) return '';
    return containers.get(item.parentId)?.identifier || t().unknownContainer.singular.label;
  }

  return '';
}
