import React from 'react';
import { Container } from '../types/container';

export interface ContainerContextProps {
  containers: Map<string, Container>;
  setContainers: (containers: Map<string, Container>) => void;
  containersLoading: boolean;
}

const defaultContext: ContainerContextProps = {
  containers: new Map(),
  setContainers: () => {},
  containersLoading: false,
};

export const ContainerContext = React.createContext<ContainerContextProps>(defaultContext);
