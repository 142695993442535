import React, { useContext, useEffect, useRef, useState } from 'react';
import { FilterProps } from '../../common/filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { removeDiacritics } from '../../../../../util/string.util';
import { OrderStatusFilter } from '../OrderStatusFilter';
import { OrderStatus, OrderUtil } from '../../../../../types/order';

interface OrderStatusFilterContentProps {
  filter: OrderStatusFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
  color: string;
}

const orderStatusFilter = (status: string, text: string, filter: FilterProps) => {
  const filterText = removeDiacritics(text.toLowerCase());
  if (removeDiacritics(status.toLowerCase()).includes(filterText)) return true;
  if (removeDiacritics(status.toLowerCase()).includes(filterText)) return true;
  return false;
};

export default function OrderStatusFilterContent({
  filter,
  filterProps,
  setFilterProps,
  color,
}: OrderStatusFilterContentProps) {
  const { stockLocations } = useContext(StockLocationContext);

  const [textFilter, setTextFilter] = useState<string>('');

  const filterOrderStatus = () => {
    return new Set(
      [...Object.keys(OrderStatus)].filter(item => orderStatusFilter(item, textFilter, filterProps)).map(item => item),
    );
  };

  const ref = useRef<HTMLDivElement | null>(null);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (!ref.current) return;
    const topFromBottom = document.documentElement.clientHeight - ref.current.getBoundingClientRect().top;
    setMaxHeight(topFromBottom < 500 ? topFromBottom - 16 : undefined);
  }, [ref.current]);

  const [values, setValues] = useState<Set<string>>(filterOrderStatus());

  const handleChange = (selected: Set<string>) => {
    filterProps.orderStatus = selected;
    if (!filterProps.orderStatus?.size) filterProps.orderStatus = undefined;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.orderStatus = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterOrderStatus());
  }, [textFilter]);

  return (
    <Grid item xs={12} className='w-full' ref={ref} style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().orderStatus.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setTextFilter(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            color={color}
            selected={filterProps.orderStatus}
            values={values}
            onChange={handleChange}
            toText={id => OrderUtil.statusToString(id as OrderStatus)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
