import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../../../../../types/translation/Translator';
import { Bin } from '../../../../../../../types/bin';
import BinInfoPane from '../Panes/BinInfoPane';
import { Grid } from '@mui/material';
import {
  BinMutations,
  DeleteBinResponse,
  DeleteBinVariables,
  UpdateBinResponse,
  UpdateBinVariables,
} from '../../../../../../../graphql/bin.graphql';
import { useMutation } from '@apollo/client';
import { BinContext } from '../../../../../../../context/BinContext';
import { testIds } from '../../../../../../../util/identifiers/identifiers.util';
import Modal from '../../../../../../../VentoryUI/components/common/Modal/Modal';
import {
  DeleteButton,
  CancelButton,
  SaveButton,
} from '../../../../../../../VentoryUI/components/common/Button/Templates';
import { CustomFieldEntityType, CustomFieldValue } from '../../../../../../../types/customField';
import { CustomFieldContext } from '../../../../../../../context/CustomFieldContext';

interface UpdateBinModalInputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  bin?: Bin;
}

export default function UpdateBinModal({ open, setOpen, bin }: UpdateBinModalInputProps) {
  if (!bin) return null; // TODO: Not found

  const { bins, setBins } = useContext(BinContext);

  const { customFields } = useContext(CustomFieldContext);

  const [binInput, setBinInput] = useState<Bin>(new Bin(bin));

  const [error, setError] = useState<string>('');

  const [update, { loading: updateLoading }] = useMutation<UpdateBinResponse, UpdateBinVariables>(BinMutations.update, {
    onCompleted: res => {
      const bin = res.updateBin[0];
      bins.set(bin.id, new Bin(bin));

      setBins(new Map(bins));
      handleClose();
    },
    onError: res => setError(res.message),
  });

  const [remove, { loading: deleteLoading }] = useMutation<DeleteBinResponse, DeleteBinVariables>(BinMutations.remove, {
    onCompleted: res => {
      res.deleteBin.forEach(bin => bins.delete(bin.id));
      setBins(new Map(bins));
      handleClose();
    },
    onError: err => setError(err.message),
  });

  const handleUpdate = async (bin: Bin) => {
    try {
      const updatedBin = bin.forUpdate();

      await update({
        variables: {
          bins: [updatedBin],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleRemove = async (bin: Bin) => {
    try {
      const deletedBin = bin.forDelete();

      await remove({
        variables: {
          bins: [deletedBin],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setBinInput(new Bin(bin));
    setError('');
    setOpen(false);
  };

  const disabled = useMemo(() => {
    const existingCtxValues = [...customFields.values()]
      .filter(v => v.entityType === CustomFieldEntityType.bin)
      .map(cf => cf.toValue());
    const inputValues = binInput.customFieldValues();
    const customFieldValues: CustomFieldValue[] = Array.from(
      new Map([...existingCtxValues, ...inputValues].map(cfv => [cfv.id, cfv])).values(),
    );

    const missingRequiredFieldCallback = (cfv: CustomFieldValue) => {
      return cfv.mandatory && !cfv.value && true;
    };
    const hasMissingRequiredCustomFields = customFieldValues.some(missingRequiredFieldCallback);

    if (!binInput.name) return true;
    if (!binInput.binStatusId) return true;
    if (hasMissingRequiredCustomFields) return true;
    if (deleteLoading) return true;

    return false;
  }, [binInput, customFields, deleteLoading]);

  const footer = (binInput: Bin) => (
    <Grid container marginTop={'auto'}>
      <Grid item>
        <DeleteButton loading={deleteLoading} disabled={updateLoading} onClick={() => handleRemove(binInput)} />
      </Grid>
      <Grid item flexGrow={1}>
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <CancelButton disabled={updateLoading || deleteLoading} onClick={handleClose} />
          </Grid>
          <Grid item>
            <SaveButton loading={updateLoading} disabled={disabled} onClick={() => handleUpdate(binInput)} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={open}
      error={error}
      onClose={handleClose}
      title={t().updateBin.singular.label}
      testId={testIds.updateBinModal}
    >
      <BinInfoPane binInput={binInput} setBinInput={setBinInput} footer={footer} />
    </Modal>
  );
}
