import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BinContext } from '../../../../../context/BinContext';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { ContainerContext } from '../../../../../context/ContainerContext';
import { ContainerTypeContext } from '../../../../../context/ContainerTypeContext';
import { CustomFieldContext } from '../../../../../context/CustomFieldContext';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import { SystemBin } from '../../../../../types/bin';
import { Container } from '../../../../../types/container';
import { CustomFieldEntityChildType, CustomFieldEntityType } from '../../../../../types/customField';
import { t } from '../../../../../types/translation/Translator';
import { FooterButton } from '../../../../../VentoryUI/components/common/Button/Button';
import Checkbox from '../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import { FlexPane } from '../../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../../VentoryUI/components/common/Paper/Paper';
import CustomFieldInput from '../../../Common/CustomFieldInput';
import ErrorBox from '../../../Common/ErrorBox';
import TextInput from '../../../Common/TextInput';
import DropdownSelect from '../../../Common/DropdownSelect';
import LoadingPackage from '../../../Common/LoadingPackage';
import { SystemStockLocation } from '../../../../../types/stockLocation';

interface ContainerConfigurationPaneProps {
  container?: Container;
  title: string;
  footerButtons: (container: Container) => FooterButton[];
}

export default function ContainerConfigurationPane({
  container,
  footerButtons,
  title,
}: ContainerConfigurationPaneProps) {
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { bins, binsLoading } = useContext(BinContext);
  const { containers, containersLoading } = useContext(ContainerContext);
  const { containerTypes, containerTypesLoading } = useContext(ContainerTypeContext);
  const { customFields, customFieldsLoading } = useContext(CustomFieldContext);

  const containerCustomFields = [...customFields.values()].filter(
    cf => cf.entityType === CustomFieldEntityType.container,
  );
  const propagationContainerCustomFields = [...customFields.values()].filter(
    cf => cf.entityChildType === CustomFieldEntityChildType.container,
  );

  const [input, setInput] = useState(
    container
      ? new Container(container)
      : new Container({ companyId: currentCompany.id, stockLocationId: SystemStockLocation.id }),
  );

  if (binsLoading || containersLoading || containerTypesLoading || customFieldsLoading) {
    return (
      <Paper>
        <div className='h-full w-full grid place-items-center'>
          <LoadingPackage />
        </div>
      </Paper>
    );
  }

  const binIsVirtual = input.binId === SystemBin.id;

  const validContainerIds = binIsVirtual
    ? new Set<string>()
    : new Set([...containers.values()].filter(el => el.id != input.id && el.binId === input.binId).map(el => el.id));

  return (
    <FlexPane
      footerButtons={footerButtons(input)}
      content={
        <Paper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p className='font-semibold text-xl'>{title}</p>
            </Grid>
            {!containerTypes.size ? (
              <Grid item xs={12}>
                <ErrorBox
                  onClick={() => navigate('/settings/advanced/container_types')}
                  error={'You have not created any container types yet! Click here to create one!'}
                />
              </Grid>
            ) : null}
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput
                    mandatory
                    label={t().identifier.singular.label}
                    placeholder={t().identifier.singular.label}
                    value={input.identifier}
                    onChange={identifier => setInput(input.withIdentifier(identifier))}
                    heightClass='h-[34px]'
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownSelect
                    mandatory
                    label={t().containerType.singular.label}
                    placeholder={t().selectContainerType.singular.label}
                    selectedValue={input.containerTypeId}
                    values={[...new Set([...containerTypes.values()].map(el => el.id))]}
                    toText={item => containerTypes.get(item || '')?.name || ''}
                    onChange={selected => setInput(input.withContainerTypeId(selected || ''))}
                  />
                </Grid>
                {!input.id ? (
                  <>
                    <Grid item xs={12}>
                      <DropdownSelect
                        label={t().stockLocation.singular.label}
                        disabled={!!input.id}
                        placeholder={t().selectStockLocation.singular.label}
                        selectedValue={input.stockLocationId === SystemStockLocation.id ? '' : input.stockLocationId}
                        values={[...new Set([...stockLocations.values()].map(el => el.id))]}
                        toText={item => stockLocations.get(item || '')?.name || ''}
                        onChange={item => setInput(input.withStockLocationId(item || undefined))}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DropdownSelect
                        label={t().bin.singular.label}
                        mandatory={!!input.stockLocationId && input.stockLocationId !== SystemStockLocation.id}
                        disabled={
                          !input.stockLocationId || input.stockLocationId === SystemStockLocation.id || !!input.id
                        }
                        placeholder={t().selectBin.singular.label}
                        selectedValue={binIsVirtual ? undefined : input.binId}
                        values={[
                          ...new Set(
                            [...bins.values()]
                              .filter(el => el.stockLocationId === input.stockLocationId)
                              .map(el => el.id),
                          ),
                        ]}
                        toText={item => bins.get(item || '')?.name || ''}
                        onChange={selected => setInput(input.withBinId(selected || ''))}
                      />
                    </Grid>
                    {validContainerIds.size ? (
                      <Grid item xs={12}>
                        <DropdownSelect
                          label={t().container.singular.label}
                          disabled={!input.binId || !!input.id}
                          placeholder={t().selectContainer.singular.label}
                          selectedValue={input.parentId}
                          values={[...validContainerIds]}
                          toText={item => containers.get(item || '')?.identifier || ''}
                          onChange={selected => setInput(input.withParentId(selected || ''))}
                        />
                      </Grid>
                    ) : null}
                  </>
                ) : null}
                {containerCustomFields.length ? (
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {containerCustomFields.map(cf => (
                        <Grid item xs={12}>
                          <CustomFieldInput
                            item={cf}
                            value={input.customFields.get(cf.id)?.value}
                            onChange={setInput}
                            entity={input}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ) : null}
                {propagationContainerCustomFields.length ? (
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {propagationContainerCustomFields.map(cf => (
                        <Grid item xs={12}>
                          <CustomFieldInput
                            item={cf}
                            value={input.customFields.get(cf.id)?.value}
                            onChange={setInput}
                            entity={input}
                            disabled={true}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <div className='w-fit'>
                    <Checkbox
                      value={input.deleteOnOutbound}
                      onChange={checked => setInput(input.withDeleteOnOutbound(checked))}
                      label={t().deleteOnOutbound.singular.upper}
                      tooltip={t().deleteOnOutboundContainerTooltip.singular.upper}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      }
    />
  );
}
