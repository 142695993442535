import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { Tag } from '../../../../../../types/tag';
import ModalPane from '../../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import TextInput from '../../../../Common/TextInput';
import { t } from '../../../../../../types/translation/Translator';
import DropdownSelect from '../../../../Common/DropdownSelect';
import { EntityType, entityTypeToString } from '../../../../../../types/comment';

export interface TagItemProps {
  setError: (error: string) => void;
  tag: Tag;
  footer: (tag: Tag) => JSX.Element;
}

export default function TagItem({ tag, footer, setError }: TagItemProps) {
  const [input, setInput] = useState<Tag>(new Tag(tag));

  return (
    <ModalPane footer={footer(input)}>
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container columnSpacing={1} rowSpacing={1}>
            <Grid item xs={6}>
              <TextInput
                mandatory
                placeholder={t().name.singular.label}
                label={t().name.singular.label}
                value={input.name}
                onChange={txt => setInput(input.withName(txt))}
              />
            </Grid>
            <Grid item xs={6}>
              <DropdownSelect
                mandatory
                label={t().type.singular.label}
                placeholder={t().type.singular.label}
                values={Object.keys(EntityType).filter(type => type === EntityType.stockLocation)}
                selectedValue={input.type}
                toText={txt => entityTypeToString(txt as EntityType)}
                onChange={type => setInput(input.withType(type as EntityType))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
