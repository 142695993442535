import React from 'react';
import { ContainerType } from '../types/containerType';

export interface ContainerTypeContextProps {
  containerTypes: Map<string, ContainerType>;
  setContainerTypes: (containerTypes: Map<string, ContainerType>) => void;
  containerTypesLoading: boolean;
}

const defaultContext: ContainerTypeContextProps = {
  containerTypes: new Map(),
  setContainerTypes: () => {},
  containerTypesLoading: false,
};

export const ContainerTypeContext = React.createContext<ContainerTypeContextProps>(defaultContext);
