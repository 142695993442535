import React, { useContext } from 'react';
import { Bin } from '../../../../../../../types/bin';
import { Divider, Grid } from '@mui/material';
import { t } from '../../../../../../../types/translation/Translator';
import TextInput from '../../../../../Common/TextInput';
import DropdownSelect from '../../../../../Common/DropdownSelect';
import { BinStatusContext } from '../../../../../../../context/BinStatusContext';
import { BinStatus } from '../../../../../../../types/binStatus';
import { testIds } from '../../../../../../../util/identifiers/identifiers.util';
import ModalPane from '../../../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { CustomFieldEntityType, CustomFieldValue, WithCustomFields } from '../../../../../../../types/customField';
import { CustomFieldContext } from '../../../../../../../context/CustomFieldContext';
import CustomFieldInput from '../../../../../Common/CustomFieldInput';
import { VentoryColor } from '../../../../../../../VentoryUI/util/color.util';

interface BinInfoPaneInputProps {
  binInput: Bin;
  setBinInput: React.Dispatch<React.SetStateAction<Bin>>;
  footer: (bin: Bin) => JSX.Element;
}

export default function BinInfoPane({ binInput, setBinInput, footer }: BinInfoPaneInputProps) {
  const { binStatuses } = useContext(BinStatusContext);
  const { customFields } = useContext(CustomFieldContext);

  const customFieldItems = [...customFields.values()].filter(v => v.entityType === CustomFieldEntityType.bin);

  const inputValues = binInput.customFieldValues();
  const defaultValues = customFieldItems.map(cf => cf.toValue());
  const customFieldValues: CustomFieldValue[] = Array.from(
    new Map([...defaultValues, ...inputValues].map(cfv => [cfv.id, cfv])).values(),
  );

  const showDivider = (index: number, columnSize: number) => {
    return index < columnSize - 1;
  };

  const binCustomField = (item: CustomFieldValue, index: number, columnSize: number): React.JSX.Element => {
    return customFields.get(item.id) ? (
      <Grid item xs={12} key={`key_container_${item.name}`}>
        <CustomFieldInput
          value={WithCustomFields.toCustomFieldsMap(binInput).get(item.id)?.value}
          item={customFields.get(item.id)}
          entity={binInput}
          onChange={setBinInput}
        />
        {showDivider(index, columnSize) ? (
          <Grid item xs={12} key={'divider' + item}>
            <Divider style={{ borderColor: VentoryColor.grey200 }} />
          </Grid>
        ) : null}
      </Grid>
    ) : (
      <Grid item xs={12} key={`key_container_${item.name}`}>
        <TextInput
          disabled
          value={binInput.customFields.get(item.id)?.value}
          label={item.name}
          placeholder={item.name}
          onChange={() => {}}
        />
      </Grid>
    );
  };

  const binNameContent = () => {
    return (
      <Grid item xs={12} key={`key_name_container_${binInput.id}`}>
        <TextInput
          mandatory
          value={binInput.name}
          label={t().binName.singular.label}
          placeholder={t().binName.singular.label}
          onChange={v => setBinInput(binInput.withName(v))}
          testId={testIds.name}
        />
      </Grid>
    );
  };

  const binStatusContent = () => {
    return (
      <Grid item xs={12} key={`key_container_${binInput.binStatusId}`}>
        <DropdownSelect
          testId={testIds.status}
          mandatory
          label={t().binStatus.singular.label}
          selectedValue={binStatuses.get(binInput.binStatusId) || null}
          maxHeight='150px'
          values={[...binStatuses.values()]}
          toText={(item: BinStatus) => item.status}
          onChange={(item: BinStatus | null) => setBinInput(binInput.withBinStatusId(item?.id))}
        />
      </Grid>
    );
  };

  const content = () => {
    if (!customFieldItems.length) {
      return (
        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            {binNameContent()}
            {binStatusContent()}
          </Grid>
        </Grid>
      );
    }

    const leftColumn = [binNameContent()];
    const rightColumn = [binStatusContent()];
    customFieldValues.forEach((cf, index) => {
      const customFieldContent = binCustomField(cf, index, customFieldItems.length);
      index % 2 === 0 ? leftColumn.push(customFieldContent) : rightColumn.push(customFieldContent);
    });

    return (
      <Grid container>
        <Grid item xs={6} pr={3}>
          <Grid container rowSpacing={2}>
            {leftColumn}
          </Grid>
        </Grid>
        <Grid item xs={6} pl={3} className={'border-l ventory-light-border'}>
          <Grid container rowSpacing={2}>
            {rightColumn}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <ModalPane footer={footer(binInput)}>
      <Grid container alignContent={'space-between'} data-testid={testIds.binInfoPane}>
        <Grid item xs={12}>
          {content()}
        </Grid>
      </Grid>
    </ModalPane>
  );
}
