import React, { useContext, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { useMutation } from '@apollo/client';
import {
  DeleteProductMasterDataResponse,
  DeleteProductMasterDataVariables,
  ProductMasterDataMutations,
} from '../../../../../graphql/productMasterData.graphql';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';
import { DeleteProductMasterDataInput } from '../../../../../types/productMasterData';

import DeleteModal from '../../../../../VentoryUI/components/common/Modal/DeleteModal';
import { testIds } from '../../../../../util/identifiers/identifiers.util';

interface DeleteProductMasterDataModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  idsToDelete: Set<string>;
}

export default function DeleteProductMasterDataModal({
  open,
  setOpen,
  idsToDelete,
}: DeleteProductMasterDataModalProps) {
  const { productMasterData, setProductMasterData } = useContext(ProductMasterDataContext);

  const [error, setError] = useState<string>('');

  const [remove, { loading }] = useMutation<DeleteProductMasterDataResponse, DeleteProductMasterDataVariables>(
    ProductMasterDataMutations.remove,
    {
      onCompleted: res => {
        res.deleteProductMasterData.forEach(pmd => productMasterData.delete(pmd.id));
        setProductMasterData(new Map(productMasterData));
        handleClose();
      },
      onError: err => setError(err.message),
    },
  );

  const handleDelete = async () => {
    try {
      const deletedProductMasterData: DeleteProductMasterDataInput[] = [];
      for (const id of idsToDelete) {
        const pmd = productMasterData.get(id);
        if (pmd) deletedProductMasterData.push(pmd.forDelete());
      }

      let deleted = 0;
      do {
        await remove({
          variables: {
            productMasterData: deletedProductMasterData.slice(deleted, deleted + 3000),
          },
        });
        deleted += 3000;
      } while (deleted < deletedProductMasterData.length);
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <DeleteModal
      testId={testIds.deleteProductMasterDataModal}
      onClose={handleClose}
      open={open}
      error={error}
      text={t().verifyDeleteProductMasterData.singular.label}
      onConfirm={handleDelete}
    />
  );
}
