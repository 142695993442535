import { toFilterString } from '../../../../util/string.util';
import React from 'react';
import { t } from '../../../../types/translation/Translator';
import { StockLocation } from '../../../../types/stockLocation';
import { BinFilter } from '../BinFilter';
import { BinStatus } from '../../../../types/binStatus';
import { Container } from '../../../../types/container';
import { Bin } from '../../../../types/bin';
import { ContainerType } from '../../../../types/containerType';
import { CustomFieldType } from '../../../../types/customField';
import { ContainerTypeFilter } from './ContainerTypeFilter';
import { ContainerFilterDropdownContent, ContainerFilterInnerContent } from './DropdownContent';
import BoxIcon from '../../../icons/Box/BoxIcon';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { FilterStorageKey, FilterProps } from '../common/filter.util';
import { StockLocationFilter } from '../StockLocationFilter';
import { TagRelation } from '../../../../types/tagRelation';

export class ContainerFilter extends BaseFilter<Container> {
  stockLocations: Map<string, StockLocation>;
  bins: Map<string, Bin>;
  containerTypes: Map<string, ContainerType>;
  binStatuses: Map<string, BinStatus>;
  tagRelations: Map<string, TagRelation>;

  constructor(
    stockLocations: Map<string, StockLocation>,
    bins: Map<string, Bin>,
    containerTypes: Map<string, ContainerType>,
    binStatuses: Map<string, BinStatus>,
    tagRelations: Map<string, TagRelation>,
  ) {
    super(FilterStorageKey.CONTAINER);
    this.stockLocations = stockLocations;
    this.bins = bins;
    this.containerTypes = containerTypes;
    this.binStatuses = binStatuses;
    this.tagRelations = tagRelations;
  }

  filterOptions = () => [
    new StockLocationFilter(this.tagRelations),
    new BinFilter(this.binStatuses, this.tagRelations),
    new ContainerTypeFilter(),
  ];

  search(item: Container, filter: string) {
    if (
      toFilterString(this.stockLocations.get(item.stockLocationId)?.name).includes(filter) ||
      toFilterString(this.bins.get(item.binId)?.name).includes(filter) ||
      toFilterString(this.containerTypes.get(item.containerTypeId)?.name).includes(filter) ||
      toFilterString(item.identifier).includes(filter) ||
      [...item.customFields.values()].some(
        cf =>
          (cf.type === CustomFieldType.text || cf.type === CustomFieldType.listOfValues) &&
          toFilterString(cf.value).includes(filter),
      )
    ) {
      return true;
    }

    return false;
  }

  filteredItems(items: Container[], query: string, filterProps: FilterProps): Container[] {
    return items.filter(item => {
      if (filterProps.stockLocation?.size && !filterProps.stockLocation.has(item.stockLocationId)) return false;
      if (filterProps.bin?.size && !filterProps.bin.has(item.binId)) return false;
      if (filterProps.containerType?.size && !filterProps.containerType.has(item.containerTypeId)) return false;

      return this.search(item, query);
    });
  }

  toLabel(props: BaseFilterProps): string {
    return t().container.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.container?.size || 0;
  }

  toIcon(): React.JSX.Element {
    return <BoxIcon />;
  }

  toDropdownContent(props: BaseFilterProps): React.JSX.Element {
    return (
      <ContainerFilterDropdownContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <ContainerFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }
}
