import React, { useContext, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { ProductTransaction, ProductTransactionType } from '../../../../../types/productTransaction';
import ProcessOrderTransactionInfoPane from './Panes/ProcessOrderTransactionInfoPane';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { StockLocationRoleAssignmentContext } from '../../../../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../../../../types/stockLocationRoleAssignment';

interface ProcessOrderTransactionModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  transaction: ProductTransaction;
  disabled: boolean;
}

export default function ProcessOrderTransactionModal({
  open,
  setOpen,
  transaction,
  disabled = false,
}: ProcessOrderTransactionModalInputProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);

  if (!open) return null;

  const [transactionInput, setTransactionInput] = useState<ProductTransaction>(new ProductTransaction(transaction));
  const [error, setError] = useState<string>('');

  const handleClose = () => {
    setOpen(false);
  };

  const stockLocationId =
    transaction.type === ProductTransactionType.inbound || transaction.type === ProductTransactionType.replenish
      ? transaction.product.toStockLocationId
      : transaction.product.fromStockLocationId;
  if (!stockLocationId) return null;

  const scanObligation =
    currentCompany.settings.featureToggles.productTransactions.scanRequired &&
    !hasStockLocationRole(currentCompany.id, stockLocationId, StockLocationRole.STOCK_LOCATION_SUPERVISOR);

  return (
    <Modal
      error={error}
      open={open}
      onClose={handleClose}
      title={t().processTransaction.singular.label}
      testId={testIds.processOrderTransactionModal}
    >
      <ProcessOrderTransactionInfoPane
        disabled={disabled || scanObligation}
        transaction={transactionInput}
        setTransaction={setTransactionInput}
        setError={setError}
        onClose={handleClose}
      />
    </Modal>
  );
}
