import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import {
  ImperialMassUnit,
  ImperialVolumeUnit,
  MetricUnit,
  Unit,
  UnitOfMeasure,
  UnitSystem,
  UnitType,
} from './unitOfMeasure';
import { t } from './translation/Translator';
import { WithCustomFields } from './customField';
import { applyMixins } from '../util/mixins';

enum ProductMasterDataValidationError {
  name = 'Please enter a product name',
  number = 'Please enter a product number',
}

export class ProductMasterData extends CompanyEntity {
  productName!: string;
  productNumber!: string;
  lotManaged?: boolean = false;
  serialManaged?: boolean = false;
  lpnManaged?: boolean = false;
  countryOfOrigin!: string;
  reorderPoint?: string;

  grossWeight?: Weight;
  netWeight?: Weight;

  dimensions?: Dimensions;

  purchasePrice?: Price;
  sellingPrice?: Price;

  productCategory?: string;

  image?: string | null;

  unitOfMeasure?: UnitOfMeasure;

  // EAN
  globalTradeItemNumber?: string;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    obj.customFields = WithCustomFields.toCustomFieldsMap(obj);
    Object.assign(this, cloneDeep(obj));
    this.parseFromJSON();
  }

  parseFromJSON() {
    if (this.dimensions) this.dimensions = new Dimensions(this.dimensions);
    if (this.grossWeight) this.grossWeight = new Weight(this.grossWeight);
    if (this.netWeight) this.netWeight = new Weight(this.netWeight);
    if (this.purchasePrice) this.purchasePrice = new Price(this.purchasePrice);
    if (this.sellingPrice) this.sellingPrice = new Price(this.sellingPrice);
  }

  parseChildren<T extends ProductMasterData>(productMasterData: T) {
    if (productMasterData.dimensions) {
      (productMasterData.dimensions as any) = productMasterData.dimensions.parse();
    }

    if (productMasterData.grossWeight) {
      (productMasterData.grossWeight as any) = productMasterData.grossWeight.parse();
    }

    if (productMasterData.netWeight) {
      (productMasterData.netWeight as any) = productMasterData.netWeight.parse();
    }

    if (productMasterData.purchasePrice && productMasterData.purchasePrice.parse) {
      (productMasterData.purchasePrice as any) = productMasterData.purchasePrice.parse();
    }

    if (productMasterData.sellingPrice && productMasterData.sellingPrice.parse) {
      (productMasterData.sellingPrice as any) = productMasterData.sellingPrice.parse();
    }

    if (productMasterData.reorderPoint)
      (productMasterData.reorderPoint as any) = parseFloat(productMasterData.reorderPoint);

    return productMasterData;
  }

  forCreate(): CreateProductMasterDataInput {
    this.validate();
    return {
      ...this,
      customFields: this.customFieldValues(),
    };
  }

  forUpdate(): UpdateProductMasterDataInput {
    return this.parseChildren(
      UpdateProductMasterDataInput.from(
        { ...this, customFields: this.customFieldValues() },
        UpdateProductMasterDataInput,
      ),
    );
  }

  forDelete(): DeleteProductMasterDataInput {
    return DeleteProductMasterDataInput.from(
      { ...this, customFields: this.customFieldValues() },
      DeleteProductMasterDataInput,
    );
  }

  validate() {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof ProductMasterData)[],
      (field: keyof ProductMasterData) => {
        if (field === 'productNumber' && !this.productNumber) throw ProductMasterDataValidationError.number;
        if (field === 'productName' && !this.productName) throw ProductMasterDataValidationError.name;
        return null;
      },
    );
  }

  withProductName(productName: string) {
    this.productName = productName;
    return cloneDeep(this);
  }

  withProductNumber(productNumber: string) {
    this.productNumber = productNumber;
    return cloneDeep(this);
  }

  withLotManaged(lotManaged: boolean) {
    this.lotManaged = lotManaged;
    return cloneDeep(this);
  }

  withSerialManaged(serialManaged: boolean) {
    this.serialManaged = serialManaged;
    return cloneDeep(this);
  }

  withLpnManaged(lpnManaged: boolean) {
    this.lpnManaged = lpnManaged;
    return cloneDeep(this);
  }

  withCountryOfOrigin(countryOfOrigin: string) {
    this.countryOfOrigin = countryOfOrigin;
    return cloneDeep(this);
  }

  withReorderPoint(reorderPoint: string) {
    this.reorderPoint = reorderPoint;
    return cloneDeep(this);
  }

  withEAN(ean: string) {
    this.globalTradeItemNumber = ean;
    return cloneDeep(this);
  }

  withUnitType(unitType: UnitType) {
    if (!this.unitOfMeasure) this.unitOfMeasure = {};

    this.unitOfMeasure.unitType = unitType;
    this.unitOfMeasure.unit = undefined;

    return this.withUnitSystem(UnitSystem.metric);
  }

  withUnitSystem(system: UnitSystem) {
    if (!this.unitOfMeasure) this.unitOfMeasure = {};
    this.unitOfMeasure.system = system;
    return this.withUnit(getDefaultUnit(system, this.unitOfMeasure.unitType));
  }

  withUnit(unit: Unit) {
    if (!this.unitOfMeasure) this.unitOfMeasure = {};
    this.unitOfMeasure.unit = unit;
    return cloneDeep(this);
  }

  withHeight(height: string) {
    if (!this.dimensions) this.dimensions = new Dimensions();
    this.dimensions.height = height;
    return cloneDeep(this);
  }

  withWidth(width: string) {
    if (!this.dimensions) this.dimensions = new Dimensions();
    this.dimensions.width = width;
    return cloneDeep(this);
  }

  withDepth(depth: string) {
    if (!this.dimensions) this.dimensions = new Dimensions();
    this.dimensions.depth = depth;
    return cloneDeep(this);
  }

  withLengthUnit(unit: LengthUnit) {
    if (!this.dimensions) {
      this.dimensions = new Dimensions(unit);
    } else {
      this.dimensions.unit = unit;
    }

    return cloneDeep(this);
  }

  withNetWeightValue(value: string) {
    if (!this.netWeight) this.netWeight = new Weight();
    this.netWeight.value = value;
    return cloneDeep(this);
  }

  withNetWeightUnit(unit: WeightUnit) {
    if (!this.netWeight) this.netWeight = new Weight({ unit: unit });
    this.netWeight.unit = unit;
    return cloneDeep(this);
  }

  withGrossWeightValue(value: string) {
    if (!this.grossWeight) this.grossWeight = new Weight();
    this.grossWeight.value = value;
    return cloneDeep(this);
  }

  withGrossWeightUnit(unit: WeightUnit) {
    if (!this.grossWeight) this.grossWeight = new Weight({ unit: unit });
    this.grossWeight.unit = unit;
    return cloneDeep(this);
  }

  withPurchasePriceValue(value: string) {
    if (!this.purchasePrice) this.purchasePrice = new Price();
    this.purchasePrice.value = value;
    return cloneDeep(this);
  }

  withPurchasePriceCurrency(currency: Currency) {
    if (!this.purchasePrice) this.purchasePrice = new Price(currency);
    this.purchasePrice.currency = currency;
    return cloneDeep(this);
  }

  withSellingPriceValue(value: string) {
    if (!this.sellingPrice) this.sellingPrice = new Price();
    this.sellingPrice.value = value;
    return cloneDeep(this);
  }

  withSellingPriceCurrency(currency: Currency) {
    if (!this.sellingPrice) this.sellingPrice = new Price(currency);
    this.sellingPrice.currency = currency;
    return cloneDeep(this);
  }

  withImage(image?: string | null) {
    this.image = image;
    return cloneDeep(this);
  }
}

export interface ProductMasterData extends WithCustomFields {}
applyMixins(ProductMasterData, [WithCustomFields]);

export class CreateProductMasterDataInput extends forCreate(ProductMasterData) {}

export class UpdateProductMasterDataInput extends forUpdate(ProductMasterData) {}

export class DeleteProductMasterDataInput extends forDelete(ProductMasterData) {}

export class Weight {
  value?: string;
  unit: WeightUnit;

  constructor(obj?: any) {
    this.value = String(obj?.value);
    this.unit = obj?.unit || WeightUnit.gram;
  }

  parse() {
    return {
      value: parseFloat(this.value || '0') || 0,
      unit: this.unit,
    };
  }
}

export class Dimensions {
  height?: string;
  width?: string;
  depth?: string;
  unit: LengthUnit;

  constructor(obj?: any) {
    this.unit = obj?.unit || LengthUnit.meter;
    this.height = String(obj?.height);
    this.width = String(obj?.width);
    this.depth = String(obj?.depth);
  }

  parse() {
    return {
      height: parseFloat(this.height || '0') || 0,
      width: parseFloat(this.width || '0') || 0,
      depth: parseFloat(this.depth || '0') || 0,
      unit: this.unit,
    };
  }
}

export class Price {
  value?: string;
  currency: Currency;

  constructor(obj?: any) {
    this.value = String(obj?.value);
    this.currency = obj?.currency || Currency.EUR;
  }

  parse() {
    return {
      value: parseFloat(this.value || '0') || 0,
      currency: this.currency,
    };
  }
}

export type Url = string;
export type RGBColor = string;
export type uuid = string;

export type int = number;

export const getDefaultUnit = (unitSystem?: UnitSystem, unitType?: UnitType) => {
  if (unitSystem === UnitSystem.imperial) {
    switch (unitType) {
      case UnitType.length:
        return ImperialLengthUnit.foot;
      case UnitType.mass:
        return ImperialMassUnit.pound;
      case UnitType.volume:
        return ImperialVolumeUnit.fluidOunce;
    }
  }

  return MetricUnit.base;
};

export enum MetricWeightUnit {
  gram = 'gram',
  kilogram = 'kilogram',
}

export enum ImperialWeightUnit {
  pound = 'pound',
  ounce = 'ounce',
}

export enum WeightUnit {
  gram = 'gram',
  kilogram = 'kilogram',
  pound = 'pound',
  ounce = 'ounce',
}

export enum MetricLengthUnit {
  centimeter = 'centimeter',
  decimeter = 'decimeter',
  meter = 'meter',
}

export enum ImperialLengthUnit {
  inch = 'inch',
  foot = 'foot',
  yard = 'yard',
}

export enum LengthUnit {
  millimeter = 'millimeter',
  centimeter = 'centimeter',
  decimeter = 'decimeter',
  meter = 'meter',
  inch = 'inch',
  foot = 'foot',
  yard = 'yard',
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  JPY = 'JPY',
  GBP = 'GBP',
  AUD = 'AUD',
  CAD = 'CAD',
  CHF = 'CHF',
  CNY = 'CNY',
  HKD = 'HDK',
  NZD = 'NZD',
}

export function weightUnitToString(unit: WeightUnit) {
  switch (unit) {
    case WeightUnit.gram:
      return t().g.singular.lower;
    case WeightUnit.kilogram:
      return t().kg.singular.lower;
    case WeightUnit.ounce:
      return t().oz.singular.lower;
    case WeightUnit.pound:
      return t().lb.singular.lower;
  }
}

export function lengthUnitToString(unit: LengthUnit) {
  switch (unit) {
    case LengthUnit.centimeter:
      return t().cm.singular.lower;
    case LengthUnit.decimeter:
      return t().dm.singular.lower;
    case LengthUnit.millimeter:
      return t().mm.singular.lower;
    case LengthUnit.meter:
      return t().m.singular.lower;
    case LengthUnit.foot:
      return 'foot';
    case LengthUnit.inch:
      return 'inch';
    case LengthUnit.yard:
      return 'yard';
  }
}
