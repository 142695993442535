import { VentoryColor } from '../../../util/color.util';
import { LegacyRef, useEffect, useRef, useState } from 'react';
import { ChartContainer, ChartContainerProps } from '../Chart';
import HorizontalAxis from '../common/HorizontalAxis';
import VerticalAxis from '../common/VerticalAxis';
import { Bar } from './Bar';
import React from 'react';
import { DashboardData } from '../../../../ui/pages/Dashboard/Common/dashboard.util';
import { LoadingSpinner } from '../../../../ui/pages/Common/LoadingSpinner';
import { Grid } from '@mui/material';
import { FilterProps } from '../../filters/common/filter.util';

interface BarChartProps extends ChartContainerProps {
  data: DashboardData;
  className?: string;
  loading: boolean;
  color?: string;
  filter: FilterProps;
  onClick?: (idx: number, format: string, value: string) => void;
  format: string;
}

export function BarChart({
  text,
  color = VentoryColor.primaryVentoryColor,
  data,
  className,
  loading,
  filter,
  onClick = () => {},
  format,
}: BarChartProps) {
  const container = useRef<HTMLDivElement>();
  const wrapper = useRef<SVGSVGElement>();

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const margin = { left: 40, bottom: 40 };

  const handleResize = () => {
    if (container.current?.offsetWidth) {
      setWidth(container.current.offsetWidth);
      setHeight(container.current.offsetHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    handleResize();
  }, [loading]);

  return (
    <ChartContainer color={color} text={text}>
      {loading ? (
        <Grid container display='flex' className='h-full w-full'>
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignContent={'center'} my={'auto'}>
            <LoadingSpinner color={color.toString()} />
          </Grid>
        </Grid>
      ) : (
        <div ref={container as LegacyRef<HTMLDivElement>} className={`flex w-full ${className}`}>
          <svg ref={wrapper as LegacyRef<SVGSVGElement>} className='flex w-full h-full'>
            <g>
              <HorizontalAxis data={data.filteredChartData} width={width} height={height} margin={margin} />
              <VerticalAxis data={data.filteredChartData} width={width} height={height} margin={margin} />
              <Bar
                data={data.filteredChartData}
                width={width}
                height={height}
                margin={margin}
                color={color}
                filter={filter}
                onClick={onClick}
                format={format}
              />
            </g>
          </svg>
        </div>
      )}
    </ChartContainer>
  );
}
