import Grid from '@mui/material/Grid';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BinContext } from '../../../../../context/BinContext';
import { ContainerContext } from '../../../../../context/ContainerContext';
import { ContainerTypeContext } from '../../../../../context/ContainerTypeContext';
import { LotContext } from '../../../../../context/LotContext';
import { ProductContext } from '../../../../../context/ProductContext';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import { Container } from '../../../../../types/container';
import { Product } from '../../../../../types/product';
import { t } from '../../../../../types/translation/Translator';
import { quantityWithSuffix } from '../../../../../types/unitOfMeasure';
import { getAllContainerChildren } from '../../../../../util/containers.util';
import { toFilterString } from '../../../../../util/string.util';
import FlexPane from '../../../../../VentoryUI/components/common/FlexPane';
import Paper from '../../../../../VentoryUI/components/common/Paper';
import SearchBar from '../../../../../VentoryUI/components/common/SearchBar';
import { FooterButton } from '../../../../../VentoryUI/components/common/Button/Button';
import {
  ProductOrContainerContainerColumn,
  ProductOrContainerNameColumn,
} from '../../../../../VentoryUI/components/common/Table/ProductOrContainer';
import Table, { TableHeader } from '../../../../../VentoryUI/components/common/Table';

interface ContainerInfoPaneInputProps {
  container: Container;
  footerButtons: (container: Container) => FooterButton[];
}

export default function ContainerInfoPane({ container, footerButtons }: ContainerInfoPaneInputProps) {
  const navigate = useNavigate();
  const { products } = useContext(ProductContext);
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { lots } = useContext(LotContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { bins } = useContext(BinContext);
  const { containerTypes, containerTypesLoading } = useContext(ContainerTypeContext);
  const { containers, containersLoading } = useContext(ContainerContext);

  const { childProducts, childContainers } = useMemo(() => {
    return getAllContainerChildren(containers, products, container);
  }, [container, containers, products]);

  const allItems = useMemo(() => {
    return [...childProducts, ...childContainers];
  }, [childProducts, childContainers]);

  const [filter, setFilter] = useState('');
  const handleFilter = (item: Product | Container) => {
    if (item instanceof Product) {
      const productName = productMasterData.get(item.productMasterDataId)?.productName;
      const productNumber = productMasterData.get(item.productMasterDataId)?.productNumber;

      if (toFilterString(productName).includes(filter) || toFilterString(productNumber).includes(filter)) {
        return true;
      }
    }

    if (item instanceof Container) {
      if (toFilterString(item.identifier).includes(filter)) return true;
    }

    return false;
  };

  const hasLPN = !!childProducts.filter(item => item.lpn).length;
  const hasSerial = !!childProducts.filter(item => item.serial).length;
  const hasLot = !!childProducts.filter(item => item.lotId).length;

  const headers: TableHeader<Product | Container>[] = [
    {
      key: 'name',
      name: t().name.singular.label,
      text: (item: Product | Container) => ProductOrContainerNameColumn(item, productMasterData),
    },
    {
      key: 'number',
      name: t().number.singular.label,
      text: (item: Product | Container) =>
        item instanceof Product
          ? productMasterData.get(item.productMasterDataId)?.productNumber || 'Unknown Product Number'
          : '',
    },
    {
      key: 'lpn',
      name: t().lpn.singular.label,
      text: (item: Product | Container) => (item instanceof Product ? item.lpn || '' : ''),
    },
    {
      key: 'serialNumber',
      name: t().serial.singular.label,
      text: (item: Product | Container) => (item instanceof Product ? item.serial || '' : ''),
    },
    {
      key: 'lotNumber',
      name: t().lotNumber.singular.label,
      text: (item: Product | Container) => (item instanceof Product ? lots.get(item.lotId || '')?.number || '' : ''),
    },
    {
      key: 'container',
      name: t().container.singular.label,
      text: (item: Product | Container) => ProductOrContainerContainerColumn(item, containers),
    },
    {
      key: 'quantity',
      sortValue: (item: Product | Container) => (item instanceof Product ? item.unitQuantity : 1),
      name: t().quantity.singular.label,
      text: (item: Product | Container) =>
        item instanceof Product
          ? quantityWithSuffix(item.quantity, productMasterData.get(item.productMasterDataId)?.unitOfMeasure)
          : '',
    },
  ].filter(item => {
    if (item.key === 'serialNumber' && !hasSerial) return false;
    if (item.key === 'lpn' && !hasLPN) return false;
    if (item.key === 'lotNumber' && !hasLot) return false;

    return true;
  });

  const bin = bins.get(container.binId || '')?.name;
  const stockLocation = stockLocations.get(container.stockLocationId || '')?.name;

  return (
    <FlexPane
      footerButtons={footerButtons(container)}
      header={
        <Paper>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={3}>
              <Grid container columnSpacing={1}>
                <Grid item flexGrow={1} marginY={'auto'}>
                  <p className='font-semibold'>{t().container.singular.label}</p>
                  <p className='text-xl font-bold text-gray-400 mt-2'>{container.identifier}</p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={3} marginY={'auto'}>
              {stockLocation ? (
                <>
                  <p className='font-semibold'>{t().stockLocation.singular.label}</p>
                  <p className='text-xl font-bold text-gray-400 mt-2'>{stockLocation}</p>
                </>
              ) : null}
            </Grid>
            <Grid item xs={3} marginY={'auto'}>
              {bin ? (
                <>
                  <p className='font-semibold mb-2'>{t().bin.singular.label}</p>
                  <p className='text-xl font-bold text-gray-400 mt-2'>{bins.get(container.binId || '')?.name || ''}</p>
                </>
              ) : null}
            </Grid>
            <Grid item xs={3} textAlign={'right'} marginY={'auto'}>
              <p className='font-semibold mb-2'>{t().containerType.singular.label}</p>
              <p className='text-xl font-bold text-gray-400 mt-2'>
                {containerTypes.get(container.containerTypeId || '')?.name || ''}
              </p>
            </Grid>
          </Grid>
        </Paper>
      }
      content={
        <FlexPane
          header={<SearchBar onChange={value => setFilter(value)} placeholder={t().filterProducts.singular.label} />}
          content={
            <Table
              loading={containersLoading || containerTypesLoading}
              items={allItems.filter(handleFilter)}
              headers={headers}
            />
          }
        />
      }
    />
  );
}
