import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import TextInput from '../Common/TextInput';
import { t } from '../../../types/translation/Translator';
import { ReactComponent as VentoryWhiteLogo } from '../../../assets/img/Ventory-WhiteNoBack.svg';
import { useMutation } from '@apollo/client';
import {
  AuthenticationResultType,
  SignInResponse,
  SignInVariables,
  UserMutations,
} from '../../../graphql/user.graphql';
import { storeAccessToken, storeRefreshToken } from '../../../util/token.util';
import { UserContext } from '../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { errorToText } from '../../../util/error.util';
import { errorCodeToString } from '../../../util/errors/errorMapper.util';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { handleKeyEvent } from '../../../util/events.util';

import Button from '../../../VentoryUI/components/common/Button';

export function LoginScreen() {
  const navigate = useNavigate();

  const { setCurrentUser } = useContext(UserContext);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [signIn, { loading: signInLoading }] = useMutation<SignInResponse, SignInVariables>(UserMutations.signIn, {
    onCompleted: res => {
      const { token, user, type } = res.signIn;
      if (type === AuthenticationResultType.authenticationCodeRequired) {
        navigate('/login/code', {
          state: {
            email: email,
            password: password,
          },
        });
        return;
      }

      storeAccessToken(token.accessToken);
      storeRefreshToken(token.refreshToken);
      setCurrentUser(user);
      navigate('/dashboard');
    },
    onError: err => {
      setError(errorToText(err));
    },
  });

  const handleSignIn = async () => await signIn({ variables: { email, password } });

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{ height: '100vh' }}
      className='bg-ventory-blue-900'
      data-testid={testIds.loginScreen}
      onKeyDown={async event => handleKeyEvent(event, 'Enter', handleSignIn)}
    >
      <Grid container width={'350px'} mb={2}>
        <Grid item xs={12}>
          <VentoryWhiteLogo />
        </Grid>
      </Grid>

      <Grid
        width={'350px'}
        container
        paddingX={3}
        paddingY={2}
        justifyContent={'center'}
        rowSpacing={1}
        className='bg-white'
      >
        <Grid item xs={12} textAlign={'center'} justifyItems={'center'}>
          <p className='text-2xl font-bold'>{'Sign In'}</p>
        </Grid>
        {error ? (
          <Grid textAlign={'center'} item xs={12} marginX={'auto'}>
            <p className='border-red-400 border-2 rounded-sm bg-red-100 py-1 text-red-500'>
              {errorCodeToString(error)}
            </p>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <TextInput
            disabled={signInLoading}
            placeholder={t().email.singular.label}
            label={t().email.singular.label}
            onChange={value => setEmail(value)}
            testId={testIds.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            disabled={signInLoading}
            type='password'
            placeholder={t().password.singular.label}
            label={t().password.singular.label}
            onChange={value => setPassword(value)}
            testId={testIds.password}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={0.5}>
            <Grid item xs={12}>
              <Button
                style='secondary'
                loading={signInLoading}
                testId={testIds.login}
                onClick={handleSignIn}
                text={t().signIn.singular.label}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={signInLoading}
                onClick={() => navigate('/reset')}
                text={t().forgotPassword.singular.label}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={3}>
          <Grid container rowSpacing={0.5}>
            <Grid item xs={12} textAlign={'center'}>
              <p>{t().noAccountYetQuestion.singular.upper}</p>
            </Grid>
            <Grid item xs={12}>
              <Button disabled={signInLoading} onClick={() => navigate('/signup')} text={t().signUp.singular.label} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
