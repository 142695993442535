import React, { useContext } from 'react';
import { ProductMasterData } from '../../../../../types/productMasterData';
import ProductMap from '../../../Common/ProductMap';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import { ProductContext } from '../../../../../context/ProductContext';
import { Product } from '../../../../../types/product';
import FlexPane from '../../../../../VentoryUI/components/common/FlexPane';

interface ProductMapPaneInputProps {
  productMasterData?: ProductMasterData;
  footer: () => JSX.Element;
}

export default function ProductMapPane({ productMasterData: pmd, footer }: ProductMapPaneInputProps) {
  if (!pmd) return null; // TODO: Entity not found

  const { stockLocations } = useContext(StockLocationContext);
  const { products } = useContext(ProductContext);

  const locations = () => {
    const items = [...(products.get(pmd.id)?.values() || [])].filter(p => stockLocations.has(p.stockLocationId));

    const map = new Map<string, Product[]>();
    items.forEach(product => map.set(product.stockLocationId, [...(map.get(product.stockLocationId) || []), product]));
    return [...map.entries()].map(([key, value]) => {
      return {
        id: key,
        quantity: value.reduce((sum, p) => sum + parseFloat(p.quantity), 0),
      };
    });
  };

  return <FlexPane content={<ProductMap productMasterData={pmd} locations={locations()} />} footer={footer()} />;
}
