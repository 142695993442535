import React, { useContext, useEffect, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { Grid } from '@mui/material';
import { OrderExportConfigurationContext } from '../../../../../context/OrderExportConfigurationContext';
import OrderExportConfigurationItem from '../Settings/Panes/Export/OrderExportConfigurationItem';
import {
  CsvExportFile,
  ReportMutations,
  ReportOrderResponse,
  ReportOrderVariables,
} from '../../../../../graphql/report.graphql';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { CompanyContext } from '../../../../../context/CompanyContext';

import { zipAndDownloadExportFiles } from '../../../../../util/export.util';
import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import Button from '../../../../../VentoryUI/components/common/Button';

interface ExportOrderModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  ids: Set<string>;
}

export function ExportOrderModal({ open, setOpen, ids }: ExportOrderModalProps) {
  const { orderExportConfigurations, orderExportConfigurationsLoading } = useContext(OrderExportConfigurationContext);
  const { currentCompany } = useContext(CompanyContext);

  const [error, setError] = useState<string>('');
  const [selected, setSelected] = useState<Set<string>>(
    new Set([...orderExportConfigurations.values()].filter(config => config.default).map(config => config.id)),
  );

  useEffect(() => {
    if (!orderExportConfigurationsLoading)
      setSelected(
        new Set([...orderExportConfigurations.values()].filter(config => config.default).map(config => config.id)),
      );
  }, [orderExportConfigurationsLoading]);

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const [download, { loading: downloadLoading }] = useMutation<ReportOrderResponse, ReportOrderVariables>(
    ReportMutations.order,
  );

  const handleDownload = async () => {
    const files: CsvExportFile[] = [];
    let hasNext = true;
    let page = 0;

    while (hasNext) {
      const response = await download({
        variables: {
          orderIds: [...ids.values()],
          page: page,
          timezone: moment.tz.guess(),
          locale: (window.navigator as any)['userLanguage'] || window.navigator.language,
          companyId: currentCompany.id,
          configurationIds: [...selected.values()],
        },
      });

      files.push(...(response.data?.generateOrderExport.data || []));

      hasNext = response.data?.generateOrderExport.hasNext || false;
      page++;
    }

    if (!files.length) return console.error('No files to download');
    zipAndDownloadExportFiles(files, orderExportConfigurations, `ventory_orders_${new Date().toISOString()}.zip`);

    handleClose();
    setSelected(new Set());
  };

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t().exportOrders.singular.label}>
      <ModalPane
        footer={
          <Grid container columnSpacing={1} justifyContent={'flex-end'}>
            <Grid item>
              <Button onClick={() => handleClose()} text={t().cancel.singular.label} disabled={downloadLoading} />
            </Grid>
            <Grid item>
              <Button
                style='secondary'
                onClick={handleDownload}
                text={t().export.singular.label}
                loading={downloadLoading}
                disabled={!selected.size}
              />
            </Grid>
          </Grid>
        }
      >
        <Grid container alignContent={'space-between'}>
          <Grid item xs={12}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <p className='text-sm font-semibold'>
                  {'Please select the configuration(s) you would like to use to export the selected order(s)'}
                </p>
              </Grid>
              <Grid item xs={12} mb={1}>
                <Grid container maxHeight={'500px'} overflow={'auto'}>
                  {[...orderExportConfigurations.values()].map(config => (
                    <Grid key={config.id} item>
                      <OrderExportConfigurationItem
                        onClick={() => {
                          selected.has(config.id) ? selected.delete(config.id) : selected.add(config.id);
                          setSelected(new Set(selected));
                        }}
                        configuration={config}
                        key={config.id}
                        ids={selected}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
