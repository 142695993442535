import React, { useContext } from 'react';
import { ProductMasterData } from '../../../../../types/productMasterData';
import CommentSection from '../../../Common/CommentSection';
import { Comment, EntityType } from '../../../../../types/comment';
import { useMutation } from '@apollo/client';
import {
  CommentMutations,
  CreateCommentResponse,
  CreateCommentVariables,
} from '../../../../../graphql/comment.graphql';
import { CommentContext } from '../../../../../context/CommentContext';

interface ProductMasterDataCommentPaneInputProps {
  footer: (input: ProductMasterData) => JSX.Element;
  productMasterData?: ProductMasterData;
}

export default function ProductMasterDataCommentPane({
  productMasterData,
  footer,
}: ProductMasterDataCommentPaneInputProps) {
  const { comments, setComments } = useContext(CommentContext);

  if (!productMasterData) return null;

  const [create, { loading: createLoading }] = useMutation<CreateCommentResponse, CreateCommentVariables>(
    CommentMutations.create,
    {
      onCompleted: res => {
        res.createComment.forEach(comment => {
          const index = comment.parentId || comment.id;
          comments.set(index, (comments.get(index) || new Map()).set(comment.id, new Comment(comment)));
        });
        setComments(new Map(comments));
      },
    },
  );

  const handleSend = async (comment: Comment) => {
    await create({
      variables: {
        comments: [comment],
      },
    });
  };

  return (
    <CommentSection
      onSend={handleSend}
      loading={createLoading}
      comments={comments}
      type={EntityType.pmd}
      entityId={productMasterData.id}
    />
  );
}
