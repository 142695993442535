import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import StaticTimeFilter, { StaticTimeFilterType } from '../../filters/StaticTimeFilter/';
import { NumberChartContainer } from '../../../../ui/pages/Dashboard/Graphs/Containers/NumberChartContainer';
import { BarChartContainer } from '../../../../ui/pages/Dashboard/Graphs/Containers/BarChartContainer';
import { NumberChartType } from '../../charts/NumberChart/NumberChart';
import { t } from '../../../../types/translation/Translator';
import { FilterProps, FilterStorageKey } from '../../filters/common/filter.util';
import { CompanyContext } from '../../../../context/CompanyContext';
import { BinStatusContext } from '../../../../context/BinStatusContext';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import { TagRelationContext } from '../../../../context/TagRelationContext';
import { UserContext } from '../../../../context/UserContext';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { useNavigate } from 'react-router-dom';
import { OrderTableSettingsContext } from '../../../../context/OrderTableSettingsContext';
import { OrderContext } from '../../../../context/OrderContext';
import dayjs, { Dayjs } from 'dayjs';

import { ContainerContext } from '../../../../context/ContainerContext';
import { TaskFilter } from '../../filters/TaskFilter';
import { OrderFilter } from '../../filters/OrderFilter';
import { ProductTransactionFilter } from '../../filters/ProductTransactionFilter';

interface OverviewMetricsProps {
  color: string;
}

export default function OverviewMetrics({ color }: OverviewMetricsProps) {
  const navigate = useNavigate();

  const { currentCompany } = useContext(CompanyContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { companyUsers } = useContext(UserContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { tagRelations } = useContext(TagRelationContext);
  const { orders } = useContext(OrderContext);
  const { orderTableSettings } = useContext(OrderTableSettingsContext);
  const { containers } = useContext(ContainerContext);

  const [filter, setFilter] = useState<FilterProps>(
    new FilterProps(StaticTimeFilterType.getTimeFrame(StaticTimeFilterType.WEEK), StaticTimeFilterType.WEEK),
  );

  const hasTasks = !!currentCompany.settings.featureToggles.tasks.tasks;
  const hasOrders = !!currentCompany.settings.featureToggles.orders.orders;

  const getTaskFilter = (key: string) => {
    return new TaskFilter(stockLocations, companyUsers, binStatuses, productMasterData, tagRelations, key);
  };

  const getOrderFilter = (key: string) => {
    return new OrderFilter(stockLocations, orderTableSettings, tagRelations, companyUsers, key);
  };

  const getTransactionFilter = (key: string) => {
    return new ProductTransactionFilter(companyUsers, productMasterData, orders, tagRelations, containers, key);
  };

  return (
    <>
      <Grid item xs={12}>
        <SectionTitle text={t().overview.singular.label} />
      </Grid>
      <Grid item xs={12}>
        <StaticTimeFilter filter={filter} setFilter={setFilter} />
      </Grid>
      <Grid item xs={12}>
        <Grid container columnSpacing={1}>
          <Grid item xs={12} sm={12} md={4} lg={2}>
            <Grid container columnSpacing={1} display={'flex'} className='h-full'>
              <NumberChartContainer
                filter={filter}
                type={NumberChartType.text}
                entity={FilterStorageKey.STOCK_LOCATION}
                size={{ xs: 12, sm: 4, md: 12 }}
                text={t().stockLocation.plural.label}
                color={color}
              />
              {hasTasks ? (
                <NumberChartContainer
                  filter={filter}
                  type={NumberChartType.text}
                  entity={FilterStorageKey.TASK}
                  size={{ xs: 12, sm: 4, md: 12 }}
                  text={t().tasks.singular.label}
                  color={color}
                  onClick={() => {
                    const key = 'om-task';
                    getTaskFilter(key).saveFilterProps({ ...filter });
                    navigate(`/tasks?filter=${key}`);
                  }}
                />
              ) : (
                <NumberChartContainer
                  filter={filter}
                  type={NumberChartType.text}
                  entity={FilterStorageKey.PRODUCT_TRANSACTION}
                  size={{ xs: 12, sm: 4, md: 12 }}
                  text={t().transactions.singular.label}
                  color={color}
                  onClick={() => {
                    const key = 'om-transaction';
                    getTransactionFilter(key).saveFilterProps({ ...filter });
                    navigate(`/stock/history?filter=${key}`);
                  }}
                />
              )}
              {hasOrders ? (
                <NumberChartContainer
                  filter={filter}
                  type={NumberChartType.text}
                  entity={FilterStorageKey.ORDER}
                  size={{ xs: 12, sm: 4, md: 12 }}
                  text={t().order.plural.label}
                  color={color}
                  onClick={() => {
                    const key = 'om-order';
                    getOrderFilter(key).saveFilterProps({ ...filter });
                    navigate(`/operations/orders?filter=${key}`);
                  }}
                />
              ) : (
                <NumberChartContainer
                  filter={filter}
                  type={NumberChartType.text}
                  entity={FilterStorageKey.PRODUCT_TRANSACTION}
                  size={{ xs: 12, sm: 4, md: 12 }}
                  text={t().transactions.singular.label}
                  color={color}
                  onClick={() => {
                    const key = 'om-transaction';
                    getTransactionFilter(key).saveFilterProps({ ...filter });
                    navigate(`/stock/history?filter=${key}`);
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={10} display={'flex'}>
            <BarChartContainer
              entity={FilterStorageKey.PRODUCT_TRANSACTION}
              text={t().numberOfTransactions.singular.label}
              color={color}
              filter={filter}
              onClick={(idx: number) => {
                const diff = dayjs(filter.timeFrame?.end).diff(dayjs(filter.timeFrame?.start), 'day');

                let startDate: Dayjs | undefined = undefined;
                let endDate: Dayjs | undefined = undefined;
                if (diff <= 29) {
                  startDate = dayjs(filter.timeFrame?.start).add(idx, 'day').startOf('day');
                  endDate = startDate.endOf('day');
                } else {
                  startDate = dayjs(filter.timeFrame?.start).add(idx, 'month').startOf('month').startOf('day');
                  endDate = startDate.endOf('month').endOf('day');
                }

                const key = 'om-transaction';
                getTransactionFilter(key).saveFilterProps({
                  ...filter,
                  timeFrame: {
                    start: startDate.toDate(),
                    end: endDate.toDate(),
                  },
                });
                navigate(`/stock/history?filter=${key}`);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
