import React, { useContext, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { Grid } from '@mui/material';
import TextInput from '../../../Common/TextInput';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { Contact } from '../../../../../types/contact';
import { useMutation } from '@apollo/client';
import {
  ContactMutations,
  CreateContactResponse,
  CreateContactVariables,
} from '../../../../../graphql/contact.graphql';
import { ContactContext } from '../../../../../context/ContactContext';
import { testIds } from '../../../../../util/identifiers/identifiers.util';

import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import Button from '../../../../../VentoryUI/components/common/Button';

interface CreateContactModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function CreateContactModal({ open, setOpen }: CreateContactModalProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { contacts, setContacts } = useContext(ContactContext);

  const [contactInput, setContactInput] = useState<Contact>(new Contact({ companyId: currentCompany.id }));
  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<CreateContactResponse, CreateContactVariables>(ContactMutations.create, {
    onCompleted: res => {
      res.createContact.forEach(contact => contacts.set(contact.id, new Contact(contact)));
      setContacts(new Map(contacts));
      handleClose();
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async () => {
    try {
      await create({
        variables: {
          contacts: [contactInput],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  return (
    <Modal
      open={open}
      error={error}
      onClose={handleClose}
      title={t().createContact.singular.label}
      testId={testIds.createContactModal}
    >
      <ModalPane
        footer={
          <Grid container justifyContent={'flex-end'} columnSpacing={1}>
            <Grid item>
              <Button
                disabled={loading}
                onClick={handleClose}
                testId={testIds.cancel}
                text={t().cancel.singular.label}
              />
            </Grid>
            <Grid item>
              <Button
                loading={loading}
                disabled={!contactInput.name}
                onClick={handleCreate}
                testId={testIds.create}
                style={'secondary'}
                text={t().create.singular.label}
              />
            </Grid>
          </Grid>
        }
      >
        <Grid container alignContent={'space-between'}>
          <Grid item xs={12} height={'250px'}>
            <Grid container columnSpacing={1}>
              <Grid item xs={6}>
                <TextInput
                  mandatory
                  label={t().name.singular.label}
                  placeholder={t().name.singular.label}
                  onChange={v => setContactInput(contactInput.withName(v))}
                  testId={testIds.name}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  value={contactInput.email}
                  label={t().contactEmail.singular.label}
                  placeholder={t().contactEmail.singular.label}
                  onChange={v => contactInput.withEmail(v)}
                  testId={testIds.contactEmail}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
