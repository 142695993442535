import { Grid } from '@mui/material';
import React from 'react';
import { ContainerType } from '../../../../../../../types/containerType';
import { t } from '../../../../../../../types/translation/Translator';
import TextInput from '../../../../../Common/TextInput';

interface ContainerTypeItemProps {
  containerType: ContainerType;
  setContainerType: (type: ContainerType) => void;
}

export default function ContainerTypeItem({ containerType, setContainerType }: ContainerTypeItemProps) {
  const isEdit = !!containerType.id?.length;

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextInput
          mandatory
          onChange={value => setContainerType(containerType.withName(value))}
          placeholder={t().name.singular.label}
          label={t().name.singular.label}
          value={containerType.name}
        />
      </Grid>
    </Grid>
  );
}
