import React from 'react';
import StatusLabel from '../../../VentoryUI/components/common/StatusLabel/StatusLabel';
import { OrderStatus, OrderUtil } from '../../../types/order';

interface OrderStatusLabelProps {
  status: OrderStatus;
  justify?: 'start' | 'center' | 'end';
}

export default function OrderStatusLabel({ status }: OrderStatusLabelProps) {
  return <StatusLabel color={OrderUtil.statusToColorStyle(status)} text={OrderUtil.statusToString(status)} />;
}
