import React, { useContext } from 'react';
import { ProductTransactionExportConfigurationContext } from '../../../../../../context/ProductTransactionExportConfigurationContext';
import LoadingPackage from '../../../../Common/LoadingPackage';
import { Grid } from '@mui/material';
import ProductTransactionExportConfigurationItem from './Export/ProductTransactionExportConfigurationItem';
import Paper from '../../../../../../VentoryUI/components/common/Paper';

export default function ProductTransactionReportPane() {
  const {
    productTransactionExportConfigurations,
    setProductTransactionExportConfigurations,
    productTransactionExportConfigurationsLoading,
  } = useContext(ProductTransactionExportConfigurationContext);

  if (productTransactionExportConfigurationsLoading) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  return (
    <Paper>
      <Grid container>
        {[...productTransactionExportConfigurations.values()].map(configuration => (
          <Grid key={configuration.id} item>
            <ProductTransactionExportConfigurationItem configuration={configuration} key={configuration.id} />
          </Grid>
        ))}
        <Grid item>
          <ProductTransactionExportConfigurationItem />
        </Grid>
      </Grid>
    </Paper>
  );
}
