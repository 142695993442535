import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../../../Common/TextInput';
import { t } from '../../../../../../types/translation/Translator';
import { CompanyRoleAssignment } from '../../../../../../types/companyRoleAssignment';
import ModalPane from '../../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { NextButtonTemplate } from '../../../../../../VentoryUI/components/common/Button/Templates/NextButton/NextButton';

interface InviteUserEmailPaneProps {
  companyRoleAssignment: CompanyRoleAssignment;
  setCompanyRoleAssignment: (role: CompanyRoleAssignment) => void;
  next: () => void;
  back: () => void;
}

export default function InviteUserEmailPane({
  companyRoleAssignment,
  setCompanyRoleAssignment,
  next,
  back,
}: InviteUserEmailPaneProps) {
  return (
    <ModalPane footerButtons={[NextButtonTemplate(next, { disabled: !companyRoleAssignment.email })]}>
      <Grid container alignContent={'space-between'}>
        <Grid item xs={12}>
          <TextInput
            mandatory
            label={t().userInviteEmail.singular.upper}
            placeholder={t().email.singular.label}
            onChange={v => setCompanyRoleAssignment(companyRoleAssignment.withEmail(v))}
            value={companyRoleAssignment.email}
          />
        </Grid>
      </Grid>
    </ModalPane>
  );
}
