import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { ProductTransactionFragments } from './productTransaction.graphql';
import { ProductTransaction, ProductTransactionSpecifiers } from '../types/productTransaction';
import { AddressFragments } from './common/address.graphql';
import { Order } from '../types/order';
import { CreateOrderInput, DeleteOrderInput, UpdateOrderInput } from '../types/order/order.input';

const order = gql`
  fragment Order on Order {
    id
    version
    companyId
    createdAt
    createdBy
    updatedAt
    status
    type
    parentType
    parentId
    stockLocationId
    contactId
    number
    contactLocation {
      name
      address {
        ...Address
      }
      email
      stockLocationId
    }
    purchaseOrderNumber
    type
    estimatedTimeOfArrival
    assignedTo
    customFields {
      id
      name
      type
      value
      mandatory
    }
    products {
      pmdId
      toStockLocationId
    }
    externalIdentifier {
      externalId
      settingsId
    }
  }
  ${AddressFragments.address}
`;

const get = gql`
  query Orders($companyId: String!, $page: Float, $since: Date) {
    orders(companyId: $companyId, page: $page, since: $since) {
      data {
        ...Order
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${order}
`;

export interface GetOrdersVariables {
  companyId: string;
  page?: number;
  since?: Date;
}

export interface GetOrdersResponse {
  orders: PaginatedOrders;
}

class PaginatedOrders extends forPaginated<Order>() {}

const create = gql`
  mutation CreateOrder($orders: [CreateOrderInput!]!) {
    createOrder(orders: $orders) {
      ...Order
    }
  }
  ${order}
`;

export interface CreateOrderVariables {
  orders: CreateOrderInput[];
}

export interface CreateOrderResponse {
  createOrder: Order[];
}

const update = gql`
  mutation UpdateOrder($orders: [UpdateOrderInput!]!) {
    updateOrder(orders: $orders) {
      ...Order
    }
  }
  ${order}
`;

export interface UpdateOrderVariables {
  orders: UpdateOrderInput[];
}

export interface UpdateOrderResponse {
  updateOrder: Order[];
}

const remove = gql`
  mutation DeleteOrder($orders: [DeleteOrderInput!]!) {
    deleteOrder(orders: $orders) {
      ...Order
    }
  }
  ${order}
`;

export interface DeleteOrderVariables {
  orders: DeleteOrderInput[];
}

export interface DeleteOrderResponse {
  deleteOrder: Order[];
}

const addOrderProduct = gql`
  mutation AddOrderProduct(
    $companyId: String!
    $orderId: String!
    $specifiers: [CreateProductTransactionSpecifiersInput!]!
  ) {
    addOrderProduct(companyId: $companyId, orderId: $orderId, specifiers: $specifiers) {
      ...ProductTransaction
    }
  }
  ${ProductTransactionFragments.productTransaction}
`;

export interface AddOrderProductVariables {
  companyId: string;
  orderId: string;
  specifiers: ProductTransactionSpecifiers[];
}

export interface AddOrderProductResponse {
  addOrderProduct: ProductTransaction[];
}

const cancelOrder = gql`
  mutation CancelOrder($companyId: String!, $orderId: String!) {
    cancelOrder(companyId: $companyId, orderId: $orderId) {
      ...Order
    }
  }
  ${order}
`;

export interface CancelOrderVariables {
  companyId: string;
  orderId: string;
}

export interface CancelOrderResponse {
  cancelOrder: Order;
}

const completeOrder = gql`
  mutation CompleteOrder($companyId: String!, $orderId: String!) {
    completeOrder(companyId: $companyId, orderId: $orderId) {
      ...Order
    }
  }
  ${order}
`;

export interface CompleteOrderVariables {
  companyId: string;
  orderId: string;
}

export interface CompleteOrderResponse {
  completeOrder: Order;
}

export const OrderQueries = {
  get,
};

export const OrderMutations = {
  create,
  update,
  remove,
  addOrderProduct,
  cancelOrder,
  completeOrder,
};

export const OrderFragments = {
  order,
};
