import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forUpdate } from './common/entity';
import dayjs from 'dayjs';

export enum VentorySubscriptionService {
  stripe = 'stripe',
  microsoft = 'microsoft',
  manual = 'manual',
}

export enum VentorySubscriptionType {
  demo = 'demo',
  trial = 'trial',
  poc = 'poc',

  custom = 'custom',
  basic = 'basic',
  core = 'core',
  business = 'business',
  enterpriseBasic = 'enterpriseBasic',
  enterpriseAdvanced = 'enterpriseAdvanced',
}

export enum VentorySubscriptionState {
  active = 'active',
  expired = 'expired',

  cancelled = 'cancelled',

  failed = 'failed',
  pending = 'pending',
  deleted = 'deleted',

  // TO REMOVE
  trial = 'trial',
}

export class VentorySubscription extends CompanyEntity {
  public service: VentorySubscriptionService = VentorySubscriptionService.manual;
  public type: VentorySubscriptionType = VentorySubscriptionType.demo;
  public state: VentorySubscriptionState = VentorySubscriptionState.active;

  public externalId: string = '00000000-0000-0000-0000-000000000000';

  public expiresOn: Date = dayjs().add(1, 'month').toDate();

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  forUpdate(): UpdateVentorySubscriptionInput {
    this.validate();
    return UpdateVentorySubscriptionInput.from(this, UpdateVentorySubscriptionInput);
  }

  forDelete() {
    return this;
  }

  withService(service: VentorySubscriptionService) {
    this.service = service;
    return cloneDeep(this);
  }

  withType(type: VentorySubscriptionType) {
    this.type = type;
    return cloneDeep(this);
  }

  withState(state: VentorySubscriptionState) {
    this.state = state;
    return cloneDeep(this);
  }

  static typeToString(type: VentorySubscriptionType) {
    switch (type) {
      case VentorySubscriptionType.demo:
        return 'Demo';
      case VentorySubscriptionType.trial:
        return 'Trial';
      case VentorySubscriptionType.poc:
        return 'PoC';
      case VentorySubscriptionType.custom:
        return 'Custom';
      case VentorySubscriptionType.basic:
        return 'Basic';
      case VentorySubscriptionType.core:
        return 'Core';
      case VentorySubscriptionType.business:
        return 'Business';
      case VentorySubscriptionType.enterpriseBasic:
        return 'Enterprise Basic';
      case VentorySubscriptionType.enterpriseAdvanced:
        return 'Enterprise Advanced';
    }
  }

  validate() {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof VentorySubscription)[],
      (field: keyof VentorySubscription) => {
        return null;
      },
    );
  }
}

export class UpdateVentorySubscriptionInput extends forUpdate(VentorySubscription) {}
