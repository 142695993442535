import React, { useContext, useEffect, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { BinContext } from '../../../../../context/BinContext';
import { Bin, SystemBin } from '../../../../../types/bin';
import BinImportModal from '../Modals/BinImportModal';
import UpdateBinModal from './Bin/Modal/UpdateBinModal';
import CreateBinModal from './Bin/Modal/CreateBinModal';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { BinStatusContext } from '../../../../../context/BinStatusContext';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { useNavigate } from 'react-router-dom';
import SearchBarWithFilter from '../../../../../VentoryUI/components/common/SearchBarWithFilter';
import { NewMenuItemTemplate } from '../../../../../VentoryUI/components/common/Menu/Templates/NewMenuItem';
import { ImportMenuItemTemplate } from '../../../../../VentoryUI/components/common/Menu/Templates/ImportMenuItem';
import { SettingsMenuItemTemplate } from '../../../../../VentoryUI/components/common/Menu/Templates/SettingsMenuItem';
import Table from '../../../../../VentoryUI/components/common/Table';
import FlexPane from '../../../../../VentoryUI/components/common/FlexPane';
import BinStatusLabel from '../../../Common/BinStatusLabel';
import { BinFilter } from '../../../../../VentoryUI/components/filters/BinFilter';
import { TagRelationContext } from '../../../../../context/TagRelationContext';

interface StockLocationBinInputProps {
  stockLocationId?: string;
  footer: () => JSX.Element;
}

export default function StockLocationBinPane({ stockLocationId, footer }: StockLocationBinInputProps) {
  if (!stockLocationId) return null; // TODO: Not found

  const navigate = useNavigate();

  const { bins } = useContext(BinContext);
  const { currentCompany } = useContext(CompanyContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { tagRelations } = useContext(TagRelationContext);

  const [filter, setFilter] = useState<string>('');
  const [selected, setSelected] = useState<string | null>(null);

  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

  const getBinsForStockLocation = () => {
    return [...bins.values()].filter(b => b.stockLocationId === stockLocationId && b.id !== SystemBin.id) || [];
  };

  const [binsForStockLocation, setBinsForStockLocation] = useState<Bin[]>(getBinsForStockLocation());
  const [items, setItems] = useState(binsForStockLocation);

  const headers = [
    {
      key: 'name',
      name: t().name.singular.label,
      text: (item: Bin) => item.name,
    },
    {
      key: 'binStatus',
      name: t().binStatus.singular.label,
      text: (item: Bin) => <BinStatusLabel status={binStatuses.get(item.binStatusId)} />,
    },
  ];

  useEffect(() => {
    const newBins = getBinsForStockLocation();
    setBinsForStockLocation([...newBins]);
  }, [bins]);

  return (
    <>
      <BinImportModal open={openImportModal} setOpen={setOpenImportModal} />
      <CreateBinModal
        open={openCreateModal}
        setOpen={setOpenCreateModal}
        bin={new Bin({ companyId: currentCompany.id, stockLocationId: stockLocationId })}
      />
      {selected && bins.has(selected) ? (
        <UpdateBinModal
          open={openUpdateModal}
          setOpen={value => {
            setOpenUpdateModal(value);
            setSelected('');
          }}
          bin={bins.get(selected || '')}
        />
      ) : null}

      <FlexPane
        testId={testIds.stockLocationBinsPane}
        header={
          <SearchBarWithFilter
            items={binsForStockLocation}
            setItems={setItems}
            menuItems={[
              NewMenuItemTemplate(() => setOpenCreateModal(true)),
              ImportMenuItemTemplate(() => setOpenImportModal(true)),
              SettingsMenuItemTemplate(() => navigate('/settings/stock_location/bins/advanced/import_configurations')),
            ]}
            placeholder={t().filterStockLocationBins.singular.label}
            testId={testIds.bins}
            filter={new BinFilter(binStatuses, tagRelations)}
          />
        }
        content={
          <Table
            items={items}
            headers={headers}
            onClick={item => {
              setOpenUpdateModal(true);
              setSelected(item.id);
            }}
          />
        }
        footer={footer()}
      />
    </>
  );
}
