import React, { useContext } from 'react';
import { ReorderRule } from '../../../../../types/reorderRule';
import { Grid } from '@mui/material';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import { BinContext } from '../../../../../context/BinContext';
import { t } from '../../../../../types/translation/Translator';
import Selector from '../../Selector';
import { BinStatusContext } from '../../../../../context/BinStatusContext';

import Paper from '../../../../../VentoryUI/components/common/Paper';

interface ReorderRuleBinPaneProps {
  rule: ReorderRule;
  setRule: (rule: ReorderRule) => void;
  stockLocationIds: string[];
}

export default function ReorderRuleBinPane({ rule, setRule, stockLocationIds }: ReorderRuleBinPaneProps) {
  const { bins } = useContext(BinContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { stockLocations } = useContext(StockLocationContext);

  const filteredBins = [...bins.values()]
    .filter(bin => {
      if (rule.excludedBinStatusIds.includes(bin.binStatusId)) return false;
      if (stockLocationIds.length && !stockLocationIds.includes(bin.stockLocationId)) return false;

      return true;
    })
    .map(bin => bin.id);

  const handleBinStatusChange = (bs: string[]) => {
    setRule(rule.withExcludedBinStatusIds(bs));
    setRule(
      rule.withExcludedBinIds(
        rule.excludedBinIds.filter(binId => {
          const bin = bins.get(binId);
          if (bin && !bs.includes(bin.binStatusId)) return true;
          return false;
        }),
      ),
    );
  };

  return (
    <Paper>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <p className='text-sm'>{t().selectBinStatusesThatYouWantToExclude.singular.upper}</p>
            </Grid>
            <Grid item xs={12}>
              <Selector
                placeholder={t().selectBinStatuses.singular.label}
                values={[...binStatuses.values()].map(bs => bs.id)}
                checkedValues={rule.excludedBinStatusIds}
                onChange={handleBinStatusChange}
                toText={bs => binStatuses.get(bs)?.status || t().unknownBinStatus.singular.label}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <p className='text-sm'>{t().selectBinsThatYouWantToExclude.singular.upper}</p>
            </Grid>
            <Grid item xs={12}>
              <Selector
                placeholder={t().selectBin.plural.label}
                values={filteredBins}
                checkedValues={rule.excludedBinIds}
                onChange={checked => setRule(rule.withExcludedBinIds(checked))}
                toText={bs => bins.get(bs)?.name || t().unknownBin.singular.label}
                toElement={binId => {
                  return (
                    <div className={'flex'}>
                      <p className={'text-sm select-none mr-3'}>{bins.get(binId)?.name || 'Unknown Bin'} </p>
                      <p className={'text-sm select-none text-gray-500'}>
                        ({stockLocations.get(bins.get(binId)?.stockLocationId || '')?.name || 'Unknown Stock Location'})
                      </p>
                    </div>
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
