import React, { useContext, useMemo, useState } from 'react';
import { Task } from '../../../../types/task';
import { CompanyContext } from '../../../../context/CompanyContext';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { UserContext } from '../../../../context/UserContext';
import { CompanyRoleAssignment } from '../../../../types/companyRoleAssignment';
import { t } from '../../../../types/translation/Translator';
import SearchBar from '../../../../VentoryUI/components/common/SearchBar';
import Table from '../../../../VentoryUI/components/common/Table';
import FlexPane from '../../../../VentoryUI/components/common/FlexPane';

interface TaskUsersPaneProps {
  task: Task;
  footer: () => JSX.Element;
}

export default function TaskUsersPane({ task, footer }: TaskUsersPaneProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { companyUsers } = useContext(UserContext);
  const { companyRoles } = useContext(CompanyRoleAssignmentContext);

  const [filter, setFilter] = useState<string>('');

  const handleFilter = (item: CompanyRoleAssignment) => {
    const lowerCaseFilter = filter.toLowerCase();
    if (
      item.email.toLowerCase().includes(lowerCaseFilter) ||
      item.role.toLowerCase().includes(lowerCaseFilter) ||
      `${companyUsers.get(item.userId)?.firstName} ${companyUsers.get(item.userId)?.lastName}`
        .toLowerCase()
        .includes(lowerCaseFilter)
    ) {
      return true;
    }
    return false;
  };

  const headers = [
    {
      key: 'email',
      name: 'Email',
      text: (item: CompanyRoleAssignment) => item.email,
    },
    {
      key: 'name',
      name: 'Name',
      text: (item: CompanyRoleAssignment) =>
        `${companyUsers.get(item.userId)?.firstName} ${companyUsers.get(item.userId)?.lastName}` || '',
    },
    {
      key: 'type',
      name: 'Type',
      text: (item: CompanyRoleAssignment) =>
        task.assignedTo.includes(item.userId) ? t().initialCounter.singular.label : t().recounter.singular.label,
    },
  ];

  const filteredItems = useMemo(() => {
    return [...companyRoles.values()]
      .flat()
      .filter(
        r =>
          r.companyId === currentCompany.id &&
          (task.assignedTo.includes(r.userId) || task.assignedForRecount.includes(r.userId)),
      )
      .filter(handleFilter);
  }, [filter, companyRoles]);

  return (
    <FlexPane
      header={<SearchBar onChange={value => setFilter(value)} placeholder={t().filterUsers.singular.label} />}
      content={<Table items={filteredItems} headers={headers} />}
      footer={footer()}
    />
  );
}
