import { Divider, Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { IntegrationContext } from '../../../../../../../context/IntegrationContext';
import IntegrationBusinessCentralSettingsInfo from './Common/IntegrationBusinessCentralSettingsInfo';
import { IntegrationSettings } from '../../../../../../../types/integrationSettings';
import LoadingPackage from '../../../../../Common/LoadingPackage';
import { useLazyQuery } from '@apollo/client';
import {
  BusinessCentralQueries,
  BusinessCentralSyncItemsResponse,
  BusinessCentralSyncItemsVariables,
  BusinessCentralSyncLocationsResponse,
  BusinessCentralSyncLocationsVariables,
  BusinessCentralSyncPurchaseOrdersResponse,
  BusinessCentralSyncPurchaseOrdersVariables,
} from '../../../../../../../graphql/businessCentral.graphql';
import { CompanyContext } from '../../../../../../../context/CompanyContext';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import Button from '../../../../../../../VentoryUI/components/common/Button';
import { t } from '../../../../../../../types/translation/Translator';

interface UpdateIntegrationBusinessCentralSettingsPaneProps {
  id: string;
  setError: (error: string) => void;
}

export default function UpdateIntegrationBusinessCentralSettingsPane({
  id,
  setError,
}: UpdateIntegrationBusinessCentralSettingsPaneProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { integrationSettings, integrationSettingsLoading } = useContext(IntegrationContext);

  const [input, setInput] = useState<IntegrationSettings | undefined>(integrationSettings.get(id));

  const [syncItems, { loading: syncItemsLoading }] = useLazyQuery<
    BusinessCentralSyncItemsResponse,
    BusinessCentralSyncItemsVariables
  >(BusinessCentralQueries.syncItems, {
    variables: {
      settingsId: id,
      companyId: currentCompany.id,
    },
    onCompleted: res => {
      setError('');
    },
    onError: err => {
      setError(err.message);
    },
  });

  const [syncLocations, { loading: syncLocationsLoading }] = useLazyQuery<
    BusinessCentralSyncLocationsResponse,
    BusinessCentralSyncLocationsVariables
  >(BusinessCentralQueries.syncLocations, {
    variables: {
      settingsId: id,
      companyId: currentCompany.id,
    },
    onCompleted: res => {
      setError('');
    },
    onError: err => {
      setError(err.message);
    },
  });

  const [syncPurchaseOrders, { loading: syncPurchaseOrdersLoading }] = useLazyQuery<
    BusinessCentralSyncPurchaseOrdersResponse,
    BusinessCentralSyncPurchaseOrdersVariables
  >(BusinessCentralQueries.syncPurchaseOrders, {
    variables: {
      settingsId: id,
      companyId: currentCompany.id,
    },
    onCompleted: res => {
      setError('');
    },
    onError: err => {
      setError(err.message);
    },
  });

  if (!input) {
    return (
      <Grid container alignItems={'center'}>
        <Grid item className='fill-gray-300 ' marginX={'auto'}>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container alignContent={'start'} rowSpacing={1}>
      {input ? (
        <IntegrationBusinessCentralSettingsInfo settingsInput={input} setSettingsInput={setInput} disabled={true} />
      ) : null}
      <Grid item my={2} xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={12}>
            <p className='text-sm font-semibold'>{t().actions.singular.label}</p>
          </Grid>
          <Grid item>
            <Button
              startIcon={<CloudSyncIcon />}
              onClick={() => syncItems()}
              loading={syncItemsLoading}
              text={t().syncItems.singular.label}
            />
          </Grid>
          <Grid item>
            <Button
              startIcon={<CloudSyncIcon />}
              onClick={() => syncLocations()}
              loading={syncLocationsLoading}
              text={t().syncLocations.singular.label}
            />
          </Grid>
          <Grid item>
            <Button
              startIcon={<CloudSyncIcon />}
              onClick={() => syncPurchaseOrders()}
              loading={syncPurchaseOrdersLoading}
              text={t().syncPurchaseOrders.singular.label}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
