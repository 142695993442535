import { gql, useSubscription } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import React from 'react';
import { forNotification } from '../Common/forNotification';
import { OrderFragments } from '../../graphql/order.graphql';
import { Order } from '../../types/order/order';
import { OrderContext } from '../../context/OrderContext';

const orderSubscription = gql`
  subscription orderNotification($companyId: String!) {
    orderNotification(companyId: $companyId) {
      companyId
      entities {
        ...Order
      }
      topic
      date
    }
  }
  ${OrderFragments.order}
`;

export class OrderNotificationResponse extends forNotification<Order>('orderNotification') {}

export default function OrderNotification({}) {
  const { currentCompany } = useContext(CompanyContext);
  const { orders, setOrders } = useContext(OrderContext);

  if (!currentCompany) return null;

  const { data: orderNotification } = useSubscription<OrderNotificationResponse>(orderSubscription, {
    variables: { companyId: currentCompany.id },
  });

  const handleOrderNotification = (notification?: OrderNotificationResponse) => {
    if (!notification?.orderNotification) return;
    const orderNotification = notification.orderNotification;

    switch (orderNotification.topic) {
      case 'CREATE':
      case 'UPDATE':
        orderNotification.entities.forEach(order => orders.set(order.id, new Order(order)));
        break;
      case 'DELETE':
        orderNotification.entities.forEach(order => (orders.has(order.id) ? orders.delete(order.id) : null));
    }
    setOrders(new Map(orders));
  };

  useEffect(() => {
    handleOrderNotification(orderNotification);
  }, [orderNotification]);

  return <></>;
}
