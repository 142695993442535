import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { TranslationLanguage } from './translation/translation';

export enum SuperUserRole {
  administrator = 'administrator',
  manager = 'manager',
  employee = 'employee',
  external = 'external',
  translator = 'translator',
}

export class SuperUser extends CompanyEntity {
  email!: string;
  userId!: string;
  role!: SuperUserRole;
  languages!: TranslationLanguage[];

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    if (!this.languages) this.languages = [];
    Object.assign(this, cloneDeep(obj));
  }

  forUpdate(): UpdateSuperUserInput {
    return UpdateSuperUserInput.from(this, UpdateSuperUserInput);
  }

  forDelete(): DeleteSuperUserInput {
    return DeleteSuperUserInput.from(this, DeleteSuperUserInput);
  }

  validate() {
    return this.validateEntity(Object.getOwnPropertyNames(this) as (keyof SuperUser)[], (field: keyof SuperUser) => {
      return null;
    });
  }

  withEmail(email: string) {
    this.email = email;
    return cloneDeep(this);
  }

  withRole(role: SuperUserRole) {
    this.role = role;
    return cloneDeep(this);
  }

  withLanguage(language: TranslationLanguage) {
    this.languages = [...new Set([...this.languages, language])];
    return cloneDeep(this);
  }

  removeLanguage(language: TranslationLanguage) {
    const items = new Set([...this.languages]);
    items.delete(language);
    this.languages = [...items];
    return cloneDeep(this);
  }
}

export class CreateSuperUserInput extends forCreate(SuperUser) {}

export class UpdateSuperUserInput extends forUpdate(SuperUser) {}

export class DeleteSuperUserInput extends forDelete(SuperUser) {}

export function superUserRoleToString(role: SuperUserRole): string {
  switch (role) {
    case SuperUserRole.administrator:
      return 'Administrator';
    case SuperUserRole.manager:
      return 'Manager';
    case SuperUserRole.employee:
      return 'Employee';
    case SuperUserRole.external:
      return 'External';
    case SuperUserRole.translator:
      return 'Translator';
  }
}
