import React from 'react';
import { ShippingLocation } from '../../../../../types/contact';
import { t } from '../../../../../types/translation/Translator';
import { Grid } from '@mui/material';
import ShippingLocationInfoPane from '../Panes/ShippingLocationInfoPane';
import { testIds } from '../../../../../util/identifiers/identifiers.util';

import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import SaveButton from '../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import CancelButton from '../../../../../VentoryUI/components/common/Button/Templates/CancelButton';

interface CreateShippingLocationModalProps {
  open: boolean;
  setOpen: (value: boolean, shippingLocation?: ShippingLocation) => void;
}

export default function CreateShippingLocationModal({ open, setOpen }: CreateShippingLocationModalProps) {
  const footer = (loc: ShippingLocation) => {
    return (
      <Grid container columnSpacing={1} justifyContent={'flex-end'}>
        <Grid item>
          <CancelButton onClick={() => setOpen(false)} />
        </Grid>
        <Grid item>
          <SaveButton disabled={!loc.name} onClick={() => setOpen(false, loc)} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal
      open={open}
      width='1000px'
      onClose={() => setOpen(false)}
      testId={testIds.createShippingLocationModal}
      title={t().createShippingLocation.singular.label}
    >
      <ShippingLocationInfoPane location={new ShippingLocation({})} footer={footer} />
    </Modal>
  );
}
