import { Bin, SystemBin } from '../types/bin';
import { BinStatus } from '../types/binStatus';
import { Container } from '../types/container';
import { Product } from '../types/product';
import { ProductTransaction, ProductTransactionStatus } from '../types/productTransaction';
import { toGroupedMap } from './map.util';

export const MAX_CONTAINER_DEPTH = 25;

export function getAllParentContainers(containers: Map<string, Container>, container: Container | string): Container[] {
  if (typeof container === 'string') {
    const found = containers.get(container);
    if (!found) return [];
    container = found;
  }

  const parents = [];
  let depth = 0;
  const queue = [container];

  while (queue.length) {
    if (depth > MAX_CONTAINER_DEPTH) throw Error('Circular container dependency!');
    const current = queue.shift();

    if (current?.parentId) {
      const parent = containers.get(current?.parentId);
      if (parent) {
        depth++;
        parents.push(parent);
        queue.push(parent);
      }
    }
  }
  return parents;
}

export function getAllChildContainers(containers: Map<string, Container>, container: Container | string): Container[] {
  if (typeof container === 'string') {
    const found = containers.get(container);
    if (!found) return [];
    container = found;
  }

  const containersByParentId = toGroupedMap([...containers.values()], 'parentId');

  const children = [];
  const queue = [{ container, depth: 0 }];

  while (queue.length) {
    const current = queue.shift();
    if (!current) break;
    if (current.depth > MAX_CONTAINER_DEPTH) throw Error('Circular container dependency!');

    const currentChildren = containersByParentId.get(current.container.id);
    if (!currentChildren?.length) continue;

    children.push(...currentChildren);
    queue.push(
      ...currentChildren.map(c => {
        return { container: c, depth: current.depth + 1 };
      }),
    );
  }

  return children;
}

/// Only returns products that are direct descendants
export function getProductsByContainerIds(
  products: Map<string, Map<string, Product>>,
  containerIds: string[],
): Product[] {
  const productsByContainerIds = toGroupedMap([...products.values()].map(m => [...m.values()]).flat(), 'containerId');
  const set = new Set(containerIds);

  return [...set.values()].map(id => productsByContainerIds.get(id) || []).flat();
}

/// Includes all children regardless of depth
export function getAllContainerChildren(
  containers: Map<string, Container>,
  products: Map<string, Map<string, Product>>,
  container: Container,
) {
  const childContainers = getAllChildContainers(containers, container);

  const childProducts = getProductsByContainerIds(
    products,
    [...childContainers, container].map(container => container.id),
  );

  return {
    childContainers,
    childProducts,
  };
}

export function getReservedContainerIds(productTransactions: Map<string, ProductTransaction>): Set<String> {
  return new Set(
    [...productTransactions.values()]
      .filter(pt => pt.status === ProductTransactionStatus.created && pt.product.containerId)
      .map(pt => pt.product.containerId!),
  );
}

export function getOpenProductTransactionsByContainerId(
  productTransactions: Map<string, ProductTransaction>,
  containerId: string,
) {
  return [...productTransactions.values()].filter(
    pt => pt.status === ProductTransactionStatus.created && pt.product.containerId === containerId,
  );
}

export function getAllChildProductTransactions(
  productTransaction: ProductTransaction,
  productTransactions: Map<string, ProductTransaction>,
): ProductTransaction[] {
  const productTransactionsByContainerTransactionId = toGroupedMap(
    [...productTransactions.values()],
    'containerTransactionId',
  );

  const queue = [{ transaction: productTransaction, depth: 0 }];
  const children = [];

  while (queue.length) {
    const current = queue.shift();
    if (!current) break;
    if (current.depth > MAX_CONTAINER_DEPTH) {
      throw Error('Circular container dependency');
    }

    const currentChildren = productTransactionsByContainerTransactionId.get(current.transaction.id);
    if (!currentChildren?.length) continue;

    queue.push(
      ...currentChildren
        .filter(t => t.product.containerId)
        .map(t => {
          return { transaction: t, depth: current.depth + 1 };
        }),
    );
    children.push(...currentChildren);
  }

  return children;
}

interface ValidDestinationContainerProps {
  container: string | Container;

  stockLocationId: string;
  binId?: string;

  bins: Map<string, Bin>;
  binStatuses: Map<string, BinStatus>;

  productTransactions: Map<string, ProductTransaction>;
  containers: Map<string, Container>;

  transaction?: ProductTransaction;
}

export function isValidDestinationContainer({
  container,
  stockLocationId,
  binId,
  bins,
  binStatuses,
  productTransactions,
  containers,
  transaction,
}: ValidDestinationContainerProps) {
  if (typeof container === 'string') {
    const found = containers.get(container);
    if (!found) return false;
    container = found;
  }

  const containerBin = bins.get(container.binId);
  if (!containerBin && container.binId !== SystemBin.id) return false;

  if (container.binId !== SystemBin.id && container.stockLocationId !== stockLocationId) return false;
  if (binId && container.binId !== binId && container.binId !== SystemBin.id) return false;

  if (containerBin) {
    const binStatus = binStatuses.get(containerBin.binStatusId);
    if (!binStatus?.inboundAllowed) return false;
  }

  const parents = getAllParentContainers(containers, container);

  const openTransactions = [container, ...parents]
    .map(el => getOpenProductTransactionsByContainerId(productTransactions, el.id)[0])
    .filter(el => !!el);

  if (!transaction && openTransactions.length) return false;

  if (openTransactions.length) {
    if (!transaction) return false;

    const origin = openTransactions[openTransactions.length - 1];
    const transactionOrigin = getRootContainerTransaction(transaction, productTransactions);

    if (!transactionOrigin) return false;
    if (origin.id !== transactionOrigin.id) return false;
  }

  return true;
}

interface ValidSourceContainerProps {
  container: Container | string;

  stockLocationId: string;
  binId?: string;

  containers: Map<string, Container>;
  bins: Map<string, Bin>;
  binStatuses: Map<string, BinStatus>;
  productTransactions: Map<string, ProductTransaction>;

  transaction: ProductTransaction;
}

export function isValidSourceContainer({
  container,
  stockLocationId,
  binId,
  bins,
  binStatuses,
  containers,
  productTransactions,
  transaction,
}: ValidSourceContainerProps) {
  if (typeof container === 'string') {
    const found = containers.get(container);
    if (!found) return false;
    container = found;
  }

  if (container.stockLocationId !== stockLocationId) return false;
  if (binId && container.binId !== binId) return false;

  const containerBin = bins.get(container.binId);
  if (!containerBin) return false;

  if (containerBin.id === SystemBin.id) return false;

  const binStatus = binStatuses.get(containerBin.binStatusId);
  if (!binStatus?.inboundAllowed) return false;

  const parents = getAllParentContainers(containers, container);

  const openTransactions = [container, ...parents]
    .map(el => getOpenProductTransactionsByContainerId(productTransactions, el.id)[0])
    .filter(el => !!el);

  if (openTransactions.length) {
    if (!transaction) return false;

    const origin = openTransactions[openTransactions.length - 1];
    const transactionOrigin = getRootContainerTransaction(transaction, productTransactions);

    if (!transactionOrigin) return false;
    if (origin.id !== transactionOrigin.id) return false;
  }

  return true;
}

export function getRootContainerTransaction(
  transaction: ProductTransaction,
  productTransactions: Map<string, ProductTransaction>,
): ProductTransaction | undefined {
  if (!transaction.containerTransactionId) return undefined;

  const queue = [transaction];

  let depth = 0;
  while (queue.length) {
    if (depth > MAX_CONTAINER_DEPTH) throw Error('Circular container dependency');
    const current = queue.shift();
    if (!current || !current.containerTransactionId) return current;

    const parent = productTransactions.get(current.containerTransactionId);
    if (!parent) return current;
    depth++;

    queue.push(parent);
  }
}
