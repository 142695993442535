import { Grid } from '@mui/material';
import Color from 'color';
import React, { useContext } from 'react';
import { CompanyContext } from '../../../context/CompanyContext';
import { classes, stringToTestId, TestIdIdentifier } from '../../../util/identifiers/identifiers.util';
import { Colors } from '../../../VentoryUI/util/color.util';

interface ToggleButtonInputProps {
  values: string[];
  selected: string;
  text: (value: string) => string;
  onChange: (value: string) => void;
  testId?: TestIdIdentifier;
}

export default function ToggleButton({ values, selected, text, onChange, testId }: ToggleButtonInputProps) {
  const { currentCompany } = useContext(CompanyContext);

  const getBackgroundColor = (active: boolean) => {
    const primaryColor = currentCompany.settings.primaryColor;
    const secondaryColor = Colors.getPrimaryColor(currentCompany);
    if (active) {
      return Color(primaryColor).isLight() ? secondaryColor : primaryColor;
    }
  };

  const getTextColor = (active: boolean) => {
    const primaryColor = currentCompany.settings.primaryColor;
    const secondaryColor = Colors.getPrimaryColor(currentCompany);
    if (active) {
      return Color(getBackgroundColor(active)).isDark() ? 'white' : 'black';
    } else {
      return Color(secondaryColor).isLight() ? (Color(primaryColor).isDark() ? primaryColor : 'dark') : 'dark';
    }
  };

  return (
    <Grid container className={classes.toggleButton.name} data-testid={testId?.name}>
      {values.map((value, index) => (
        <Grid
          item
          textAlign={'center'}
          className={`p-2 h-10 border-t border-b cursor-pointer ${
            index === values.length - 1
              ? 'border-r rounded-r-md'
              : index === 0
              ? 'border-l rounded-l-md border-r'
              : 'border-r'
          }`}
          key={value}
          data-testid={stringToTestId(value)}
          sx={{
            backgroundColor: getBackgroundColor(value === selected),
            color: getTextColor(value === selected),
          }}
          onClick={() => onChange(value)}
        >
          <p className='font-semibold text-sm select-none'>{text(value)}</p>
        </Grid>
      ))}
    </Grid>
  );
}
