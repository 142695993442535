import { ClickAwayListener, Grid } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { classes } from '../../../../util/identifiers/identifiers.util';
import KebabMenuIcon from '../../../icons/Kebab/KebabMenuIcon';
import MenuItem, { MenuItemProps } from './MenuItem';
// import { ListIcon } from '../../../icons/List/ListIcon';

interface MenuProps {
  items: MenuItemProps[];
}

export default function Menu({ items }: MenuProps) {
  const button = useRef<HTMLDivElement>(null);
  const menu = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };
  const closeMenu = () => {
    setOpen(false);
  };

  const position = useMemo(() => {
    if (!button.current || !menu.current) return 'left-0';

    const buttonRect = button.current.getBoundingClientRect();
    const menuRect = menu.current.getBoundingClientRect();

    const spaceRight = window.innerWidth - buttonRect.right;
    const spaceLeft = buttonRect.left;

    if (spaceRight >= menuRect.width) {
      return 'left-0';
    } else if (spaceLeft >= menuRect.width) {
      return 'right-0';
    } else {
      return 'left-0';
    }
  }, [open]);

  if (!items.length) return null;

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Grid
        item
        className={`${classes.menu.name} bg-white rounded-3 border border-ventory-light-border relative cursor-pointer select-none`}
        style={{
          padding: '9px 13px',
        }}
        onClick={toggleMenu}
        ref={button}
        data-testid={'menu'}
      >
        <KebabMenuIcon />

        <Grid
          container
          ref={menu}
          className={`${position} rounded-4 bg-white absolute p-2 z-10 min-w-[200px]`}
          rowSpacing={2}
          style={{
            visibility: open ? 'visible' : 'hidden',
            boxShadow: '0 8px 16px 0 rgba(16, 24, 40, 0.2)', // This does not match the Figma but that's because the figma's values wouldn't do anything
            marginTop: '16px',
          }}
        >
          {items.map(item => (
            <MenuItem {...item} key={item.text} />
          ))}
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
}
