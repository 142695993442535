import React, { JSX } from 'react';

import { t } from '../../../../types/translation/Translator';
import { StockLocation } from '../../../../types/stockLocation';
import { OrderTableSettings } from '../../../../types/orderTableSettings';
import { MessageQuestionCircleIcon } from '../../../icons/MessageQuestionCircle/MessageQuestionCircleIcon';

import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { OrderTypeFilterDropdownContent, OrderTypeFilterInnerContent } from './DropdownContent';

export class OrderTypeFilter extends BaseFilter<string> {
  filteredStockLocations: Map<string, StockLocation>;
  tableSettings: Map<string, OrderTableSettings>;
  constructor(filteredStockLocations: Map<string, StockLocation>, tableSettings: Map<string, OrderTableSettings>) {
    super();
    this.filteredStockLocations = filteredStockLocations;
    this.tableSettings = tableSettings;
  }
  toLabel(): string {
    return t().orderType.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.orderType?.size || 0;
  }

  toIcon(): JSX.Element {
    return <MessageQuestionCircleIcon className={this.className} />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <OrderTypeFilterDropdownContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <OrderTypeFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }
}
