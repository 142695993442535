import React from 'react';
import { Lot } from '../../../../types/lot';
import { toFilterString } from '../../../../util/string.util';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { t } from '../../../../types/translation/Translator';
import { LotFilterInnerContent, LotFilterDropdownContent } from './DropdownContent';
import { FilterProps } from '../common/filter.util';
import PackageSearchIcon from '../../../icons/Package/PackageSearchIcon';

export class LotFilter extends BaseFilter<Lot> {
  toLabel = () => {
    return t().lot.singular.label;
  };

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <LotFilterInnerContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toDropdownContent(props: BaseFilterProps): React.JSX.Element {
    return (
      <LotFilterDropdownContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toIcon(): React.JSX.Element {
    return <PackageSearchIcon className={this.className} />;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.lot?.size || 0;
  }

  search(item: Lot, textFilter: string) {
    if (toFilterString(item.number).includes(textFilter)) {
      return true;
    }

    return false;
  }

  filteredItems(items: Lot[], searchInput: string, filterProps: FilterProps) {
    const result = items.filter(item => {
      return this.search(item, searchInput);
    });

    return result;
  }
}
