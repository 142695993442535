import React from 'react';
import { Bin } from '../../../../types/bin';
import { toFilterString } from '../../../../util/string.util';
import { BinStatus } from '../../../../types/binStatus';
import { t } from '../../../../types/translation/Translator';
import { BinIcon } from '../../../icons/Bin/BinIcon';
import { FilterProps, FilterStorageKey } from '../common/filter.util';
import { BinFilterDropdownContent, BinFilterInnerContent } from './DropdownContent';
import { StockLocationFilter } from '../StockLocationFilter';
import { BinStatusFilter } from './BinStatusFilter';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { TagRelation } from '../../../../types/tagRelation';
import { TagFilter } from '../TagFilter/TagFilter';
import { EntityType } from '../../../../types/comment';

export class BinFilter extends BaseFilter<Bin> {
  binStatuses: Map<string, BinStatus>;
  tagRelations: Map<string, TagRelation>;

  constructor(binStatuses: Map<string, BinStatus>, tagRelations: Map<string, TagRelation>) {
    super(FilterStorageKey.BIN);
    this.binStatuses = binStatuses;
    this.tagRelations = tagRelations;
  }

  filterOptions(): BaseFilter<any>[] {
    return [
      new StockLocationFilter(this.tagRelations),
      new BinFilter(this.binStatuses, this.tagRelations),
      new BinStatusFilter(),
      new TagFilter(),
    ];
  }

  toLabel(): string {
    return t().bin.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.bin?.size || 0;
  }

  toIcon(): React.JSX.Element {
    return <BinIcon className={this.className} />;
  }

  toDropdownContent(props: BaseFilterProps): React.JSX.Element {
    return (
      <BinFilterDropdownContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <BinFilterInnerContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  search(item: Bin, textFilter: string) {
    if (
      toFilterString(item.name).includes(textFilter) ||
      toFilterString(this.binStatuses.get(item.binStatusId)?.status).includes(textFilter)
    ) {
      return true;
    }

    return false;
  }

  filteredItems(items: Bin[], query: string, filterProps: FilterProps) {
    const stockLocations = TagFilter.filterByTag(this.tagRelations, EntityType.stockLocation, filterProps);

    return items.filter(item => {
      if (
        filterProps.stockLocation &&
        filterProps.stockLocation.size &&
        !filterProps.stockLocation.has(item.stockLocationId)
      ) {
        return false;
      }

      if (filterProps.binStatus?.size && !filterProps.binStatus.has(item.binStatusId)) return false;

      if (filterProps.bin?.size && !filterProps.bin.has(item.id)) return false;

      if (stockLocations.size && !stockLocations.has(item.stockLocationId)) {
        return false;
      }

      return this.search(item, query);
    });
  }
}
