import React, { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { StockLocation } from '../../../../../types/stockLocation';
import { StockLocationContext } from '../../../../../context/StockLocationContext';

import InnerFilterContent, { FilterInnerContentProps } from '../../common/DropdownContent/DynamicFilterInnerContent';

export default function StockLocationFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<StockLocation>) {
  const { stockLocations } = useContext(StockLocationContext);

  return (
    <InnerFilterContent
      filterProps={{ filterProps, setFilterProps }}
      filter={filter}
      items={[...(filterProps.stockLocation || [])]}
      text={item => stockLocations.get(item)?.name || t().unknownStockLocation.singular.label}
      onRemove={i => {
        filterProps.stockLocation?.delete(i);
        if (!filterProps.stockLocation?.size) filterProps.stockLocation = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
