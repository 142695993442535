import { t } from '../../../../../../types/translation/Translator';
import React from 'react';

import { testIds } from '../../../../../../util/identifiers/identifiers.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../../Button';

export function FinishButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.finish,
    style: 'secondary',
    text: t().finish.singular.label,
    onClick: onClick,
    ...props,
  };
}

interface FinishButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function FinishButton({ onClick, ...props }: FinishButtonProps) {
  return <Button {...FinishButtonTemplate(onClick, props)} />;
}
