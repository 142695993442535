import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import SettingsScreen from '../Settings/SettingsScreen';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import CreateStockLocationPane from '../Settings/StockLocation/CreateStockLocationPane';
import UpdateStockLocationPane from '../Settings/StockLocation/UpdateStockLocationPane';
import ReferenceDataScreen from '../ReferenceData/ReferenceDataScreen';
import UpdateProductMasterDataPane from '../ReferenceData/ProductMasterData/UpdateProductMasterDataPane';
import CreateProductMasterDataPane from '../ReferenceData/ProductMasterData/CreateProductMasterDataPane';
import UpdateCompanyPane from '../Settings/Company/UpdateCompanyPane';
import StockScreen from '../Stock/StockScreen';
import ProductDetailPane from '../Stock/Product/Panes/ProductDetailPane';
import TaskScreen from '../Task/TaskScreen';
import DashboardScreen from '../Dashboard/DashboardScreen';
import UpdateUserPane from '../Settings/User/UpdateUserPane';
import OperationScreen from '../Operation/OperationScreen';
import UpdateOrderPane from '../Operation/Order/Panes/UpdateOrderPane';
import UpdateContactPane from '../Operation/Contact/Panes/UpdateContactPane';
import FeatureRequestScreen from '../Support/FeatureRequestScreen';
import ReportIncidentRequestScreen from '../Support/ReportIncidentScreen';
import AlertScreen from '../Alerts/AlertScreen';
import TaskDetailPane from '../Task/Panes/TaskDetailPane';
import TaskSettingsScreen from '../Task/Settings/TaskSettingsScreen';
import CreateTaskExportConfigurationPane from '../Task/Settings/Panes/Export/Panes/CreateTaskExportConfigurationPane';
import UpdateTaskExportConfigurationPane from '../Task/Settings/Panes/Export/Panes/UpdateTaskExportConfigurationPane';
import BinDetailPane from '../Stock/Bin/Panes/BinDetailPane';
import AdvancedSettingsScreen from '../Settings/Advanced/AdvancedSettingsScreen';
import DeveloperScreen from './Developer/DeveloperScreen';
import OrderSettingsScreen from '../Operation/Order/Settings/OrderSettingsScreen';
import CreateOrderExportConfigurationPane from '../Operation/Order/Settings/Panes/Export/Panes/CreateOrderExportConfigurationPane';
import UpdateOrderExportConfigurationPane from '../Operation/Order/Settings/Panes/Export/Panes/UpdateOrderExportConfigurationPane';
import { UserContext } from '../../../context/UserContext';
import PromptUserRenameModal from './Modals/PromptUserRenameModal';
import UpdateIntegrationSettingsPane from '../Settings/Advanced/Panes/Integrations/Panes/UpdateIntegrationSettingsPane';
import ProductSettingsScreen from '../Stock/Product/Settings/ProductSettingsScreen';
import StockLocationAdvancedSettingsPane from '../Settings/StockLocation/StockLocationAdvancedSettingsPane';
import BinAdvancedSettingsPane from '../Settings/StockLocation/Panes/Bin/BinAdvancedSettingsPane';
import ReferenceDataSettingsScreen from '../ReferenceData/Settings/ReferenceDataSettingsScreen';
import UserAdvancedSettingsPane from '../Settings/User/UserAdvancedSettingsPane';
import CreateProductExportConfigurationPane from '../Stock/Product/Settings/Panes/Export/Panes/CreateProductExportConfigurationPane';
import UpdateProductExportConfigurationPane from '../Stock/Product/Settings/Panes/Export/Panes/UpdateProductExportConfigurationPane';
import ProductTransactionSettingsScreen from '../Stock/History/Settings/ProductTransactionSettingsScreen';
import CreateProductTransactionExportConfigurationPane from '../Stock/History/Settings/Panes/Export/Panes/CreateProductTransactionExportConfigurationPane';
import UpdateProductTransactionExportConfigurationPane from '../Stock/History/Settings/Panes/Export/Panes/UpdateProductTransactionExportConfigurationPane';
import Content from '../../../VentoryUI/components/common/Content';
import UpdateReorderRuleScreen from '../Common/ReorderRules/UpdateReorderRuleScreen';
import CreateReorderRuleScreen from '../Common/ReorderRules/CreateReorderRuleScreen';
import CreateProductMasterDataExportConfigurationPane from '../ReferenceData/Settings/Panes/Export/Panes/CreateProductMasterDataExportConfigurationPane';
import UpdateProductMasterDataExportConfigurationPane from '../ReferenceData/Settings/Panes/Export/Panes/UpdateProductMasterDataExportConfigurationPane';
import { Sidebar } from '../../../VentoryUI/components/common/Sidebar/Sidebar';
import CreateContainerPane from '../Stock/Container/Panes/CreateContainerPane';
import ContainerDetailPane from '../Stock/Container/Panes/ContainerDetailPane';
import { CompanyContext } from '../../../context/CompanyContext';

export default function HomeScreen() {
  const { currentUser } = useContext(UserContext);
  const { currentCompany } = useContext(CompanyContext);
  const location = useLocation();

  const [openUserRenamePrompt, setOpenUserRenamePrompt] = useState<boolean>(false);

  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    JSON.parse(localStorage.getItem('sidebarCollapsed') || 'false') || false,
  );

  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', JSON.stringify(sidebarCollapsed));
  }, [sidebarCollapsed]);

  if (
    currentUser?.firstName === 'Invited' &&
    currentUser?.lastName &&
    !openUserRenamePrompt &&
    process.env.REACT_APP_ENV !== 'test'
  ) {
    setOpenUserRenamePrompt(true);
  }

  const sidebarWidth = sidebarCollapsed ? '68px' : '226px'; // 68 = 52[base] + 16[margin] | 226 = 210[base] + 16[margin]

  return (
    <Grid
      container
      display={'flex'}
      flexWrap={'nowrap'}
      className='w-full h-screen'
      style={{ backgroundColor: 'rgb(245, 246, 247)' }}
    >
      <Grid item display={'flex'} width={sidebarWidth} style={{ transition: 'width 0.3s linear' }}>
        <Sidebar collapsed={sidebarCollapsed} setCollapsed={setSidebarCollapsed} />
      </Grid>
      <Grid item flexGrow={1} className='w-full h-full'>
        <Content>
          <Routes>
            <Route path='/reference_data/stock_triggers/create/*' element={<CreateReorderRuleScreen />} />
            <Route path='/reference_data/stock_triggers/:id/update/*' element={<UpdateReorderRuleScreen />} />
            <Route path='/developer/*' element={<DeveloperScreen />} />
            <Route path='/reference_data/pmd/create/*' element={<CreateProductMasterDataPane />} />
            <Route path='/reference_data/pmd/:id/update/*' element={<UpdateProductMasterDataPane />} />
            <Route path='/reference_data/settings/*' element={<ReferenceDataSettingsScreen />} />
            <Route
              path='/reference_data/settings/report/create'
              element={<CreateProductMasterDataExportConfigurationPane />}
            />
            <Route
              path='/reference_data/settings/report/:id/update'
              element={<UpdateProductMasterDataExportConfigurationPane />}
            />
            <Route path='/stock/products/:id/*' element={<ProductDetailPane />} />
            <Route path='/stock/bins/:id/*' element={<BinDetailPane />} />
            <Route path='/stock/products/settings/*' element={<ProductSettingsScreen />} />
            <Route path='/stock/products/settings/report/create' element={<CreateProductExportConfigurationPane />} />
            <Route
              path='/stock/products/settings/report/:id/update'
              element={<UpdateProductExportConfigurationPane />}
            />
            {currentCompany.settings.featureToggles.containers.containers ? (
              <>
                <Route path='/stock/containers/create/*' element={<CreateContainerPane />} />
                <Route path='/stock/containers/:id/*' element={<ContainerDetailPane />} />
              </>
            ) : null}
            <Route path='/stock/history/settings/*' element={<ProductTransactionSettingsScreen />} />
            <Route
              path='/stock/history/settings/report/create'
              element={<CreateProductTransactionExportConfigurationPane />}
            />
            <Route
              path='/stock/history/settings/report/:id/update'
              element={<UpdateProductTransactionExportConfigurationPane />}
            />
            <Route path='/settings/stock_location/advanced/*' element={<StockLocationAdvancedSettingsPane />} />
            <Route path='/settings/stock_location/bins/advanced/*' element={<BinAdvancedSettingsPane />} />
            <Route path='/settings/stock_location/create/*' element={<CreateStockLocationPane />} />
            <Route path='/settings/stock_location/:id/update/*' element={<UpdateStockLocationPane />} />
            <Route path='/settings/users/advanced/*' element={<UserAdvancedSettingsPane />} />
            <Route path='/settings/users/:id/update/*' element={<UpdateUserPane />} />
            <Route path='/settings/company/update/*' element={<UpdateCompanyPane />} />
            <Route path='/settings/advanced/*' element={<AdvancedSettingsScreen />} />
            <Route path='/settings/*' element={<SettingsScreen />} />
            <Route path='/tasks/settings/report/create' element={<CreateTaskExportConfigurationPane />} />
            <Route path='/tasks/settings/report/update/:id' element={<UpdateTaskExportConfigurationPane />} />
            <Route path='/tasks/task/:id/*' element={<TaskDetailPane />} />
            <Route path='/tasks/settings/*' element={<TaskSettingsScreen />} />
            <Route path='/operations/orders/settings/*' element={<OrderSettingsScreen />} />
            <Route path='/operations/orders/settings/report/create' element={<CreateOrderExportConfigurationPane />} />
            <Route
              path='/operations/orders/settings/report/update/:id'
              element={<UpdateOrderExportConfigurationPane />}
            />
            <Route path='/tasks/*' element={<TaskScreen />} />
            <Route path='/alerts/*' element={<AlertScreen />} />
            <Route path='/operations/orders/:id/update/*' element={<UpdateOrderPane />} />
            <Route path='/operations/contacts/:id/update/*' element={<UpdateContactPane />} />
            <Route path='/operations/*' element={<OperationScreen />} />
            <Route path='/reference_data/*' element={<ReferenceDataScreen />} />
            <Route path='/stock/*' element={<StockScreen />} />
            <Route path='/dashboard' element={<DashboardScreen />} />
            <Route path='/support/feature_request' element={<FeatureRequestScreen />} />
            <Route path='/support/incident_report' element={<ReportIncidentRequestScreen />} />
            <Route path='/settings/advanced/integrations/:id/update/*' element={<UpdateIntegrationSettingsPane />} />
            <Route path='/*' element={<Navigate to={'/dashboard'} />} />
          </Routes>
        </Content>
      </Grid>
      <PromptUserRenameModal open={openUserRenamePrompt} setOpen={v => setOpenUserRenamePrompt(v)} />
    </Grid>
  );
}
