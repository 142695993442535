import { useContext, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { Grid } from '@mui/material';
import TextInput from '../../../Common/TextInput';
import Dropdown from '../../../Common/Dropdown';
import React from 'react';
import LoadingPackage from '../../../Common/LoadingPackage';
import {
  AuthenticationSettings,
  AuthenticationType,
  authenticationTypeToString,
} from '../../../../../types/authenticationSettings';
import { useMutation, useQuery } from '@apollo/client';
import {
  AuthenticationSettingsMutations,
  AuthenticationSettingsQueries,
  CreateAuthenticationSettingsResponse,
  CreateAuthenticationSettingsVariables,
  GetAuthenticationSettingsForUserResponse,
  GetAuthenticationSettingsForUserVariables,
  UpdateAuthenticationSettingsResponse,
  UpdateAuthenticationSettingsVariables,
} from '../../../../../graphql/authenticationSettings.graphql';
import BackButton from '../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Paper from '../../../../../VentoryUI/components/common/Paper';
import FlexPane from '../../../../../VentoryUI/components/common/FlexPane';

interface UserAuthenticationPaneProps {
  error: string;
  setError: (error: string) => void;
}

export default function UserAuthenticationPane({ error, setError }: UserAuthenticationPaneProps) {
  const navigate = useNavigate();

  const userId = useParams()['id'];
  if (!userId) return null;

  const { currentCompany } = useContext(CompanyContext);

  const [authenticationSettingsInput, setAuthenticationSettingsInput] = useState<AuthenticationSettings>(
    new AuthenticationSettings({ companyId: currentCompany.id }),
  );

  const { loading: getLoading } = useQuery<
    GetAuthenticationSettingsForUserResponse,
    GetAuthenticationSettingsForUserVariables
  >(AuthenticationSettingsQueries.getForUser, {
    variables: {
      userId: userId,
    },
    onCompleted: res => {
      setAuthenticationSettingsInput(new AuthenticationSettings(res.authenticationSettingsForUser));
    },
    onError: err => setError(err.message),
  });

  const [create, { loading: createLoading }] = useMutation<
    CreateAuthenticationSettingsResponse,
    CreateAuthenticationSettingsVariables
  >(AuthenticationSettingsMutations.create, {
    onCompleted: res => {
      setAuthenticationSettingsInput(new AuthenticationSettings(res.createAuthenticationSettings));
      navigate('/settings/users');
    },
    onError: err => setError(err.message),
  });

  const [update, { loading: updateLoading }] = useMutation<
    UpdateAuthenticationSettingsResponse,
    UpdateAuthenticationSettingsVariables
  >(AuthenticationSettingsMutations.update, {
    onCompleted: res => {
      setAuthenticationSettingsInput(new AuthenticationSettings(res.updateAuthenticationSettings));
      navigate('/settings/users');
    },
    onError: err => setError(err.message),
  });

  const handleSave = async () => {
    if (authenticationSettingsInput.userId) {
      await update({
        variables: {
          authenticationSettings: authenticationSettingsInput.forUpdate(),
        },
      });
    } else {
      authenticationSettingsInput.userId = userId;

      await create({
        variables: {
          authenticationSettings: new AuthenticationSettings({
            companyId: currentCompany.id,
            userId: userId,
            type: authenticationSettingsInput.type,
            expiryInHours: authenticationSettingsInput.expiryInHours,
          }),
        },
      });
    }
  };

  if (getLoading) {
    return (
      <Paper>
        <Grid container alignItems={'center'}>
          <Grid item className='fill-gray-300 ' marginX={'auto'}>
            <LoadingPackage />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <FlexPane
      content={
        <Paper>
          <Grid container rowSpacing={2} columnSpacing={1} alignContent={'start'}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3}>
                  <Dropdown
                    label={t().authenticationType.singular.label}
                    values={[...Object.keys(AuthenticationType)]}
                    selectedValue={authenticationSettingsInput.type}
                    toText={item => authenticationTypeToString(item)}
                    onChange={item =>
                      setAuthenticationSettingsInput(authenticationSettingsInput.withType(item as AuthenticationType))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            {authenticationSettingsInput.type === AuthenticationType.code ? (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={3}>
                    <TextInput
                      label={t().expiryInHours.singular.label}
                      value={authenticationSettingsInput.expiryInHours.toString()}
                      onChange={item =>
                        setAuthenticationSettingsInput(authenticationSettingsInput.withExpiryInHours(parseFloat(item)))
                      }
                      type={'number'}
                      suffix={t().hour.plural.label}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Paper>
      }
      footer={
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <BackButton onClick={() => navigate('/settings/users')} disabled={createLoading || updateLoading} />
          </Grid>
          <Grid item>
            <SaveButton onClick={handleSave} loading={createLoading || updateLoading} />
          </Grid>
        </Grid>
      }
    />
  );
}
