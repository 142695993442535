import { Grid } from '@mui/material';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import React, { useContext, useState } from 'react';
import { FilterProps, FilterStorageKey } from '../../filters/common/filter.util';
import { NumberChartContainer } from '../../../../ui/pages/Dashboard/Graphs/Containers/NumberChartContainer';
import { NumberChartType } from '../../charts/NumberChart/NumberChart';
import { RadialChartContainer } from '../../../../ui/pages/Dashboard/Graphs/Containers/RadialChartContainer';
import { BarChartContainer } from '../../../../ui/pages/Dashboard/Graphs/Containers/BarChartContainer';
import { t } from '../../../../types/translation/Translator';
import { TaskFilter } from '../../filters/TaskFilter';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { UserContext } from '../../../../context/UserContext';
import { BinStatusContext } from '../../../../context/BinStatusContext';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import { TagRelationContext } from '../../../../context/TagRelationContext';
import { useNavigate } from 'react-router-dom';
import SearchBarWithFilter from '../SearchBarWithFilter/SearchBarWithFilter';
import { TaskStatus, TaskType } from '../../../../types/task';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

interface TaskMetricsProps {
  color: string;
}

export default function TaskMetrics({ color }: TaskMetricsProps) {
  dayjs.extend(customParseFormat);

  const navigate = useNavigate();

  const { stockLocations } = useContext(StockLocationContext);
  const { companyUsers } = useContext(UserContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { tagRelations } = useContext(TagRelationContext);

  const getFilter = (key: string) => {
    return new TaskFilter(stockLocations, companyUsers, binStatuses, productMasterData, tagRelations, key);
  };

  const key = 'tm';
  const filter = getFilter(key);
  const [filterProps, setFilterProps] = useState<FilterProps>(filter.getFilterProps());

  return (
    <>
      <Grid item xs={12} mt={1}>
        <SectionTitle text={t().taskMetrics.singular.label} />
      </Grid>
      <Grid item xs={12} mb={1}>
        <Grid container>
          <Grid item>
            <SearchBarWithFilter
              items={[]}
              setItems={() => {}}
              onChange={() => {
                setFilterProps(filter.getFilterProps());
              }}
              filter={filter}
              search={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} display={'flex'}>
        <Grid container columnSpacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12} display={'flex'}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container columnSpacing={1} className='h-full'>
                  <NumberChartContainer
                    filter={filterProps}
                    entity={FilterStorageKey.TASK}
                    color={color}
                    type={NumberChartType.text}
                    size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    text={t().total.singular.label}
                    onClick={() => {
                      navigate(`/tasks?filter=${key}`);
                    }}
                  />
                  <NumberChartContainer
                    filter={{
                      ...filterProps,
                      taskStatus: new Set([
                        ...(filterProps.taskStatus || new Set())?.values(),
                        TaskStatus.complete,
                        TaskStatus.processed,
                      ]),
                    }}
                    entity={FilterStorageKey.TASK}
                    color={color}
                    type={NumberChartType.text}
                    size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    text={t().complete.singular.label}
                    onClick={() => {
                      const key = 'tm-complete';
                      const statusFilter = getFilter(key);
                      const props = filter.getFilterProps();
                      props.taskStatus = new Set(props.taskStatus);
                      props.taskStatus.add(TaskStatus.complete);
                      props.taskStatus.add(TaskStatus.processed);
                      statusFilter.saveFilterProps(props);
                      navigate(`/tasks?filter=${key}`);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container columnSpacing={1} className='h-full'>
                  <NumberChartContainer
                    filter={filterProps}
                    entity={FilterStorageKey.TASK}
                    color={color}
                    type={NumberChartType.text}
                    size={{ xs: 4, sm: 4, md: 4, lg: 6 }}
                    text={t().inProgress.singular.label}
                    additionalFilter={'status = inProgress'}
                    onClick={() => {
                      const key = 'tm-progress';
                      const statusFilter = getFilter(key);
                      const props = filter.getFilterProps();
                      props.taskStatus = new Set(props.taskStatus);
                      props.taskStatus.add(TaskStatus.inProgress);
                      statusFilter.saveFilterProps(props);
                      navigate(`/tasks?filter=${key}`);
                    }}
                  />
                  <NumberChartContainer
                    filter={filterProps}
                    entity={FilterStorageKey.TASK}
                    color={color}
                    type={NumberChartType.text}
                    size={{ xs: 4, sm: 4, md: 4, lg: 3 }}
                    text={t().overdue.singular.label}
                    additionalFilter={'overdue = true'}
                    onClick={() => {
                      const key = 'tm-overdue';
                      const statusFilter = getFilter(key);
                      const props = filter.getFilterProps();
                      props.taskStatus = new Set(props.taskStatus);
                      props.taskStatus.add(TaskStatus.overdue);
                      statusFilter.saveFilterProps(props);
                      navigate(`/tasks?filter=${key}`);
                    }}
                  />
                  <NumberChartContainer
                    filter={filterProps}
                    entity={FilterStorageKey.TASK}
                    color={color}
                    type={NumberChartType.text}
                    size={{ xs: 4, sm: 4, md: 4, lg: 3 }}
                    text={t().recount.singular.label}
                    additionalFilter={'parentId != null'}
                    onClick={() => {
                      const key = 'tm-recount';
                      const statusFilter = getFilter(key);
                      const props = filter.getFilterProps();
                      props.taskType = new Set(props.taskType);
                      props.taskType.add(TaskType.recount);
                      statusFilter.saveFilterProps(props);
                      navigate(`/tasks?filter=${key}`);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} md={6} sm={12} display={'flex'}>
            <Grid container columnSpacing={1} display={'flex'} className='h-full'>
              <Grid item xs={6} className='h-full'>
                <RadialChartContainer
                  text={t().binMetrics.singular.label}
                  entity={FilterStorageKey.BIN_FOR_TASK}
                  color={color}
                  filter={filterProps}
                />
              </Grid>
              <Grid item xs={6} className='h-full'>
                <RadialChartContainer
                  text={t().productMetrics.singular.label}
                  entity={FilterStorageKey.PRODUCT_FOR_TASK}
                  color={color}
                  filter={filterProps}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className='min-h-[400px]'>
        <BarChartContainer
          text={t().numberOfCounts.singular.label}
          entity={FilterStorageKey.TASK}
          color={color}
          filter={filterProps}
          className='min-h-[400px]'
          onClick={(idx: number, format: string, value: string) => {
            const search = { ...filterProps };

            let startDate: Dayjs | undefined = undefined;
            let endDate: Dayjs | undefined = undefined;
            // Todo: rewrite when implementing new dashboards
            if (!search.timeFrame) {
              if (format === 'MM/YYYY') {
                startDate = dayjs(`01/${value}`, 'DD/MM/YYYY').startOf('day');
                endDate = dayjs(`01/${value}`, 'DD/MM/YYYY').endOf('month').endOf('day');
              } else if (format === 'YYYY/MM') {
                startDate = dayjs(`${value}/01`, 'YYYY/MM/DD').startOf('day');
                endDate = dayjs(`${value}/01`, 'YYYY/MM/DD').endOf('month').endOf('day');
              }
            } else {
              const diff = dayjs(search.timeFrame?.end).diff(dayjs(search.timeFrame?.start), 'day');

              if (diff <= 29) {
                startDate = dayjs(search.timeFrame?.start).add(idx, 'day').startOf('day');
                endDate = startDate.endOf('day');
              } else {
                startDate = dayjs(search.timeFrame?.start).add(idx, 'month').startOf('month').startOf('day');
                endDate = startDate.endOf('month').endOf('day');
              }
            }

            if (!startDate || !endDate) return;

            const key = 'tm-taskChart';
            const statusFilter = getFilter(key);
            const props = filter.getFilterProps();
            props.timeFrame = {
              start: startDate.toDate(),
              end: endDate.toDate(),
            };
            statusFilter.saveFilterProps(props);
            navigate(`/tasks?filter=${key}`);
          }}
        />
      </Grid>
    </>
  );
}
