import { Grid } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { CreateTaskInput, Task, TaskType } from '../../../../../types/task';
import { BinContext } from '../../../../../context/BinContext';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';
import { ProductContext } from '../../../../../context/ProductContext';
import ToggleButton from '../../../Common/ToggleButton';
import Selector from '../../../Common/Selector';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { BackButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/NextButton/NextButton';
import { ContainerContext } from '../../../../../context/ContainerContext';
import { LotContext } from '../../../../../context/LotContext';

interface CreateTaskEntityPaneInputProps {
  task: CreateTaskInput;
  setTask: (task: Task) => void;
  next: () => void;
  back: () => void;
}

enum TaskEntityView {
  bin = 'bin',
  product = 'product',
  lot = 'lot',
  container = 'container',
}

function taskEntityViewToLocalizedString(view: string) {
  switch (view) {
    case TaskEntityView.bin:
      return t().bin.plural.label;
    case TaskEntityView.container:
      return t().container.plural.label;
    case TaskEntityView.product:
      return t().product.plural.label;
    case TaskEntityView.lot:
      return t().lot.plural.label;
  }

  return '';
}

export function CreateTaskEntityPane({ task, setTask, next, back }: CreateTaskEntityPaneInputProps) {
  const { bins } = useContext(BinContext);
  const { containers } = useContext(ContainerContext);
  const { lots } = useContext(LotContext);
  const { productMasterData } = useContext(ProductMasterDataContext);
  const { products } = useContext(ProductContext);

  const [view, setView] = useState<TaskEntityView>(TaskEntityView.bin);

  const { binsWithProducts, lotsWithProducts, containersWithProducts } = useMemo(() => {
    const binsWithProducts = new Set<string>();
    const lotsWithProducts = new Set<string>();
    const containersWithProducts = new Set<string>();

    [...products.values()]
      .map(v => [...v.values()])
      .flat()
      .forEach(p => {
        if (p.stockLocationId !== task.stockLocationId) return;
        binsWithProducts.add(p.binId);
        if (p.lotId) lotsWithProducts.add(p.lotId);
        if (p.containerId) containersWithProducts.add(p.containerId);
      });

    return { binsWithProducts, lotsWithProducts, containersWithProducts };
  }, [products, bins, lots, containers]);

  const selectView = () => {
    switch (view) {
      case TaskEntityView.bin:
        return (
          <Selector
            testId={testIds.bins}
            key={'binItems'}
            placeholder={t().filterBins.singular.label}
            values={[...bins.values()]
              .filter(bin => {
                return binsWithProducts.has(bin.id) || task.type === TaskType.blind;
              })
              .map(bin => bin.id)}
            checkedValues={task.binIds()}
            toText={item => bins.get(item)?.name || t().unknownBin.singular.label}
            onChange={checked => setTask(task.withBinIds(checked))}
          />
        );
      case TaskEntityView.product:
        return (
          <Selector<string>
            testId={testIds.products}
            key={'productItems'}
            placeholder={t().filterProducts.singular.label}
            values={[...products.keys()]}
            checkedValues={task.productMasterDataIds()}
            toText={item => productMasterData.get(item)?.productName || t().unknownProduct.singular.label}
            onChange={checked => setTask(task.withProductMasterDataIds(checked))}
          />
        );
      case TaskEntityView.container:
        return (
          <Selector
            testId={testIds.containers}
            key={'containers'}
            placeholder={t().filterContainer.plural.label}
            values={[...containers.values()]
              .filter(container => {
                return containersWithProducts.has(container.id) || task.type === TaskType.blind;
              })
              .map(container => container.id)}
            checkedValues={task.containerIds()}
            toText={item => containers.get(item)?.identifier || t().unknownContainer.singular.label}
            onChange={checked => setTask(task.withContainerIds(checked))}
          />
        );
      case TaskEntityView.lot:
        return (
          <Selector
            testId={testIds.lots}
            key={'lots'}
            placeholder={t().filterLot.plural.label}
            values={[...lots.values()]
              .filter(lot => {
                return lotsWithProducts.has(lot.id) || task.type === TaskType.blind;
              })
              .map(lot => lot.id)}
            checkedValues={task.lotIds()}
            toText={item => lots.get(item)?.number || t().unknownLot.singular.label}
            onChange={checked => setTask(task.withLotIds(checked))}
          />
        );

      default:
        return '';
    }
  };

  return (
    <ModalPane
      overflow='auto'
      testId={testIds.createTaskEntityPane}
      footerButtons={[BackButtonTemplate(back), NextButtonTemplate(next, { disabled: !task.identifiers })]}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            <Grid item flexGrow={1} marginY={'auto'}>
              {task.type === TaskType.cycle ? (
                <p className='text-sm font-semibold'>{t().selectBinsOrProducts.singular.label}</p>
              ) : (
                <p className='text-sm font-semibold'>{t().selectBin.plural.label}</p>
              )}
            </Grid>
            {task.type === TaskType.cycle ? (
              <Grid item>
                <ToggleButton
                  testId={testIds.entity}
                  onChange={value => {
                    task.withBinIds([]);
                    task.withProductMasterDataIds([]);

                    switch (value) {
                      case TaskEntityView.bin:
                    }
                    if (value == TaskEntityView.bin) {
                      task.withProductMasterDataIds([]);
                    } else {
                      task.withBinIds([]);
                    }
                    setView(value as TaskEntityView);
                  }}
                  selected={view}
                  values={Object.keys(TaskEntityView).filter(el => {
                    if (el === TaskEntityView.product && task.type === TaskType.blind) return false;
                    return true;
                  })}
                  text={value => taskEntityViewToLocalizedString(value)}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              {selectView()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
