import React from 'react';
import { JSX } from 'react';
import { t } from '../../../../types/translation/Translator';
import { BinIcon } from '../../../icons/Bin/BinIcon';
import { ContainerType } from '../../../../types/containerType';
import { ContainerTypeFilterDropdownContent, ContainerTypeFilterInnerContent } from './DropdownContent';
import { toFilterString } from '../../../../util/string.util';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { FilterProps } from '../common/filter.util';

export class ContainerTypeFilter extends BaseFilter<ContainerType> {
  filterOptions = () => [];

  search(item: ContainerType, filter: string) {
    if (toFilterString(item.name).includes(filter)) {
      return true;
    }
    return false;
  }

  filteredItems(items: ContainerType[], query: string, filterProps: FilterProps): ContainerType[] {
    return items.filter(item => {
      return this.search(item, query);
    });
  }

  toLabel(): string {
    return t().containerType.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.containerType?.size || 0;
  }

  toIcon(): JSX.Element {
    return <BinIcon />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <ContainerTypeFilterDropdownContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <ContainerTypeFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }
}
