import * as d3 from 'd3';
import { ChartData } from '../Chart';
import { useState } from 'react';
import { VentoryColor } from '../../../util/color.util';
import React from 'react';
import { FilterProps } from '../../filters/common/filter.util';

interface BarProps {
  width: number;
  height: number;
  data: ChartData[];
  margin: { left: number; bottom: number };
  color?: string;
  filter: FilterProps;
  onClick: (idx: number, format: string, value: string) => void;
  format: string;
}

export function Bar({
  width,
  height,
  data,
  margin,
  color = VentoryColor.primaryVentoryColor,
  filter,
  onClick,
  format,
}: BarProps) {
  const scaleX = d3
    .scaleBand()
    .paddingInner(0.6)
    .domain(data.map(({ label }) => label))
    .range([margin.left, width - 2 * margin.left]);

  const scaleY = d3
    .scaleLinear()
    .domain([0, Math.max(...data.map(({ value }) => value))])
    .range([height - margin.bottom / 2, 60]);

  const [hover, setHover] = useState<string | undefined>(undefined);

  return (
    <>
      {data.map(({ value, label }, idx) => {
        const fillColor = color;
        const strokeColor = 'red';
        const strokeWidth = 0;
        let radius = 0;

        const x = (scaleX(label) || 0) + margin.left;
        const y = (scaleY(value) || 0) + strokeWidth - margin.bottom / 2;
        const width = scaleX.bandwidth();
        const h = height - scaleY(value) - margin.bottom / 2 - strokeWidth;

        if (!value) return null;

        if (h < radius) radius = h;

        const textWidth = value.toString().length === 1 ? 30 : value.toString().length * 15;

        return (
          <svg key={`${value}${label}`}>
            <path
              onMouseEnter={() => setHover(label)}
              onMouseLeave={() => setHover(undefined)}
              onClick={() => onClick(idx, format, label)}
              className='hover:stroke-2 hover:stroke-white cursor-pointer'
              d={`m${x + width - radius},${y} Q${x + width},${y} ${x + width},${y + radius} v${
                h - radius
              } h-${width} v-${h - radius} Q${x},${y} ${x + radius},${y} h${width - 2 * radius}`}
              fill={fillColor}
              stroke={strokeColor}
              strokeWidth={strokeWidth}
            />
            {hover === label ? (
              <>
                <rect
                  width={textWidth}
                  height={'30'}
                  x={x + width / 2 - textWidth / 2}
                  y={height - h - margin.bottom - 35}
                  fill={'#c2cbcd'}
                />
                <text
                  transform={`translate(${x + width / 2}, ${height - h - margin.bottom - 15})`}
                  fontSize={15}
                  textAnchor={'middle'}
                  fill={'white'}
                >
                  {value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </text>
              </>
            ) : null}
          </svg>
        );
      })}
    </>
  );
}
