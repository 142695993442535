import React, { useContext, useEffect, useState } from 'react';
import { FilterProps } from '../../common/filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { FileContext } from '../../../../../context/FileContext';
import { StringUtil, toFilterString } from '../../../../../util/string.util';
import ScrollableFilterContent from '../../common/DropdownContent/ScrollableFilterContent';
import { FileTypeCategoryFilter } from '../FileTypeCategoryFilter';

interface FileTypeCategoryFilterContentProps {
  filter: FileTypeCategoryFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

const fileTypeCategoryFilter = (file: string, text: string, filter: FilterProps) => {
  return toFilterString(file).includes(toFilterString(text));
};

export default function FileTypeCategoryFilterContent({
  filter,
  filterProps,
  setFilterProps,
}: FileTypeCategoryFilterContentProps) {
  const { files } = useContext(FileContext);

  const [textFilter, setTextFilter] = useState<string>('');

  const filterFileTypeCategories = () => {
    return new Set(
      [...files.values()]
        .map(file => file.fileTypeCategory)
        .filter(file => fileTypeCategoryFilter(file, textFilter, filterProps)),
    );
  };

  const [values, setValues] = useState<Set<string>>(filterFileTypeCategories());

  const handleChange = (selected: Set<string>) => {
    filterProps.fileTypeCategory = selected;
    if (!filterProps.fileTypeCategory?.size) filterProps.fileTypeCategory = undefined;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.fileTypeCategory = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterFileTypeCategories());
  }, [textFilter]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().file.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setTextFilter(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            selected={filterProps.fileTypeCategory}
            values={values}
            onChange={handleChange}
            toText={fileTypeCategory => StringUtil.upperCaseFirstCharacter(fileTypeCategory)}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}
