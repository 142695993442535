import React, { useContext, useEffect, useState } from 'react';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { DashboardContext, getEntityDataCacheKey } from '../../../../../context/DashboardContext';
import {
  DashboardQueries,
  GetDashboardFilterResponse,
  GetDashboardFilterVariables,
  toFilterParameters,
} from '../../../../../graphql/dashboard.graphql';
import { useQuery } from '@apollo/client';
import { DashboardData } from '../../Common/dashboard.util';
import { BarChart } from '../../../../../VentoryUI/components/charts/BarChart/BarChart';
import { FilterProps, FilterStorageKey } from '../../../../../VentoryUI/components/filters/common/filter.util';

interface BarChartContainerProps {
  text: string;
  entity: FilterStorageKey;
  color: string;
  filter: FilterProps;
  className?: string;
  onClick?: (idx: number, format: string, value: string) => void;
}

export function BarChartContainer({
  text,
  entity,
  color,
  filter,
  className = 'min-h-[355px]',
  onClick,
}: BarChartContainerProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { cache, setCache } = useContext(DashboardContext);

  const [data, setData] = useState<DashboardData>({ filteredChartData: [], total: 0, format: '' });

  const cacheKey = getEntityDataCacheKey(entity, filter);
  const hasCachedData = cache.has(cacheKey);

  const { loading } = useQuery<GetDashboardFilterResponse, GetDashboardFilterVariables>(DashboardQueries.get, {
    skip: hasCachedData,
    variables: {
      companyId: currentCompany.id,
      locale: (window.navigator as any)['userLanguage'] || window.navigator.language,
      parameters: toFilterParameters(entity, filter),
    },
    onCompleted: res => {
      cache.set(cacheKey, res.dashboardQuery);
      setCache(new Map(cache));

      setData(res.dashboardQuery);
    },
    onError: err => console.log(err),
  });

  useEffect(() => {
    if (hasCachedData) setData(cache.get(cacheKey)!);
  }, [cache, filter]);

  return (
    <BarChart
      data={data}
      text={text}
      color={color}
      className={className}
      loading={loading}
      filter={filter}
      onClick={onClick}
      format={data.format}
    />
  );
}
