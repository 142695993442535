import { t } from '../../../../../../types/translation/Translator';
import React from 'react';

import { testIds } from '../../../../../../util/identifiers/identifiers.util';
import { Button, ButtonProps, ButtonTemplateProps } from '../../Button';

export function CancelButtonTemplate(onClick: () => void, props?: ButtonTemplateProps): ButtonProps {
  return {
    testId: testIds.cancel,
    text: t().cancel.singular.label,
    onClick: onClick,
    ...props,
  };
}

interface CancelButtonProps extends ButtonTemplateProps {
  onClick: () => void;
}

export default function CancelButton({ onClick, ...props }: CancelButtonProps) {
  return <Button {...CancelButtonTemplate(onClick, props)} />;
}
