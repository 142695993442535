import { gql } from '@apollo/client';
import { Container, CreateContainerInput, DeleteContainerInput, UpdateContainerInput } from '../types/container';
import { IdArray } from '../types/common/entity';
import { forPaginated } from './common/paginated.graphql';

const container = gql`
  fragment Container on Container {
    id
    version
    companyId
    stockLocationId
    binId
    containerTypeId
    parentId
    identifier
    deleteOnOutbound
    customFields {
      id
      name
      value
      mandatory
      type
      external
    }
  }
`;

const get = gql`
  query Containers($companyId: String!, $page: Float) {
    containers(companyId: $companyId, page: $page) {
      data {
        ...Container
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${container}
`;

export interface GetContainersVariables {
  companyId: string;
  page?: number;
}

export interface GetContainersResponse {
  containers: PaginatedContainers;
}

class PaginatedContainers extends forPaginated<Container>() {}

const create = gql`
  mutation CreateContainer($containers: [CreateContainerInput!]!) {
    createContainer(containers: $containers) {
      ...Container
    }
  }
  ${container}
`;

export interface CreateContainerVariables {
  containers: CreateContainerInput[];
}

export interface CreateContainerResponse {
  createContainer: Container[];
}

const update = gql`
  mutation UpdateContainer($containers: [UpdateContainerInput!]!) {
    updateContainer(containers: $containers) {
      ...Container
    }
  }
  ${container}
`;

export interface UpdateContainerVariables {
  containers: UpdateContainerInput[];
}

export interface UpdateContainerResponse {
  updateContainer: Container[];
}

const remove = gql`
  mutation DeleteContainer($containers: [DeleteContainerInput!]!) {
    deleteContainer(containers: $containers) {
      ...Container
    }
  }
  ${container}
`;

export interface DeleteContainerVariables {
  containers: DeleteContainerInput[];
}

export interface DeleteContainerResponse {
  deleteContainer: IdArray;
}

export const ContainerQueries = {
  get,
};

export const ContainerMutations = {
  create,
  update,
  remove,
};

export const ContainerFragment = {
  container,
};
