import React, { useContext } from 'react';
import Grid from '@mui/material/Grid/Grid';
import { t } from '../../../../../types/translation/Translator';
import { ReorderRule } from '../../../../../types/reorderRule';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import ToggleButton from '../../ToggleButton';
import {
  StockLocationAggregateType,
  stockLocationAggregateTypeToString,
} from '../../../../../types/common/stockLocationAggregateType';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import Selector from '../../Selector';
import { BinContext } from '../../../../../context/BinContext';

import Paper from '../../../../../VentoryUI/components/common/Paper';

interface ReorderRuleStockLocationPaneProps {
  rule: ReorderRule;
  setRule: (rule: ReorderRule) => void;
  stockLocationIds: string[];
  setStockLocationIds: (stockLocationIds: string[]) => void;
}

export default function ReorderRuleStockLocationPane({
  rule,
  setRule,
  stockLocationIds,
  setStockLocationIds,
}: ReorderRuleStockLocationPaneProps) {
  const { stockLocations } = useContext(StockLocationContext);
  const { bins } = useContext(BinContext);

  const handleStockLocationsChanged = (sl: string[]) => {
    setStockLocationIds(sl);

    setRule(
      rule.withExcludedBinIds(
        rule.excludedBinIds.filter(binId => {
          const bin = bins.get(binId);
          if (bin && sl.includes(bin.stockLocationId)) return true;
          return false;
        }),
      ),
    );
  };

  return (
    <Paper>
      <Grid container spacing={1} alignContent={'space-between'}>
        <Grid item xs={12}>
          <ToggleButton
            onChange={value => {
              setRule(rule.withStockLocationAggregateType(value as StockLocationAggregateType));
            }}
            selected={rule.stockLocationAggregateType}
            values={Object.values(StockLocationAggregateType)}
            text={value => stockLocationAggregateTypeToString(value as StockLocationAggregateType)}
          />
        </Grid>
        {rule.stockLocationAggregateType === StockLocationAggregateType.allOf ||
        rule.stockLocationAggregateType === StockLocationAggregateType.anyOf ? (
          <Grid item xs={12}>
            <Selector
              testId={testIds.stockLocation}
              placeholder={t().forTheFollowingStockLocations.singular.upper}
              values={[...[...stockLocations.values()].map(sl => sl.id)]}
              checkedValues={stockLocationIds}
              toText={item => stockLocations.get(item)?.name || 'Unknown'}
              onChange={handleStockLocationsChanged}
            />
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
}
