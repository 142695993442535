import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { toFilterString } from '../../../../util/string.util';
import { TestIdIdentifier } from '../../../../util/identifiers/identifiers.util';
import FilterIcon from '../../../icons/Filter/FilterIcon';
import SearchBar from '../SearchBar/SearchBar';
import FilterModal from '../FilterModal/FilterModal';
import Menu from '../Menu/Menu';
import { MenuItemProps } from '../Menu/MenuItem';
import { Button, ButtonProps } from '../Button/Button';
import { BaseFilter } from '../../filters/common/BaseFilter';
import { t } from '../../../../types/translation/Translator';
import { FilterProps, getFilterCount } from '../../filters/common/filter.util';

// TODO: We should split this up when redesigning the Dashboards
interface SearchBarWithFilterProps<T> {
  testId?: TestIdIdentifier;
  loading?: boolean;
  placeholder?: string;
  filter: BaseFilter<T>;
  items: T[];
  setItems: (items: T[]) => void;
  onChange?: () => void;

  menuItems?: MenuItemProps[];
  buttons?: ButtonProps[];
  additional?: React.ReactNode[];

  search?: boolean;
}

export default function SearchBarWithFilter<T>({
  loading = false,
  placeholder,
  items,
  setItems,
  testId,
  menuItems,
  buttons,
  additional,
  filter,
  search = true,
  onChange = () => {},
}: SearchBarWithFilterProps<T>) {
  const [itemCount, setItemCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [filterProps, setFilterProps] = useState<FilterProps>(filter.getFilterProps());

  const handleChange = (item: string) => {
    setQuery(toFilterString(item, { removeDiacritics: true }));
  };

  const handleFilter = () => {
    if (!items.length) return;
    setItems([...filter.filteredItems(items, query, filterProps)]);
  };

  useEffect(() => {
    handleFilter();
  }, [loading, filterProps, query]);

  useEffect(() => {
    handleFilter();
    setItemCount(items.length);
  }, [items]);

  useEffect(() => {
    onChange();
  }, [filterProps]);

  const saveFilterProps = (filterProps: FilterProps) => {
    setFilterProps(filterProps);
    filter.saveFilterProps(filterProps);
  };

  return (
    <>
      <FilterModal
        open={open}
        onClose={() => setOpen(false)}
        filter={filter}
        filterProps={filterProps}
        setFilterProps={saveFilterProps}
      />
      <Grid container>
        <Grid item flexGrow={1}>
          <Grid container>
            {search ? (
              <Grid item pr={1}>
                <SearchBar onChange={handleChange} placeholder={placeholder} testId={testId} width={'w-[320px]'} />
              </Grid>
            ) : null}
            {filter.filterOptions().length ? (
              <Grid item>
                <Button
                  startIcon={<FilterIcon />}
                  text={t().filter.singular.label}
                  badge={getFilterCount(filterProps)}
                  onClick={() => setOpen(true)}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container columnSpacing={2}>
            {buttons?.length ? (
              <Grid item>
                <Grid container columnSpacing={1}>
                  {buttons.map((b, index) => (
                    <Button {...b} key={index} />
                  ))}
                </Grid>
              </Grid>
            ) : null}
            {additional?.length ? (
              <Grid item>
                <Grid container columnSpacing={1}>
                  {additional.map(e => (
                    <Grid item key={e?.toString()}>
                      {e}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : null}
            {menuItems?.length ? <Menu items={menuItems || []} /> : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
