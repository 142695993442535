import React, { JSX } from 'react';
import dayjs from 'dayjs';
import { toFilterString } from '../../../../util/string.util';
import { FileEntity } from '../../../../types/file';
import { t } from '../../../../types/translation/Translator';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { CalendarIcon } from '../../../icons/Calendar/CalendarIcon';
import { FilterProps } from '../common/filter.util';
import DateTimeFilterDropdownContent from './DropdownContent/DateTimeFilterContent';

export class DateTimeFilter extends BaseFilter<FileEntity> {
  toLabel(): string {
    return t().dateAndTime.singular.label;
  }

  toString(props: BaseFilterProps): string {
    if (!props) return this.toLabel();

    return props.filterProps.timeFrame
      ? `${new Date(props.filterProps.timeFrame.start).toLocaleDateString()} - ${new Date(
          props.filterProps.timeFrame.end,
        ).toLocaleDateString()}`
      : this.toLabel();
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <CalendarIcon className={this.className} />;
  }

  toDropdownContent(props: BaseFilterProps, open: boolean): JSX.Element {
    if (!open) {
      props.setFilterProps({ ...props.filterProps });
      return <></>;
    } else {
      return (
        <DateTimeFilterDropdownContent
          key={'dateTimeFilterContent'}
          filter={props.filterProps}
          setFilter={props.setFilterProps}
          color={this.color || 'black'}
        />
      );
    }
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return this.toString(props);
  }

  search(item: FileEntity, textFilter: string) {
    if (toFilterString(item.name).includes(textFilter) || toFilterString(item.type).includes(textFilter)) {
      return true;
    }

    return false;
  }

  filteredItems(items: FileEntity[], query: string, filterProps: FilterProps) {
    return items.filter(item => {
      if (
        filterProps.timeFrame &&
        (dayjs(filterProps.timeFrame.start).startOf('day').isAfter(dayjs(item.createdAt)) ||
          dayjs(filterProps.timeFrame.end).endOf('day').isBefore(dayjs(item.createdAt)))
      ) {
        return false;
      }

      if (filterProps.user && !filterProps.user.has(item.createdBy)) return false;
      if (filterProps.fileTypeCategory && !filterProps.fileTypeCategory.has(item.fileTypeCategory)) return false;

      return this.search(item, query);
    });
  }
}
