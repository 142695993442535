import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Pane from '../../../../VentoryUI/components/common/Pane';
import DeveloperSettingsPane from './Panes/DeveloperSettingsPane';

export default function DeveloperScreen() {
  const [error, setError] = useState<string>('');

  const tabs = [
    {
      text: 'Settings',
      path: '/developer/settings',
      key: 'settings',
    },
  ];

  return (
    <Pane tabs={tabs} error={error}>
      <Routes>
        <Route path='/settings' element={<DeveloperSettingsPane setError={setError} />} />
      </Routes>
    </Pane>
  );
}
