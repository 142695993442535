import React from 'react';
import { StockLocation } from '../../../types/stockLocation';
import OrderStatusLabel from './OrderStatusLabel';
import { OrderColumn, OrderTableSettings } from '../../../types/orderTableSettings';
import { toMap } from '../../../util/map.util';
import { removeDiacritics, toFilterString } from '../../../util/string.util';
import { TableHeader } from '../../../VentoryUI/components/common/Table';
import { getTextForCustomField } from '../../../types/customField';
import { User } from '../../../types/user';
import { Order, OrderType, OrderUtil } from '../../../types/order';

const getSourceLocation = (order: Order) => {
  switch (order.type) {
    case OrderType.inbound:
      return null;
    case OrderType.outbound:
      return order.stockLocationId;
    case OrderType.move:
      return order.stockLocationId;
  }
};

const getDestinationLocation = (order: Order) => {
  switch (order.type) {
    case OrderType.inbound:
      return order.stockLocationId;
    case OrderType.outbound:
      return null;
    case OrderType.move:
      return order.stockLocationId;
    case OrderType.replenish:
      return order.stockLocationId;
  }
};

export const orderTableHeaders = (
  stockLocations: Map<string, StockLocation>,
  users: Map<string, User>,
  settings: OrderTableSettings,
) => {
  const wantedColumns = toMap(settings.columns, 'column');

  const columns: TableHeader<Order>[] = [];

  if (wantedColumns.has(OrderColumn.orderNumber)) {
    columns.push({
      key: 'number',
      name: wantedColumns.get(OrderColumn.orderNumber)?.label || '',
      text: (item: Order) => item.number || 'Unknown Order Number',
      column: OrderColumn.orderNumber,
    });
  }

  if (wantedColumns.has(OrderColumn.type)) {
    columns.push({
      key: 'type',
      name: wantedColumns.get(OrderColumn.type)?.label || '',
      text: (item: Order) => OrderUtil.typeToString(item.type),
      column: OrderColumn.type,
    });
  }

  if (wantedColumns.has(OrderColumn.createdOn)) {
    columns.push({
      key: 'createdOn',
      name: wantedColumns.get(OrderColumn.createdOn)?.label || '',
      text: (item: Order) => `${new Date(item.createdAt).toLocaleDateString()}`,
      column: OrderColumn.createdOn,
      sortValue: (item: Order) => item.createdAt,
    });
  }

  if (wantedColumns.has(OrderColumn.updatedOn)) {
    columns.push({
      key: 'updatedOn',
      name: wantedColumns.get(OrderColumn.updatedOn)?.label || '',
      text: (item: Order) => `${new Date(item.updatedAt).toLocaleDateString()}`,
      column: OrderColumn.updatedOn,
      sortValue: (item: Order) => item.updatedAt,
    });
  }

  if (wantedColumns.has(OrderColumn.sourceLocation)) {
    columns.push({
      key: 'source',
      name: wantedColumns.get(OrderColumn.sourceLocation)?.label || '',
      text: (item: Order) =>
        getSourceLocation(item) ? stockLocations.get(item.stockLocationId)?.name || 'Unknown Stock Location' : '',
      column: OrderColumn.sourceLocation,
    });
  }

  if (wantedColumns.has(OrderColumn.destinationLocation)) {
    columns.push({
      key: 'destination',
      name: wantedColumns.get(OrderColumn.destinationLocation)?.label || '',
      text: (item: Order) =>
        getDestinationLocation(item) ? stockLocations.get(item.stockLocationId)?.name || 'Unknown Stock Location' : '',
      column: OrderColumn.destinationLocation,
    });
  }

  if (wantedColumns.has(OrderColumn.status)) {
    columns.push({
      key: 'status',
      name: wantedColumns.get(OrderColumn.status)?.label || '',
      text: (item: Order) => (
        <OrderStatusLabel
          status={item.status}
          justify={wantedColumns.size - 1 === wantedColumns.get(OrderColumn.status)?.index ? 'end' : 'start'}
        />
      ),
      column: OrderColumn.status,
      sortValue: (item: Order) => item.status,
    });
  }

  if (wantedColumns.has(OrderColumn.createdBy)) {
    columns.push({
      key: 'createdBy',
      name: wantedColumns.get(OrderColumn.createdBy)?.label || '',
      text: (item: Order) => users.get(item.createdBy)?.email || 'Unknown User',
      column: OrderColumn.createdBy,
    });
  }

  const defaultKeys = new Set(Object.keys(OrderColumn));
  const customFieldColumns = [...wantedColumns.values()].filter(item => !defaultKeys.has(item.column));

  customFieldColumns.forEach(item => {
    columns.push({
      key: item.column,
      name: item.label,
      text: (o: Order) => {
        const customFieldValue = o.customFieldValues().find(i => i.name === item.column);
        if (!customFieldValue) return '';
        return getTextForCustomField(customFieldValue);
      },
      column: item.column,
    });
  });

  return columns.sort(
    (a: TableHeader<Order>, b: TableHeader<Order>) =>
      (wantedColumns.get(a.column || '')?.index || -1) - (wantedColumns.get(b.column || '')?.index || -1),
  );
};

export const orderTableFilter = (
  item: Order,
  filter: string,
  stockLocations: Map<string, StockLocation>,
  users: Map<string, User>,
  settings: OrderTableSettings,
) => {
  const filterString = removeDiacritics(toFilterString(filter));
  const wantedColumns = toMap(settings.columns, 'column');

  if (!filter) return true;

  if (wantedColumns.has(OrderColumn.orderNumber) && toFilterString(item.number).includes(filterString)) return true;
  if (wantedColumns.has(OrderColumn.status) && toFilterString(item.status).includes(filterString)) return true;
  if (wantedColumns.has(OrderColumn.type) && toFilterString(item.type).includes(filterString)) return true;

  if (
    wantedColumns.has(OrderColumn.createdOn) &&
    new Date(item.createdAt).toLocaleDateString().toLocaleLowerCase().includes(filterString)
  ) {
    return true;
  }

  if (
    wantedColumns.has(OrderColumn.updatedOn) &&
    new Date(item.updatedAt).toLocaleDateString().toLocaleLowerCase().includes(filterString)
  ) {
    return true;
  }

  if (
    wantedColumns.has(OrderColumn.destinationLocation) &&
    item.type === OrderType.inbound &&
    removeDiacritics(toFilterString(stockLocations.get(item.stockLocationId)?.name)).includes(filterString)
  ) {
    return true;
  }

  if (
    wantedColumns.has(OrderColumn.sourceLocation) &&
    item.type === OrderType.outbound &&
    removeDiacritics(toFilterString(stockLocations.get(item.stockLocationId)?.name)).includes(filterString)
  ) {
    return true;
  }

  if (
    wantedColumns.has(OrderColumn.createdBy) &&
    removeDiacritics(toFilterString(users.get(item.createdBy)?.email)).includes(filterString)
  ) {
    return true;
  }

  const defaultKeys = new Set(Object.keys(OrderColumn));
  const customFieldColumns = [...wantedColumns.values()].filter(item => !defaultKeys.has(item.column));

  for (const custom of customFieldColumns) {
    const field = item
      .customFieldValues()
      .find(i => i.name === custom.column)
      ?.value.toLocaleLowerCase();
    if (field?.includes(filterString)) return true;
  }

  return false;
};
