import { Grid } from '@mui/material';
import TextInput from '../../../../../../Common/TextInput';
import React from 'react';
import { IntegrationSettings } from '../../../../../../../../types/integrationSettings';
import { t } from '../../../../../../../../types/translation/Translator';

interface IntegrationBusinessCentralSettingsInfoProps {
  settingsInput: IntegrationSettings;
  setSettingsInput: (settings: IntegrationSettings) => void;
  disabled: boolean;
}

export default function IntegrationBusinessCentralSettingsInfo({
  settingsInput,
  setSettingsInput,
  disabled,
}: IntegrationBusinessCentralSettingsInfoProps) {
  return (
    <Grid container alignContent={'start'} rowSpacing={1} columnSpacing={1}>
      <Grid item xs={6}>
        <TextInput
          disabled={disabled}
          mandatory
          value={settingsInput.name}
          onChange={v => setSettingsInput(settingsInput.withName(v))}
          placeholder={t().name.singular.label}
          label={t().name.singular.label}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          mandatory
          disabled={disabled}
          value={settingsInput.tenantId}
          onChange={v => setSettingsInput(settingsInput.withTenantId(v))}
          placeholder={t().tenantId.singular.label}
          label={t().tenantId.singular.label}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          mandatory
          disabled={disabled}
          value={settingsInput.environment}
          onChange={v => setSettingsInput(settingsInput.withEnvironment(v))}
          placeholder={t().environment.singular.label}
          label={t().environment.singular.label}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          mandatory
          disabled={disabled}
          value={settingsInput.clientId}
          onChange={v => setSettingsInput(settingsInput.withClientId(v))}
          placeholder={t().clientId.singular.label}
          label={t().clientId.singular.label}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          mandatory
          type='password'
          disabled={disabled}
          value={settingsInput.clientSecret}
          onChange={v => setSettingsInput(settingsInput.withClientSecret(v))}
          placeholder={t().clientSecret.singular.label}
          label={t().clientSecret.singular.label}
        />
      </Grid>
    </Grid>
  );
}
