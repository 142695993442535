import { Coordinates } from '../../../../types/common/address';

const getDistanceBetweenTwoCoordinates = (a: Coordinates, b: Coordinates) => {
  return Math.sqrt(Math.pow(a.longitude - b.longitude, 2) + Math.pow(a.latitude - b.latitude, 2));
};

export const ZOOM_FOR_SINGLE_STOCK_LOCATION = 14;

// Inverse function of largest possible distance between points and the largest distance of any point from the center
const largestPossibleDistanceBetweenTwoPoints = 180;
const zoomCorrectionFactor = 24;
export const getZoomByLargestDistanceFromCenter = (largestDistanceFromCenter: number) => {
  return largestPossibleDistanceBetweenTwoPoints / (largestDistanceFromCenter + zoomCorrectionFactor);
};

export class CoordinatesArray extends Array<Coordinates> {
  public largestDistanceFromCenter(center: Coordinates): number {
    let max = 0;
    this.forEach(co => {
      const distance = getDistanceBetweenTwoCoordinates(center, co);
      if (distance > max) max = distance;
    });
    return max;
  }

  public getMiddle(): Coordinates {
    let longitude = 0;
    let latitude = 0;
    this.forEach(co => {
      longitude += co.longitude;
      latitude += co.latitude;
    });
    longitude /= this.length;
    latitude /= this.length;
    return { latitude, longitude };
  }
}
