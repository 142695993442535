import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { Grid } from '@mui/material';
import TextInput from '../../Common/TextInput';
import { UserContext } from '../../../../context/UserContext';
import { UpdateUserResponse, UpdateUserVariables, UserMutations } from '../../../../graphql/user.graphql';
import { useMutation } from '@apollo/client';
import { User } from '../../../../types/user';

import Modal from '../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { SaveButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';

interface PromptUserRenameModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function PromptUserRenameModal({ open, setOpen }: PromptUserRenameModalProps) {
  const { currentUser, setCurrentUser } = useContext(UserContext);

  const [error, setError] = useState<string>('');

  if (!currentUser) return null;

  const handleUpdate = async () => {
    if (!currentUser) return;

    if (currentUser.firstName === 'Invited') {
      setError("First name can not be 'Invited'");
      return;
    }

    try {
      await update({
        variables: {
          user: currentUser,
        },
      });
    } catch (e) {
      setError(String(e));
    }
    handleClose();
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const [update, { loading: updateLoading }] = useMutation<UpdateUserResponse, UpdateUserVariables>(
    UserMutations.update,
    {
      onCompleted: res => {
        const user = res.updateUser;
        setCurrentUser(new User(user));
      },
      onError: res => setError(res.message),
    },
  );

  return (
    <Modal open={open} height='250px' onClose={() => {}} title={t().updateUserDetails.singular.upper} error={error}>
      <ModalPane footerButtons={[SaveButtonTemplate(handleUpdate, { loading: updateLoading })]}>
        <Grid container rowSpacing={1} height={'100%'} alignContent={'space-between'}>
          <Grid item xs={12}>
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={6}>
                <TextInput
                  mandatory
                  label={t().firstName.singular.label}
                  placeholder={t().firstName.singular.label}
                  value={currentUser?.firstName}
                  onChange={v => (currentUser.firstName = v)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  mandatory
                  label={t().lastName.singular.label}
                  placeholder={t().lastName.singular.label}
                  value={currentUser?.lastName}
                  onChange={v => (currentUser.lastName = v)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
