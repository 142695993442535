import { gql } from '@apollo/client';
import { Company, CompanySettings, DeleteCompanyInput, UpdateCompanyInput } from '../types/company';
import { forPaginated } from './common/paginated.graphql';

const company = gql`
  fragment Company on Company {
    id
    version
    companyId
    name
    status {
      service
      type
      state
    }
    settings {
      primaryColor
      accentColor
      headerColor
      companyLogo
      companyFavicon
      scanConfigurations {
        regex
        barcodeFormat
        regexOption
        type
      }
      featureToggles {
        alerts {
          alerts
        }
        orders {
          orders
          externalReferenceId
          purchaseOrderNumber
          estimatedTimeOfArrival
          customer
          supplier
          editProductsOnInbound
          editProductsOnOutbound
          productSelectionFirst
          sendMailOnAssigned
          ignoreAvailableQuantity
          picking
          productBasedApproach
        }
        products {
          container
          gs1Barcodes
          products
        }
        productTransactions {
          productTransactions
          web
          mobile
          quickActions
          scanRequired
        }
        company {
          ventoryVisionAR
          sessionStorage
        }
        sap {
          allowUncounted
        }
        tasks {
          tasks
          allowStockUpdate
          cycleCount
          blindCount
          taskOrders {
            taskOrders
            inbound
            move
            outbound
          }
        }
        containers {
          containers
        }
      }
    }
  }
`;

export const get = gql`
  query Company($id: String!) {
    company(id: $id) {
      ...Company
    }
  }
  ${company}
`;

export interface GetCompanyVariables {
  id: string;
}

export interface GetCompanyResponse {
  company: Company;
}

export const getAll = gql`
  query Companies {
    companies {
      data {
        ...Company
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${company}
`;

export interface GetCompaniesVariables {}

export interface GetCompaniesResponse {
  companies: PaginatedCompanies;
}

export class PaginatedCompanies extends forPaginated<Company>() {}

export const create = gql`
  mutation CreateCompany($company: CreateCompanyInput!) {
    createCompany(company: $company) {
      ...Company
    }
  }
  ${company}
`;

export interface CreateCompanyVariables {
  company: {
    name: string;
    settings?: CompanySettings;
  };
}

export interface CreateCompanyResponse {
  createCompany: { id: string };
}

export const update = gql`
  mutation UpdateCompany($company: UpdateCompanyInput!) {
    updateCompany(company: $company) {
      ...Company
    }
  }
  ${company}
`;

export interface UpdateCompanyVariables {
  company: UpdateCompanyInput;
}

export interface UpdateCompanyResponse {
  updateCompany: Company;
}

export const remove = gql`
  mutation DeleteCompany($company: DeleteCompanyInput!) {
    deleteCompany(company: $company) {
      ...Company
    }
  }
  ${company}
`;

export interface DeleteCompanyVariables {
  company: DeleteCompanyInput;
}

export interface DeleteCompanyResponse {
  deleteCompany: Company;
}

const createDemo = gql`
  mutation CreateDemoCompany {
    createDemoCompany {
      ...Company
    }
  }
  ${company}
`;

export interface CreateDemoCompanyVariables {}

export interface CreateDemoCompanyResponse {
  createDemoCompany: Company;
}

export const CompanyQueries = {
  get,
  getAll,
};

export const CompanyMutations = {
  create,
  update,
  remove,
  createDemo,
};

export const CompanyFragments = {
  company,
};
