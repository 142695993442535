import { gql } from '@apollo/client';
import { forPaginated } from './common/paginated.graphql';
import { CreateProductTransactionInput, ProductTransaction } from '../types/productTransaction';

const productTransaction = gql`
  fragment ProductTransaction on ProductTransaction {
    id
    version
    companyId
    type
    createdAt
    createdBy
    processedBy
    processedAt
    pickedAt
    pickedBy
    parentId
    parentType
    status
    containerTransactionId
    processChildren
    customFields {
      id
      value
      mandatory
      type
      name
    }
    externalIdentifier {
      settingsId
      externalId
      type
    }
    product {
      fromStockLocationId
      toStockLocationId
      toProductId
      fromProductId
      toContainerId
      fromContainerId
      lotId
      pmdId
      serialNbr
      lpn
      toBinId
      fromBinId
      quantity
      containerId
      processedQuantity
    }
    reasonForFailure
    externalIdentifier {
      settingsId
      type
    }
    metadata {
      lot {
        id
        companyId
        productMasterDataId
        number
        expirationDate
      }
      fromBin {
        id
        companyId
        stockLocationId
        binStatusId
        name
      }
      toBin {
        id
        companyId
        stockLocationId
        binStatusId
        name
      }
      productMasterData {
        id
        companyId
        productName
        productNumber
        unitOfMeasure {
          system
          unit
          unitType
        }
      }
    }
  }
`;

const get = gql`
  query ProductTransactions($companyId: String!, $page: Float, $batchSize: Float, $since: Date) {
    productTransactions(companyId: $companyId, page: $page, batchSize: $batchSize, since: $since) {
      data {
        ...ProductTransaction
      }
      page
      pageSize
      batchSize
      hasNext
    }
  }
  ${productTransaction}
`;

export interface GetProductTransactionsVariables {
  companyId: string;
  page?: number;
  batchSize?: number;
  since?: Date;
}

export interface GetProductTransactionsResponse {
  productTransactions: PaginatedProductTransactions;
}

class PaginatedProductTransactions extends forPaginated<ProductTransaction>() {}

const createAndProcess = gql`
  mutation CreateAndProcessTransaction($productTransactions: [CreateProductTransactionInput!]!) {
    createAndProcessTransaction(productTransactions: $productTransactions) {
      ...ProductTransaction
    }
  }
  ${productTransaction}
`;

export interface CreateAndProcessProductTransactionVariables {
  productTransactions: CreateProductTransactionInput[];
}

export interface CreateAndProcessProductTransactionResponse {
  createAndProcessTransaction: ProductTransaction[];
}

const process = gql`
  mutation ProcessProductTransaction($productTransactions: [ProcessProductTransactionInput!]!) {
    processProductTransaction(productTransactions: $productTransactions) {
      ...ProductTransaction
    }
  }
  ${productTransaction}
`;

export interface ProcessProductTransactionsVariables {
  productTransactions: ProductTransaction[];
}

export interface ProcessProductTransactionsResponse {
  processProductTransaction: ProductTransaction[];
}

const updateCustomFields = gql`
  mutation UpdateProductTransactionCustomFields($productTransactions: [UpdateProductTransactionInput!]!) {
    updateProductTransactionCustomFields(productTransactions: $productTransactions) {
      ...ProductTransaction
    }
  }
  ${productTransaction}
`;

export interface UpdateProductTransactionCustomFieldsVariables {
  productTransactions: ProductTransaction[];
}

export interface UpdateProductTransactionCustomFieldsResponse {
  updateProductTransactionCustomFields: ProductTransaction[];
}

const rollback = gql`
  mutation RollbackProductTransaction($productTransactions: [RollbackProductTransactionInput!]!) {
    rollbackProductTransaction(productTransactions: $productTransactions) {
      ...ProductTransaction
    }
  }
  ${productTransaction}
`;

export interface RollbackProductTransactionsVariables {
  productTransactions: ProductTransaction[];
}

export interface RollbackProductTransactionsResponse {
  rollbackProductTransaction: ProductTransaction[];
}

export const ProductTransactionQueries = {
  get,
};

export const ProductTransactionMutations = {
  createAndProcess,
  process,
  updateCustomFields,
  rollback,
};

export const ProductTransactionFragments = {
  productTransaction,
};
