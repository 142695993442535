import { Grid } from '@mui/material';
import { Order, OrderStatus, OrderType, OrderAuthorization } from '../../../../../../types/order/order';
import React, { useContext } from 'react';
import { StockLocationRoleAssignmentContext } from '../../../../../../context/StockLocationRoleAssignmentContext';
import { testIds } from '../../../../../../util/identifiers/identifiers.util';
import { t } from '../../../../../../types/translation/Translator';
import { Button } from '../../../../../../VentoryUI/components/common/Button/Button';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { AddButton } from '../../../../../../VentoryUI/components/common/Button';
import { CustomFieldEntitySubType, CustomFieldEntityType } from '../../../../../../types/customField';
import { CustomFieldContext } from '../../../../../../context/CustomFieldContext';

interface UpdateOrderPaneFooterProps {
  order: Order;
  loading: {
    update: boolean;
    cancel: boolean;
    complete: boolean;
  };
  actions: {
    onBack: () => void;
    onComplete: () => void;
    onCancel: () => void;
    onAssigned: () => void;
    onAddProduct: () => void;
    onUpdate: () => void;
  };
}

export default function UpdateOrderPaneFooter({ order, loading, actions }: UpdateOrderPaneFooterProps) {
  const { customFields } = useContext(CustomFieldContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);

  const entitySubtype =
    order.type === OrderType.inbound ? CustomFieldEntitySubType.inbound : CustomFieldEntitySubType.outbound;

  const customFieldItems = [...customFields.values()].filter(
    v => v.entityType === CustomFieldEntityType.order && (!v.entitySubtype || v.entitySubtype === entitySubtype),
  );

  const completeText =
    order.externalIdentifier?.settingsId === '001' && order.type === OrderType.outbound
      ? order.status === OrderStatus.pickingComplete
        ? t().issueGoods.singular.label
        : t().pickingComplete.singular.label
      : t().completeOrder.singular.label;

  const isCompleted = new Set<OrderStatus>([OrderStatus.complete, OrderStatus.cancelled]).has(order.status);
  const isExternal = !!order.externalIdentifier?.externalId;

  const authorization = new OrderAuthorization();

  const canProcess = hasStockLocationRole(order.companyId, order.stockLocationId, authorization.forProcess());
  const canUpdate = hasStockLocationRole(order.companyId, order.stockLocationId, authorization.forUpdate());

  const showCompleteButton = canProcess && !isCompleted && !isExternal;
  const showCancelButton = canUpdate && !isCompleted;
  const showAssignedButton = canUpdate && !isCompleted;
  const showAddProductsButton = canUpdate && !isCompleted && !isExternal;
  const showUpdateButton = canUpdate && !isCompleted && !isExternal && customFieldItems.length;

  const backButton = (
    <Grid item>
      <Button
        onClick={actions.onBack}
        testId={testIds.back}
        text={t().back.singular.label}
        disabled={loading.update || loading.cancel}
      />
    </Grid>
  );

  let completeButton = null;
  if (showCompleteButton) {
    completeButton = (
      <Grid item>
        <Button
          style='secondary'
          loading={loading.complete}
          onClick={actions.onComplete}
          testId={testIds.complete}
          text={completeText}
          disabled={loading.update || loading.cancel}
        />
      </Grid>
    );
  }

  let cancelButton = null;
  if (showCancelButton) {
    cancelButton = (
      <Grid item>
        <Button
          loading={loading.cancel}
          onClick={actions.onCancel}
          testId={testIds.cancel}
          text={t().cancelOrder.singular.label}
          disabled={loading.update}
        />
      </Grid>
    );
  }

  let assignedButton = null;
  if (showAssignedButton) {
    assignedButton = (
      <Grid item>
        <Button
          disabled={loading.update || loading.cancel || loading.complete}
          onClick={actions.onAssigned}
          testId={testIds.assignUsers}
          text={t().reassignUsers.singular.label}
          startIcon={<GroupAddIcon />}
        />
      </Grid>
    );
  }

  let addProductsButton = null;
  if (showAddProductsButton) {
    addProductsButton = (
      <AddButton
        disabled={loading.cancel || loading.complete || loading.update}
        onClick={actions.onAddProduct}
        testId={testIds.addProducts}
        text={t().addProducts.singular.label}
      />
    );
  }

  let updateButton = null;
  if (showUpdateButton) {
    updateButton = (
      <Button
        style='secondary'
        loading={loading.update}
        disabled={loading.cancel || loading.complete}
        onClick={actions.onUpdate}
        testId={testIds.update}
        text={t().update.singular.label}
      />
    );
  }

  return (
    <Grid container justifyContent={'space-between'} data-testid={testIds.orderPaneFooter}>
      <Grid item>
        <Grid container columnSpacing={1}>
          {[cancelButton, completeButton]}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          {[assignedButton, addProductsButton, backButton, updateButton]}
        </Grid>
      </Grid>
    </Grid>
  );
}
