import React, { useContext, useEffect, useState } from 'react';
import { FilterProps } from '../../common/filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { LotContext } from '../../../../../context/LotContext';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';
import { LotFilter } from '../LotFilter';

interface LotFilterContentProps {
  filter: LotFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export default function LotFilterContent({ filter, filterProps, setFilterProps }: LotFilterContentProps) {
  const { lots } = useContext(LotContext);
  const { productMasterData } = useContext(ProductMasterDataContext);

  const [textFilter, setTextFilter] = useState<string>('');

  const filterLots = () => {
    return new Set(filter.filteredItems([...lots.values()], textFilter, filterProps).map(lot => lot.id));
  };

  const [values, setValues] = useState<Set<string>>(filterLots());

  const handleChange = (selected: Set<string>) => {
    filterProps.lot = selected;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.lot = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterLots());
  }, [textFilter]);

  return (
    <Grid item xs={12} className='w-full'>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().lot.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setTextFilter(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            selected={filterProps.lot}
            values={values}
            onChange={handleChange}
            toText={id => `${lots.get(id)?.number}`}
            toSubText={id => `${productMasterData.get(lots.get(id)?.productMasterDataId || '')?.productName || ''}`}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
