import React from 'react';
import { ScanRule } from '../../../../types/scanRule';
import { toFilterString } from '../../../../util/string.util';
import { BaseFilter } from '../common/BaseFilter';

export class ScanRuleFilter extends BaseFilter<ScanRule> {
  toLabel(): string {
    return 'Not Implemented';
  }

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <></>;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  search(item: ScanRule, textFilter: string) {
    const flattenedConfigurationValues = item.configurationValues.flatMap(obj =>
      Object.values(obj)
        .flat()
        .map(val => toFilterString(val)),
    );

    if (flattenedConfigurationValues.includes(textFilter) || toFilterString(item.type).includes(textFilter)) {
      return true;
    }

    return false;
  }

  filteredItems(items: ScanRule[], query: string) {
    const result = items.filter(item => {
      return this.search(item, query);
    });

    return result;
  }
}
