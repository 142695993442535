import React from 'react';
import InnerFilterContent, { FilterInnerContentProps } from '../../common/DropdownContent/DynamicFilterInnerContent';
import { OrderStatus, OrderUtil } from '../../../../../types/order';

export default function OrderStatusFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<string>) {
  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.orderStatus || [])]}
      text={item => OrderUtil.statusToString(item as OrderStatus)}
      onRemove={i => {
        filterProps.orderStatus?.delete(i);
        if (!filterProps.orderStatus?.size) filterProps.orderStatus = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
