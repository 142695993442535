import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import TextInput from '../../../../ui/pages/Common/TextInput';
import { TestIdIdentifier } from '../../../../util/identifiers/identifiers.util';
import CloseIcon from '../../../icons/Close/CloseIcon';
import { DeleteIcon } from '../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../util/color.util';
import { Button } from '../Button/Button';
import DeleteButton from '../Button/Templates/DeleteButton';
import Modal from './Modal';

interface DeleteModalProps {
  text: string;
  error?: string;
  loading?: boolean;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  testId?: TestIdIdentifier;
  warning?: string;
  check?: string;
  checkLabel?: string;
  checkPlaceholder?: string;
}

export default function DeleteModal({
  text,
  open,
  onClose,
  onConfirm,
  error,
  loading,
  testId,
  warning,
  check,
  checkLabel,
  checkPlaceholder,
}: DeleteModalProps) {
  const [confirmCheck, setConfirmCheck] = useState<string>('');

  return (
    <Modal
      testId={testId}
      onClose={onClose}
      header={
        <Grid item xs={12}>
          <Grid container className='px-6 pt-6' justifyContent={'space-between'} alignItems='center'>
            <DeleteIcon color={VentoryColor.deleteRed} className='border ventory-border-light rounded-2 p-2 h-fit' />

            <CloseIcon
              className='text-gray-500 flex justify-center items-center hover:text-black cursor-pointer hover:bg-gray-100 rounded-4 h-[44px] w-[44px]'
              onClick={onClose}
            />
          </Grid>
        </Grid>
      }
      open={open}
      error={error}
      title=''
      width='400px'
    >
      {warning ? (
        <Grid item xs={12} className='bg-yellow-100 h3 py-3 px-4 border-2 border-yellow-200' mt={1}>
          <p style={{ textAlign: 'center' }} className='font-semibold text-yellow-500 text-sm'>
            {warning}
          </p>
        </Grid>
      ) : null}
      <Grid container className='px-6 py-6'>
        <Grid item xs={12}>
          <p className='text-md font-bold mb-2'>{t().confirm.singular.label}</p>
        </Grid>
        <Grid item xs={12}>
          <p className='text-sm'>{text}</p>
        </Grid>
        {check ? (
          <Grid item xs={12} mt={2}>
            <p className='font-semibold text-sm text-gray-500'>{checkLabel}</p>
            <TextInput placeholder={checkPlaceholder} onChange={v => setConfirmCheck(v)} />
          </Grid>
        ) : null}
        <Grid item xs={12} className={'pt-6'}>
          <Grid container justifyContent={'flex-end'} columnSpacing={1}>
            <Grid item>
              <Button onClick={onClose} text={t().cancel.singular.label} disabled={loading} />
            </Grid>
            <Grid item>
              <DeleteButton onClick={onConfirm} loading={loading} disabled={!!check && confirmCheck !== check} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
