import React, { useContext, useMemo, useState } from 'react';
import { FlexPane } from '../../../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table from '../../../../../../VentoryUI/components/common/Table/Table';
import { ContainerTypeContext } from '../../../../../../context/ContainerTypeContext';
import { ContainerType } from '../../../../../../types/containerType';
import CreateContainerTypeModal from './Modals/CreateContainerTypeModal';
import { CreateButtonTemplate } from '../../../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import UpdateContainerTypeModal from './Modals/UpdateContainerTypeModal';
import { ContainerTypeFilter } from '../../../../../../VentoryUI/components/filters/ContainerFilter/ContainerTypeFilter';
import { t } from '../../../../../../types/translation/Translator';
import SearchBarWithFilter from '../../../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';

interface ContainerTypePaneProps {
  setError: (error: string) => void;
}

export default function ContainerTypePane({ setError }: ContainerTypePaneProps) {
  const { containerTypes, containerTypesLoading } = useContext(ContainerTypeContext);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [updateModalContainerType, setUpdateModalContainerType] = useState<ContainerType | null>(null);

  const allItems = useMemo(() => {
    return [...containerTypes.values()];
  }, [containerTypes]);

  const [items, setItems] = useState(allItems);

  const headers = [
    {
      key: 'name',
      name: 'Name',
      text: (item: ContainerType) => item.name,
    },
  ];

  return (
    <>
      {createModalOpen ? <CreateContainerTypeModal open={createModalOpen} setOpen={setCreateModalOpen} /> : null}
      {updateModalContainerType ? (
        <UpdateContainerTypeModal
          containerType={updateModalContainerType}
          open={updateModalContainerType !== null}
          setOpen={open => {
            if (open == true) return;
            setUpdateModalContainerType(null);
          }}
        />
      ) : null}
      <FlexPane
        header={
          <SearchBarWithFilter
            loading={containerTypesLoading}
            items={allItems}
            setItems={setItems}
            placeholder={t().filterContainerType.plural.label}
            buttons={[CreateButtonTemplate(() => setCreateModalOpen(true))]}
            filter={new ContainerTypeFilter()}
          />
        }
        content={
          <Table
            items={items}
            totalItemCount={allItems.length}
            headers={headers}
            onClick={item => setUpdateModalContainerType(item)}
          />
        }
      />
    </>
  );
}
