import { Grid } from '@mui/material';
import TextInput from '../../../Common/TextInput';
import { t } from '../../../../../types/translation/Translator';
import React, { useState } from 'react';
import { ShippingLocation } from '../../../../../types/contact';
import DropdownSelect from '../../../Common/DropdownSelect';
import { countries } from '../../../../../util/country';
import { testIds } from '../../../../../util/identifiers/identifiers.util';

import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';

interface ShippingLocationInfoPaneProps {
  location: ShippingLocation;
  disabled?: boolean;
  footer: (loc: ShippingLocation) => JSX.Element;
}

export default function ShippingLocationInfoPane({
  location,
  footer,
  disabled = false,
}: ShippingLocationInfoPaneProps) {
  const [locationInput, setLocationInput] = useState<ShippingLocation>(new ShippingLocation(location));

  return (
    <ModalPane footer={footer(locationInput)}>
      <Grid container alignContent={'space-between'} rowSpacing={3} data-testid={testIds.shippingLocationInfoPane}>
        <Grid item xs={12}>
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    mandatory
                    disabled={disabled}
                    label={t().name.singular.label}
                    placeholder={t().name.singular.label}
                    value={locationInput.name}
                    onChange={v => setLocationInput(locationInput.withName(v))}
                    testId={testIds.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    disabled={disabled}
                    value={locationInput.email}
                    label={t().contactEmail.singular.label}
                    placeholder={t().contactEmail.singular.label}
                    onChange={v => locationInput.withEmail(v)}
                    testId={testIds.contactEmail}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container columnSpacing={1} rowSpacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    disabled={disabled}
                    value={locationInput.address?.addressLine1}
                    label={t().addressLine1.singular.label}
                    placeholder={t().addressLine1.singular.label}
                    onChange={v => locationInput.withAddressLine1(v)}
                    testId={testIds.addressLine1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    disabled={disabled}
                    value={locationInput.address?.addressLine2}
                    label={t().addressLine2.singular.label}
                    placeholder={t().addressLine2.singular.label}
                    onChange={v => locationInput.withAddressLine2(v)}
                    testId={testIds.addressLine2}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={disabled}
                    value={locationInput.address?.postalCode}
                    label={t().postalCode.singular.label}
                    placeholder={t().postalCode.singular.label}
                    onChange={v => locationInput.withPostalCode(v)}
                    testId={testIds.postalCode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={disabled}
                    value={locationInput.address?.city}
                    label={t().city.singular.label}
                    placeholder={t().city.singular.label}
                    onChange={v => locationInput.withCity(v)}
                    testId={testIds.city}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    disabled={disabled}
                    value={locationInput.address?.region}
                    label={t().region.singular.label}
                    placeholder={t().region.singular.label}
                    onChange={v => locationInput.withRegion(v)}
                    testId={testIds.region}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DropdownSelect
                    disabled={disabled}
                    testId={testIds.country}
                    placeholder={t().country.singular.label}
                    label={t().country.singular.label}
                    selectedValue={countries.find(c => c.cca2 === locationInput.address?.countryCode) || null}
                    maxHeight='150px'
                    values={countries}
                    toText={(item: any) => item.name.common}
                    onChange={(item: any) =>
                      setLocationInput(locationInput.withCountryCode(item ? item.cca2 : undefined))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
