import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CompanyContext } from '../../../../context/CompanyContext';
import SettingsIcon from '../../../icons/Settings/SettingsIcon';
import { ReactComponent as VentoryWhiteLogo } from '../../../../assets/img/Ventory-WhiteNoBack.svg';
import { Colors, VentoryColor } from '../../../util/color.util';
import { t } from '../../../../types/translation/Translator';
import { useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon } from '../../../icons/Home/HomeIcon';
import StockIcon from '../../../icons/Stock/StockIcon';
import PackageSearchIcon from '../../../icons/Package/PackageSearchIcon';
import { ListIcon } from '../../../icons/List/ListIcon';
import BoxedCheckIcon from '../../../icons/BoxedCheck/BoxedCheckIcon';
import BellIcon from '../../../icons/Bell/BellIcon';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { Divider } from '@mui/material';
import { UserContext } from '../../../../context/UserContext';
import CollapseIcon from '../../../icons/Collapse/CollapseIcon';
import SidebarUserMenu from '../UserMenu/SidebarUserMenu';
import FileViewLoader from '../File/FileViewLoader';
import ImageView from '../File/Image/ImageView';
import { FileEntityType } from '../../../../types/file';

interface SidebarItem {
  index: number;
  text: string;
  path: string;
  identifier: string;
  icon: React.ReactNode;
}

interface SidebarProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

export function Sidebar({ collapsed, setCollapsed }: SidebarProps) {
  const location = useLocation();
  const { currentCompany } = useContext(CompanyContext);
  const { currentUser } = useContext(UserContext);

  const [selectedIndex, setSelectedIndex] = useState(1);

  const list = useMemo(
    () =>
      [
        {
          index: 0,
          text: t().dashboard.singular.label,
          path: `/dashboard`,
          identifier: 'dashboard',
          icon: <HomeIcon />,
        },
        {
          index: 1,
          text: t().stock.singular.label,
          path: `/stock/products`,
          identifier: 'stock',
          icon: <StockIcon />,
        },
        {
          index: 2,
          text: t().referenceData.singular.label,
          path: `/reference_data/products`,
          identifier: 'reference_data',
          icon: <PackageSearchIcon />,
        },
        {
          index: 3,
          text: t().order.plural.label,
          path: '/operations/orders',
          identifier: 'operations',
          icon: <ListIcon height={20} width={20} />,
        },
        { index: 4, text: t().tasks.singular.label, path: `/tasks`, identifier: 'tasks', icon: <BoxedCheckIcon /> },
        {
          index: 5,
          text: t().alert.plural.label,
          path: `/alerts`,
          identifier: 'alerts',
          icon: <BellIcon />,
        },
        {
          index: 6,
          text: t().settings.singular.label,
          path: `/settings/profile`,
          identifier: 'settings',
          icon: <SettingsIcon width={20} height={20} />,
        },
      ].filter(item => {
        if (item.identifier === 'alerts' && !currentCompany.settings.featureToggles.alerts.alerts) return false;
        if (item.identifier === 'tasks' && !currentCompany.settings.featureToggles.tasks.tasks) return false;
        if (item.identifier === '/stock/' && !currentCompany.settings.featureToggles.products.products) return false;
        if (item.identifier === 'operations' && !currentCompany.settings.featureToggles.orders.orders) return false;

        return true;
      }),
    [currentCompany],
  );

  useEffect(() => {
    const path = location.pathname;
    const index = [...list].find(i => path.includes(i.identifier))?.index ?? -1;
    if (index === -1) setSelectedIndex(0);
    else setSelectedIndex(index);
  }, [location]);

  return (
    <div
      className='grid bg-white grid-rows-center-flex select-none my-4 ml-4 rounded-4 border border-ventory-grey-200 overflow-hidden'
      style={{ width: collapsed ? '52px' : '210px' }}
      data-testid={testIds.sidebar}
    >
      <div
        className='mb-4 rounded-t-[8px]'
        style={{
          backgroundColor: Colors.getHeaderColor(currentCompany),
        }}
      >
        <SidebarHeader collapsed={collapsed} />
      </div>
      <div className='grid w-full h-fit text-ventory-grey-700 text-[14px]'>
        {list.map(item => (
          <SidebarItem
            key={item.index}
            item={item}
            collapsed={collapsed}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        ))}
      </div>
      <div className='grid w-full'>
        <SidebarFooter collapsed={collapsed} setCollapsed={setCollapsed} />
      </div>
    </div>
  );
}

interface SidebarHeaderProps {
  collapsed: boolean;
}

function SidebarHeader({ collapsed }: SidebarHeaderProps) {
  const { currentCompany } = useContext(CompanyContext);

  const favicon = currentCompany.settings.companyFavicon;
  const logo = currentCompany.settings.companyLogo;

  const fileId = useMemo(() => {
    if (collapsed) {
      return favicon || logo || 'default-favicon';
    } else {
      return logo || favicon || 'default-logo';
    }
  }, [collapsed, currentCompany]);

  const content = () => {
    if (fileId === 'default-favicon') {
      return (
        <ImageView
          className='object-scale-down h-[32px] w-[32px]'
          url={'https://ventorystoragedev.blob.core.windows.net/ventory/favicon.ico'}
        />
      );
    } else if (fileId === 'default-logo') {
      return <VentoryWhiteLogo color={VentoryColor.grey500} stroke={VentoryColor.grey500} />;
    } else {
      return (
        <FileViewLoader
          className='object-scale-down px-[8px]'
          fileId={fileId}
          fileEntityType={FileEntityType.company}
        />
      );
    }
  };

  return <div className={`h-[48px] my-[6px] w-full flex items-center justify-center`}>{content()}</div>;
}

interface SidebarItemProps {
  item: SidebarItem;
  collapsed: boolean;
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
}

function SidebarItem({ item, collapsed, selectedIndex, setSelectedIndex }: SidebarItemProps) {
  const { currentCompany } = useContext(CompanyContext);
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  const style = () => {
    if (item.index === selectedIndex || hovered) {
      return {
        backgroundColor: Colors.companyLightBackground(currentCompany),
        color: Colors.companyDarkText(currentCompany),
      };
    }
  };

  const icon = () => {
    return (
      <div
        className={`flex w-[36px] h-[36px] justify-center items-center cursor-pointer rounded-3`}
        style={style()}
        onClick={() => navigate(item.path)}
      >
        {item.icon}
      </div>
    );
  };

  return (
    <div
      className='px-2 py-1 text-nowrap text-ellipsis'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <span title={item.text}>
        {collapsed ? (
          icon()
        ) : (
          <div
            className='flex h-[36px] w-full cursor-pointer justify-start items-center rounded-3 font-medium'
            style={style()}
            onClick={() => navigate(item.path)}
            data-testid={item.identifier}
          >
            {icon()}
            {!collapsed ? <p>{item.text}</p> : null}
          </div>
        )}
      </span>
    </div>
  );
}

interface SidebarFooterProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

function SidebarFooter({ collapsed, setCollapsed }: SidebarFooterProps) {
  return (
    <>
      <SidebarUserMenu collapsed={collapsed} />
      <Divider style={{ margin: '4px 0' }} />
      <div
        className={`my-1 mx-2 flex h-[36px] cursor-pointer text-ventory-grey-500`}
        onClick={() => setCollapsed(!collapsed)}
      >
        <CollapseIcon collapsed={collapsed} className='pl-2' />
        {collapsed ? null : <p className='pl-2 flex items-center text-[14px]'>{t().collapse.singular.label}</p>}
      </div>
    </>
  );
}
