import React from 'react';
import { useContext } from 'react';
import { ContainerTypeContext } from '../../../../../context/ContainerTypeContext';
import { ContainerType } from '../../../../../types/containerType';
import { t } from '../../../../../types/translation/Translator';
import InnerFilterContent, { FilterInnerContentProps } from '../../common/DropdownContent/DynamicFilterInnerContent';

export default function ContainerTypeFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<ContainerType>) {
  const { containerTypes } = useContext(ContainerTypeContext);

  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.containerType?.values() || [])]}
      text={item => containerTypes.get(item)?.id || t().unknownContainerType.singular.label}
      onRemove={i => {
        filterProps.containerType?.delete(i);
        if (!filterProps.containerType?.size) filterProps.containerType = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
