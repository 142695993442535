import * as atlas from 'azure-maps-control';
import React, { useEffect, useState } from 'react';
import { Coordinates } from '../../../types/common/address';
import { t } from '../../../types/translation/Translator';
import { Grid } from '@mui/material';
import FactoryIcon from '@mui/icons-material/Factory';

interface ClickableMapProps {
  zoom: number;
  onClick: (coordinates: Coordinates) => void;
  coordinates?: Coordinates;
}

export default function ClickableMap({ coordinates, zoom, onClick }: ClickableMapProps) {
  const key = process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY || 'no_key';
  const sourceId = 'ventory_source';
  const pointId = 'ventory_marker';
  const layerId = 'ventory_layer';

  const [map, setMap] = useState<atlas.Map>();
  let loaded = false;

  const createMap = () => {
    if (loaded) return;

    setMap(
      new atlas.Map('map', {
        center: [coordinates?.longitude || 0, coordinates?.latitude || -100],
        zoom: zoom,

        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: key,
        },
      }),
    );

    loaded = true;
  };

  const setMarker = () => {
    if (!map || !coordinates) return;

    map.setCamera({
      center: [coordinates.longitude, coordinates.latitude],
    });

    map.events.add('ready', () => {
      map.getCanvasContainer().style.cursor = 'pointer';

      // Remove existing layer and dataSource
      if (map.layers.getLayerById(layerId)) map.layers.remove(layerId);
      if (map.sources.getById(sourceId)) map.sources.remove(sourceId);

      const point = new atlas.Shape(new atlas.data.Point([coordinates.longitude, coordinates.latitude]), pointId);

      //Create a data source and add it to the map.
      const dataSource = new atlas.source.DataSource(sourceId);
      map.sources.add(dataSource);

      //Add the data to the data source.
      dataSource.add(point);

      map.layers.add(
        new atlas.layer.SymbolLayer(dataSource, layerId, {
          iconOptions: {
            //For smoother animation, ignore the placement of the icon. This skips the label collision calculations and allows the icon to overlap map labels.
            ignorePlacement: true,

            //For smoother animation, allow symbol to overlap all other symbols on the map.
            allowOverlap: true,
          },
        }),
      );

      map.events.add('click', function (e) {
        if (e.position) {
          point.setCoordinates(e.position);
          onClick({ longitude: e.position[0], latitude: e.position[1] });
        }
      });
    });
  };

  useEffect(() => {
    if (!coordinates) loaded = false;
    createMap();
  }, [coordinates]);

  useEffect(() => {
    setMarker();
  }, [coordinates, map]);

  if (!coordinates) {
    return (
      <Grid container height={'100%'} alignContent={'center'}>
        <Grid item xs={12} textAlign={'center'}>
          <FactoryIcon className='text-gray-300' style={{ fontSize: '5rem' }} />
          <p className='font-semibold text-sm text-gray-300'>{t().noAddress.singular.label}</p>
        </Grid>
        <div id='map' className='hidden'></div>
      </Grid>
    );
  }

  return (
    <div
      key={'myMap'}
      id='map'
      style={{
        width: '100%',
        height: '500px',
      }}
    ></div>
  );
}
