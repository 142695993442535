import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { UserContext } from '../../../../../context/UserContext';
import {
  CompanyMutations,
  DeleteCompanyResponse,
  DeleteCompanyVariables,
} from '../../../../../graphql/company.graphql';
import DeleteModal from '../../../../../VentoryUI/components/common/Modal/DeleteModal';

interface DeleteCompanyModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function DeleteCompanyModal({ open, setOpen }: DeleteCompanyModalProps) {
  const { signOut } = useContext(UserContext);
  const { currentCompany } = useContext(CompanyContext);

  const [error, setError] = useState<string>('');

  const [remove, { loading }] = useMutation<DeleteCompanyResponse, DeleteCompanyVariables>(CompanyMutations.remove, {
    onCompleted: res => {
      signOut();
    },
    onError: err => setError(err.message),
  });

  const handleDelete = async () => {
    try {
      await remove({
        variables: {
          company: currentCompany.forDelete(),
        },
      });

      if (!error) handleClose();
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <DeleteModal
      open={open}
      error={error}
      onClose={handleClose}
      text={t().verifyDeleteCompany.singular.upper}
      onConfirm={handleDelete}
      checkPlaceholder={currentCompany.name}
      checkLabel={t().pleaseEnterCompanyNameToConfirm.singular.upper}
      check={currentCompany.name}
      loading={loading}
    />
  );
}
