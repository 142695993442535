import { Grid } from '@mui/material';
import { NumberChartType } from '../../charts/NumberChart/NumberChart';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import React, { useContext, useState } from 'react';
import { FilterProps, FilterStorageKey } from '../../filters/common/filter.util';
import { t } from '../../../../types/translation/Translator';
import { BarChartContainer } from '../../../../ui/pages/Dashboard/Graphs/Containers/BarChartContainer';
import { NumberChartContainer } from '../../../../ui/pages/Dashboard/Graphs/Containers/NumberChartContainer';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { OrderTableSettingsContext } from '../../../../context/OrderTableSettingsContext';
import { TagRelationContext } from '../../../../context/TagRelationContext';
import { useNavigate } from 'react-router-dom';
import { OrderStatus, OrderType } from '../../../../types/order/order';
import SearchBarWithFilter from '../SearchBarWithFilter/SearchBarWithFilter';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { OrderFilter } from '../../filters/OrderFilter';
import { UserContext } from '../../../../context/UserContext';

interface OrderMetricsProps {
  color: string;
}

export default function OrderMetrics({ color }: OrderMetricsProps) {
  dayjs.extend(customParseFormat);

  const navigate = useNavigate();

  const { orderTableSettings } = useContext(OrderTableSettingsContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { tagRelations } = useContext(TagRelationContext);
  const { companyUsers } = useContext(UserContext);

  const getFilter = (key: string) => {
    return new OrderFilter(stockLocations, orderTableSettings, tagRelations, companyUsers, key);
  };

  const key = 'om';
  const filter = getFilter(key);
  const [filterProps, setFilterProps] = useState<FilterProps>(filter.getFilterProps());

  return (
    <>
      <Grid item xs={12} mt={1}>
        <SectionTitle text={t().orderMetrics.singular.label} />
      </Grid>
      <Grid item xs={12} mb={1}>
        <Grid container>
          <Grid item>
            <SearchBarWithFilter
              items={[]}
              setItems={() => {}}
              onChange={() => {
                setFilterProps(filter.getFilterProps());
              }}
              filter={filter}
              search={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} display={'flex'}>
        <Grid container columnSpacing={1}>
          <Grid item lg={4} md={6} sm={12} xs={12} display={'flex'}>
            <Grid container display={'flex'}>
              <Grid item xs={12} display={'flex'}>
                <Grid container columnSpacing={1} display={'flex'}>
                  <NumberChartContainer
                    type={NumberChartType.text}
                    size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    text={t().total.singular.label}
                    color={color}
                    filter={filterProps}
                    entity={FilterStorageKey.ORDER}
                    onClick={() => {
                      navigate(`/operations/orders?filter=${key}`);
                    }}
                  />
                  <NumberChartContainer
                    type={NumberChartType.text}
                    size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    text={t().complete.singular.label}
                    color={color}
                    filter={filterProps}
                    entity={FilterStorageKey.ORDER}
                    additionalFilter='status = complete OR status = released'
                    onClick={() => {
                      const key = 'om-complete';
                      const statusFilter = getFilter(key);

                      const props = filter.getFilterProps();
                      props.orderStatus = new Set(props.orderStatus);
                      props.orderStatus.add(OrderStatus.complete);
                      props.orderStatus.add(OrderStatus.released);
                      statusFilter.saveFilterProps(props);

                      navigate(`/operations/orders?filter=${key}`);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} display={'flex'}>
                <Grid container columnSpacing={1} display={'flex'}>
                  <NumberChartContainer
                    type={NumberChartType.text}
                    size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    text={t().inbound.singular.label}
                    color={color}
                    filter={filterProps}
                    entity={FilterStorageKey.ORDER}
                    additionalFilter='type = inbound'
                    onClick={() => {
                      const key = 'om-inbound';
                      const statusFilter = getFilter(key);

                      const props = filter.getFilterProps();
                      props.orderType = new Set(props.orderType);
                      props.orderType.add(OrderType.inbound);
                      statusFilter.saveFilterProps(props);

                      navigate(`/operations/orders?filter=${key}`);
                    }}
                  />
                  <NumberChartContainer
                    type={NumberChartType.text}
                    size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                    text={t().outbound.singular.label}
                    color={color}
                    filter={filterProps}
                    entity={FilterStorageKey.ORDER}
                    additionalFilter='type = outbound'
                    onClick={() => {
                      const key = 'om-outbound';
                      const statusFilter = getFilter(key);

                      const props = filter.getFilterProps();
                      props.orderType = new Set(props.orderType);
                      props.orderType.add(OrderType.outbound);
                      statusFilter.saveFilterProps(props);

                      navigate(`/operations/orders?filter=${key}`);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8} md={6} sm={12} display={'flex'}>
            <Grid container columnSpacing={1} display={'flex'} className='h-full'>
              <BarChartContainer
                text={t().numberOfOrders.singular.label}
                entity={FilterStorageKey.ORDER}
                color={color}
                filter={filterProps}
                className='min-h-[200px]'
                onClick={(idx: number, format: string, value: string) => {
                  const search = { ...filterProps };

                  let startDate: Dayjs | undefined = undefined;
                  let endDate: Dayjs | undefined = undefined;
                  // Todo: rewrite when implementing new dashboards
                  if (!search.timeFrame) {
                    if (format === 'MM/YYYY') {
                      startDate = dayjs(`01/${value}`, 'DD/MM/YYYY').startOf('day');
                      endDate = dayjs(`01/${value}`, 'DD/MM/YYYY').endOf('month').endOf('day');
                    } else if (format === 'YYYY/MM') {
                      startDate = dayjs(`${value}/01`, 'YYYY/MM/DD').startOf('day');
                      endDate = dayjs(`${value}/01`, 'YYYY/MM/DD').endOf('month').endOf('day');
                    }
                  } else {
                    const diff = dayjs(search.timeFrame?.end).diff(dayjs(search.timeFrame?.start), 'day');

                    if (diff <= 29) {
                      startDate = dayjs(search.timeFrame?.start).add(idx, 'day').startOf('day');
                      endDate = startDate.endOf('day');
                    } else {
                      startDate = dayjs(search.timeFrame?.start).add(idx, 'month').startOf('month').startOf('day');
                      endDate = startDate.endOf('month').endOf('day');
                    }
                  }

                  if (!startDate || !endDate) return;

                  const key = 'tm-taskChart';
                  const statusFilter = getFilter(key);
                  const props = filter.getFilterProps();
                  props.timeFrame = {
                    start: startDate.toDate(),
                    end: endDate.toDate(),
                  };
                  statusFilter.saveFilterProps(props);

                  navigate(`/operations/orders?filter=${key}`);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
