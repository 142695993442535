import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { CreateTaskInput, Task } from '../../../../../types/task';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import DatePicker from '../../../Common/DatePicker';
import { TaskSettingsContext } from '../../../../../context/TaskSettingsContext';
import { TaskSettings } from '../../../../../types/taskSettings';
import { CompanyContext } from '../../../../../context/CompanyContext';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { BackButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/NextButton/NextButton';

interface CreateTaskDueDatePaneInputProps {
  task: CreateTaskInput;
  setTask: (task: Task) => void;
  next: () => void;
  back: () => void;
  loading: boolean;
}

export function CreateTaskDueDatePane({ task, setTask, next, back, loading }: CreateTaskDueDatePaneInputProps) {
  const { taskSettings } = useContext(TaskSettingsContext);
  const { currentCompany } = useContext(CompanyContext);
  const taskSetting = new TaskSettings(
    taskSettings.size ? [...taskSettings.values()][0] : { companyId: currentCompany.id },
  );

  return (
    <ModalPane
      testId={testIds.createTaskDueDatePane}
      footerButtons={[
        BackButtonTemplate(back),
        NextButtonTemplate(next, { disabled: taskSetting.dueDateMandatory && !task.dueDate }),
      ]}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={6}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <DatePicker
                label={t().addDueDate.singular.label}
                placeholder={t().setDueDate.singular.label}
                value={new Date(task.dueDate || '')}
                onChange={date => {
                  setTask(task.withDueDate(date));
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
