export function toMap<E>(input: E[], keySupplier: keyof E | ((item: E) => string)): Map<string, E> {
  if (typeof keySupplier === 'function') {
    return new Map(input.map(value => [keySupplier(value), value]));
  }

  return new Map(input.map(value => [String(value[keySupplier]), value]));
}

export function toGroupedMap<E>(input: E[], keySupplier: keyof E): Map<string, E[]> {
  const map = new Map<string, E[]>();

  for (const item of input) {
    const key = String(item[keySupplier]);
    if (map.has(key)) map.get(key)!.push(item);
    else map.set(key, [item]);
  }

  return map;
}
