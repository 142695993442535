import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { t } from '../../../../../../../types/translation/Translator';
import BarcodeConfigurationInfoPane from '../Panes/BarcodeConfigurationInfoPane';
import { ScanConfiguration } from '../../../../../../../types/company';
import { cloneDeep } from '@apollo/client/utilities';
import Modal from '../../../../../../../VentoryUI/components/common/Modal/Modal';
import CancelButton from '../../../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import CreateButton from '../../../../../../../VentoryUI/components/common/Button/Templates/CreateButton';

interface CreateBarcodeConfigurationModalInputProps {
  open: boolean;
  setOpen: (open: boolean, configuration?: ScanConfiguration) => void;
  configuration?: ScanConfiguration;
}

export default function CreateBarcodeConfigurationModal({
  open,
  setOpen,
  configuration,
}: CreateBarcodeConfigurationModalInputProps) {
  if (!configuration) return null; // TODO: Not found

  const [error, setError] = useState<string>('');

  const [configurationInput, setConfigurationInput] = useState<ScanConfiguration>(cloneDeep(configuration));

  const handleCreate = async () => {
    setError('');
    setOpen(false, configurationInput);
  };

  const handleClose = () => {
    setError('');
    setOpen(false, undefined);
  };

  const footer = (config: ScanConfiguration) => {
    return (
      <Grid container marginTop={'auto'} columnSpacing={1} justifyContent={'flex-end'}>
        <Grid item>
          <CancelButton disabled={false} onClick={handleClose} />
        </Grid>
        <Grid item>
          <CreateButton disabled={!configurationInput.regex} onClick={() => handleCreate()} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t().createBarcodeConfiguration.singular.label}>
      <BarcodeConfigurationInfoPane
        configuration={configurationInput}
        setConfiguration={setConfigurationInput}
        footer={footer}
      />
    </Modal>
  );
}
