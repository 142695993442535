import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { StockLocationRole } from './stockLocationRoleAssignment';
import { CompanyRole } from './companyRoleAssignment';

export enum TaskSettingEntityType {
  company = 'company',
  stockLocation = 'stockLocation',
}

export class TaskSettings extends CompanyEntity {
  entityType!: string;
  entityId!: string;
  discrepancyThreshold: number = 0;

  stockLocationRolesForInitialCount: StockLocationRole[] = [
    StockLocationRole.STOCK_LOCATION_MANAGER,
    StockLocationRole.STOCK_LOCATION_SUPERVISOR,
    StockLocationRole.STOCK_LOCATION_USER,
  ];
  stockLocationRolesForRecount: StockLocationRole[] = [
    StockLocationRole.STOCK_LOCATION_MANAGER,
    StockLocationRole.STOCK_LOCATION_SUPERVISOR,
    StockLocationRole.STOCK_LOCATION_USER,
  ];
  companyRolesForInitialCount: CompanyRole[] = [...Object.values(CompanyRole)];
  companyRolesForRecount: CompanyRole[] = [...Object.values(CompanyRole)];
  requireUniqueRecountUser: boolean = false;
  sendPushNotificationToAssignees: boolean = false;
  assignInitialAssigneesToRecount: boolean = false;
  dueDateMandatory: boolean = false;
  stockLocationRoleForUpdateStock: StockLocationRole = StockLocationRole.STOCK_LOCATION_SUPERVISOR;
  public prefix?: string = '';
  public suffix?: string = '';
  public digits: number = 5;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
    this.entityId = obj.entityId || obj.companyId;
    this.entityType = obj.entityType || TaskSettingEntityType.company;
    this.companyRolesForInitialCount = obj.companyRolesForInitialCount || [...Object.values(CompanyRole)];
    this.companyRolesForRecount = obj.companyRolesForRecount || [...Object.values(CompanyRole)];
    this.stockLocationRolesForInitialCount = obj.stockLocationRolesForInitialCount || [
      StockLocationRole.STOCK_LOCATION_MANAGER,
      StockLocationRole.STOCK_LOCATION_SUPERVISOR,
      StockLocationRole.STOCK_LOCATION_USER,
    ];
    this.stockLocationRolesForRecount = obj.stockLocationRolesForRecount || [
      StockLocationRole.STOCK_LOCATION_MANAGER,
      StockLocationRole.STOCK_LOCATION_SUPERVISOR,
      StockLocationRole.STOCK_LOCATION_USER,
    ];
    this.dueDateMandatory = obj.dueDateMandatory || false;
    this.stockLocationRoleForUpdateStock =
      obj.stockLocationRoleForUpdateStock || StockLocationRole.STOCK_LOCATION_SUPERVISOR;
  }

  forUpdate(): UpdateTaskSettingsInput {
    return UpdateTaskSettingsInput.from(this, UpdateTaskSettingsInput);
  }

  forDelete(): DeleteTaskSettingsInput {
    return DeleteTaskSettingsInput.from(this, DeleteTaskSettingsInput);
  }

  validate() {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof TaskSettings)[],
      (field: keyof TaskSettings) => {
        return null;
      },
    );
  }

  withDiscrepancyThreshold(value: string) {
    (this.discrepancyThreshold as any) = parseFloat(value) || 0;
    return cloneDeep(this);
  }

  withStockLocationRolesForInitialCount(roles: StockLocationRole[]) {
    this.stockLocationRolesForInitialCount = roles;
    return cloneDeep(this);
  }

  withStockLocationRolesForRecount(roles: StockLocationRole[]) {
    this.stockLocationRolesForRecount = roles;
    return cloneDeep(this);
  }

  withCompanyRolesForInitialCount(roles: CompanyRole[]) {
    this.companyRolesForInitialCount = roles;
    return cloneDeep(this);
  }

  withCompanyRolesForRecount(roles: CompanyRole[]) {
    this.companyRolesForRecount = roles;
    return cloneDeep(this);
  }

  withRequireUniqueRecountUser(value: boolean) {
    this.requireUniqueRecountUser = value;
    return cloneDeep(this);
  }

  withSendPushNotificationToAssignees(value: boolean) {
    this.sendPushNotificationToAssignees = value;
    return cloneDeep(this);
  }

  withAssignInitialAssigneesToRecount(value: boolean) {
    this.assignInitialAssigneesToRecount = value;
    return cloneDeep(this);
  }

  withDueDateMandatory(value: boolean) {
    this.dueDateMandatory = value;
    return cloneDeep(this);
  }

  withStockLocationRoleForUpdateStock(value: StockLocationRole) {
    this.stockLocationRoleForUpdateStock = value;
    return cloneDeep(this);
  }

  withPrefix(prefix: string) {
    this.prefix = prefix;
    return cloneDeep(this);
  }

  withSuffix(suffix: string) {
    this.suffix = suffix;
    return cloneDeep(this);
  }

  withDigits(value: string) {
    (this.digits as any) = parseFloat(value) || 0;
    if (this.digits < 3) this.digits = 3;
    return cloneDeep(this);
  }
}

export class CreateTaskSettingsInput extends forCreate(TaskSettings) {}

export class UpdateTaskSettingsInput extends forUpdate(TaskSettings) {}

export class DeleteTaskSettingsInput extends forDelete(TaskSettings) {}
