import React from 'react';
import { BinStatus } from '../../../types/binStatus';
import StatusLabel from '../../../VentoryUI/components/common/StatusLabel/StatusLabel';
import { Colors } from '../../../VentoryUI/util/color.util';

interface BinStatusLabelProps {
  status?: BinStatus;
}

export default function BinStatusLabel({ status }: BinStatusLabelProps) {
  const text = status?.status || 'Unknown Bin Status';

  const color = status?.color || 'gray';
  return (
    <StatusLabel
      color={{
        backgroundColor: Colors.lightBackground(color),
        color: Colors.darkText(color),
      }}
      text={text}
    />
  );
}
