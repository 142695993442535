import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { t } from '../../../../../../../types/translation/Translator';
import { BinStatusContext } from '../../../../../../../context/BinStatusContext';
import {
  BinStatusMutations,
  DeleteBinStatusResponse,
  DeleteBinStatusVariables,
  UpdateBinStatusResponse,
  UpdateBinStatusVariables,
} from '../../../../../../../graphql/binStatus.graphql';
import { BinStatus } from '../../../../../../../types/binStatus';
import { testIds } from '../../../../../../../util/identifiers/identifiers.util';
import CancelButton from '../../../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import DeleteButton from '../../../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import SaveButton from '../../../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Modal from '../../../../../../../VentoryUI/components/common/Modal/Modal';
import BinStatusInfoPane from '../Panes/BinStatusInfoPane';

interface UpdateBinStatusModalInputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  binStatus?: BinStatus;
}

export default function UpdateBinStatusModal({ open, setOpen, binStatus }: UpdateBinStatusModalInputProps) {
  if (!binStatus) return null; // TODO: Not found

  const { binStatuses, setBinStatuses } = useContext(BinStatusContext);

  const [error, setError] = useState<string>('');

  const [update, { loading: updateLoading }] = useMutation<UpdateBinStatusResponse, UpdateBinStatusVariables>(
    BinStatusMutations.update,
    {
      onCompleted: res => {
        const binStatus = res.updateBinStatus[0];
        binStatuses.set(binStatus.id, new BinStatus(binStatus));
        setBinStatuses(new Map(binStatuses));
        handleClose();
      },
      onError: res => setError(res.message),
    },
  );

  const [remove, { loading: deleteLoading }] = useMutation<DeleteBinStatusResponse, DeleteBinStatusVariables>(
    BinStatusMutations.remove,
    {
      onCompleted: res => {
        res.deleteBinStatus.forEach(bs => binStatuses.delete(bs.id));
        setBinStatuses(new Map(binStatuses));
        handleClose();
      },
      onError: res => setError(res.message),
    },
  );

  const handleUpdate = async (binStatus: BinStatus) => {
    try {
      binStatus.validate();

      await update({
        variables: {
          binStatuses: [binStatus.forUpdate()],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleRemove = async (binStatus: BinStatus) => {
    try {
      const deletedBinStatus = binStatus.forDelete();

      await remove({
        variables: {
          binStatuses: [deletedBinStatus],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const footer = (binStatus: BinStatus) => (
    <Grid container marginTop={'auto'}>
      <Grid item>
        <DeleteButton disabled={updateLoading} loading={deleteLoading} onClick={() => handleRemove(binStatus)} />
      </Grid>
      <Grid item flexGrow={1}>
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <CancelButton disabled={updateLoading || deleteLoading} onClick={handleClose} />
          </Grid>
          <Grid item>
            <SaveButton loading={updateLoading} disabled={deleteLoading} onClick={() => handleUpdate(binStatus)} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={open}
      error={error}
      onClose={handleClose}
      title={t().updateBinStatus.singular.label}
      testId={testIds.updateBinStatusModal}
    >
      <BinStatusInfoPane binStatus={binStatus} footer={footer} />
    </Modal>
  );
}
