import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useContext, useState, useEffect } from 'react';
import { LoadingSpinner } from '../../../../ui/pages/Common/LoadingSpinner';
import { CompanyContext } from '../../../../context/CompanyContext';
import { FileContext } from '../../../../context/FileContext';
import { SuperUserContext } from '../../../../context/SuperUserContext';
import { GetFileDataResponse, GetFileDataVariables, FileQueries } from '../../../../graphql/file.graphql';
import { FileEntityType, FileWithSas } from '../../../../types/file';

interface FileLoaderProps {
  fileId: string;
  fileEntityType?: FileEntityType;
  companyId?: string;
  onLoadEnd: (fileSas: FileWithSas) => void;
  children: React.ReactNode;
}

export default function FileLoader({ fileId, fileEntityType, onLoadEnd, companyId, children }: FileLoaderProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { superUser } = useContext(SuperUserContext);
  const { files, filesLoading, fileCache, setFileCache } = useContext(FileContext);
  const [loading, setLoading] = useState(true);
  const [getData] = useLazyQuery<GetFileDataResponse, GetFileDataVariables>(FileQueries.getData);

  useEffect(() => {
    const sas = fileCache.get(fileId);
    if (sas && dayjs().isBefore(sas.expirationDate)) {
      onLoadEnd(sas);
      setLoading(false);
      return;
    }

    const file = files.get(fileId);
    if (!file && !fileEntityType && !superUser) {
      console.error(
        'Tried loading a file that was not found in the context and no File Entity Type was provided, fileId: ' +
          fileId,
      );
      return setLoading(false);
    }

    setLoading(true);
    getData({
      variables: {
        id: fileId,
        companyId: companyId || file?.companyId || currentCompany.id,
        entityType: fileEntityType || file?.entityType || FileEntityType.company,
      },
      onCompleted: result => {
        setFileCache(new Map(fileCache).set(result.fileSas.id, new FileWithSas(result.fileSas)));
        onLoadEnd(new FileWithSas(result.fileSas));
        setLoading(false);
      },
      onError: error => {
        console.error(error);
        setLoading(false);
      },
    });
  }, [fileId, files]);

  if (filesLoading || loading) {
    return (
      <div className='flex items-center justify-center'>
        <LoadingSpinner style='primary' />
      </div>
    );
  }

  return <>{children}</>;
}
