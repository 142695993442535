import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import TextInput from '../../Common/TextInput';
import { useMutation } from '@apollo/client';
import { CompanyMutations, CreateCompanyResponse, CreateCompanyVariables } from '../../../../graphql/company.graphql';
import { CreateCompanyInput } from '../../../../types/company';
import { useNavigate } from 'react-router-dom';
import { t } from '../../../../types/translation/Translator';
import { UserContext } from '../../../../context/UserContext';

import CreateButton from '../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import Button from '../../../../VentoryUI/components/common/Button';

export default function OnboardingCompanyPane() {
  const navigate = useNavigate();

  const { signOut } = useContext(UserContext);

  const [company, setCompany] = useState<CreateCompanyInput>(new CreateCompanyInput(''));

  const [create, { loading }] = useMutation<CreateCompanyResponse, CreateCompanyVariables>(CompanyMutations.create, {
    onCompleted: res => window.location.reload(),
  });

  const handleCreate = async () =>
    await create({
      variables: {
        company: {
          name: company.name,
          settings: company.settings,
        },
      },
    });

  return (
    <Grid container direction='column' alignItems='center' justifyContent='center' sx={{ height: '100vh' }}>
      <Grid container p={2} width={'350px'} rowSpacing={1}>
        <Grid item xs={12} textAlign={'center'}>
          <p className='font-semibold text-base mb-2'>{`You're not part of a company`}</p>
          <p className='text-sm mt-4'>{'Ask a colleague for an invite'}</p>
          <p className='text-sm'>{t().or.singular.label}</p>
          <p className='text-sm mb-2'>{'create a new company'}</p>
        </Grid>
        <Grid item display='block' xs={12} textAlign={'center'}>
          <TextInput
            disabled={loading}
            placeholder='Company Name'
            onChange={value => setCompany(company.withName(value))}
          />
        </Grid>
        <Grid item xs={12}>
          <Button disabled={loading} onClick={signOut} text={t().signOut.singular.label} />
        </Grid>
        <Grid item display={'block'} xs={12}>
          <CreateButton loading={loading} onClick={handleCreate} disabled={!company.name} />
        </Grid>
      </Grid>
    </Grid>
  );
}
