import { t } from '../../../../../types/translation/Translator';
import { SuperUser } from '../../../../../types/superUser';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import React from 'react';
import {
  CreateSuperUserResponse,
  CreateSuperUserVariables,
  SuperUserMutations,
} from '../../../../../graphql/superUser.graphql';
import SuperUserInfoPane from '../Panes/SuperUserInfoPane';

import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import CancelButton from '../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import CreateButton from '../../../../../VentoryUI/components/common/Button/Templates/CreateButton';

interface CreateSuperUserModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  superUsers: Map<string, SuperUser>;
  setSuperUsers: (superUsers: Map<string, SuperUser>) => void;
}

export default function CreateSuperUserModal({ open, setOpen, superUsers, setSuperUsers }: CreateSuperUserModalProps) {
  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<CreateSuperUserResponse, CreateSuperUserVariables>(
    SuperUserMutations.create,
    {
      onCompleted: res => {
        const superUser = res.createSuperUser[0];
        setSuperUsers(new Map(superUsers).set(superUser.id, new SuperUser(superUser)));
        setOpen(false);
      },
      onError: res => setError(res.message),
    },
  );

  const handleCreate = async (superUser: SuperUser) => {
    try {
      await create({
        variables: {
          superUsers: [superUser],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const footer = (user: SuperUser) => (
    <Grid container marginTop={'auto'} columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <CancelButton disabled={loading} onClick={handleClose} />
      </Grid>
      <Grid item>
        <CreateButton loading={loading} disabled={!user.role || !user.email} onClick={() => handleCreate(user)} />
      </Grid>
    </Grid>
  );

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t().createSuperUser.singular.label}>
      <SuperUserInfoPane
        superUser={new SuperUser({ companyId: '00000000-0000-0000-0000-000000000000' })}
        footer={footer}
      />
    </Modal>
  );
}
