import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export function ChevronRightIcon({ className, onClick }: IconInputProps) {
  return (
    <Icon className={className} onClick={onClick}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        stroke='currentColor'
      >
        <path
          strokeWidth='1.66667'
          d='M6 12L10 8L6 4'
          shapeRendering='optimizeQuality'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Icon>
  );
}
