import React, { useState } from 'react';
import { OrderTableSettingsView } from './Views/OrderTableSettingsView';
import Dropdown from '../../../../Common/Dropdown';
import { ProductTransactionTableSettingsView } from './Views/OrderTransactionTableSettingsView';
import Paper from '../../../../../../VentoryUI/components/common/Paper';
import FlexPane from '../../../../../../VentoryUI/components/common/FlexPane';
import { t } from '../../../../../../types/translation/Translator';

interface OrderTableSettingsPaneProps {
  setError: (error: string) => void;
}

enum OrderTableType {
  order = 'order',
  transaction = 'transaction',
}

function orderTableTypeToString(type: OrderTableType) {
  switch (type) {
    case OrderTableType.order:
      return t().order.singular.label;
    case OrderTableType.transaction:
      return t().orderTransaction.singular.label;
  }
}

export default function OrderTableSettingsPane({ setError }: OrderTableSettingsPaneProps) {
  const [type, setType] = useState(OrderTableType.order);

  const content = () => {
    switch (type) {
      case OrderTableType.order:
        return <OrderTableSettingsView setError={setError} />;
      case OrderTableType.transaction:
        return <ProductTransactionTableSettingsView setError={setError} />;
    }
  };

  return (
    <FlexPane
      header={
        <Paper fit>
          <Dropdown
            label={t().type.singular.label}
            values={Object.values(OrderTableType)}
            toText={el => orderTableTypeToString(el)}
            selectedValue={type}
            onChange={value => value && setType(value)}
          />
        </Paper>
      }
      content={content()}
    />
  );
}
