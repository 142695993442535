import React, { useContext, useEffect, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import ScrollableFilterContent from '../../common/DropdownContent/ScrollableFilterContent';
import { ContainerTypeContext } from '../../../../../context/ContainerTypeContext';
import { ContainerTypeFilter } from '../ContainerTypeFilter';
import { FilterProps } from '../../common/filter.util';

interface ContainerTypeFilterDropdownContentProps {
  filter: ContainerTypeFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export default function ContainerTypeFilterDropdownContent({
  filter,
  filterProps,
  setFilterProps,
}: ContainerTypeFilterDropdownContentProps) {
  const { containerTypes } = useContext(ContainerTypeContext);

  const [query, setQuery] = useState<string>('');

  const filterContainerTypes = () => {
    return new Set(filter.filteredItems([...containerTypes.values()], query, filterProps).map(ct => ct.id));
  };

  const [values, setValues] = useState<Set<string>>(filterContainerTypes());

  const handleChange = (selected: Set<string>) => {
    filterProps.containerType = selected;
    if (!filterProps.containerType?.size) filterProps.containerType = undefined;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.containerType = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterContainerTypes());
  }, [query]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().containerType.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setQuery(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            selected={filterProps.containerType}
            values={values}
            onChange={handleChange}
            toText={id => `${containerTypes.get(id)?.name}`}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}
