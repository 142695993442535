export enum ProductInfoPaneTableHeaderKey {
  stockLocation = 'stockLocation',
  bin = 'bin',
  lpn = 'lpn',
  serialNumber = 'serialNumber',
  lotNumber = 'lotNumber',
  lotExpiry = 'lotExpiry',
  quantity = 'quantity',
  container = 'container',
}
