import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { CompanyContext } from '../../../../../../../context/CompanyContext';
import { ContainerTypeContext } from '../../../../../../../context/ContainerTypeContext';
import {
  ContainerTypeMutations,
  UpdateContainerTypeResponse,
  UpdateContainerTypeVariables,
} from '../../../../../../../graphql/containerType.graphql';
import { ContainerType } from '../../../../../../../types/containerType';
import { t } from '../../../../../../../types/translation/Translator';
import { CancelButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import { SaveButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Modal from '../../../../../../../VentoryUI/components/common/Modal/Modal';
import { ModalPane } from '../../../../../../../VentoryUI/components/common/Modal/components';
import ContainerTypeItem from './ContainerTypeItem';

interface UpdateContainerTypeModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  containerType: ContainerType;
}

export default function UpdateContainerTypeModal({ open, setOpen, containerType }: UpdateContainerTypeModalProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { containerTypes, setContainerTypes } = useContext(ContainerTypeContext);

  const [error, setError] = useState('');
  const [updatedContainerType, setUpdatedContainerType] = useState(new ContainerType(containerType));

  const [save, { loading }] = useMutation<UpdateContainerTypeResponse, UpdateContainerTypeVariables>(
    ContainerTypeMutations.update,
    {
      variables: { containerTypes: [updatedContainerType] },
      onCompleted: data => {
        data.updateContainerType.forEach(ct => containerTypes.set(ct.id, new ContainerType(ct)));
        setContainerTypes(new Map(containerTypes));
        setOpen(false);
      },
      onError: error => {
        setError(error.graphQLErrors[0]?.message);
      },
    },
  );

  const handleSave = () => {
    save();
  };

  const canSave = () => {
    if (!updatedContainerType.name) return false;
    return true;
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} title={t().updateContainerType.singular.upper} error={error}>
      <ModalPane
        footerButtons={[
          {
            ...CancelButtonTemplate(() => {
              setError('');
              setUpdatedContainerType(new ContainerType({ companyId: currentCompany.companyId }));
              setOpen(false);
            }),
            disabled: loading,
          },
          { ...SaveButtonTemplate(handleSave), loading, disabled: !canSave() },
        ]}
      >
        <ContainerTypeItem containerType={updatedContainerType} setContainerType={setUpdatedContainerType} />
      </ModalPane>
    </Modal>
  );
}
