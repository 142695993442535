import React from 'react';
import { ChartData } from '../ui/pages/Dashboard/Graphs/GraphContainer';
import { DashboardDynamicFilterParameters, DashboardEntityType } from '../ui/pages/Dashboard/Common/dashboard.util';
import { DashboardParamters, toDashboardParameters, toFilterParameters } from '../graphql/dashboard.graphql';
import { FilterProps, FilterStorageKey } from '../VentoryUI/components/filters/common/filter.util';

export function getDashboardCacheKey(
  type: DashboardEntityType,
  parameters?: DashboardDynamicFilterParameters,
  additionalFilters?: string[],
) {
  const params = toDashboardParameters(type, parameters, additionalFilters);
  const key = `${type}|${additionalFilters?.join(',')}|${Object.getOwnPropertyNames(params)
    .map(p => String(params[p as keyof DashboardParamters]))
    .filter(p => p && p !== 'undefined')}`;
  return key;
}

export function getEntityDataCacheKey(type: FilterStorageKey, parameters?: FilterProps, additionalFilters?: string[]) {
  const params = toFilterParameters(type, parameters, additionalFilters);
  const key = `${type}|${additionalFilters?.join(',')}|${Object.getOwnPropertyNames(params)
    .map(p => String(params[p as keyof DashboardParamters]))
    .filter(p => p && p !== 'undefined')}`;
  return key;
}

export interface DashboardContextProps {
  cache: Map<string, { filteredChartData: ChartData[]; total: number; format: string }>;
  setCache: (cache: Map<string, { filteredChartData: ChartData[]; total: number; format: string }>) => void;
}

const defaultContext: DashboardContextProps = {
  cache: new Map(),
  setCache: () => {},
};

export const DashboardContext = React.createContext<DashboardContextProps>(defaultContext);
