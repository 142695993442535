import ButtonRow from './ButtonRow';
import { Button, FooterButton, ButtonProps, ButtonTemplateProps } from './Button';

export default Button;
export { ButtonRow };
export type { FooterButton, ButtonProps, ButtonTemplateProps };

// Adding templates here to allow for consolidated imports elsewhere
import AddButton, { AddButtonTemplate } from './Templates/AddButton';
import BackButton, { BackButtonTemplate } from './Templates/BackButton';
import CancelButton, { CancelButtonTemplate } from './Templates/CancelButton';
import CreateButton, { CreateButtonTemplate } from './Templates/CreateButton';
import DeleteButton, { DeleteButtonTemplate } from './Templates/DeleteButton';
import ExportButton, { ExportButtonTemplate } from './Templates/ExportButton';
import FinishButton, { FinishButtonTemplate } from './Templates/FinishButton';
import NewButton, { NewButtonTemplate } from './Templates/NewButton';
import NextButton, { NextButtonTemplate } from './Templates/NextButton';
import ProcessButton, { ProcessButtonTemplate } from './Templates/ProcessButton';
import SaveButton, { SaveButtonTemplate } from './Templates/SaveButton';
import UpdateButton, { UpdateButtonTemplate } from './Templates/UpdateButton';

export {
  AddButton,
  AddButtonTemplate,
  BackButton,
  BackButtonTemplate,
  CancelButton,
  CancelButtonTemplate,
  CreateButton,
  CreateButtonTemplate,
  DeleteButton,
  DeleteButtonTemplate,
  ExportButton,
  ExportButtonTemplate,
  FinishButton,
  FinishButtonTemplate,
  NewButton,
  NewButtonTemplate,
  NextButton,
  NextButtonTemplate,
  ProcessButton,
  ProcessButtonTemplate,
  SaveButton,
  SaveButtonTemplate,
  UpdateButton,
  UpdateButtonTemplate,
};
