import React from 'react';
import TextInput from '../../TextInput';
import Grid from '@mui/material/Grid/Grid';
import { t } from '../../../../../types/translation/Translator';
import Checkbox from '../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import { ReorderRule } from '../../../../../types/reorderRule';
import Selector from '../../Selector';
import { TriggerType, triggerTypeToString } from '../../../../../types/trigger';
import { testIds } from '../../../../../util/identifiers/identifiers.util';

import Paper from '../../../../../VentoryUI/components/common/Paper';

interface ReorderRuleInfoPaneProps {
  rule: ReorderRule;
  setRule: (rule: ReorderRule) => void;
  appliesToAllProducts: boolean;
  setAppliesToAllProducts: (applies: boolean) => void;
}

export default function ReorderRuleInfoPane({
  rule,
  setRule,
  appliesToAllProducts,
  setAppliesToAllProducts,
}: ReorderRuleInfoPaneProps) {
  return (
    <Paper>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextInput
                label={t().name.singular.label}
                placeholder={t().name.singular.label}
                onChange={v => setRule(rule.withName(v))}
                value={rule.name}
                mandatory
              />
            </Grid>

            <Grid item xs={12}>
              <Checkbox
                label={t().createReplenishOrderAuto.singular.upper}
                value={rule.automaticallyCreateOrder}
                onChange={value => setRule(rule.withAutomaticallyCreateOrder(value))}
              />
            </Grid>

            <Grid item xs={12}>
              <Checkbox
                label={t().appliesToAllProducts.singular.upper}
                value={appliesToAllProducts}
                onChange={value => setAppliesToAllProducts(value)}
              />
            </Grid>

            {appliesToAllProducts ? (
              <>
                <Grid item xs={12}>
                  <TextInput
                    mandatory
                    label={t().reorderQuantity.singular.label}
                    onChange={v => {
                      setRule(rule.withReorderQuantity(v));
                    }}
                    min={0}
                    value={rule.reorderQuantity}
                    dynamicUpdate
                    type='number'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    mandatory
                    min={0}
                    label={t().replenishQuantity.singular.label}
                    onChange={v => {
                      setRule(rule.withReplenishQuantity(v));
                    }}
                    value={rule.replenishQuantity}
                    dynamicUpdate
                    type='number'
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <p className='mb-1 text-sm font-medium text-slate-800'>{t().triggerTypes.singular.label}</p>
              <Selector
                testId={testIds.triggerType}
                selectAll={false}
                placeholder={t().triggerTypeText.singular.label}
                values={[...Object.keys(TriggerType).values()] || []}
                checkedValues={rule.triggerTypes}
                disabled={item => item === TriggerType.alert}
                toText={item => triggerTypeToString(item as TriggerType)}
                onChange={checked => setRule(rule.withTriggerTypes(checked as TriggerType[]))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
