import React, { useContext, useMemo, useState } from 'react';
import { ClickAwayListener, Grid } from '@mui/material';
import RepeatIcon from '@mui/icons-material/Repeat';
import { t } from '../../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyIcon from '@mui/icons-material/Key';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { UserContext } from '../../../../context/UserContext';
import { SuperUserContext } from '../../../../context/SuperUserContext';
import MenuItem, { MenuItemProps } from '../Menu/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import ProfileImage from '../File/Image/ProfileImageViewLoader';
import { SuperUserRole } from '../../../../types/superUser';

interface SidebarUserMenuProps {
  collapsed?: boolean;
}

export default function SidebarUserMenu({ collapsed }: SidebarUserMenuProps) {
  const navigate = useNavigate();

  const { currentUser, signOut } = useContext(UserContext);
  const { superUser } = useContext(SuperUserContext);

  const [hidden, setHidden] = useState<boolean>(true);

  const menuItems: MenuItemProps[] = useMemo(() => {
    const shown: MenuItemProps[] = [];

    shown.push({
      text: t().settings.singular.label,
      onClick: () => {
        setHidden(true);
        navigate('/settings/profile');
      },
      icon: <SettingsIcon />,
    });

    if (superUser) {
      shown.push({
        onClick: () => {
          setHidden(true);
          superUser?.role === SuperUserRole.translator ? navigate('/admin/translations') : navigate('/admin');
        },
        text: t().administrator.singular.label,
        icon: <KeyIcon />,
      });
    } else {
      shown.push({
        onClick: () => {
          setHidden(true);
          navigate('/company');
        },
        text: t().company.singular.label,
        icon: <RepeatIcon />,
      });
    }

    shown.push({
      onClick: () => {
        setHidden(true);
        navigate('/developer/settings');
      },
      text: t().developerOptions.singular.label,
      icon: <SettingsSuggestIcon />,
    });

    shown.push({
      onClick: () => {
        setHidden(true);
        signOut();
      },
      text: t().signOut.singular.label,
      icon: <LogoutIcon />,
    });

    return shown;
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setHidden(true)}>
      <Grid data-testid={'sidebar-user-menu'} container className='relative cursor-pointer select-none'>
        <div
          className='my-1 ml-3 flex h-[28px] text-ventory-grey-600 cursor-pointer'
          onClick={() => setHidden(!hidden)}
        >
          <div className='h-[28px]'>
            <ProfileImage
              className='min-w-[28px] w-[28px] aspect-square rounded-full object-fill'
              fileId={currentUser?.profilePicture}
            />
          </div>
          {collapsed ? null : (
            <p className='h-[28px] pl-3 flex items-center font-medium text-[12px] text-ellipsis max-w-[160px]'>
              {currentUser ? `${currentUser?.firstName} ${currentUser?.lastName}` : t().unknownUser.singular.label}
            </p>
          )}
        </div>
        {!hidden ? (
          <Grid item xs={12} className='absolute z-50 inline-block right-[-5px] bottom-[140px]'>
            <Grid container className='fixed max-w-[250px] h-[124px]'>
              <Grid
                container
                alignContent={'start'}
                className='p-2 ventory-border shadow-sm rounded-4 cursor-pointer select-none bg-white'
              >
                {menuItems.map((item, index) => (
                  <MenuItem {...item} key={index.toString()} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </ClickAwayListener>
  );
}
