import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductExportConfigurationContext } from '../../../../../../../../../context/ProductExportConfigurationContext';
import {
  DeleteProductExportConfigurationResponse,
  DeleteProductExportConfigurationVariables,
  ProductExportConfigurationMutations,
} from '../../../../../../../../../graphql/productExportConfiguration.graphql';
import {
  DeleteProductExportConfigurationInput,
  ProductExportConfiguration,
} from '../../../../../../../../../types/productExportConfiguration';
import DeleteModal from '../../../../../../../../../VentoryUI/components/common/Modal/DeleteModal';

interface DeleteProductExportConfigurationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  ids: Set<string>;
}

export default function DeleteProductExportConfigurationModal({
  open,
  setOpen,
  ids,
}: DeleteProductExportConfigurationModalProps) {
  const navigate = useNavigate();

  const [error, setError] = useState<string>('');
  const { productExportConfigurations, setProductExportConfigurations } = useContext(ProductExportConfigurationContext);

  const [remove, { loading }] = useMutation<
    DeleteProductExportConfigurationResponse,
    DeleteProductExportConfigurationVariables
  >(ProductExportConfigurationMutations.remove, {
    onCompleted: res => {
      const config = res.deleteProductExportConfiguration[0];
      productExportConfigurations.delete(config.id);
      setProductExportConfigurations(new Map(productExportConfigurations));
      navigate('/stock/products/settings/report');
    },
    onError: err => setError(err.message),
  });

  const handleDelete = async () => {
    try {
      const deletedProductExportConfiguration: DeleteProductExportConfigurationInput[] = [];

      for (const id of ids) {
        const config = productExportConfigurations.get(id);
        if (config) deletedProductExportConfiguration.push(new ProductExportConfiguration(config).forDelete());
      }

      await remove({
        variables: {
          productExportConfigurations: deletedProductExportConfiguration,
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  return (
    <DeleteModal
      text={'Are you sure you want to delete this product export configuration?'}
      onConfirm={handleDelete}
      open={open}
      error={error}
      onClose={handleClose}
    />
  );
}
