import React, { useContext, useEffect, useState } from 'react';
import { FilterProps } from '../../common/filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { UserContext } from '../../../../../context/UserContext';
import { UserFilter } from '../UserFilter';
import ScrollableFilterContent from '../../common/DropdownContent/ScrollableFilterContent';

interface UserFilterDropdownContentProps {
  filter: UserFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export default function UserFilterDropdownContent({
  filter,
  filterProps,
  setFilterProps,
}: UserFilterDropdownContentProps) {
  const { companyUsers } = useContext(UserContext);

  const [query, setQuery] = useState<string>('');

  const filterUsers = (): Set<string> => {
    return new Set(filter.filteredItems([...companyUsers.values()], query, filterProps).map(user => user.id));
  };

  const [values, setValues] = useState<Set<string>>(filterUsers());

  const handleChange = (selected: Set<string>) => {
    filterProps.user = selected;
    if (!filterProps.user?.size) filterProps.user = undefined;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.user = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterUsers());
  }, [query]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().user.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setQuery(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            selected={filterProps.user}
            values={values}
            onChange={handleChange}
            toText={id => companyUsers.get(id)?.email}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}
