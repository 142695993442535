import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { calculateFormattedFileSize, FileEntity, FileWithSas } from '../../../types/file';
import { FileViewModal } from '../../../VentoryUI/components/common/File/Modals';
import { FileContext } from '../../../context/FileContext';
import { useLazyQuery } from '@apollo/client';
import { FileQueries, GetFileDataResponse, GetFileDataVariables } from '../../../graphql/file.graphql';

interface FileInputProps {
  file?: FileEntity;
  icon?: boolean;
}

export default function FileInput({ file, icon = true }: FileInputProps) {
  if (!file) return null;

  const { fileCache, setFileCache } = useContext(FileContext);

  const [error, setError] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  const [getData] = useLazyQuery<GetFileDataResponse, GetFileDataVariables>(FileQueries.getData);

  const handleClick = async () => {
    if (file.primaryFileType === 'video' || file.primaryFileType === 'image') {
      setPreviewOpen(true);
    } else {
      const cachedFileSas = fileCache.get(file.id);
      if (cachedFileSas) return window.open(cachedFileSas.signature);

      setPreviewOpen(false);

      await getData({
        variables: { companyId: file.companyId, entityType: file.entityType, id: file.id },
        onCompleted: response => {
          setFileCache(new Map(fileCache).set(file.id, new FileWithSas(response.fileSas)));
          window.open(response.fileSas.signature);
        },
        onError: error => setError(error.message),
      });
    }
  };

  const size = calculateFormattedFileSize(file.size);
  return (
    <>
      {<FileViewModal open={previewOpen} setOpen={setPreviewOpen} file={file} />}

      <Grid container my={1} className='cursor-pointer select-none' onClick={handleClick}>
        {icon ? (
          <Grid item alignContent={'center'}>
            <InsertDriveFileOutlinedIcon />
          </Grid>
        ) : null}
        <Grid item ml={1}>
          <Grid container>
            <Grid item xs={12}>
              <p className='text-sm text-slate-800'>{file.name}</p>
            </Grid>
            <Grid item xs={12}>
              <p className='text-xs text-gray-500'>{`${size.size.toFixed(0)} ${size.sizeType}`}</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
