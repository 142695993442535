import React from 'react';
import { BinStatus } from '../../../../types/binStatus';
import { EntityType } from '../../../../types/comment';
import { Product } from '../../../../types/product';
import { ProductMasterData } from '../../../../types/productMasterData';
import { TagRelation } from '../../../../types/tagRelation';
import { t } from '../../../../types/translation/Translator';
import { toFilterString } from '../../../../util/string.util';
import { ProductIcon } from '../../../icons/Product/ProductIcon';
import { BinFilter } from '../BinFilter/BinFilter';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { FilterStorageKey, FilterProps } from '../common/filter.util';
import { StockLocationFilter } from '../StockLocationFilter';
import { TagFilter } from '../TagFilter';
import { Bin } from '../../../../types/bin';

export class ProductFilter extends BaseFilter<Product> {
  productMasterData: Map<string, ProductMasterData>;
  bins: Map<string, Bin>;
  binStatuses: Map<string, BinStatus>;
  tagRelations: Map<string, TagRelation>;

  constructor(
    productMasterData: Map<string, ProductMasterData>,
    bins: Map<string, Bin>,
    binStatuses: Map<string, BinStatus>,
    tagRelations: Map<string, TagRelation>,
  ) {
    super(FilterStorageKey.PRODUCT);
    this.productMasterData = productMasterData;
    this.bins = bins;
    this.binStatuses = binStatuses;
    this.tagRelations = tagRelations;
  }

  filterOptions = () => [
    new StockLocationFilter(this.tagRelations),
    new BinFilter(this.binStatuses, this.tagRelations),
    new TagFilter(),
  ];

  toLabel = () => {
    return t().product.singular.label;
  };

  toCount(props: BaseFilterProps) {
    return props.filterProps.product?.size || 0;
  }

  toDropdownContent() {
    return <></>;
  }

  toInnerContent = () => {
    return <></>;
  };

  toIcon = () => {
    return <ProductIcon className={this.className} />;
  };

  search = (item: Product, textFilter: string) => {
    const productName = this.productMasterData.get(item.productMasterDataId)?.productName;
    const productNumber = this.productMasterData.get(item.productMasterDataId)?.productNumber;
    const bin = this.bins.get(item.binId)?.name;

    if (
      toFilterString(productName).includes(textFilter) ||
      toFilterString(productNumber).includes(textFilter) ||
      toFilterString(bin).includes(textFilter) ||
      toFilterString(item.lpn).includes(textFilter) ||
      toFilterString(item.serial).includes(textFilter)
    ) {
      return true;
    }
    return false;
  };

  filteredItems(items: Product[], query: string, filterProps: FilterProps): Product[] {
    const stockLocations = TagFilter.filterByTag(this.tagRelations, EntityType.stockLocation, filterProps);

    return items.filter(item => {
      if (
        filterProps.stockLocation &&
        filterProps.stockLocation.size &&
        !filterProps.stockLocation.has(item.stockLocationId)
      ) {
        return false;
      }

      if (filterProps.bin && filterProps.bin.size && !filterProps.bin.has(item.binId)) {
        return false;
      }

      if (stockLocations.size && !stockLocations.has(item.stockLocationId)) {
        return false;
      }

      return this.search(item, query);
    });
  }
}
