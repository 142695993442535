import { ClickAwayListener, Divider, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { t } from '../../../types/translation/Translator';
import { classes, TestIdIdentifier } from '../../../util/identifiers/identifiers.util';
import SearchBar from './SearchBar';
import { removeDiacritics, toFilterString } from '../../../util/string.util';

import Checkbox from '../../../VentoryUI/components/common/Checkbox/Checkbox';

interface MultiSelectValue<T> {
  item: T;
  value: string;
  index: number;
  checked: boolean;
  disabled: boolean;
}

interface SelectorInputProps<T> {
  values: T[];
  checkedValues: T[];
  disabledValues?: T[];
  selectAll?: boolean;
  toText: (item: T) => string;
  filterFn?: (item: T, filter: string) => boolean;
  toElement?: (item: T, disabled?: boolean) => JSX.Element;
  onChange: (checked: T[]) => void;
  placeholder?: string;
  disabled?: (item: T) => boolean;
  showSearch?: boolean;
  testId?: TestIdIdentifier;
}

function valuesToMultiSelectValues<T>(
  values: T[],
  checkedValues: T[],
  disabledValues: T[],
  toString: (item: T) => string,
) {
  const stringValueMap: Map<string, T> = new Map(values.map(value => [String(toString(value)), value]));
  const stringCheckedValueMap: Map<string, T> = new Map(checkedValues.map(value => [String(toString(value)), value]));
  const stringDisabledValueMap: Map<string, T> = new Map(disabledValues.map(value => [String(toString(value)), value]));

  return [...new Set([...stringValueMap.values(), ...stringCheckedValueMap.values()]).values()].map((item, idx) => ({
    item,
    value: toString(item),
    index: idx,
    checked: stringCheckedValueMap.has(toString(item)),
    disabled: stringDisabledValueMap.has(toString(item)),
  }));
}

export default function Selector<T>({
  values,
  checkedValues,
  disabledValues = [],
  selectAll = true,
  onChange,
  toText,
  toElement,
  filterFn,
  placeholder,
  disabled = item => false,
  showSearch = true,
  testId,
}: SelectorInputProps<T>) {
  const [hidden, setHidden] = useState<boolean>(true);
  const [items, setItems] = useState<MultiSelectValue<T>[]>(
    valuesToMultiSelectValues(values, checkedValues, disabledValues, toText),
  );
  const [filter, setFilter] = useState<string>('');

  const [scrollTop, setScrollTop] = useState<number>(0);

  useEffect(() => {
    setItems(valuesToMultiSelectValues(values, checkedValues, disabledValues, toText));
  }, [values]);

  const checkedItems = new Set(items.filter(i => i.checked).map(i => i.value));
  const filteredItems = new Set(
    items
      .filter(i => (filterFn ? filterFn(i.item, filter) : removeDiacritics(toFilterString(i.value)).includes(filter)))
      .map(i => i.value),
  );

  const selectAllState =
    checkedItems.size === filteredItems.size &&
    [...checkedItems.values()].filter(i => filteredItems.has(i)).length === filteredItems.size;

  const handleChange = (item: MultiSelectValue<T>) => {
    if (item.disabled || disabled(item.item)) return;
    item.checked = !item.checked;
    setItems([...items]);
    onChange(items.filter(i => i.checked).map(i => i.item));
  };

  const handleSelectAll = () => {
    items.forEach(i => {
      if (i.disabled || disabled(i.item)) return;
      if (i.value.toLowerCase().includes(filter)) i.checked = !selectAllState;
      else i.checked = false;
    });
    setItems([...items]);
    onChange(items.filter(i => i.checked).map(i => i.item));
  };

  const handleScroll = (event?: React.UIEvent<HTMLDivElement>) => {
    setScrollTop(event?.currentTarget.scrollTop || 0);
  };

  const dropdownContent = () => {
    if (!filteredItems.size) {
      return (
        <Grid item xs={12} textAlign={'center'}>
          <p className='font-semibold text-gray-300'>{t().noItemsFound.singular.label}</p>
        </Grid>
      );
    }

    const visibleItems = items
      .filter(i => (filterFn ? filterFn(i.item, filter) : removeDiacritics(toFilterString(i.value)).includes(filter)))
      .splice(0, 15 + scrollTop / 20);

    return (
      <Grid container data-testid={testId?.name} className={classes.selector.name}>
        {selectAll ? (
          <Grid item xs={12} key={`selectAll`}>
            <Grid container marginY={'auto'}>
              <Grid
                item
                display='flex'
                justifyContent={'flex-start'}
                marginY={'auto'}
                width={'30px'}
                className={classes.selectAll.name}
              >
                <Checkbox value={selectAllState} onChange={handleSelectAll} />
              </Grid>
              <Grid item marginY={'auto'}>
                <p className='text-sm select-none' onClick={handleSelectAll}>
                  {`${t().selectAll.singular.label} (${checkedValues.length})`}
                </p>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {selectAll ? (
          <Grid item my={1} xs={12}>
            <Divider />
          </Grid>
        ) : null}
        <Grid container maxHeight={'300px'} onScroll={handleScroll} className='overflow-auto'>
          {visibleItems
            .sort((a, b) => {
              const aDisabled = a.disabled || disabled(a.item);
              const bDisabled = b.disabled || disabled(b.item);

              if (aDisabled && bDisabled) {
                if (a.checked) return -1;
                if (b.checked) return 1;
                return 0;
              } else if (aDisabled && !bDisabled) {
                if (a.checked) return -1;
                return 1;
              } else if (!aDisabled && bDisabled) {
                if (b.checked) return 1;
                return -1;
              } else if (!aDisabled && !bDisabled) {
                return 0;
              }

              return 0;
            })
            .map((item, index, arr) => {
              return (
                <Grid item mb={1} xs={12} key={`${item}${index}`} className={classes.selectorItem.name}>
                  <Grid container marginY={'auto'}>
                    <Grid item display='flex' justifyContent={'flex-start'} my={'auto'} width={'30px'}>
                      <Checkbox
                        disabled={item.disabled || disabled(item.item)}
                        value={item.checked}
                        onChange={() => handleChange(item)}
                      />
                    </Grid>
                    <Grid
                      item
                      marginY={'auto'}
                      onClick={() => handleChange(item)}
                      style={{ opacity: item.disabled ? 0.4 : 1 }}
                    >
                      {toElement ? toElement(item.item) : <p className='text-sm select-none'>{item.value}</p>}
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setHidden(true)}>
      <Grid container width={'100%'} onClick={() => setHidden(false)}>
        {showSearch ? (
          <Grid container>
            <Grid item flexGrow={1} my={'auto'}>
              <SearchBar placeholder={placeholder} onChange={v => setFilter(v)} />
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12} className='relative inline-block w-full'>
          <Grid container py={2} px={1} alignContent={'flex-start'}>
            {dropdownContent()}
          </Grid>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
}
