import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { classes, TestIdIdentifier } from '../../../../util/identifiers/identifiers.util';
import { handleKeyEvent } from '../../../../util/events.util';
import Tooltip from '../../../../ui/pages/Common/Tooltip';
import { CompanyContext } from '../../../../context/CompanyContext';
import { Colors, VentoryColor } from '../../../util/color.util';
import CheckIcon from '../../../icons/Check/CheckIcon';

interface CheckboxInputProps {
  value: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  disabled?: boolean;
  dynamicUpdate?: boolean;
  testId?: TestIdIdentifier;
  tooltip?: string | JSX.Element;
  subText?: string;
}

export default function Checkbox({
  value,
  onChange,
  label,
  disabled = false,
  tooltip,
  testId,
  subText,
}: CheckboxInputProps) {
  const { currentCompany } = useContext(CompanyContext);

  const [checked, setChecked] = useState<boolean>(value);

  const handleChange = () => {
    if (disabled) return;
    setChecked(!checked);
    onChange(!checked);
  };

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <Grid
      container
      width={'fit-content'}
      className={`select-none cursor-pointer`}
      onClick={event => {
        event.stopPropagation();
        handleChange();
      }}
    >
      <Grid item display='flex' justifyContent={'center'} alignItems={'center'} className='relative'>
        <input
          data-testid={testId?.name}
          readOnly
          disabled={disabled}
          checked={checked}
          type='checkbox'
          name='bordered-checkbox'
          tabIndex={0}
          onKeyDown={event => handleKeyEvent(event, 'Enter', handleChange)}
          style={{
            appearance: 'none',
            cursor: disabled ? 'not-allowed' : 'pointer',
            backgroundColor: disabled
              ? VentoryColor.grey200
              : checked
              ? Colors.companyLightBackground(currentCompany)
              : 'white',
            borderColor: checked && !disabled ? Colors.companyDarkText(currentCompany) : VentoryColor.grey300,
          }}
          className={`${classes.checkbox.name} cursor-pointer border ventory-border-color h-[20px] w-[20px] rounded-3`}
        />
        {checked ? (
          <CheckIcon
            className='absolute'
            color={disabled ? VentoryColor.grey400 : Colors.companyDarkText(currentCompany)}
          />
        ) : null}
      </Grid>
      {label ? (
        <Grid item flexGrow={1} className='cursor-pointer pl-2 flex items-center'>
          <p className='text-sm'>{label}</p>
        </Grid>
      ) : null}
      {subText ? (
        <Grid item xs={12}>
          <p className='pl-7 text-[12px] my-auto font-[400] text-ventory-grey-300 whitespace-nowrap overflow-hidden overflow-ellipsis'>
            {subText}
          </p>
        </Grid>
      ) : null}
      {tooltip ? (
        <div className='ml-2'>
          <Tooltip element={tooltip} />
        </div>
      ) : null}
    </Grid>
  );
}
