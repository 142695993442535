import React from 'react';
import dayjs from 'dayjs';

import { Order } from '../../../../types/order/order';
import { StockLocation } from '../../../../types/stockLocation';
import { OrderTableSettings } from '../../../../types/orderTableSettings';
import { TagRelation } from '../../../../types/tagRelation';
import { EntityType } from '../../../../types/comment';
import { toFilterString } from '../../../../util/string.util';

import { orderTableFilter } from '../../../../ui/pages/Common/OrderTable';

import { BaseFilter } from '../common/BaseFilter';
import { FilterProps, FilterStorageKey } from '../common/filter.util';
import { OrderStatusFilter } from './OrderStatusFilter';
import { OrderTypeFilter } from './OrderTypeFilter';
import { StockLocationFilter } from '../StockLocationFilter';
import { DateTimeFilter } from '../DateTimeFilter';
import { TagFilter } from '../TagFilter';
import { t } from '../../../../types/translation/Translator';
import { User } from '../../../../types/user';
import { CreatedByFilter } from '../CreatedByFilter/CreatedByFilter';

export class OrderFilter extends BaseFilter<Order> {
  stockLocations: Map<string, StockLocation>;
  tableSettings: Map<string, OrderTableSettings>;
  tagRelations: Map<string, TagRelation>;
  users: Map<string, User>;

  constructor(
    stockLocations: Map<string, StockLocation>,
    tableSettings: Map<string, OrderTableSettings>,
    tagRelations: Map<string, TagRelation>,
    users: Map<string, User>,
    key?: string,
  ) {
    super(FilterStorageKey.ORDER, key);
    this.stockLocations = stockLocations;
    this.tableSettings = tableSettings;
    this.tagRelations = tagRelations;
    this.users = users;
  }

  filterOptions = () => [
    new DateTimeFilter(),
    new StockLocationFilter(this.tagRelations),
    new OrderStatusFilter(this.stockLocations, this.tableSettings),
    new OrderTypeFilter(this.stockLocations, this.tableSettings),
    new TagFilter(),
    new CreatedByFilter(this.users),
  ];

  filteredItems(items: Order[], query: string, filterProps: FilterProps): Order[] {
    const stockLocations = TagFilter.filterByTag(this.tagRelations, EntityType.stockLocation, filterProps);

    const result = items.filter(item => {
      if (filterProps.orderType && filterProps.orderType.size && !filterProps.orderType.has(item.type)) return false;

      if (filterProps.orderStatus && filterProps.orderStatus.size && !filterProps.orderStatus.has(item.status)) {
        return false;
      }

      if (filterProps.user && filterProps.user.size && !filterProps.user.has(item.createdBy)) {
        return false;
      }

      if (
        filterProps.stockLocation &&
        filterProps.stockLocation.size &&
        !filterProps.stockLocation.has(item.stockLocationId)
      ) {
        return false;
      }

      if (
        filterProps.timeFrame &&
        (dayjs(filterProps.timeFrame.start).startOf('day').isAfter(dayjs(item.createdAt)) ||
          dayjs(filterProps.timeFrame.end).endOf('day').isBefore(dayjs(item.createdAt)))
      ) {
        return false;
      }

      if (stockLocations.size && !stockLocations.has(item.stockLocationId)) {
        return false;
      }

      return this.search(item, query);
    });

    return result.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
  }

  search(item: Order, filter: string) {
    const textFilterString = toFilterString(filter);

    const defaultOrderTableSettings = new OrderTableSettings(
      [...this.tableSettings.values()][0] || { companyId: item.companyId },
    );

    return orderTableFilter(
      item,
      textFilterString,
      this.stockLocations,
      this.users,
      this.tableSettings.get(item.companyId) || defaultOrderTableSettings,
    );
  }

  toLabel(): string {
    return t().order.singular.label;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  toDropdownContent(): React.JSX.Element {
    return <></>;
  }

  toIcon(): React.JSX.Element {
    return <></>;
  }

  toCount(): number {
    return 0;
  }
}
