import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import Dropdown from '../../../../Common/Dropdown';
import {
  StockLocationRole,
  StockLocationRoleAssignment,
  stockLocationRoleToString,
} from '../../../../../../types/stockLocationRoleAssignment';
import { t } from '../../../../../../types/translation/Translator';
import DropdownSelect from '../../../../Common/DropdownSelect';
import { StockLocationContext } from '../../../../../../context/StockLocationContext';
import ModalPane from '../../../../../../VentoryUI/components/common/Modal/components/ModalPane';

interface StockLocationRoleInfoPaneProps {
  role?: StockLocationRoleAssignment;
  footer: (input: StockLocationRoleAssignment) => JSX.Element;
  assignedStockLocationIds: Set<string>;
}

export default function StockLocationRoleInfoPane({
  footer,
  role,
  assignedStockLocationIds,
}: StockLocationRoleInfoPaneProps) {
  const { stockLocations } = useContext(StockLocationContext);

  if (!role) return null; // TODO: Entity not found

  const [roleInput, setRoleInput] = useState<StockLocationRoleAssignment>(new StockLocationRoleAssignment(role));

  return (
    <ModalPane footer={footer(roleInput)}>
      <Grid container height={'300px'} alignContent={'space-between'}>
        <Grid item xs={6}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <DropdownSelect
                mandatory
                label={t().selectStockLocation.singular.label}
                values={[...stockLocations.keys()].filter(k => !assignedStockLocationIds.has(k))}
                selectedValue={roleInput.stockLocationId || null}
                toText={item => stockLocations.get(item)?.name || 'Unknown Stock Location'}
                onChange={item => setRoleInput(roleInput.withStockLocationId(item || undefined))}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                label={t().setCompanyRole.singular.label}
                values={Object.values(StockLocationRole) as StockLocationRole[]}
                selectedValue={roleInput.role || StockLocationRole.STOCK_LOCATION_VIEWER}
                toText={item => stockLocationRoleToString(item)}
                onChange={item => setRoleInput(roleInput.withRole(item))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
