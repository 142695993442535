import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { TableSettingsColumn } from './tableSettings';
import { t } from './translation/Translator';

export enum OrderColumn {
  orderNumber = 'orderNumber',
  type = 'type',

  createdOn = 'createdOn',
  createdBy = 'createdBy',

  updatedOn = 'updatedOn',

  sourceLocation = 'sourceLocation',
  destinationLocation = 'destinationLocation',

  status = 'status',
}

export class OrderTableSettings extends CompanyEntity {
  columns: OrderTableColumn[] = defaultOrderTableColumn;
  userId?: string;

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  forUpdate(): UpdateOrderTableSettingsInput {
    return UpdateOrderTableSettingsInput.from(this, UpdateOrderTableSettingsInput);
  }

  forDelete(): DeleteOrderTableSettingsInput {
    return DeleteOrderTableSettingsInput.from(this, DeleteOrderTableSettingsInput);
  }

  validate() {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof OrderTableSettings)[],
      (field: keyof OrderTableSettings) => {
        return null;
      },
    );
  }
}

export class CreateOrderTableSettingsInput extends forCreate(OrderTableSettings) {}

export class UpdateOrderTableSettingsInput extends forUpdate(OrderTableSettings) {}

export class DeleteOrderTableSettingsInput extends forDelete(OrderTableSettings) {}

export class OrderTableColumn implements TableSettingsColumn<OrderColumn> {
  index: number;
  column: string;
  label: string;

  constructor(index: number, column: string, label: string) {
    this.index = index;
    this.column = column;
    this.label = label;
  }
}

export const defaultOrderTableColumn: OrderTableColumn[] = [
  {
    index: 0,
    column: OrderColumn.orderNumber,
    label: t().order.singular.label,
  },
  {
    index: 1,
    column: OrderColumn.type,
    label: t().type.singular.label,
  },
  {
    index: 2,
    column: OrderColumn.createdOn,
    label: t().order.singular.label,
  },
  {
    index: 3,
    column: OrderColumn.sourceLocation,
    label: t().sourceStockLocation.singular.label,
  },
  {
    index: 4,
    column: OrderColumn.destinationLocation,
    label: t().destinationStockLocation.singular.label,
  },
  {
    index: 5,
    column: OrderColumn.status,
    label: t().status.singular.label,
  },
];

export function orderColumnToString(col: string) {
  switch (col) {
    case OrderColumn.orderNumber:
      return t().order.singular.label;
    case OrderColumn.type:
      return t().type.singular.label;
    case OrderColumn.createdOn:
      return t().createdAt.singular.label;
    case OrderColumn.updatedOn:
      return t().updatedAt.singular.label;
    case OrderColumn.sourceLocation:
      return t().sourceStockLocation.singular.label;
    case OrderColumn.destinationLocation:
      return t().destinationStockLocation.singular.label;
    case OrderColumn.status:
      return t().status.singular.label;
    case OrderColumn.createdBy:
      return t().createdBy.singular.label;
  }
  return col;
}

export function stringToOrderColumn(col: string) {
  switch (col) {
    case t().order.singular.label:
      return OrderColumn.orderNumber;
    case t().type.singular.label:
      return OrderColumn.type;
    case t().createdAt.singular.label:
      return OrderColumn.createdOn;
    case t().updatedAt.singular.label:
      return OrderColumn.updatedOn;
    case t().sourceStockLocation.singular.label:
      return OrderColumn.sourceLocation;
    case t().destinationStockLocation.singular.label:
      return OrderColumn.destinationLocation;
    case t().status.singular.label:
      return OrderColumn.status;
    case t().createdBy.singular.label:
      return OrderColumn.createdBy;
  }
  return col;
}
