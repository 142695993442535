import { Grid } from '@mui/material';
import React, { useState } from 'react';
import TextInput from '../Common/TextInput';
import { t } from '../../../types/translation/Translator';
import { ReactComponent as VentoryWhiteLogo } from '../../../assets/img/Ventory-WhiteNoBack.svg';
import { useLazyQuery } from '@apollo/client';
import { ForgotPasswordResponse, ForgotPasswordVariables, UserQueries } from '../../../graphql/user.graphql';
import { useNavigate } from 'react-router-dom';
import { errorCodeToString } from '../../../util/errors/errorMapper.util';
import { handleKeyEvent } from '../../../util/events.util';

import Button from '../../../VentoryUI/components/common/Button';

export function ForgotPasswordScreen() {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [notification, setNotification] = useState<string>('');

  const [reset, { loading: resetLoading }] = useLazyQuery<ForgotPasswordResponse, ForgotPasswordVariables>(
    UserQueries.forgotPassword,
    {
      onCompleted: res => {
        setNotification(`An email has been sent to ${email}`);
        setEmail('');
      },
      onError: e => setError(e.message),
    },
  );

  const handleReset = async () => {
    await reset({
      variables: {
        email: email,
      },
    });
  };

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{ height: '100vh' }}
      className='bg-ventory-blue-900'
      onKeyDown={event => handleKeyEvent(event, 'Enter', handleReset)}
    >
      <Grid container width={'350px'} mb={2}>
        <Grid item xs={12}>
          <VentoryWhiteLogo />
        </Grid>
      </Grid>

      <Grid
        width={'350px'}
        container
        paddingX={3}
        paddingY={2}
        justifyContent={'center'}
        rowSpacing={1}
        className='bg-white'
      >
        <Grid item xs={12} textAlign={'center'} justifyItems={'center'}>
          <p className='text-2xl font-bold'>{'Reset Password'}</p>
        </Grid>
        {error ? (
          <Grid textAlign={'center'} item xs={12} marginX={'auto'}>
            <p className='border-red-400 border-2 rounded-sm bg-red-100 py-1 text-red-500'>
              {errorCodeToString(error)}
            </p>
          </Grid>
        ) : null}
        {notification ? (
          <Grid textAlign={'center'} item xs={12} marginX={'auto'}>
            <p className='border-green-400 border-2 rounded-sm bg-green-100 py-1 text-green-500'>{notification}</p>
          </Grid>
        ) : null}
        <Grid item xs={12} mt={2}>
          <TextInput
            mandatory
            dynamicUpdate
            value={email}
            disabled={resetLoading}
            placeholder={t().email.singular.label}
            label={t().email.singular.label}
            onChange={value => setEmail(value)}
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <Grid container rowSpacing={0.5}>
            <Grid item xs={12}>
              <Button
                style='secondary'
                disabled={resetLoading}
                loading={resetLoading}
                onClick={handleReset}
                text={t().reset.singular.label}
              />
            </Grid>
            <Grid item xs={12}>
              <Button disabled={resetLoading} onClick={() => navigate('/login')} text={t().back.singular.label} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
