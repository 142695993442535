import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import { ProductTransactionContext } from '../../../../context/ProductTransactionContext';
import { UserContext } from '../../../../context/UserContext';
import {
  ProductTransaction,
  ProductTransactionParentType,
  productTransactionParentTypeToString,
} from '../../../../types/productTransaction';
import { Grid } from '@mui/material';
import { iconForType } from '../../../../util/productTransactions.util';
import ProductTransactionDetailModal from '../Product/Modals/ProductTransactionDetailModal';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { StockLocationRoleAssignmentContext } from '../../../../context/StockLocationRoleAssignmentContext';
import { quantityWithSuffix } from '../../../../types/unitOfMeasure';
import { ProductTransactionExportModal } from './Modals/ProductTransactionExportModal';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter';
import { OrderContext } from '../../../../context/OrderContext';
import { MenuItemProps } from '../../../../VentoryUI/components/common/Menu/MenuItem';
import ExportIcon from '../../../../VentoryUI/icons/Export/ExportIcon';
import { SettingsMenuItemTemplate } from '../../../../VentoryUI/components/common/Menu/Templates/SettingsMenuItem';
import FlexPane from '../../../../VentoryUI/components/common/FlexPane';
import Table from '../../../../VentoryUI/components/common/Table';
import { SystemUser } from '../../../../types/user';
import ImportIcon from '../../../../VentoryUI/icons/Import/ImportIcon';
import { CompanyContext } from '../../../../context/CompanyContext';
import { BinStatusContext } from '../../../../context/BinStatusContext';
import { ProductTransactionFilter } from '../../../../VentoryUI/components/filters/ProductTransactionFilter';
import { TagRelationContext } from '../../../../context/TagRelationContext';
import { ContainerContext } from '../../../../context/ContainerContext';
import PackageIcon from '../../../../VentoryUI/icons/Package/PackagIcon';
import BoxIcon from '../../../../VentoryUI/icons/Box/BoxIcon';

interface ProductHistoryOverviewPaneInputProps {}

export default function ProductHistoryOverviewPane({}: ProductHistoryOverviewPaneInputProps) {
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const filterParam = params.get('filter') || '';

  const { currentCompany } = useContext(CompanyContext);
  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { containers } = useContext(ContainerContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { orders, setOrders, ordersLoading, loadAllOrders } = useContext(OrderContext);
  const { productTransactions, setProductTransactions, productTransactionsLoading, loadAllProductTransactions } =
    useContext(ProductTransactionContext);
  const { companyUsers } = useContext(UserContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { tagRelations } = useContext(TagRelationContext);

  const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<ProductTransaction | null>(null);
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

  const items = useMemo(
    () =>
      [...productTransactions.values()]
        .filter(a => a.parentType !== ProductTransactionParentType.transaction)
        .sort(
          (a, b) => new Date(b.processedAt || b.createdAt).getTime() - new Date(a.processedAt || a.createdAt).getTime(),
        ),
    [productTransactions],
  );
  const [filteredItems, setFilteredItems] = useState<ProductTransaction[]>(items);

  const headers = [
    {
      key: 'processedAt',
      name: t().processedAt.singular.label,
      text: (item: ProductTransaction) =>
        `${new Date(item.processedAt || item.createdAt).toLocaleDateString()} - ${new Date(
          item.processedAt || item.createdAt,
        ).toLocaleTimeString()}`,
      sortValue: (item: ProductTransaction) => item.processedAt || item.createdAt,
      defaultSort: true,
    },
    {
      key: 'processedBy',
      name: t().processedBy.singular.label,
      text: (item: ProductTransaction) => {
        if (!item.processedBy) return '';
        return (
          companyUsers.get(item.processedBy || '')?.email ||
          (item.processedBy === SystemUser.id ? SystemUser.email : 'Unknown user')
        );
      },
    },
    {
      key: 'number',
      name: t().number.singular.label,
      text: (item: ProductTransaction) => {
        return (
          <Grid container>
            <Grid item className='pr-2'>
              {(() => {
                if (item.product.pmdId) return <PackageIcon />;
                if (item.product.containerId) return <BoxIcon />;
                return <></>;
              })()}
            </Grid>
            <Grid item className='content-center'>
              <p className='text-[12px] text-ventory-grey-900 font-[400]'>
                {(() => {
                  if (item.product.pmdId) {
                    return (
                      productMasterData.get(item.product.pmdId || '')?.productName ||
                      t().unknownProductReference.singular.label
                    );
                  } else if (item.product.containerId) {
                    return (
                      containers.get(item.product.containerId || '')?.identifier || t().unknownContainer.singular.label
                    );
                  }
                })()}
              </p>
            </Grid>
          </Grid>
        );
      },
    },
    {
      key: 'action',
      name: t().actions.singular.label,
      text: (item: ProductTransaction) =>
        item.parentType === ProductTransactionParentType.order && item.parentId
          ? orders.get(item.parentId)?.number || 'Order'
          : productTransactionParentTypeToString(item.parentType),
    },
    {
      key: 'quantity',
      name: t().quantity.singular.label,
      text: (item: ProductTransaction) => (
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item marginY={'auto'} className='h-7'>
            <p
              title={item.product.quantity.toString()}
              className={`h-7 text-ellipsis py-1 overflow-hidden text-sm whitespace-nowrap`}
            >
              {quantityWithSuffix(
                (item.product.processedQuantity || item.product.quantity).toString(),
                productMasterData.get(item.product.pmdId || '')?.unitOfMeasure,
              )}
            </p>
          </Grid>
          <Grid item marginY={'auto'}>
            {iconForType(item)}
          </Grid>
        </Grid>
      ),
      sortValue: (item: ProductTransaction) => item.product.processedQuantity || item.product.quantity,
      columnWidth: 0.5,
    },
  ];

  const loadAll = async () => {
    loadAllProductTransactions();
    loadAllOrders();
  };

  const menuItems: MenuItemProps[] = useMemo(() => {
    return [
      {
        text: t().export.singular.label,
        icon: <ExportIcon />,
        onClick: () => setExportModalOpen(true),
      },
      SettingsMenuItemTemplate(() => navigate('settings/report')),
      {
        text: t().loadAll.singular.label,
        icon: <ImportIcon />,
        onClick: loadAll,
      },
    ];
  }, []);

  return (
    <>
      <ProductTransactionExportModal
        transactionIds={new Set(filteredItems.map(item => item.id))}
        open={exportModalOpen}
        setOpen={setExportModalOpen}
      />
      {selectedTransaction ? (
        <ProductTransactionDetailModal
          open={openDetailModal}
          setOpen={v => {
            setOpenDetailModal(v);
            setSelectedTransaction(null);
          }}
          transaction={selectedTransaction}
        />
      ) : null}

      <FlexPane
        header={
          <SearchBarWithFilter
            loading={productMasterDataLoading || productTransactionsLoading}
            items={items}
            setItems={setFilteredItems}
            placeholder={t().filterProductTransactions.singular.label}
            menuItems={menuItems}
            filter={new ProductTransactionFilter(companyUsers, productMasterData, orders, tagRelations, containers)}
          />
        }
        content={
          <Table
            loading={productMasterDataLoading || ordersLoading || productTransactionsLoading}
            items={filteredItems}
            headers={headers}
            totalItemCount={items.length}
            onClick={item => {
              setSelectedTransaction(item);
              setOpenDetailModal(true);
            }}
          />
        }
      />
    </>
  );
}
