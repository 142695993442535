import React from 'react';
import { Order } from '../types/order/order';

export interface OrderContextProps {
  orders: Map<string, Order>;
  setOrders: (bins: Map<string, Order>) => void;
  ordersLoading: boolean;
  loadAllOrders: () => void;
}

const defaultContext: OrderContextProps = {
  orders: new Map(),
  setOrders: () => {},
  ordersLoading: false,
  loadAllOrders: async () => {},
};

export const OrderContext = React.createContext<OrderContextProps>(defaultContext);
