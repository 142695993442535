import React from 'react';
import CloseIcon from '../../../../icons/Close/CloseIcon';
import { FilterProps } from '../filter.util';
import { BaseFilter, BaseFilterProps } from '../BaseFilter';

export interface FilterInnerContentProps<T> {
  filter: BaseFilter<T>;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

interface DynamicFilterInnerContentProps<T> {
  items: string[];
  filter: BaseFilter<T>;
  filterProps: BaseFilterProps;
  text?: (item: string) => string;
  onRemove: (item: string) => void;
}

export default function InnerFilterContent<T>({
  items,
  filter,
  filterProps,
  text,
  onRemove,
}: DynamicFilterInnerContentProps<T>) {
  const label = filter.toLabel(filterProps);
  if (!items.length) return <p className='text-[13px] font-[500] mt-[1px]'> {label}</p>;

  return (
    <div className='flex overflow-x-scroll overflow-y-hidden no-scrollbar py-1 gap-1'>
      {items.map(i => {
        const content = text ? text(i) : i;
        return (
          <div
            className={'flex rounded-2 border ventory-border-color whitespace-nowrap p-1 items-center max-w-[120px]'}
            key={i}
          >
            <span title={content} className='text-[12px] font-semibold mr-1 w-fit text-ellipsis overflow-hidden'>
              {content}
            </span>
            <div
              onClick={event => {
                event.stopPropagation();
                onRemove(i);
              }}
              className={'h-[12px] w-[12px] flex justify-center items-center hover:bg-gray-100 rounded-1'}
            >
              <CloseIcon height={'6'} width={'6'} />
            </div>
          </div>
        );
      })}
    </div>
  );
}
