import { Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { CreateTaskInput, Task, TaskType } from '../../../../../types/task';
import TextInput from '../../../Common/TextInput';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import DropdownSelect from '../../../Common/DropdownSelect';
import { ProductContext } from '../../../../../context/ProductContext';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { StockLocationRoleAssignmentContext } from '../../../../../context/StockLocationRoleAssignmentContext';
import { UserContext } from '../../../../../context/UserContext';
import { StockLocationRole } from '../../../../../types/stockLocationRoleAssignment';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { NextButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/NextButton/NextButton';
import { BackButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/BackButton';

interface CreateTaskStockLocationPaneInputProps {
  task: CreateTaskInput;
  setTask: (task: Task) => void;
  next: () => void;
  back: () => void;
  error: string;
  setError: (err: string) => void;
}

export function CreateTaskStockLocationPane({
  task,
  setTask,
  next,
  back,
  error,
  setError,
}: CreateTaskStockLocationPaneInputProps) {
  const { stockLocations } = useContext(StockLocationContext);
  const { products } = useContext(ProductContext);
  const { stockLocationRoles } = useContext(StockLocationRoleAssignmentContext);
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    if (task.type === TaskType.cycle && task.stockLocationId) {
      let hasStock = false;
      for (const [key, value] of [...products.entries()]) {
        const hasQuantity = [...value.values()]
          .filter(p => task.stockLocationId === p.stockLocationId)
          .reduce((sum, p) => sum + parseFloat(p.unitQuantity), 0);
        if (hasQuantity) {
          hasStock = true;
          break;
        }
      }

      if (!hasStock) setError(t().noStockCycleCount.singular.upper);
      else setError('');
    }
  }, [task]);

  const possibleStockLocations = [...stockLocations.values()].filter(sl => {
    const role = stockLocationRoles
      .get(sl.id)
      ?.flat()
      .find(u => u.userId === currentUser?.userId)?.role;

    if (role === StockLocationRole.STOCK_LOCATION_VIEWER || role === StockLocationRole.STOCK_LOCATION_USER)
      return false;
    return true;
  });

  return (
    <ModalPane
      testId={testIds.createTaskStockLocationPane}
      footerButtons={[BackButtonTemplate(back), NextButtonTemplate(next, { disabled: !task.stockLocationId })]}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={6}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <DropdownSelect
                testId={testIds.stockLocation}
                mandatory
                placeholder={t().selectStockLocation.singular.label}
                label={t().stockLocation.singular.label}
                values={possibleStockLocations}
                selectedValue={stockLocations.get(task.stockLocationId) || null}
                toText={item => item.name}
                onChange={v => setTask(task.withStockLocationId(v?.id))}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                placeholder={'External Reference'}
                onChange={v => task.withExternalReference(v)}
                testId={testIds.externalReference}
                label={t().externalReference.singular.label}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
