import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { UserContext } from '../../../../../context/UserContext';
import { ChangePasswordResponse, ChangePasswordVariables, UserMutations } from '../../../../../graphql/user.graphql';
import { Grid } from '@mui/material';
import React from 'react';
import TextInput from '../../../Common/TextInput';
import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { CancelButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import { SaveButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/SaveButton';

interface UpdatePasswordModalInputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function UpdatePasswordModal({ open, setOpen }: UpdatePasswordModalInputProps) {
  const { currentUser } = useContext(UserContext);

  const [newPassword, setNewPassword] = useState<string>('');
  const [oldPassword, setOldPassword] = useState<string>('');
  const [verifyPassword, setVerfiyPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [update, { loading: updateLoading }] = useMutation<ChangePasswordResponse, ChangePasswordVariables>(
    UserMutations.changePassword,
    {
      onCompleted: res => handleClose(),
      onError: res => setError(res.message),
    },
  );

  const handleUpdate = async () => {
    if (verifyPassword !== newPassword) {
      setError('Please make sure both passwords match');
      return;
    }

    try {
      await update({
        variables: {
          newPassword: newPassword,
          oldPassword: oldPassword,
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setNewPassword('');
    setVerfiyPassword('');
    setOldPassword('');
    setError('');
    setOpen(false);
  };

  if (!currentUser) return null;

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t().changePassword.singular.label}>
      <ModalPane
        footerButtons={[
          CancelButtonTemplate(handleClose, { disabled: updateLoading }),
          SaveButtonTemplate(handleUpdate, {
            disabled: !oldPassword || !newPassword || !verifyPassword,
            loading: updateLoading,
          }),
        ]}
      >
        <Grid container height={'500px'} rowSpacing={1} alignContent={'space-between'}>
          <Grid item xs={12}>
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid mt={2} item xs={12} className='bg-yellow-100 h3 py-2 px-4 border-2 border-yellow-200'>
                <p style={{ textAlign: 'center' }} className='font-semibold text-yellow-500 text-sm'>
                  {t().passwordRequirementInfo.singular.label}
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  mandatory
                  autoComplete='old-password'
                  type='password'
                  label={t().oldPassword.singular.label}
                  placeholder={t().oldPassword.singular.label}
                  value={oldPassword}
                  onChange={v => setOldPassword(v)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  mandatory
                  autoComplete='new-password'
                  type='password'
                  label={t().newPassword.singular.label}
                  placeholder={t().newPassword.singular.label}
                  value={newPassword}
                  onChange={v => setNewPassword(v)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  mandatory
                  autoComplete='new-password'
                  type='password'
                  label={t().confirmPassword.singular.label}
                  placeholder={t().confirmPassword.singular.label}
                  value={verifyPassword}
                  onChange={v => setVerfiyPassword(v)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
