import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import CancelIcon from '@mui/icons-material/Cancel';
import { ProductTransaction, ProductTransactionStatus, ProductTransactionType } from '../types/productTransaction';
import React from 'react';
import RestoreIcon from '@mui/icons-material/Restore';

// TODO: Probably not the right place for this
export const iconForType = (item: ProductTransaction) => {
  if (item.status === ProductTransactionStatus.failed) {
    return <MobiledataOffIcon className='text-red-500' />;
  } else if (item.status === ProductTransactionStatus.cancelled) {
    return <CancelIcon className='text-red-500' />;
  } else if (item.status === ProductTransactionStatus.rollback) {
    return <RestoreIcon className='text-orange-500' />;
  }

  const color = item.status === ProductTransactionStatus.created ? 'text-gray-500' : undefined;

  switch (item.type) {
    case ProductTransactionType.inbound:
      return <ArrowDownwardIcon className={`${color || 'text-green-500'}`} />;
    case ProductTransactionType.outbound:
      return <ArrowUpwardIcon className={`${color || 'text-yellow-500'}`} />;
    case ProductTransactionType.move:
      return <ImportExportIcon className={`${color || 'text-blue-500'}`} />;
  }
};
