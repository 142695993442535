import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import StockLocationItem from './Common/StockLocationItem';
import DeleteStockLocationModal from './Modals/DeleteStockLocationModal';
import { StockLocation } from '../../../../types/stockLocation';
import StockLocationImportModal from './Modals/StockLocationImportModal';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { CompanyContext } from '../../../../context/CompanyContext';
import { CompanyRole } from '../../../../types/companyRoleAssignment';
import { NewMenuItemTemplate } from '../../../../VentoryUI/components/common/Menu/Templates/NewMenuItem';
import { ImportMenuItemTemplate } from '../../../../VentoryUI/components/common/Menu/Templates/ImportMenuItem';
import { SettingsMenuItemTemplate } from '../../../../VentoryUI/components/common/Menu/Templates/SettingsMenuItem';
import FlexPane from '../../../../VentoryUI/components/common/FlexPane';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter';
import { StockLocationFilter } from '../../../../VentoryUI/components/filters/StockLocationFilter';
import { TagRelationContext } from '../../../../context/TagRelationContext';

interface StockLocationSettingsPaneInputProps {}

export default function StockLocationSettingsPane({}: StockLocationSettingsPaneInputProps) {
  const navigate = useNavigate();

  const { stockLocations } = useContext(StockLocationContext);
  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { currentCompany } = useContext(CompanyContext);
  const { tagRelations } = useContext(TagRelationContext);

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selected, setSelected] = useState<StockLocation>();
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);

  const [items, setItems] = useState([...stockLocations.values()]);

  const menuItems = useMemo(() => {
    if (!hasCompanyRole(currentCompany.id, CompanyRole.administrator)) return [];

    return [
      NewMenuItemTemplate(() => navigate('create')),
      ImportMenuItemTemplate(() => setOpenImportModal(true)),
      SettingsMenuItemTemplate(() => navigate('advanced/import_configurations')),
    ];
  }, []);

  const allItems = useMemo(() => {
    return [...stockLocations.values()];
  }, [stockLocations]);

  return (
    <>
      <DeleteStockLocationModal
        open={openDelete}
        stockLocation={selected}
        setOpen={v => {
          setOpenDelete(v);
          setSelected(undefined);
        }}
      />
      <StockLocationImportModal open={openImportModal} setOpen={setOpenImportModal} />

      <FlexPane
        contentOverflow='auto'
        testId={testIds.stockLocationSettingsPane}
        header={
          <SearchBarWithFilter<StockLocation>
            placeholder={t().filterStockLocations.singular.label}
            menuItems={menuItems}
            items={allItems}
            setItems={setItems}
            filter={new StockLocationFilter(tagRelations)}
          />
        }
        content={
          <div className='grid gap-y-2'>
            {[...items.values()].map(sl => (
              <StockLocationItem
                key={sl.id}
                stockLocation={sl}
                onDelete={sl => {
                  setSelected(sl);
                  setOpenDelete(true);
                }}
              />
            ))}
          </div>
        }
      />
    </>
  );
}
