import React from 'react';
import { CircularProgress } from '@mui/material';
import { StyleType } from '../../../VentoryUI/components/common/Style';

interface LoadingSpinnerProps {
  style?: StyleType;
  color?: string;
  size?: string;
}

export function LoadingSpinner({ style, color, size = '16px' }: LoadingSpinnerProps) {
  return (
    <div className={`flex items-center justify-center`}>
      <CircularProgress
        size={size}
        sx={{
          color: color || (style === 'primary' ? 'black' : 'white'),
        }}
      />
    </div>
  );
}
