import React from 'react';
import { Trigger } from '../../../../types/trigger';
import { toFilterString } from '../../../../util/string.util';
import { BaseFilter } from '../common/BaseFilter';

export class TriggerFilter extends BaseFilter<Trigger> {
  triggerTexts: Map<string, string>;

  constructor(triggerTexts: Map<string, string>) {
    super();
    this.triggerTexts = triggerTexts;
  }

  search(item: Trigger, query: string): boolean {
    if (toFilterString(this.triggerTexts.get(item.id)).includes(query)) {
      return true;
    }

    return false;
  }

  toLabel(): string {
    return '';
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  toIcon(): JSX.Element {
    return <></>;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toCount(): number {
    return 0;
  }
}
