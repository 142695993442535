import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import { ScanConfigurationType } from './company';

enum ScanRuleValidationError {}

export enum ScanRuleConfigurationValueKey {
  ignoredRegex = 'ignoredRegex',
}

export enum ScanRuleType {
  ignoreScan = 'ignoreScan',
  ignoreValidation = 'ignoreValidation',
}

export class ScanRuleConfigurationValue {
  field: ScanConfigurationType = ScanConfigurationType.productNumber;
  key!: ScanRuleConfigurationValueKey;
  stringValue?: string;
  stringArrayValue: string[] = [];

  constructor(obj: any) {
    Object.assign(this, cloneDeep(obj));
  }
}

export class ScanRule extends CompanyEntity {
  type!: string;
  configurationValues: ScanRuleConfigurationValue[] = [
    new ScanRuleConfigurationValue({
      field: ScanConfigurationType.productNumber,
      key: ScanRuleConfigurationValueKey.ignoredRegex,
    }),
  ];

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  forUpdate(): UpdateScanRuleInput {
    return UpdateScanRuleInput.from(this, UpdateScanRuleInput);
  }

  forDelete(): DeleteScanRuleInput {
    return DeleteScanRuleInput.from(this, DeleteScanRuleInput);
  }

  validate() {
    return this.validateEntity(Object.getOwnPropertyNames(this) as (keyof ScanRule)[], (field: keyof ScanRule) => {
      return null;
    });
  }

  withConfigurationField(field: ScanConfigurationType) {
    this.configurationValues[0].field = field;
    return cloneDeep(this);
  }

  withConfigurationValue(value: string) {
    this.configurationValues[0].stringValue = value;
    return cloneDeep(this);
  }
}

export class CreateScanRuleInput extends forCreate(ScanRule) {}

export class UpdateScanRuleInput extends forUpdate(ScanRule) {}

export class DeleteScanRuleInput extends forDelete(ScanRule) {}
