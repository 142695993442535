import React from 'react';
import Icon, { IconInputProps } from '../Icon';

export default function BoxIcon({ className, height = 16, width = 16 }: IconInputProps) {
  return (
    <Icon className={className}>
      <svg
        width={width}
        height={height}
        viewBox='0 0 32 32'
        fill='none'
        stroke='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M27.3337 10.6667V21.6C27.3337 23.8402 27.3337 24.9603 26.8977 25.816C26.5142 26.5686 25.9023 27.1805 25.1496 27.564C24.294 28 23.1739 28 20.9337 28H11.067C8.82678 28 7.70668 28 6.85103 27.564C6.09838 27.1805 5.48646 26.5686 5.10297 25.816C4.66699 24.9603 4.66699 23.8402 4.66699 21.6V10.6667M4.80033 4H27.2003C27.9471 4 28.3204 4 28.6056 4.14532C28.8565 4.27316 29.0605 4.47713 29.1883 4.72801C29.3337 5.01323 29.3337 5.3866 29.3337 6.13333V8.53333C29.3337 9.28007 29.3337 9.65344 29.1883 9.93865C29.0605 10.1895 28.8565 10.3935 28.6056 10.5213C28.3204 10.6667 27.9471 10.6667 27.2003 10.6667H4.80033C4.05359 10.6667 3.68022 10.6667 3.395 10.5213C3.14412 10.3935 2.94015 10.1895 2.81232 9.93865C2.66699 9.65344 2.66699 9.28007 2.66699 8.53333V6.13333C2.66699 5.3866 2.66699 5.01323 2.81232 4.72801C2.94015 4.47713 3.14412 4.27316 3.395 4.14532C3.68022 4 4.05359 4 4.80033 4ZM12.8003 15.3333H19.2003C19.9471 15.3333 20.3204 15.3333 20.6056 15.4787C20.8565 15.6065 21.0605 15.8105 21.1883 16.0613C21.3337 16.3466 21.3337 16.7199 21.3337 17.4667V18.5333C21.3337 19.2801 21.3337 19.6534 21.1883 19.9387C21.0605 20.1895 20.8565 20.3935 20.6056 20.5213C20.3204 20.6667 19.9471 20.6667 19.2003 20.6667H12.8003C12.0536 20.6667 11.6802 20.6667 11.395 20.5213C11.1441 20.3935 10.9401 20.1895 10.8123 19.9387C10.667 19.6534 10.667 19.2801 10.667 18.5333V17.4667C10.667 16.7199 10.667 16.3466 10.8123 16.0613C10.9401 15.8105 11.1441 15.6065 11.395 15.4787C11.6802 15.3333 12.0536 15.3333 12.8003 15.3333Z'
        />
      </svg>
    </Icon>
  );
}
