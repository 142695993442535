import { Divider, Grid } from '@mui/material';
import React, { useContext } from 'react';
import { t } from '../../../../../../types/translation/Translator';
import {
  StockLocationRole,
  StockLocationRoleAssignment,
  stockLocationRoleToString,
  stringToStockLocationRole,
} from '../../../../../../types/stockLocationRoleAssignment';
import { StockLocationContext } from '../../../../../../context/StockLocationContext';
import { CompanyContext } from '../../../../../../context/CompanyContext';
import SelectorWithDropdown from '../../../../Common/SelectorWithDropdown';
import { CompanyRole, CompanyRoleAssignment, companyRoleToString } from '../../../../../../types/companyRoleAssignment';
import Dropdown from '../../../../Common/Dropdown';
import { CompanyRoleAssignmentContext } from '../../../../../../context/CompanyRoleAssignmentContext';
import { StockLocationRoleAssignmentContext } from '../../../../../../context/StockLocationRoleAssignmentContext';
import ModalPane from '../../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { BackButtonTemplate } from '../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../../../VentoryUI/components/common/Button/Templates/NextButton/NextButton';

interface InviteUserStockLocationRolePaneProps {
  stockLocationRoleAssignments: StockLocationRoleAssignment[];
  setStockLocationRoleAssignments: (roles: StockLocationRoleAssignment[]) => void;
  next: () => void;
  back: () => void;
  loading: boolean;
  companyRoleAssignment: CompanyRoleAssignment;
  setCompanyRoleAssignment: (role: CompanyRoleAssignment) => void;
}

export default function InviteUserStockLocationRolePane({
  stockLocationRoleAssignments,
  setStockLocationRoleAssignments,
  next,
  back,
  loading,
  companyRoleAssignment,
  setCompanyRoleAssignment,
}: InviteUserStockLocationRolePaneProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { hasStockLocationRole, stockLocationRoles } = useContext(StockLocationRoleAssignmentContext);

  return (
    <ModalPane
      footerButtons={[BackButtonTemplate(back, { disabled: loading }), NextButtonTemplate(next, { loading: loading })]}
    >
      <Grid container alignContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={6}>
              <Dropdown
                label={t().setCompanyRole.singular.label}
                values={(Object.values(CompanyRole) as CompanyRole[]).filter(c => {
                  if (c === CompanyRole.administrator && !hasCompanyRole(currentCompany.id, CompanyRole.administrator))
                    return false;
                  return true;
                })}
                selectedValue={companyRoleAssignment.role || CompanyRole.employee}
                toText={item => companyRoleToString(item)}
                onChange={item => {
                  setCompanyRoleAssignment(companyRoleAssignment.withRole(item));
                  setStockLocationRoleAssignments([]);
                }}
              />
            </Grid>

            {companyRoleAssignment.role === CompanyRole.employee ? (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <p className='text-sm font-semibold mb-1'>{t().selectStockLocationRoles.singular.label}</p>
                  <SelectorWithDropdown
                    maxHeight='240px'
                    toDropdownText={stockLocationRoleToString}
                    placeholder={t().filterStockLocations.singular.label}
                    toValue={item => stringToStockLocationRole(item) || StockLocationRole.STOCK_LOCATION_VIEWER}
                    values={[...stockLocations.values()]
                      .filter(sl => hasStockLocationRole(sl.companyId, sl.id, StockLocationRole.STOCK_LOCATION_MANAGER))
                      .map(sl => ({
                        id: sl.id,
                        value: StockLocationRole.STOCK_LOCATION_VIEWER,
                      }))}
                    checkedValues={stockLocationRoleAssignments.map(slr => ({
                      id: slr.stockLocationId,
                      value: slr.role,
                    }))}
                    disabled={item =>
                      !!stockLocationRoles.get(item.id)?.find(sl => sl.email === companyRoleAssignment.email)
                    }
                    toText={item => stockLocations.get(item.id)?.name || 'Unknown Stock Location'}
                    dropdownValues={[...Object.values(StockLocationRole)]}
                    onChange={item => {
                      setStockLocationRoleAssignments([
                        ...item.map(
                          id =>
                            new StockLocationRoleAssignment({
                              companyId: currentCompany.id,
                              stockLocationId: id.id,
                              role: id.value,
                            }),
                        ),
                      ]);
                    }}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
