import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { t } from '../../../../../types/translation/Translator';
import { SuperUser, SuperUserRole, superUserRoleToString } from '../../../../../types/superUser';
import TextInput from '../../../Common/TextInput';
import DropdownSelect from '../../../Common/DropdownSelect';

import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import Checkbox from '../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import { TranslationLanguage } from '../../../../../types/translation/translation';

interface SuperUserInfoPaneInputProps {
  superUser: SuperUser;
  footer: (input: SuperUser) => JSX.Element;
}

export default function SuperUserInfoPane({ superUser, footer }: SuperUserInfoPaneInputProps) {
  const [superUserInput, setSuperUserInput] = useState<SuperUser>(new SuperUser(superUser));

  return (
    <ModalPane footer={footer(superUserInput)}>
      <Grid container alignContent={'space-between'}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={6}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <TextInput
                  disabled={!!superUserInput.id}
                  mandatory
                  value={superUserInput.email}
                  label={t().email.singular.label}
                  placeholder={t().email.singular.label}
                  onChange={v => superUserInput.withEmail(v)}
                />
              </Grid>
              <Grid item xs={12}>
                <DropdownSelect
                  mandatory
                  label={t().role.singular.label}
                  placeholder={t().role.singular.label}
                  selectedValue={superUserInput.role || null}
                  maxHeight='150px'
                  values={Object.keys(SuperUserRole)}
                  toText={item => superUserRoleToString(item as SuperUserRole)}
                  onChange={item => setSuperUserInput(superUserInput.withRole(item as SuperUserRole))}
                />
              </Grid>
              {superUserInput.role === SuperUserRole.translator || superUserInput.role === SuperUserRole.external ? (
                <Grid item xs={12}>
                  <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                      <p className='text-sm font-medium text-slate-800'>{'Languages'}</p>
                    </Grid>
                    <Grid item xs={12} ml={2}>
                      <Checkbox
                        label='English'
                        value={superUserInput.languages.includes(TranslationLanguage.en)}
                        onChange={v =>
                          setSuperUserInput(
                            v
                              ? superUserInput.withLanguage(TranslationLanguage.en)
                              : superUserInput.removeLanguage(TranslationLanguage.en),
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} ml={2}>
                      <Checkbox
                        label='Lithuanian'
                        value={superUserInput.languages.includes(TranslationLanguage.lt)}
                        onChange={v =>
                          setSuperUserInput(
                            v
                              ? superUserInput.withLanguage(TranslationLanguage.lt)
                              : superUserInput.removeLanguage(TranslationLanguage.lt),
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
