import React from 'react';
import TagLabel, { TagLabelProps } from '../TagLabel';
import CloseIcon from '@mui/icons-material/Close';
import { VentoryColor } from '../../../../util/color.util';

function CloseTagTemplate(props: CloseTagLabelProps): TagLabelProps {
  return {
    color: VentoryColor.blue700,
    backgroundColor: VentoryColor.blue50,
    suffix: <CloseIcon sx={{ width: '18px', height: '18px' }} />,
    ...props,
  };
}

interface CloseTagLabelProps {
  onClick: () => Promise<void>;
  text: string;
}

export default function CloseTagLabel({ ...props }: CloseTagLabelProps) {
  return <TagLabel {...CloseTagTemplate(props)} />;
}
