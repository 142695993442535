import React from 'react';
import InnerFilterContent, { FilterInnerContentProps } from '../../common/DropdownContent/DynamicFilterInnerContent';
import { CompanyRoleAssignment } from '../../../../../types/companyRoleAssignment';

export default function CompanyRoleAssignmentFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<CompanyRoleAssignment>) {
  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.role || [])]}
      text={item => item}
      onRemove={i => {
        filterProps.role?.delete(i);
        if (!filterProps.role?.size) filterProps.role = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
