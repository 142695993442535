import React from 'react';
import { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { ContainerContext } from '../../../../../context/ContainerContext';
import InnerFilterContent, { FilterInnerContentProps } from '../../common/DropdownContent/DynamicFilterInnerContent';
import { Container } from '../../../../../types/container';

export default function ContainerFilterInnerContent({
  filter,
  filterProps,
  setFilterProps,
}: FilterInnerContentProps<Container>) {
  const { containers } = useContext(ContainerContext);

  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.container || [])]}
      text={item => containers.get(item)?.identifier || t().unknownContainer.singular.label}
      onRemove={i => {
        filterProps.container?.delete(i);
        if (!filterProps.container?.size) filterProps.container = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
