import React, { useContext, useMemo } from 'react';
import { useState } from 'react';
import { t } from '../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { ProductMasterDataContext } from '../../../context/ProductMasterDataContext';
import { ProductMasterData } from '../../../types/productMasterData';
import ImportProductMasterDataModal from './ProductMasterData/Modals/ImportProductMasterDataModal';
import DeleteProductMasterDataModal from './ProductMasterData/Modals/DeleteProductMasterDataModal';
import { testIds } from '../../../util/identifiers/identifiers.util';
import { CompanyContext } from '../../../context/CompanyContext';
import { StockLocationContext } from '../../../context/StockLocationContext';
import { StockLocationRoleAssignmentContext } from '../../../context/StockLocationRoleAssignmentContext';
import { StockLocationRole } from '../../../types/stockLocationRoleAssignment';
import { CompanyRoleAssignmentContext } from '../../../context/CompanyRoleAssignmentContext';
import { ExportProductMasterDataModal } from './ProductMasterData/Modals/ExportProductMasterDataModal';
import { ButtonProps } from '../../../VentoryUI/components/common/Button';
import SearchBarWithFilter from '../../../VentoryUI/components/common/SearchBarWithFilter';
import { DeleteButtonTemplate, ExportButtonTemplate } from '../../../VentoryUI/components/common/Button/Templates';
import { MenuItemProps } from '../../../VentoryUI/components/common/Menu/MenuItem';
import { NewMenuItemTemplate } from '../../../VentoryUI/components/common/Menu/Templates/NewMenuItem';
import { ImportMenuItemTemplate } from '../../../VentoryUI/components/common/Menu/Templates/ImportMenuItem';
import { SettingsMenuItemTemplate } from '../../../VentoryUI/components/common/Menu/Templates/SettingsMenuItem';
import { ProductMasterDataFilter } from '../../../VentoryUI/components/filters/ProductMasterDataFilter';
import Pane from '../../../VentoryUI/components/common/Pane';
import FlexPane from '../../../VentoryUI/components/common/FlexPane';
import Table from '../../../VentoryUI/components/common/Table';
import { FileContext } from '../../../context/FileContext';
import { FileEntityType } from '../../../types/file';
import { Grid } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export default function ReferenceDataScreen() {
  const navigate = useNavigate();

  const { productMasterData, productMasterDataLoading } = useContext(ProductMasterDataContext);
  const { currentCompany } = useContext(CompanyContext);
  const { hasCompanyRole } = useContext(CompanyRoleAssignmentContext);
  const { hasStockLocationRole } = useContext(StockLocationRoleAssignmentContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { files } = useContext(FileContext);

  const [items, setItems] = useState([...productMasterData.values()]);

  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<Set<string>>(new Set());

  const headers = [
    {
      key: 'productName',
      name: t().productName.singular.label,
      text: (item: ProductMasterData) => item.productName,
    },
    {
      key: 'productNumber',
      name: t().productNumber.singular.label,
      text: (item: ProductMasterData) => item.productNumber,
    },
    {
      key: 'files',
      name: t().filesAndAssets.singular.label,
      sortValue: (item: ProductMasterData) =>
        [...files.values()].filter(file => file.entityType === FileEntityType.pmd && file.entityId === item.id).length,
      text: (item: ProductMasterData) => {
        const assets = [...files.values()].filter(
          file => file.entityType === FileEntityType.pmd && file.entityId === item.id,
        ).length;

        if (assets) {
          return (
            <Grid container display={'flex'}>
              <Grid item alignContent={'center'}>
                <p>{assets.toString()}</p>
              </Grid>
              <Grid item alignContent={'center'}>
                <AttachFileIcon sx={{ width: 16, height: 16, mb: 0.2 }} />
              </Grid>
            </Grid>
          );
        } else {
          return '';
        }
      },
    },
    {
      key: 'ean',
      name: t().globalTradeItemNumber.singular.label,
      text: (item: ProductMasterData) => item.globalTradeItemNumber || '',
    },
  ];

  const buttons: ButtonProps[] = useMemo(() => {
    if (!selected.size) return [];

    return [
      { ...DeleteButtonTemplate(() => setOpenDeleteModal(true)) },
      { ...ExportButtonTemplate(() => setOpenExportModal(true)) },
    ];
  }, [selected]);

  const menuItems: MenuItemProps[] = useMemo(() => {
    if (
      ![...stockLocations.values()].filter(sl =>
        hasStockLocationRole(currentCompany.id, sl.id, StockLocationRole.STOCK_LOCATION_MANAGER),
      ).length
    )
      return [];

    return [
      NewMenuItemTemplate(() => navigate('pmd/create')),
      ImportMenuItemTemplate(() => setOpenImportModal(true)),
      SettingsMenuItemTemplate(() => navigate('settings/import_configurations')),
    ];
  }, [stockLocations]);

  const allItems = useMemo(() => {
    return [...productMasterData.values()];
  }, [productMasterData]);

  return (
    <>
      <ImportProductMasterDataModal open={openImportModal} setOpen={setOpenImportModal} />
      <DeleteProductMasterDataModal open={openDeleteModal} setOpen={setOpenDeleteModal} idsToDelete={selected} />
      <ExportProductMasterDataModal open={openExportModal} setOpen={setOpenExportModal} ids={selected} />

      <Pane testId={testIds.referenceDataScreen}>
        <FlexPane
          header={
            <SearchBarWithFilter
              loading={productMasterDataLoading}
              placeholder={t().filterProductReferenceData.singular.label}
              buttons={buttons}
              menuItems={menuItems}
              items={allItems}
              setItems={setItems}
              filter={new ProductMasterDataFilter()}
            />
          }
          content={
            <Table
              title={t().referenceData.singular.label}
              loading={productMasterDataLoading}
              items={items}
              headers={headers}
              totalItemCount={allItems.length}
              onClick={item => navigate(`/reference_data/pmd/${item.id}/update/info`)}
              onSelected={items => setSelected(new Set(items))}
            />
          }
        />
      </Pane>
    </>
  );
}
