import { useMutation } from '@apollo/client';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { ProductExportConfigurationContext } from '../../../../../context/ProductExportConfigurationContext';
import {
  CsvExportFile,
  ReportMutations,
  ReportProductResponse,
  ReportProductVariables,
} from '../../../../../graphql/report.graphql';
import ProductExportConfigurationItem from '../Settings/Panes/Export/ProductExportConfigurationItem';
import { Grid } from '@mui/material';
import { zipAndDownloadExportFiles } from '../../../../../util/export.util';
import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { CancelButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/CancelButton';

interface ProductExportModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  productIds: Set<string>;
}

export function ProductExportModal({ open, setOpen, productIds }: ProductExportModalProps) {
  const { productExportConfigurations, productExportConfigurationsLoading } = useContext(
    ProductExportConfigurationContext,
  );
  const { currentCompany } = useContext(CompanyContext);

  const [error, setError] = useState<string>('');
  const [selected, setSelected] = useState<Set<string>>(
    new Set([...productExportConfigurations.values()].filter(config => config.default).map(config => config.id)),
  );

  useEffect(() => {
    if (!productExportConfigurationsLoading)
      setSelected(
        new Set([...productExportConfigurations.values()].filter(config => config.default).map(config => config.id)),
      );
  }, [productExportConfigurationsLoading]);

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const [download, { loading: downloadLoading }] = useMutation<ReportProductResponse, ReportProductVariables>(
    ReportMutations.product,
  );

  const handleDownload = async () => {
    const files: CsvExportFile[] = [];
    let hasNext = true;
    let page = 0;

    while (hasNext) {
      const response = await download({
        variables: {
          page: page,
          productIds: [...productIds.keys()],
          timezone: moment.tz.guess(),
          locale: (window.navigator as any)['userLanguage'] || window.navigator.language,
          companyId: currentCompany.id,
          configurationIds: [...selected.values()],
        },
      });
      files.push(...(response.data?.generateProductExport.data || []));

      hasNext = response.data?.generateProductExport.hasNext || false;
      page++;
    }

    if (!files.length) return console.error('No files to download');
    zipAndDownloadExportFiles(files, productExportConfigurations, `ventory_products_${new Date().toISOString()}.zip`);

    setSelected(new Set());
    handleClose();
  };

  return (
    <Modal open={open} error={error} onClose={handleClose} title={t().exportProducts.singular.label}>
      <ModalPane
        footerButtons={[
          CancelButtonTemplate(handleClose, { disabled: downloadLoading }),
          {
            onClick: handleDownload,
            text: t().export.singular.label,
            loading: downloadLoading,
            disabled: !selected.size,
            style: 'secondary',
          },
        ]}
      >
        <Grid container alignContent={'space-between'}>
          <Grid item xs={12}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <p className='text-sm font-semibold'>{t().stockExportConfigurationSelectPlaceholder.singular.label}</p>
              </Grid>
              <Grid item xs={12} mb={1}>
                <Grid container maxHeight={'500px'} overflow={'auto'}>
                  {[...productExportConfigurations.values()].map(config => (
                    <Grid key={config.id} item>
                      <ProductExportConfigurationItem
                        onClick={() => {
                          selected.has(config.id) ? selected.delete(config.id) : selected.add(config.id);
                          setSelected(new Set(selected));
                        }}
                        configuration={config}
                        key={config.id}
                        ids={selected}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
