import React, { useContext } from 'react';
import { CustomField, CustomFieldType, WithCustomFields } from '../../../types/customField';
import TextInput from './TextInput';
import { Divider, Grid } from '@mui/material';
import Checkbox from '../../../VentoryUI/components/common/Checkbox/Checkbox';
import DatePicker from './DatePicker';
import DropdownSelect from './DropdownSelect';
import { t } from '../../../types/translation/Translator';
import { TranslationPluralType } from '../../../types/translation/common';
import dayjs from 'dayjs';
import { FileContext } from '../../../context/FileContext';
import FileInput from './FileInput';
import { FileEntityType } from '../../../types/file';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface CustomFieldInputProps {
  item: CustomField | undefined;
  value: any;
  entity: WithCustomFields;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  disabled?: boolean;
  pmdId?: string;
}

export default function CustomFieldInput({
  value,
  item,
  entity,
  onChange,
  disabled = false,
  pmdId,
}: CustomFieldInputProps) {
  if (!item) return null;

  const { files } = useContext(FileContext);

  const key = item.name.charAt(0).toLowerCase().concat(item.name.substring(1).replaceAll(' ', ''));
  const label = ((t() as any)[key] as TranslationPluralType)?.singular.label;

  const handleChange = (item: CustomField, value: any) => {
    switch (item.type) {
      case CustomFieldType.text:
        return onChange(entity.withCustomField({ ...item, value: String(value) }));
      case CustomFieldType.bool:
        return onChange(entity.withCustomField({ ...item, value: String(value) }));
      case CustomFieldType.date:
        return onChange(entity.withCustomField({ ...item, value: new Date(value).toISOString() }));
      case CustomFieldType.listOfValues:
        if (value === null) {
          return onChange(entity.removeCustomField(item.id));
        } else {
          return onChange(entity.withCustomField({ ...item, value: String(value) }));
        }
      case CustomFieldType.file:
        const current = entity.customFields.get(item.id)?.value;
        if (current) {
          return onChange(entity.withCustomField({ ...item, value: `${current};;${value}` }));
        } else {
          return onChange(entity.withCustomField({ ...item, value: String(value) }));
        }
    }
  };

  switch (item.type) {
    case CustomFieldType.text:
      return (
        <TextInput
          dynamicUpdate
          disabled={disabled}
          label={label || item.name}
          placeholder={label || item.name}
          mandatory={item.mandatory}
          value={value}
          onChange={v => handleChange(item, v)}
        />
      );
    case CustomFieldType.bool:
      return (
        <Grid container mt={1}>
          <Grid item xs={12} marginY={'auto'}>
            <Checkbox
              disabled={disabled}
              label={label || item.name}
              value={value === 'true' || false}
              onChange={v => handleChange(item, v)}
            />
          </Grid>
        </Grid>
      );
    case CustomFieldType.date:
      return (
        <DatePicker
          dynamicUpdate
          disabled={disabled}
          label={label || item.name}
          placeholder={label || item.name}
          mandatory={item.mandatory}
          value={dayjs(value) <= dayjs(0) ? undefined : new Date(value)}
          onChange={(val?: Date | undefined) => handleChange(item, val)}
        />
      );
    case CustomFieldType.listOfValues:
      return (
        <DropdownSelect
          mandatory={item.mandatory}
          label={label || item.name}
          placeholder={label || item.name}
          values={item.values}
          selectedValue={value || null}
          toText={item => item || ''}
          onChange={v => handleChange(item, v)}
          disabled={disabled}
        />
      );
    case CustomFieldType.file:
      const cfValue = entity.customFields.get(item.id)?.value;
      const attached = new Set(cfValue?.split(';;'));
      if (disabled) {
        if (!attached?.size) return null;
        return (
          <>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <p className='mt-1 text-sm font-medium text-slate-800'>{item.name}</p>
            {[...attached.values()]
              .map(id => files.get(id))
              .map(file => (
                <FileInput file={file} />
              ))}
          </>
        );
      }

      const items = [...files.values()].filter(
        file => file.entityType === FileEntityType.pmd && file.entityId === pmdId,
      );

      return (
        <>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <DropdownSelect
            mandatory={item.mandatory}
            label={label || item.name}
            placeholder={label || item.name}
            selectOnly
            values={items.filter(file => !attached.has(file.id))}
            selectedValue={null}
            toText={item => item.name || ''}
            onChange={v => (v ? handleChange(item, v.id) : null)}
            disabled={disabled}
          />
          <div className='py-1'></div>
          {cfValue && attached?.size
            ? [...attached.values()]
                .map(id => files.get(id))
                .map(file => (
                  <Grid container>
                    <Grid
                      item
                      alignContent={'center'}
                      className='cursor-pointer hover:text-red-500'
                      onClick={() => {
                        const current = entity.customFields.get(item.id);
                        if (current) {
                          const items = new Set(current.value.split(';;'));
                          items.delete(file?.id || '');
                          return onChange(entity.withCustomField({ ...item, value: [...items.values()].join(';;') }));
                        }
                      }}
                    >
                      <CloseOutlinedIcon />
                    </Grid>
                    <Grid item flexGrow={1}>
                      <FileInput file={file} icon={false} />
                    </Grid>
                  </Grid>
                ))
            : null}
        </>
      );
  }
}
