import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { BinContext } from '../../../../context/BinContext';
import { useNavigate } from 'react-router-dom';
import { Bin } from '../../../../types/bin';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { BinStatusContext } from '../../../../context/BinStatusContext';
import BinStatusLabel from '../../Common/BinStatusLabel';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter';
import FlexPane from '../../../../VentoryUI/components/common/FlexPane';
import Table from '../../../../VentoryUI/components/common/Table';
import { BinFilter } from '../../../../VentoryUI/components/filters/BinFilter';
import { TagRelationContext } from '../../../../context/TagRelationContext';

interface BinOverviewProps {}

export default function BinOverviewPane({}: BinOverviewProps) {
  const navigate = useNavigate();

  const { bins, binsLoading } = useContext(BinContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { tagRelations } = useContext(TagRelationContext);

  const [items, setItems] = useState<Bin[]>([...bins.values()]);

  const headers = [
    {
      key: 'binName',
      name: t().name.singular.label,
      text: (item: Bin) => bins.get(item.id)?.name || '',
    },
    {
      key: 'stockLocation',
      name: t().stockLocation.singular.label,
      text: (item: Bin) => stockLocations.get(item.stockLocationId)?.name || '',
    },
    {
      key: 'status',
      name: t().status.singular.label,
      text: (item: Bin) => <BinStatusLabel status={binStatuses.get(item.binStatusId)} />,
      sortValue: (item: Bin) => binStatuses.get(item.binStatusId),
    },
  ];

  const allItems = useMemo(() => {
    return [...bins.values()];
  }, [bins]);

  return (
    <FlexPane
      header={
        <SearchBarWithFilter
          loading={binsLoading}
          items={allItems}
          setItems={setItems}
          placeholder={t().filterBins.singular.label}
          filter={new BinFilter(binStatuses, tagRelations)}
        />
      }
      content={
        <Table
          loading={binsLoading}
          items={items}
          totalItemCount={allItems.length}
          headers={headers}
          onClick={item => navigate(`/stock/bins/${item.id}/info`)}
        />
      }
    />
  );
}
