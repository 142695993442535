import { Grid } from '@mui/material';
import React, { useState } from 'react';
import {
  CustomField,
  customFieldEntityParentTypeToLocalizedString,
  CustomFieldEntityType,
  CustomFieldType,
  customFieldEntitySubtypeToString,
  customFieldEntityTypeToString,
  customFieldTypeToString,
  customFieldEntityChildTypeToString,
} from '../../../../../types/customField';
import Dropdown from '../../../Common/Dropdown';
import { t } from '../../../../../types/translation/Translator';
import TextInput from '../../../Common/TextInput';
import Checkbox from '../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import DatePicker from '../../../Common/DatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import DropdownSelect from '../../../Common/DropdownSelect';
import dayjs from 'dayjs';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import Button from '../../../../../VentoryUI/components/common/Button';
import { AddIcon } from '../../../../../VentoryUI/icons/Add/AddIcon';
import Table from '../../../../../VentoryUI/components/common/Table';
import { FlexPane } from '../../../../../VentoryUI/components/common/FlexPane/FlexPane';

export interface CustomFieldItemProps {
  setError: (error: string) => void;
  customField: CustomField;
  footer: (field: CustomField) => JSX.Element;
}

export default function CustomFieldItem({ customField, footer, setError }: CustomFieldItemProps) {
  const [input, setInput] = useState<CustomField>(new CustomField(customField));
  const [newValue, setNewValue] = useState<string>('');
  const [customError, setCustomError] = useState<string>('');

  const handleAddValue = () => {
    if (!newValue) return;
    try {
      input.addValue(newValue);
      setNewValue('');
      setCustomError('');
    } catch (e) {
      setCustomError(String(e));
    }
  };

  const defaultValueInput = () => {
    switch (input.type) {
      case CustomFieldType.text:
        return (
          <TextInput
            value={input.defaultValue}
            label={t().defaultValue.singular.label}
            placeholder={t().defaultValue.singular.label}
            onChange={value => input.withDefaultValue(value)}
            disabled={!input.entityType || !input.type}
          />
        );
      case CustomFieldType.bool:
        return (
          <Grid item xs={12} mt={2}>
            <Checkbox
              label={t().defaultValue.singular.label}
              value={input.defaultValue === 'true' ? true : false}
              onChange={value => input.withDefaultValue(value ? 'true' : 'false')}
              disabled={!input.entityType || !input.type}
            />
          </Grid>
        );
      case CustomFieldType.date:
        const value = input.defaultValue || '';
        const date = dayjs(value).isValid() ? dayjs(value).toDate() : undefined;

        return (
          <DatePicker
            label={t().defaultValue.singular.label}
            placeholder={t().defaultValue.singular.label}
            value={date}
            onChange={date => input.withDefaultValue(dayjs(date).format('MM/DD/YYYY'))}
            disabled={!input.entityType || !input.type}
          />
        );
      case CustomFieldType.listOfValues:
        return (
          <DropdownSelect
            disabled={!input.values.length || !input.entityType || !input.type}
            values={input.values}
            label={t().defaultValue.singular.label}
            placeholder={t().defaultValue.singular.label}
            selectedValue={input.defaultValue || null}
            toText={(item?: string) => item || ''}
            onChange={item => setInput(input.withDefaultValue(item || undefined))}
          />
        );
    }
  };

  return (
    <ModalPane footer={footer(input)}>
      <Grid container spacing={1} className='h-full'>
        <div className='flex flex-col h-full'>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Dropdown
                mandatory
                maxHeight='200px'
                values={[...Object.keys(CustomFieldEntityType)] as CustomFieldEntityType[]}
                label={t().entityType.singular.label}
                selectedValue={input.entityType}
                toText={cf => customFieldEntityTypeToString(cf)}
                onChange={cf => setInput(input.withEntityType(cf))}
                placeholder={t().entityType.singular.label}
              />
            </Grid>
            <Grid item xs={6}>
              <DropdownSelect
                mandatory={!!input.validEntitySubtypes().length}
                disabled={!input.validEntitySubtypes().length}
                maxHeight='200px'
                values={input.validEntitySubtypes()}
                label={t().entitySubtype.singular.label}
                selectedValue={input.entitySubtype || null}
                toText={cf => customFieldEntitySubtypeToString(cf)}
                onChange={cf => setInput(input.withEntitySubtype(cf))}
                placeholder={t().entitySubtype.singular.label}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label={t().name.singular.label}
                placeholder={t().name.singular.label}
                onChange={name => input.withName(name)}
                mandatory
                value={input.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                dynamicUpdate
                value={input.index.toString()}
                type={'number'}
                label={t().priority.singular.label}
                placeholder={t().priority.singular.label}
                onChange={name => input.withIndex(parseFloat(name))}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Dropdown
                    mandatory
                    maxHeight='200px'
                    values={input.validTypes()}
                    label={t().type.singular.label}
                    selectedValue={input.type}
                    toText={cf => customFieldTypeToString(cf)}
                    onChange={cf => setInput(input.withType(cf))}
                    placeholder={t().type.singular.label}
                    disabled={!input.entityType}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} marginY={'auto'}>
              <Grid container>
                <Grid item xs={12}>
                  {defaultValueInput()}
                </Grid>
              </Grid>
            </Grid>
            {input.validEntityParentTypes().length ? (
              <Grid item xs={6}>
                <Dropdown
                  mandatory
                  label={t().entityParentType.singular.label}
                  toText={value => customFieldEntityParentTypeToLocalizedString(value)}
                  values={input.validEntityParentTypes()}
                  selectedValue={input.entityParentType}
                  onChange={value => setInput(input.withEntityParentType(value))}
                />
              </Grid>
            ) : null}
            {input.validEntityChildTypes().length ? (
              <Grid item xs={6}>
                <DropdownSelect
                  disabled={false}
                  mandatory
                  label={t().entityChildType.singular.label}
                  placeholder={t().entityChildType.singular.label}
                  toText={value => customFieldEntityChildTypeToString(value)}
                  values={input.validEntityChildTypes()}
                  selectedValue={input.entityChildType}
                  onChange={value => setInput(input.withEntityChildType(value || undefined))}
                />
              </Grid>
            ) : null}
            {input.type !== CustomFieldType.bool ? (
              <Grid item xs={12} marginY={'auto'} mb={1}>
                <Grid container>
                  <Grid item xs={12} mt={1}>
                    <Checkbox
                      label={t().mandatory.singular.label}
                      value={input.mandatory}
                      onChange={mandatory => input.withMandatory(mandatory)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          {input.type === CustomFieldType.listOfValues ? (
            <FlexPane
              header={
                <Grid container columnSpacing={1} rowSpacing={1}>
                  {customError ? (
                    <Grid textAlign={'center'} item xs={12} marginX={'auto'}>
                      <p className='border-red-400 border-2 rounded-sm bg-red-100 py-1 text-red-500 text-sm'>
                        {customError}
                      </p>
                    </Grid>
                  ) : null}
                  <Grid item flexGrow={1} marginY={'auto'}>
                    <TextInput
                      dynamicUpdate
                      placeholder={t().valueName.singular.upper}
                      onChange={value => setNewValue(value)}
                      value={newValue}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
                      <Grid item mt={0.5}>
                        <Button startIcon={<AddIcon />} onClick={handleAddValue} text={t().add.singular.label} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
              content={
                <Table
                  modal
                  items={input.values}
                  headers={[
                    {
                      key: 'name',
                      name: 'Name',
                      text: (item: string) => item,
                    },
                    {
                      key: 'remove',
                      name: '',
                      text: (item: string) => (
                        <Grid container justifyContent={'flex-end'}>
                          <Grid item className='text-red-500'>
                            <DeleteIcon onClick={() => setInput(input.removeValue(item))} />
                          </Grid>
                        </Grid>
                      ),
                    },
                  ]}
                />
              }
            />
          ) : null}
        </div>
      </Grid>
    </ModalPane>
  );
}
