import React from 'react';
import dayjs from 'dayjs';
import { Alert } from '../../../../types/alert';
import { BinStatus } from '../../../../types/binStatus';
import { ProductMasterData } from '../../../../types/productMasterData';
import { t } from '../../../../types/translation/Translator';
import { TriggerEntityType } from '../../../../types/trigger';
import { toFilterString } from '../../../../util/string.util';
import { BaseFilter } from '../common/BaseFilter';
import { DateTimeFilter } from '../DateTimeFilter';
import { FilterStorageKey, FilterProps } from '../common/filter.util';
import { LotFilter } from '../LotFilter';
import { ProductMasterDataFilter } from '../ProductMasterDataFilter';
import { StockLocationFilter } from '../StockLocationFilter';
import { TagRelation } from '../../../../types/tagRelation';
import { TagFilter } from '../TagFilter/TagFilter';

export class AlertFilter extends BaseFilter<Alert> {
  productMasterData: Map<string, ProductMasterData>;
  binStatuses: Map<string, BinStatus>;
  tagRelations: Map<string, TagRelation>;

  constructor(
    productMasterData: Map<string, ProductMasterData>,
    binStatuses: Map<string, BinStatus>,
    tagRelations: Map<string, TagRelation>,
  ) {
    super(FilterStorageKey.ALERT);
    this.binStatuses = binStatuses;
    this.productMasterData = productMasterData;
    this.tagRelations = tagRelations;
  }

  toLabel(): string {
    return t().alert.singular.label;
  }

  filterOptions = () => [
    new DateTimeFilter(),
    new StockLocationFilter(this.tagRelations),
    new ProductMasterDataFilter(),
    new LotFilter(),
    new TagFilter(),
  ];

  toCount(): number {
    return 0;
  }

  toIcon(): JSX.Element {
    return <></>;
  }

  toDropdownContent(): JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  search(item: Alert, textFilter: string) {
    if (toFilterString(item.alertString).includes(textFilter)) {
      return true;
    }

    return false;
  }

  filteredItems(items: Alert[], query: string, filterProps: FilterProps) {
    const result = items.filter(item => {
      if (filterProps.stockLocation?.size && !item.stockLocationIds.some(sl => filterProps.stockLocation?.has(sl)))
        return false;
      if (
        filterProps.timeFrame &&
        (dayjs(filterProps.timeFrame.start).startOf('day').isAfter(dayjs(item.createdAt)) ||
          dayjs(filterProps.timeFrame.end).endOf('day').isBefore(dayjs(item.createdAt)))
      ) {
        return false;
      }

      if (filterProps.product?.size) {
        if (item.entityType !== TriggerEntityType.pmd) return false;
        return filterProps.product.has(item.triggeredValue);
      }

      if (filterProps.lot?.size) {
        if (item.entityType !== TriggerEntityType.lot) return false;
        return filterProps.lot.has(item.triggeredValue);
      }

      return this.search(item, query);
    });

    return result;
  }
}
