const accessTokenKey = process.env.REACT_APP_ACCESS_TOKEN_STORE || '';
const refreshTokenKey = process.env.REACT_APP_REFRESH_TOKEN_STORE || '';

const currentCompanyIdKey = 'currentCompanyId';

if (!accessTokenKey || !refreshTokenKey) {
  throw new Error('Invalid refresh or access token keys (environment variable missing)');
}

export function getAccessToken(): string {
  return localStorage.getItem(accessTokenKey) || sessionStorage.getItem(accessTokenKey) || '';
}

export function storeAccessToken(token: string) {
  localStorage.setItem(accessTokenKey, token);
  return sessionStorage.setItem(accessTokenKey, token);
}

export function getRefreshToken(): string {
  return localStorage.getItem(refreshTokenKey) || sessionStorage.getItem(refreshTokenKey) || '';
}

export function storeRefreshToken(token: string) {
  localStorage.setItem(refreshTokenKey, token);
  return sessionStorage.setItem(refreshTokenKey, token);
}

export function getCurrentCompanyId(): string | null {
  return localStorage.getItem(currentCompanyIdKey);
}

export function storeCurrentCompanyId(id: string) {
  return localStorage.setItem(currentCompanyIdKey, id);
}

export function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
}

export function setCookie(name: string, value: string) {
  document.cookie = `${name}=${value};`;
}
