import React from 'react';
import {
  BarcodeFormat,
  ScanConfiguration,
  ScanConfigurationType,
  barcodeFormatToString,
  scanConfigurationTypeToString,
} from '../../../../../../../types/company';
import { Grid } from '@mui/material';
import Dropdown from '../../../../../Common/Dropdown';
import { t } from '../../../../../../../types/translation/Translator';
import TextInput from '../../../../../Common/TextInput';
import { cloneDeep } from '@apollo/client/utilities';
import ModalPane from '../../../../../../../VentoryUI/components/common/Modal/components/ModalPane';

interface BarcodeConfigurationInfoPaneProps {
  configuration: ScanConfiguration;
  setConfiguration: (configuration: ScanConfiguration) => void;
  footer: (input: ScanConfiguration) => JSX.Element;
}

export default function BarcodeConfigurationInfoPane({
  configuration,
  setConfiguration,
  footer,
}: BarcodeConfigurationInfoPaneProps) {
  return (
    <ModalPane footer={footer(configuration)}>
      <Grid container height={'100%'} columnSpacing={2} alignContent={'space-between'}>
        <Grid item xs={6}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Dropdown
                label={t().barcodeConfigurationType.singular.label}
                selectedValue={configuration.type}
                values={[...Object.keys(ScanConfigurationType)]}
                toText={item => scanConfigurationTypeToString(item as ScanConfigurationType)}
                onChange={item => {
                  configuration.type = item as ScanConfigurationType;
                  setConfiguration(cloneDeep(configuration));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                dynamicUpdate
                mandatory
                onChange={item => {
                  configuration.regex = item;
                  setConfiguration(cloneDeep(configuration));
                }}
                value={configuration.regex}
                label={t().regexInput.singular.label}
                placeholder={t().regexInput.singular.label}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Dropdown
                maxHeight='200px'
                label={t().barcodeFormat.singular.label}
                selectedValue={configuration.barcodeFormat}
                values={[...Object.keys(BarcodeFormat)].filter(
                  item => item !== BarcodeFormat.all && item !== BarcodeFormat.unknown,
                )}
                toText={item => barcodeFormatToString(item as BarcodeFormat)}
                onChange={item => {
                  configuration.barcodeFormat = item as BarcodeFormat;
                  setConfiguration(cloneDeep(configuration));
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
