import React, { useContext, useEffect, useState } from 'react';
import { FilterProps } from '../../common/filter.util';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { ProductMasterDataContext } from '../../../../../context/ProductMasterDataContext';
import ScrollableFilterContent from '../../common/DropdownContent/ScrollableFilterContent';
import { ProductMasterDataFilter } from '../ProductMasterDataFilter';

interface ProductMasterDataFilterDropdownContentProps {
  filter: ProductMasterDataFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export default function ProductMasterDataFilterDropdownContent({
  filter,
  filterProps,
  setFilterProps,
}: ProductMasterDataFilterDropdownContentProps) {
  const { productMasterData } = useContext(ProductMasterDataContext);

  const [query, setQuery] = useState<string>('');

  const filterProducts = () => {
    return new Set(filter.filteredItems([...productMasterData.values()], query, filterProps).map(pmd => pmd.id));
  };

  const [values, setValues] = useState<Set<string>>(filterProducts());

  const handleChange = (selected: Set<string>) => {
    filterProps.product = selected;
    if (!filterProps.product?.size) filterProps.product = undefined;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.product = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterProducts());
  }, [query]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().product.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setQuery(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            selected={filterProps.product}
            values={values}
            onChange={handleChange}
            toText={id => productMasterData.get(id)?.productName}
            toSubText={id => productMasterData.get(id)?.productNumber}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}
