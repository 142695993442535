import React from 'react';
import { useContext } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { UserContext } from '../../../../../context/UserContext';
import InnerFilterContent, { FilterInnerContentProps } from '../../common/DropdownContent/DynamicFilterInnerContent';
import { User } from '../../../../../types/user';

export default function UserFilterInnerContent({ filter, filterProps, setFilterProps }: FilterInnerContentProps<User>) {
  const { companyUsers } = useContext(UserContext);

  return (
    <InnerFilterContent
      filter={filter}
      filterProps={{ filterProps, setFilterProps }}
      items={[...(filterProps.user || [])]}
      text={item => {
        const user = companyUsers.get(item);
        if (!user) return t().unknownUser.singular.label;
        return `${user.firstName} ${user.lastName}`;
      }}
      onRemove={i => {
        filterProps.user?.delete(i);
        if (!filterProps.user?.size) filterProps.user = undefined;
        setFilterProps({ ...filterProps });
      }}
    />
  );
}
