import { cloneDeep } from '@apollo/client/utilities';
import { StockLocationEntity } from '../common/entity';
import { ProductTransactionSpecifiers } from '../productTransaction';
import { ShippingLocation } from '../contact';
import { WithCustomFields } from '../customField';
import { applyMixins } from '../../util/mixins';
import { StockLocationRole } from '../stockLocationRoleAssignment';
import { EntityAuthorization } from '../common/entityAuthorization';

export enum OrderType {
  inbound = 'inbound',
  outbound = 'outbound',
  move = 'move',
  replenish = 'replenish',
}

export enum OrderStatus {
  open = 'open',

  created = 'created',
  inProgress = 'inProgress',
  complete = 'complete',

  shipped = 'shipped',
  arrived = 'arrived',

  cancelled = 'cancelled',
  failed = 'failed',

  disabled = 'disabled',
  released = 'released',

  pickingComplete = 'pickingComplete',
}

export enum OrderParentType {
  order = 'order',
  task = 'task',
}

export class Order extends StockLocationEntity {
  public stockLocationId!: string;
  public contactId?: string = undefined;
  public contactLocation?: ShippingLocation = undefined;
  public assignedTo: string[] = [];
  public type: OrderType = OrderType.inbound;
  public status: OrderStatus = OrderStatus.created;
  public products: Map<string, ProductTransactionSpecifiers> = new Map();
  public externalReferenceId?: string = undefined;
  public parentType?: OrderParentType;
  public parentId?: string;
  public purchaseOrderNumber?: string;
  public estimatedTimeOfArrival?: Date;
  public number?: string;

  authorization: OrderAuthorization = new OrderAuthorization();

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId, obj.stockLocationId);
    obj.customFields = WithCustomFields.toCustomFieldsMap(obj);
    Object.assign(this, cloneDeep(obj));
  }
}

export class OrderAuthorization extends EntityAuthorization<Order> {
  forCreate = () => StockLocationRole.STOCK_LOCATION_SUPERVISOR;
  forUpdate = () => StockLocationRole.STOCK_LOCATION_SUPERVISOR;
  forDelete = () => StockLocationRole.STOCK_LOCATION_SUPERVISOR;
  forGet = () => StockLocationRole.STOCK_LOCATION_VIEWER;

  forProcess = () => StockLocationRole.STOCK_LOCATION_USER;
}

export interface Order extends WithCustomFields {}
applyMixins(Order, [WithCustomFields]);
