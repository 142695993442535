import React, { useContext, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { CsvUploadConfiguration, CsvUploadType } from '../../../../../types/csvUploadConfiguration';
import {
  CsvUploadConfigurationQueries,
  GetCsvUploadConfigurationForFileResponse,
  GetCsvUploadConfigurationForFileVariables,
} from '../../../../../graphql/csvUploadConfiguration';
import { useLazyQuery } from '@apollo/client';
import { Base64 } from 'js-base64';
import CreateCsvUploadConfigurationMapping from '../../StockLocation/Modals/CreateCsvUploadConfigurationMapping';
import { CsvUploadConfigurationSelect } from '../../StockLocation/Modals/CsvUploadConfigurationSelect';
import { Grid } from '@mui/material';
import UploadCsvInput from '../../../Common/UploadCsvInput';
import CsvUploadConfigurationInspect from '../../StockLocation/Modals/CsvUploadConfigurationInspect';
import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import Button from '../../../../../VentoryUI/components/common/Button';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';

enum UserImportStatus {
  fileUpload,
  selectConfig,
  createConfig,
  inspect,
}

interface UserImportModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function UserImportModal({ open, setOpen }: UserImportModalInputProps) {
  const { currentCompany } = useContext(CompanyContext);

  const [file, setFile] = useState<File | null>(null);
  const [status, setStatus] = useState<UserImportStatus>(UserImportStatus.fileUpload);

  const [configurations, setConfigurations] = useState<CsvUploadConfiguration[]>([]);
  const [otherConfigurations, setOtherConfigurations] = useState<CsvUploadConfiguration[]>([]);
  const [fileHeaders, setFileHeaders] = useState<string[]>([]);
  const [requiredHeaders, setRequiredHeaders] = useState<string[]>([]);
  const [optionalHeaders, setOptionalHeaders] = useState<string[]>([]);
  const [fileAsBase64, setFileAsBase64] = useState<string | null>(null);
  const [deleteExisting, setDeleteExisting] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [inspectId, setInspectId] = useState<string>();

  const [getConfigurations, { loading: getConfigurationsLoading }] = useLazyQuery<
    GetCsvUploadConfigurationForFileResponse,
    GetCsvUploadConfigurationForFileVariables
  >(CsvUploadConfigurationQueries.getForFile, {
    onCompleted: res => {
      setConfigurations(res.csvConfigurationsForFile.configurations.map(c => new CsvUploadConfiguration(c)));
      setFileHeaders(res.csvConfigurationsForFile.fileHeaders);
      setRequiredHeaders(res.csvConfigurationsForFile.requiredHeaders);
      setOptionalHeaders(res.csvConfigurationsForFile.optionalHeaders);
      setOtherConfigurations(res.csvConfigurationsForFile.otherConfigurations);
      if (
        !res.csvConfigurationsForFile.configurations.length &&
        !res.csvConfigurationsForFile.otherConfigurations.length
      ) {
        setStatus(UserImportStatus.createConfig);
      } else {
        setStatus(UserImportStatus.selectConfig);
      }
    },
    onError: err => setError(err.message),
  });

  const handleFile = (files: FileList | null) => {
    if (!files) {
      setFile(null);
      return;
    }
    setFile(files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return;

    let asBase64 = Base64.encode(await file.text());

    await getConfigurations({
      variables: {
        companyId: currentCompany.id,
        type: CsvUploadType.user,
        csvFileAsBase64Data: asBase64,
      },
    });
    setFileAsBase64(asBase64);
  };

  const handleClose = () => {
    setFile(null);
    setStatus(UserImportStatus.fileUpload);
    setConfigurations([]);
    setOtherConfigurations([]);
    setFileHeaders([]);
    setRequiredHeaders([]);
    setOptionalHeaders([]);
    setFileAsBase64(null);
    setOpen(false);
    setDeleteExisting(false);
    setError('');
  };

  const handleBack = () => {
    setError('');
    switch (status) {
      case UserImportStatus.fileUpload:
        return;
      case UserImportStatus.selectConfig:
        return setStatus(UserImportStatus.fileUpload);
      case UserImportStatus.createConfig:
        return setStatus(UserImportStatus.fileUpload);
    }
  };

  const content = () => {
    if (status === UserImportStatus.createConfig && fileAsBase64) {
      return (
        <CreateCsvUploadConfigurationMapping
          onClose={handleClose}
          onBack={handleBack}
          type={CsvUploadType.user}
          required={requiredHeaders}
          optional={optionalHeaders}
          headers={fileHeaders}
          csvFileAsBase64Data={fileAsBase64}
          setError={setError}
        />
      );
    }

    if (status === UserImportStatus.selectConfig && fileAsBase64) {
      return (
        <CsvUploadConfigurationSelect
          otherConfigurations={otherConfigurations}
          deleteExisting={deleteExisting}
          onClose={handleClose}
          onCreate={() => setStatus(UserImportStatus.createConfig)}
          onBack={handleBack}
          csvFileAsBase64Data={fileAsBase64}
          type={CsvUploadType.user}
          configurations={configurations}
          setError={setError}
          onInspect={id => {
            setInspectId(id);
            setStatus(UserImportStatus.inspect);
          }}
        />
      );
    }

    if (status === UserImportStatus.inspect) {
      return (
        <CsvUploadConfigurationInspect
          headers={fileHeaders}
          configuration={otherConfigurations.find(c => c.id === inspectId)}
          onBack={handleBack}
        />
      );
    }

    return (
      <ModalPane
        footer={
          <Grid container>
            <Grid item>
              <Grid item>
                <a
                  style={{ textDecoration: 'none' }}
                  href={`${process.env.REACT_APP_STORAGE_URL}/ventory/user_template.csv`}
                  download='import_template.csv'
                >
                  <Button onClick={() => {}} text={t().downloadTemplate.singular.label} />
                </a>
              </Grid>
            </Grid>
            <Grid item flexGrow={1}>
              <Grid container columnSpacing={1} justifyContent={'flex-end'}>
                <Grid item>
                  <Button disabled={getConfigurationsLoading} onClick={handleClose} text={t().cancel.singular.label} />
                </Grid>
                <Grid item>
                  <Button
                    loading={getConfigurationsLoading}
                    disabled={!file}
                    style='secondary'
                    onClick={handleFileUpload}
                    text={t().next.singular.label}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      >
        <Grid container height={'100%'} alignContent={'space-between'}>
          <Grid item xs={12}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <UploadCsvInput file={file} onFile={handleFile} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    );
  };

  return (
    <Modal
      open={open}
      error={error}
      onClose={handleClose}
      width='70%'
      height='650px'
      title={t().importUsers.singular.label}
    >
      {content()}
    </Modal>
  );
}
