import { Grid } from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { classes } from '../../../util/identifiers/identifiers.util';
import { removeDiacritics, toFilterString } from '../../../util/string.util';

interface SearchBarProps {
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
}

export default function SearchBar({ onChange, placeholder = '', disabled = false, id }: SearchBarProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(removeDiacritics(toFilterString(event.currentTarget.value)));
  };

  return (
    <Grid
      container
      marginY={'auto'}
      key={id}
      className={`mt-1 pl-3 pr-1 py-2 h-10 disabled:opacity-50 rounded bg-white border shadow-sm border-slate-300 placeholder-slate-400 block sm:text-sm focus:ring-1`}
    >
      <Grid item flexGrow={1} my={'auto'}>
        <input
          placeholder={placeholder}
          disabled={disabled}
          type='text'
          onChange={handleChange}
          className={`${classes.searchBar.name} focus:outline-none w-full`}
          id={id}
        />
      </Grid>
      <Grid item textAlign={'end'} className='text-gray-500'>
        <SearchIcon />
      </Grid>
    </Grid>
  );
}
