import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import LoadingPackage from '../../../Common/LoadingPackage';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import Pane from '../../../../../VentoryUI/components/common/Pane/Pane';
import { ContainerContext } from '../../../../../context/ContainerContext';
import { ContainerTypeContext } from '../../../../../context/ContainerTypeContext';
import ContainerInfoPane from './ContainerInfoPane';
import ContainerConfigurationPane from './ContainerConfigurationPane';
import { SaveButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import { Container } from '../../../../../types/container';
import { useMutation } from '@apollo/client';
import {
  ContainerMutations,
  DeleteContainerResponse,
  DeleteContainerVariables,
  UpdateContainerResponse,
  UpdateContainerVariables,
} from '../../../../../graphql/container.graphql';
import { BackButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { CustomFieldContext } from '../../../../../context/CustomFieldContext';
import { CustomFieldEntityType } from '../../../../../types/customField';
import { DeleteButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/DeleteButton';
import { FooterButton } from '../../../../../VentoryUI/components/common/Button/Button';
import NotFound from '../../../../../assets/html/notFound';

const tabs = () => [
  {
    text: t().info.singular.label,
    path: 'info',
    key: 'info',
  },
  {
    text: t().configuration.singular.label,
    path: 'configuration',
    key: 'configuration',
  },
];

export default function ContainerDetailPane({}) {
  const navigate = useNavigate();

  const id = useParams()['id'] || '';

  const { containerTypesLoading } = useContext(ContainerTypeContext);
  const { containers, setContainers, containersLoading } = useContext(ContainerContext);
  const { customFields } = useContext(CustomFieldContext);

  const containerCustomFields = [...customFields.values()].filter(
    cf => cf.entityType === CustomFieldEntityType.container,
  );

  const [error, setError] = useState<string>('');

  const [update, { loading: updateLoading }] = useMutation<UpdateContainerResponse, UpdateContainerVariables>(
    ContainerMutations.update,
    {
      onCompleted: data => {
        data.updateContainer.forEach(element => containers.set(element.id, new Container(element)));
        setContainers(new Map(containers));
        navigate('/stock/containers');
      },
      onError: error => {
        setError(error.graphQLErrors[0].message);
      },
    },
  );

  const [remove, { loading: removeLoading }] = useMutation<DeleteContainerResponse, DeleteContainerVariables>(
    ContainerMutations.remove,
    {
      onCompleted: data => {
        data.deleteContainer.forEach(ct => containers.delete(ct.id));
        setContainers(new Map(containers));
        navigate('/stock/containers');
      },
      onError: error => {
        setError(error.graphQLErrors[0]?.message ?? error.toString());
      },
    },
  );

  const loading = updateLoading || removeLoading;

  const footerButtons = (container: Container, tab: 'info' | 'configuration'): FooterButton[] => {
    const buttons: FooterButton[] = [
      {
        ...DeleteButtonTemplate(() => remove({ variables: { containers: [container.forDelete()] } })),
        align: 'left',
        loading: removeLoading,
        disabled: updateLoading,
      },
      { ...BackButtonTemplate(() => navigate('/stock/containers')), disabled: loading },
    ];

    if (tab === 'configuration') {
      buttons.push({
        ...SaveButtonTemplate(() => update({ variables: { containers: [container.forUpdate()] } })),
        disabled: container.validate([...customFields.values()]).length > 0 || removeLoading,
      });
    }

    return buttons;
  };

  const container = containers.get(id);

  return (
    <Pane error={error} tabs={tabs()} testId={testIds.productDetailPane}>
      {(containersLoading || containerTypesLoading) && !container ? (
        <Grid container alignItems={'center'}>
          <Grid item className='fill-gray-300 ' marginX={'auto'}>
            <LoadingPackage />
          </Grid>
        </Grid>
      ) : !container ? (
        <Grid container>
          <NotFound />
        </Grid>
      ) : (
        <Routes>
          <Route
            path='info'
            element={<ContainerInfoPane container={container} footerButtons={c => footerButtons(c, 'info')} />}
          />
          <Route
            path='configuration'
            element={
              <ContainerConfigurationPane
                title={t().updateContainer.singular.label}
                container={container}
                footerButtons={c => footerButtons(c, 'configuration')}
              />
            }
          />
        </Routes>
      )}
    </Pane>
  );
}
