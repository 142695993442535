import React, { JSX } from 'react';
import { toFilterString } from '../../../../util/string.util';
import { FileTypeCategory } from '../../../../types/file';
import { t } from '../../../../types/translation/Translator';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import { FilterProps } from '../common/filter.util';
import { FileTypeCategoryFilterContent, FileTypeCategoryFilterInnerContent } from './DropdownContent';

export class FileTypeCategoryFilter extends BaseFilter<FileTypeCategory> {
  toLabel(): string {
    return t().fileTypeCategory.singular.label;
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.fileTypeCategory?.size || 0;
  }

  toIcon(): JSX.Element {
    return <FilePresentOutlinedIcon className={this.className + ' stroke-none'} />;
  }

  toDropdownContent(props: BaseFilterProps): JSX.Element {
    return (
      <FileTypeCategoryFilterContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <FileTypeCategoryFilterInnerContent
        filter={this}
        filterProps={props.filterProps}
        setFilterProps={props.setFilterProps}
      />
    );
  }

  search(item: FileTypeCategory, textFilter: string) {
    if (toFilterString(item).includes(textFilter)) {
      return true;
    }

    return false;
  }

  filteredItems(items: FileTypeCategory[], query: string, filterProps: FilterProps) {
    return items.filter(item => {
      return this.search(item, query);
    });
  }
}
