import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { ContainerContext } from '../../../../context/ContainerContext';
import { useNavigate } from 'react-router-dom';
import { Container } from '../../../../types/container';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { removeDiacritics, toFilterString } from '../../../../util/string.util';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Table, { TableHeader } from '../../../../VentoryUI/components/common/Table/Table';
import { CreateButtonTemplate } from '../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import { ContainerTypeContext } from '../../../../context/ContainerTypeContext';
import { BinContext } from '../../../../context/BinContext';
import SearchBarWithFilter from '../../../../VentoryUI/components/common/SearchBarWithFilter/SearchBarWithFilter';
import { ContainerFilter } from '../../../../VentoryUI/components/filters/ContainerFilter/ContainerFilter';
import { BinStatusContext } from '../../../../context/BinStatusContext';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { TagRelationContext } from '../../../../context/TagRelationContext';

interface ContainerOverviewProps {}

export default function ContainerOverviewPane({}: ContainerOverviewProps) {
  const navigate = useNavigate();

  const { containerTypes, containerTypesLoading } = useContext(ContainerTypeContext);
  const { containers, containersLoading } = useContext(ContainerContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { binStatuses } = useContext(BinStatusContext);
  const { bins } = useContext(BinContext);
  const { tagRelations } = useContext(TagRelationContext);

  const allItems = useMemo(() => {
    return [...containers.values()];
  }, [containers]);

  const [items, setItems] = useState<Container[]>(allItems);

  const handleFilter = (item: Container, filter: string) => {
    if (
      removeDiacritics(toFilterString(stockLocations.get(item.stockLocationId)?.name)).includes(filter) ||
      removeDiacritics(toFilterString(item.identifier)).includes(filter)
    ) {
      return true;
    }
    return false;
  };

  const headers: TableHeader<Container>[] = [
    {
      key: 'identifier',
      name: t().identifier.singular.label,
      text: (item: Container) => containers.get(item.id)?.identifier || '',
    },

    {
      key: 'stockLocation',
      name: t().stockLocation.singular.label,
      text: (item: Container) => stockLocations.get(item.stockLocationId)?.name || '',
    },
    {
      key: 'bin',
      name: t().bin.singular.label,
      text: (item: Container) => bins.get(item.binId)?.name || '',
    },
    {
      key: 'parentContainer',
      name: t().parentContainer.singular.label,
      text: (item: Container) => containers.get(item.parentId || '')?.identifier || '',
    },
    {
      key: 'type',
      name: t().containerType.singular.label,
      text: (item: Container) => containerTypes.get(item.containerTypeId)?.name || '',
    },
  ];

  return (
    <FlexPane
      header={
        <SearchBarWithFilter
          loading={containersLoading || containerTypesLoading}
          items={allItems}
          setItems={setItems}
          placeholder={t().filterContainer.plural.label}
          testId={testIds.containers}
          buttons={[CreateButtonTemplate(() => navigate('/stock/containers/create'))]}
          filter={new ContainerFilter(stockLocations, bins, containerTypes, binStatuses, tagRelations)}
        />
      }
      content={
        <Table
          loading={containersLoading || containerTypesLoading}
          items={items}
          totalItemCount={allItems.length}
          headers={headers}
          onClick={item => navigate(`/stock/containers/${item.id}/info`)}
        />
      }
    />
  );
}
