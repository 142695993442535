import { cloneDeep } from '@apollo/client/utilities';
import { applyMixins } from '../util/mixins';
import { forCreate, forDelete, forUpdate, StockLocationEntity } from './common/entity';
import { CustomField, CustomFieldEntityType, WithCustomFields } from './customField';

enum ContainerValidationError {
  missingIdentifier,
  missingBin,
  missingStockLocation,
  missingContainerType,
  missingRequiredCustomField,
}

export class Container extends StockLocationEntity {
  static none(companyId: string, stockLocationId: string, binId: string) {
    return new Container({ id: 'NONE', companyId, stockLocationId, binId, identifier: 'None' });
  }

  identifier!: string;
  binId!: string;
  containerTypeId!: string;
  parentId?: string;
  labels: string[] = [];
  deleteOnOutbound: boolean = false;

  constructor(obj: any) {
    if (!obj.companyId || !obj.stockLocationId) return;
    super(obj.companyId, obj.stockLocationId);
    obj.customFields = WithCustomFields.toCustomFieldsMap(obj);
    Object.assign(this, cloneDeep(obj));
  }

  forUpdate(): UpdateContainerInput {
    return UpdateContainerInput.from({ ...this, customFields: this.customFieldValues() }, UpdateContainerInput);
  }

  forDelete(): DeleteContainerInput {
    return DeleteContainerInput.from(this, DeleteContainerInput);
  }

  validate(customFields: CustomField[]): ContainerValidationError[] {
    const errors: ContainerValidationError[] = [];

    if (!this.identifier) errors.push(ContainerValidationError.missingIdentifier);
    if (!this.containerTypeId) errors.push(ContainerValidationError.missingContainerType);
    if (this.stockLocationId && !this.binId) errors.push(ContainerValidationError.missingBin);

    const containerCustomFields = customFields.filter(cf => cf.entityType === CustomFieldEntityType.container);
    const customFieldValueMap = this.customFields;
    for (const customField of containerCustomFields) {
      if (customField.mandatory && !customFieldValueMap.get(customField.id)?.value) {
        errors.push(ContainerValidationError.missingRequiredCustomField);
        break;
      }
    }

    return errors;
  }

  withStockLocationId(stockLocationId?: string | undefined): this {
    this.binId = '';
    this.parentId = undefined;
    return super.withStockLocationId(stockLocationId);
  }

  withContainerTypeId(containerTypeId: string) {
    this.containerTypeId = containerTypeId;
    return cloneDeep(this);
  }

  withIdentifier(identifier: string) {
    this.identifier = identifier;
    return cloneDeep(this);
  }

  withParentId(parentId: string | undefined) {
    this.parentId = parentId;
    return cloneDeep(this);
  }

  withBinId(binId: string) {
    this.binId = binId;
    this.parentId = undefined;
    return cloneDeep(this);
  }

  withDeleteOnOutbound(deleteOnOutbound: boolean) {
    this.deleteOnOutbound = deleteOnOutbound;
    return cloneDeep(this);
  }
}

export interface Container extends WithCustomFields {}
applyMixins(Container, [Container, WithCustomFields]);

export class CreateContainerInput extends forCreate(Container) {}

export class UpdateContainerInput extends forUpdate(Container) {}

export class DeleteContainerInput extends forDelete(Container) {}
