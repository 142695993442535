import { useContext, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import React from 'react';
import { Trigger, TriggerType, triggerTypeToString } from '../../../../../types/trigger';
import { useMutation } from '@apollo/client';
import {
  TriggerMutations,
  UpdateTriggerResponse,
  UpdateTriggerVariables,
} from '../../../../../graphql/trigger.graphql';
import { TriggerContext } from '../../../../../context/TriggerContext';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import Selector from '../../../Common/Selector';
import { Grid } from '@mui/material';
import Checkbox from '../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { BackButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { UpdateButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/UpdateButton';

interface UpdateTriggerModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  trigger: Trigger | null;
  onClose: () => void;
}

export default function UpdateTriggerModal({ open, setOpen, trigger, onClose }: UpdateTriggerModalProps) {
  if (!trigger) return null;

  const { triggers, setTriggers } = useContext(TriggerContext);

  const [triggerInput, setTriggerInput] = useState<Trigger>(trigger);
  const [error, setError] = useState<string>('');

  const [update, { loading }] = useMutation<UpdateTriggerResponse, UpdateTriggerVariables>(TriggerMutations.update, {
    onCompleted: res => {
      res.updateTrigger.forEach(trigger => triggers.set(trigger.id, new Trigger(trigger)));
      setTriggers(new Map(triggers));
      handleClose();
    },
    onError: err => setError(err.message),
  });

  const handleFinish = async () => {
    try {
      await update({
        variables: {
          triggers: [triggerInput],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
    onClose();
  };

  return (
    <Modal open={open} height='500px' error={error} onClose={handleClose} title={t().triggers.singular.label}>
      <ModalPane
        footerButtons={[
          BackButtonTemplate(handleClose, { disabled: loading }),
          UpdateButtonTemplate(handleFinish, { loading: loading }),
        ]}
      >
        <Grid container height={'100%'} alignContent={'space-between'}>
          <Grid item xs={12}>
            <Grid item xs={12} mb={2}>
              <Checkbox
                label={t().enabled.singular.label}
                value={trigger.enabled}
                onChange={v => setTriggerInput(trigger.withEnabled(v))}
              />
            </Grid>
            <Grid item xs={12}>
              <Selector
                testId={testIds.triggerType}
                selectAll={false}
                placeholder={t().triggerTypeText.singular.label}
                values={[...Object.keys(TriggerType).values()]}
                checkedValues={trigger.types}
                disabled={item => item === TriggerType.alert || !trigger.enabled}
                toText={item => triggerTypeToString(item as TriggerType)}
                onChange={checked => setTriggerInput(trigger.withTypes(checked as TriggerType[]))}
              />
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
