import CableIcon from '@mui/icons-material/Cable';
import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { useNavigate } from 'react-router-dom';
import { IntegrationContext } from '../../../../../context/IntegrationContext';
import { CompanyRelationService } from '../../../../../types/integrationSettings';
import AddButton from '../../../../../VentoryUI/components/common/Button/Templates/AddButton';
import FlexPane from '../../../../../VentoryUI/components/common/FlexPane';
import Paper from '../../../../../VentoryUI/components/common/Paper';
import SearchBar from '../../../../../VentoryUI/components/common/SearchBar';
import CreateIntegrationModal from './Integrations/Modals/CreateIntegrationModal';

interface IntegrationsPaneProps {}

export default function IntegrationsPane({}: IntegrationsPaneProps) {
  const navigate = useNavigate();

  const { integrationSettings, integrationSettingsLoading } = useContext(IntegrationContext);

  const [filter, setFilter] = useState<string>('');
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

  const handleFilter = (item: any) => {
    const lowerCaseFilter = filter.toLowerCase();
    return true;
  };

  return (
    <>
      <CreateIntegrationModal open={openCreateModal} setOpen={setOpenCreateModal} />

      <FlexPane
        header={
          <Grid container>
            <Grid item flexGrow={1} marginY={'auto'}>
              <SearchBar onChange={value => setFilter(value)} placeholder={t().filterIntegrations.singular.label} />
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1} justifyContent={'flex-end'}>
                <Grid item>
                  <AddButton onClick={() => setOpenCreateModal(true)} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        content={
          integrationSettingsLoading ? (
            <></>
          ) : (
            <Paper>
              <Grid container>
                {[...integrationSettings.values()].map(item => (
                  <Grid
                    key={item.id}
                    item
                    textAlign={'center'}
                    className='h-52 w-52 border cursor-pointer select-none'
                    onClick={() => navigate(`/settings/advanced/integrations/${item.id}/update`)}
                  >
                    <Grid container rowSpacing={0.5} height={'100%'} alignContent={'center'}>
                      <Grid item xs={12}>
                        <CableIcon />
                      </Grid>
                      <Grid item xs={12}>
                        <p className='text-sm font-semibold'>{item.name}</p>
                      </Grid>
                      <Grid item xs={12}>
                        <p className='text-xs'>
                          {item.type === CompanyRelationService.sap ? 'SAP' : 'Business Central'}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          )
        }
      />
    </>
  );
}
