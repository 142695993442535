import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { useMutation } from '@apollo/client';
import {
  StockLocationMutations,
  UpdateStockLocationResponse,
  UpdateStockLocationVariables,
} from '../../../../graphql/stockLocation.graphql';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import StockLocationInfoPane from './Panes/StockLocationInfoPane';
import { StockLocationContext } from '../../../../context/StockLocationContext';
import { StockLocation } from '../../../../types/stockLocation';
import StockLocationBinPane from './Panes/StockLocationBinPane';
import StockLocationMapPane from './Panes/StockLocationMapPane';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import BackButton from '../../../../VentoryUI/components/common/Button/Templates/BackButton';
import SaveButton from '../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Pane from '../../../../VentoryUI/components/common/Pane';
import StockLocationUserPane from './Panes/StockLocationUserPane';
import { CompanyContext } from '../../../../context/CompanyContext';

const tabs = () => [
  {
    text: t().stockLocation.singular.label,
    path: 'info',
    key: 'update',
  },
  {
    text: t().map.singular.label,
    path: 'map',
    key: 'tab-map',
  },
  {
    text: t().bin.plural.label,
    path: 'bins',
    key: 'bins',
  },
  {
    text: t().user.plural.label,
    path: 'users',
    key: 'users',
  },
];

export default function EditStockLocationPane() {
  const navigate = useNavigate();

  const id = useParams()['id'] || '';

  const { stockLocations, setStockLocations } = useContext(StockLocationContext);
  const { currentCompany } = useContext(CompanyContext);

  const [stockLocationInput, setStockLocationInput] = useState<StockLocation>(
    stockLocations.get(id) || new StockLocation({ companyId: currentCompany.id }),
  );

  const [update, { loading }] = useMutation<UpdateStockLocationResponse, UpdateStockLocationVariables>(
    StockLocationMutations.update,
    {
      onCompleted: res => {
        const stockLocation = res.updateStockLocation[0];
        setStockLocations(new Map(stockLocations).set(stockLocation.id, new StockLocation(stockLocation)));
        navigate('/settings/stock_location');
      },
    },
  );

  const handleUpdate = async (stockLocation: StockLocation) => {
    await update({
      variables: {
        stockLocations: [stockLocation.forUpdate()],
      },
    });
  };

  const infoFooter = (stockLocation: StockLocation) => (
    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <BackButton disabled={loading} onClick={() => navigate('/settings/stock_location')} />
      </Grid>
      <Grid item>
        <SaveButton loading={loading} onClick={() => handleUpdate(stockLocation)} />
      </Grid>
    </Grid>
  );

  const roleFooter = () => (
    <Grid container columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <BackButton disabled={loading} onClick={() => navigate('/settings/stock_location')} />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Pane tabs={tabs()} testId={testIds.updateStockLocationPane}>
        <Routes>
          <Route
            path='info'
            element={
              <StockLocationInfoPane
                stockLocation={stockLocationInput}
                setStockLocation={setStockLocationInput}
                footer={infoFooter}
              />
            }
          />
          <Route
            path='map'
            element={
              <StockLocationMapPane
                stockLocation={stockLocationInput}
                setStockLocation={setStockLocationInput}
                footer={infoFooter}
              />
            }
          />
          <Route path='bins' element={<StockLocationBinPane stockLocationId={id} footer={roleFooter} />} />
          <Route
            path='users'
            element={
              <StockLocationUserPane
                stockLocation={stockLocationInput}
                setStockLocation={setStockLocationInput}
                footer={infoFooter}
              />
            }
          />
        </Routes>
      </Pane>
    </>
  );
}
