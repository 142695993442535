import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BinContext } from '../../../../../context/BinContext';
import { ContainerContext } from '../../../../../context/ContainerContext';
import { CustomFieldContext } from '../../../../../context/CustomFieldContext';
import {
  ContainerMutations,
  CreateContainerResponse,
  CreateContainerVariables,
} from '../../../../../graphql/container.graphql';
import { SystemBin } from '../../../../../types/bin';
import { Container } from '../../../../../types/container';
import { SystemStockLocation } from '../../../../../types/stockLocation';
import { t } from '../../../../../types/translation/Translator';
import { BackButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { CreateButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import Pane from '../../../../../VentoryUI/components/common/Pane/Pane';
import ContainerConfigurationPane from './ContainerConfigurationPane';

interface CreateContainerPaneProps {}

export default function CreateContainerPane({}: CreateContainerPaneProps) {
  const navigate = useNavigate();

  const { containers, setContainers } = useContext(ContainerContext);
  const [error, setError] = useState('');
  const { customFields } = useContext(CustomFieldContext);
  const { bins } = useContext(BinContext);

  const [create, { loading }] = useMutation<CreateContainerResponse, CreateContainerVariables>(
    ContainerMutations.create,
    {
      onCompleted: data => {
        data.createContainer.forEach(container => containers.set(container.id, new Container(container)));
        setContainers(new Map(containers));
        navigate('/stock/containers');
      },
      onError: error => {
        setError(error.message);
      },
    },
  );

  const footerButtons = (container: Container) => {
    return [
      { ...BackButtonTemplate(() => navigate('/stock/containers'), { disabled: loading }) },
      {
        ...CreateButtonTemplate(() => {
          if (!container.stockLocationId || !container.binId) {
            container.stockLocationId = SystemStockLocation.id;
            container.binId = SystemBin.id;
          }

          create({
            variables: { containers: [{ ...container, customFields: container.customFieldValues() as any } as any] },
          });
        }),
        disabled: container.validate([...customFields.values()]).length > 0,
        loading,
      },
    ];
  };

  return (
    <Pane error={error}>
      <ContainerConfigurationPane title={t().createContainer.singular.label} footerButtons={footerButtons} />
    </Pane>
  );
}
