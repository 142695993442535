import React from 'react';
import { CustomField } from '../../../../types/customField';
import { toFilterString } from '../../../../util/string.util';
import { BaseFilter } from '../common/BaseFilter';
import { t } from '../../../../types/translation/Translator';

export class CustomFieldFilter extends BaseFilter<CustomField> {
  toLabel(): string {
    return t().customFields.singular.label;
  }

  toIcon(): React.JSX.Element {
    return <></>;
  }

  toDropdownContent(): React.JSX.Element {
    return <></>;
  }

  toInnerContent(): string | JSX.Element {
    return <></>;
  }

  toCount(): number {
    return 0;
  }

  search(item: CustomField, textFilter: string) {
    if (
      toFilterString(item.name).includes(textFilter) ||
      toFilterString(item.entityType).includes(textFilter) ||
      toFilterString(item.entitySubtype).includes(textFilter)
    ) {
      return true;
    }

    return false;
  }
}
