import { useMutation } from '@apollo/client';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { useContext } from 'react';
import { t } from '../../../../../../../types/translation/Translator';
import { CustomTranslationContext } from '../../../../../../../context/CustomTranslationContext';
import {
  CustomTranslationMutations,
  UpdateCustomTranslationRecordResponse,
  UpdateCustomTranslationRecordVariables,
} from '../../../../../../../graphql/translations/customTranslation.graphql';
import { english, translationsManager } from '../../../../../../../types/translation/Translator';
import { CustomTranslation } from '../../../../../../../types/translation/customTranslation';
import { SaveButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/SaveButton';
import Modal from '../../../../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import TextInput from '../../../../../Common/TextInput';
import { TranslationKey } from '../../../../../../../types/translation/TranslationKey';
import { TranslationUtils } from '../../../../../../../types/translation/common';

interface CustomTranslationRecordModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  translationKey: TranslationKey;
  customTranslation: CustomTranslation;
}

export default function CustomTranslationRecordModal({
  open,
  setOpen,
  translationKey,
  customTranslation,
}: CustomTranslationRecordModalProps) {
  if (!open) return null;

  const { customTranslations, setCustomTranslations } = useContext(CustomTranslationContext);
  const [record, setRecord] = useState(
    customTranslation.translations[translationKey]?.toRecord() || { singular: { lower: '' } },
  );
  const [error, setError] = useState('');

  const englishRecord = english.translations[translationKey].toRecord();
  if (!englishRecord) return null;

  const [update, { loading }] = useMutation<
    UpdateCustomTranslationRecordResponse,
    UpdateCustomTranslationRecordVariables
  >(CustomTranslationMutations.updateRecord, {
    onCompleted: response => {
      const updated = new CustomTranslation(response.updateCustomTranslationRecord);
      customTranslations.set(response.updateCustomTranslationRecord.id, updated);
      setCustomTranslations(new Map(customTranslations));
      translationsManager.setCustomTranslation(updated, { merge: true });
      handleClose();
    },
    onError: error => setError(error.message),
  });

  const handleSave = async () => {
    await update({
      variables: {
        key: translationKey,
        companyId: customTranslation.companyId,
        customTranslationId: customTranslation.id,
        record,
      },
    });
  };

  const handleClose = () => {
    setError('');
    setRecord({ singular: { lower: '' } });
    setOpen(false);
  };

  const hasVariables =
    TranslationUtils.checkVariables(englishRecord.singular.lower, record.singular.lower) &&
    TranslationUtils.checkVariables(englishRecord.singular.upper || '', record.singular.upper || '') &&
    TranslationUtils.checkVariables(englishRecord.plural?.lower || '', record.plural?.lower || '') &&
    TranslationUtils.checkVariables(englishRecord.plural?.upper || '', record.plural?.upper || '');

  const hasRequired =
    !(englishRecord.singular.lower && !record.singular.lower) &&
    !(englishRecord.singular.upper && !record.singular.upper) &&
    !(englishRecord.plural?.lower && !record.plural?.lower) &&
    !(englishRecord.plural?.upper && !record.plural?.upper);

  const canSave = () => {
    if (!record.singular.lower && !record.singular.upper && !record.plural?.lower && !record.plural?.upper) return true;
    return hasVariables && hasRequired;
  };

  return (
    <Modal title={'Translation Record'} onClose={handleClose} open={open} error={error}>
      <ModalPane
        footerButtons={[
          SaveButtonTemplate(handleSave, {
            loading,
            disabled: !canSave(),
            tooltip:
              !canSave() && !hasVariables
                ? 'Variables like this: "${VALUE}" must be present in your translation.'
                : null,
          }),
        ]}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={6}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <p className='text-base font-medium text-ventory-grey-900'>{t().singular.singular.label}</p>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t().lowercase.singular.label}
                  disabled
                  value={englishRecord.singular.lower}
                  onChange={() => null}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t().uppercase.singular.label}
                  disabled
                  value={englishRecord.singular.upper}
                  onChange={() => null}
                />
              </Grid>
              <Grid item xs={12} my={1}></Grid>
              <Grid item xs={12}>
                <p className='text-base font-medium text-ventory-grey-900'>{t().plural.singular.label}</p>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t().lowercase.singular.label}
                  disabled
                  value={englishRecord.plural?.lower}
                  onChange={() => null}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={t().uppercase.singular.label}
                  disabled
                  value={englishRecord.plural?.upper}
                  onChange={() => null}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <p className='text-base font-medium text-ventory-grey-900'>{t().singular.singular.label}</p>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  dynamicUpdate
                  label={t().lowercase.singular.label}
                  value={record.singular.lower}
                  onChange={value => {
                    if (record.singular) {
                      record.singular.lower = value;
                    } else {
                      record.singular = { lower: value };
                    }
                    setRecord({ ...record });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  dynamicUpdate
                  label={t().uppercase.singular.upper}
                  value={record.singular.upper}
                  onChange={value => {
                    if (record.singular) {
                      record.singular.upper = value;
                    } else {
                      record.singular = { lower: '', upper: value };
                    }
                    setRecord({ ...record });
                  }}
                  disabled={!englishRecord.singular.upper}
                />
              </Grid>
              <Grid item xs={12} my={1}></Grid>
              <Grid item xs={12}>
                <p className='text-base font-medium text-ventory-grey-900'>{t().plural.singular.label}</p>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  dynamicUpdate
                  label={t().lowercase.singular.label}
                  value={record.plural?.lower}
                  disabled={!englishRecord.plural?.lower}
                  onChange={value => {
                    if (record.plural) {
                      record.plural.lower = value;
                    } else {
                      record.plural = { lower: value };
                    }
                    setRecord({ ...record });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  dynamicUpdate
                  label={t().uppercase.singular.label}
                  value={record.plural?.upper}
                  onChange={value => {
                    if (record.plural) {
                      record.plural.upper = value;
                    } else {
                      record.plural = { lower: '', upper: value };
                    }
                    setRecord({ ...record });
                  }}
                  disabled={!englishRecord.plural?.upper}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    </Modal>
  );
}
