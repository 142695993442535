import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import {
  CustomField,
  customFieldEntitySubtypeToString,
  customFieldEntityTypeToString,
  customFieldTypeToString,
} from '../../../../../types/customField';

import { CustomFieldContext } from '../../../../../context/CustomFieldContext';
import { CompanyContext } from '../../../../../context/CompanyContext';

import CreateCustomFieldModal from '../Modals/CreateCustomFieldModal';
import UpdateCustomFieldModal from '../Modals/UpdateCustomFieldModal';

import SearchBarWithFilter from '../../../../../VentoryUI/components/common/SearchBarWithFilter';
import { CustomFieldFilter } from '../../../../../VentoryUI/components/filters/CustomFieldFilter';
import { AddButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/AddButton';
import FlexPane from '../../../../../VentoryUI/components/common/FlexPane';
import Table from '../../../../../VentoryUI/components/common/Table';

export default function CustomFieldPane() {
  const { currentCompany } = useContext(CompanyContext);
  const { customFields } = useContext(CustomFieldContext);

  const [selected, setSelected] = useState<CustomField>(new CustomField({ companyId: currentCompany.id }));
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);

  const [items, setItems] = useState([...customFields.values()]);

  const headers = [
    {
      key: 'name',
      name: 'Name',
      text: (item: CustomField) => item.name,
    },
    {
      key: 'type',
      name: 'Type',
      text: (item: CustomField) => customFieldTypeToString(item.type),
    },
    {
      key: 'entityType',
      name: 'Entity Type',
      text: (item: CustomField) => customFieldEntityTypeToString(item.entityType),
    },
    {
      key: 'entitySubType',
      name: 'Entity Subtype',
      text: (item: CustomField) => customFieldEntitySubtypeToString(item.entitySubtype),
    },
    {
      key: 'mandatory',
      name: 'Mandatory',
      text: (item: CustomField) => (item.mandatory ? 'Yes' : 'No'),
    },
  ];

  const handleOpenUpdateModal = () => {
    setOpenUpdateModal(false);
    setSelected(new CustomField({ companyId: currentCompany.id }));
  };

  const allItems = useMemo(() => {
    return [...customFields.values()];
  }, [customFields]);

  return (
    <>
      <CreateCustomFieldModal open={openCreateModal} setOpen={setOpenCreateModal} />
      <UpdateCustomFieldModal open={openUpdateModal} setOpen={handleOpenUpdateModal} customField={selected} />
      <FlexPane
        header={
          <SearchBarWithFilter
            items={allItems}
            setItems={setItems}
            placeholder={t().filterCustomFields.singular.label}
            buttons={[AddButtonTemplate(() => setOpenCreateModal(true))]}
            filter={new CustomFieldFilter()}
          />
        }
        content={
          <Table
            items={items}
            totalItemCount={allItems.length}
            headers={headers}
            onClick={item => {
              setSelected(item);
              setOpenUpdateModal(true);
            }}
          />
        }
      />
    </>
  );
}
