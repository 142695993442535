import React, { useContext, useState } from 'react';
import { t } from '../../../../../types/translation/Translator';
import { CompanyContext } from '../../../../../context/CompanyContext';
import { CsvUploadConfiguration, CsvUploadType } from '../../../../../types/csvUploadConfiguration';
import {
  CsvUploadConfigurationQueries,
  GetCsvUploadConfigurationForFileResponse,
  GetCsvUploadConfigurationForFileVariables,
} from '../../../../../graphql/csvUploadConfiguration';
import { useLazyQuery } from '@apollo/client';
import { Base64 } from 'js-base64';
import CreateCsvUploadConfigurationMapping from '../../../Settings/StockLocation/Modals/CreateCsvUploadConfigurationMapping';
import { CsvUploadConfigurationSelect } from '../../../Settings/StockLocation/Modals/CsvUploadConfigurationSelect';
import { Grid } from '@mui/material';
import UploadCsvInput from '../../../Common/UploadCsvInput';
import Checkbox from '../../../../../VentoryUI/components/common/Checkbox/Checkbox';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import DropdownSelect from '../../../Common/DropdownSelect';
import { StockLocation } from '../../../../../types/stockLocation';
import { StockLocationRoleAssignmentContext } from '../../../../../context/StockLocationRoleAssignmentContext';
import { UserContext } from '../../../../../context/UserContext';
import { StockLocationRole } from '../../../../../types/stockLocationRoleAssignment';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import CsvUploadConfigurationInspect from '../../../Settings/StockLocation/Modals/CsvUploadConfigurationInspect';
import Modal from '../../../../../VentoryUI/components/common/Modal/Modal';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { CancelButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/CancelButton';
import { NextButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/NextButton/NextButton';

enum ProductImportStatus {
  fileUpload,
  selectConfig,
  createConfig,
  inspect,
}

interface ProductImportModalInputProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function ProductImportModal({ open, setOpen }: ProductImportModalInputProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { stockLocations } = useContext(StockLocationContext);
  const { stockLocationRoles } = useContext(StockLocationRoleAssignmentContext);
  const { currentUser } = useContext(UserContext);

  const [file, setFile] = useState<File | null>(null);
  const [status, setStatus] = useState<ProductImportStatus>(ProductImportStatus.fileUpload);

  const [configurations, setConfigurations] = useState<CsvUploadConfiguration[]>([]);
  const [otherConfigurations, setOtherConfigurations] = useState<CsvUploadConfiguration[]>([]);
  const [fileHeaders, setFileHeaders] = useState<string[]>([]);
  const [requiredHeaders, setRequiredHeaders] = useState<string[]>([]);
  const [optionalHeaders, setOptionalHeaders] = useState<string[]>([]);
  const [fileAsBase64, setFileAsBase64] = useState<string | null>(null);
  const [selectedStockLocation, setSelectedStockLocation] = useState<StockLocation | null>(null);
  const [deleteExisting, setDeleteExisting] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [inspectId, setInspectId] = useState<string>();

  const [getConfigurations, { loading: getConfigurationsLoading }] = useLazyQuery<
    GetCsvUploadConfigurationForFileResponse,
    GetCsvUploadConfigurationForFileVariables
  >(CsvUploadConfigurationQueries.getForFile, {
    onCompleted: res => {
      setConfigurations(res.csvConfigurationsForFile.configurations.map(c => new CsvUploadConfiguration(c)));
      setOtherConfigurations(res.csvConfigurationsForFile.otherConfigurations.map(c => new CsvUploadConfiguration(c)));
      setFileHeaders(res.csvConfigurationsForFile.fileHeaders);
      setRequiredHeaders(res.csvConfigurationsForFile.requiredHeaders);
      setOptionalHeaders(res.csvConfigurationsForFile.optionalHeaders);
      if (!selectedStockLocation) {
        setRequiredHeaders(headers => [...headers, 'Stock Location Name', 'Stock Location Identifier']);
        setOptionalHeaders(headers => {
          headers.splice(headers.indexOf('Stock Location Name'), 1);
          headers.splice(headers.indexOf('Stock Location Identifier'), 1);
          return headers;
        });
      }
      if (
        !res.csvConfigurationsForFile.configurations.length &&
        !res.csvConfigurationsForFile.otherConfigurations.length
      ) {
        setStatus(ProductImportStatus.createConfig);
      } else {
        setStatus(ProductImportStatus.selectConfig);
      }
    },
    onError: err => setError(err.message),
  });

  const handleFile = (files: FileList | null) => {
    if (!files) {
      setFile(null);
      return;
    }
    setFile(files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return;

    let asBase64 = Base64.encode(await file.text());

    await getConfigurations({
      variables: {
        companyId: currentCompany.id,
        type: CsvUploadType.product,
        csvFileAsBase64Data: asBase64,
      },
    });
    setFileAsBase64(asBase64);
  };

  const handleClose = () => {
    setFile(null);
    setStatus(ProductImportStatus.fileUpload);
    setConfigurations([]);
    setOtherConfigurations([]);
    setFileHeaders([]);
    setRequiredHeaders([]);
    setOptionalHeaders([]);
    setFileAsBase64(null);
    setSelectedStockLocation(null);
    setOpen(false);
    setDeleteExisting(false);
    setError('');
  };

  const handleBack = () => {
    setError('');
    switch (status) {
      case ProductImportStatus.fileUpload:
        return;
      case ProductImportStatus.selectConfig:
        return setStatus(ProductImportStatus.fileUpload);
      case ProductImportStatus.createConfig:
        return setStatus(ProductImportStatus.fileUpload);
      case ProductImportStatus.inspect:
        return setStatus(ProductImportStatus.selectConfig);
    }
  };

  const content = () => {
    if (status === ProductImportStatus.createConfig && fileAsBase64) {
      return (
        <CreateCsvUploadConfigurationMapping
          onClose={handleClose}
          onBack={handleBack}
          type={CsvUploadType.product}
          required={requiredHeaders}
          optional={optionalHeaders}
          headers={fileHeaders}
          csvFileAsBase64Data={fileAsBase64}
          stockLocationId={selectedStockLocation?.id}
          setError={setError}
          setRequired={setRequiredHeaders}
        />
      );
    }

    if (status === ProductImportStatus.selectConfig && fileAsBase64) {
      return (
        <CsvUploadConfigurationSelect
          deleteExisting={deleteExisting}
          onClose={handleClose}
          onCreate={() => setStatus(ProductImportStatus.createConfig)}
          onBack={handleBack}
          csvFileAsBase64Data={fileAsBase64}
          type={CsvUploadType.product}
          configurations={configurations}
          otherConfigurations={otherConfigurations}
          stockLocationId={selectedStockLocation?.id}
          setError={setError}
          onInspect={id => {
            setInspectId(id);
            setStatus(ProductImportStatus.inspect);
          }}
        />
      );
    }

    if (status === ProductImportStatus.inspect) {
      return (
        <CsvUploadConfigurationInspect
          headers={fileHeaders}
          configuration={otherConfigurations.find(c => c.id === inspectId)}
          onBack={handleBack}
        />
      );
    }

    const possibleStockLocations = [...stockLocations.values()].filter(sl => {
      const role = stockLocationRoles
        .get(sl.id)
        ?.flat()
        .find(u => u.userId === currentUser?.userId)?.role;

      if (
        role === StockLocationRole.STOCK_LOCATION_VIEWER ||
        role === StockLocationRole.STOCK_LOCATION_USER ||
        role === StockLocationRole.STOCK_LOCATION_SUPERVISOR
      )
        return false;
      return true;
    });

    return (
      <ModalPane
        testId={testIds.importStockFileUploadPane}
        footerButtons={[
          {
            align: 'left',
            onClick: () => window.open(`${process.env.REACT_APP_STORAGE_URL}/ventory/import_template.csv`, '_blank'),
            text: t().downloadTemplate.singular.label,
          },
          CancelButtonTemplate(handleClose, { disabled: getConfigurationsLoading }),
          NextButtonTemplate(handleFileUpload, { disabled: !file }),
        ]}
      >
        <Grid container height={'100%'} alignContent={'space-between'}>
          <Grid item xs={12}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <DropdownSelect
                  testId={testIds.stockLocation}
                  placeholder={t().selectStockLocation.singular.label}
                  values={possibleStockLocations}
                  selectedValue={selectedStockLocation}
                  toText={sl => sl.name}
                  onChange={v => {
                    setSelectedStockLocation(v);
                    if (!v) setDeleteExisting(false);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <UploadCsvInput file={file} onFile={handleFile} />
              </Grid>
              {!!selectedStockLocation ? (
                <Grid item xs={12}>
                  <Checkbox
                    value={deleteExisting}
                    label={'Delete all products'}
                    onChange={v => setDeleteExisting(v)}
                    testId={testIds.deleteExisting}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </ModalPane>
    );
  };

  return (
    <Modal
      open={open}
      error={error}
      onClose={handleClose}
      width='70%'
      height='650px'
      title={t().importProduct.singular.label}
      testId={testIds.importStockModal}
    >
      {content()}
    </Modal>
  );
}
