import React, { useContext, useEffect, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import { DeleteIcon } from '../../../../icons/Delete/DeleteIcon';
import { VentoryColor } from '../../../../util/color.util';
import { t } from '../../../../../types/translation/Translator';
import { StockLocationContext } from '../../../../../context/StockLocationContext';
import MultiSelectionList from '../../../common/MultiSelectionList/MultiSelectionList';
import SearchBar from '../../../common/SearchBar/SearchBar';
import { ContainerContext } from '../../../../../context/ContainerContext';
import ScrollableFilterContent from '../../common/DropdownContent/ScrollableFilterContent';
import { ContainerFilter } from '../ContainerFilter';
import { FilterProps } from '../../common/filter.util';

interface ContainerFilterDropdownContentProps {
  filter: ContainerFilter;
  filterProps: FilterProps;
  setFilterProps: (filter: FilterProps) => void;
}

export default function ContainerFilterDropdownContent({
  filter,
  filterProps,
  setFilterProps,
}: ContainerFilterDropdownContentProps) {
  const { stockLocations } = useContext(StockLocationContext);
  const { containers } = useContext(ContainerContext);

  const [query, setQuery] = useState<string>('');

  const filterContainers = () => {
    return new Set(filter.filteredItems([...containers.values()], query, filterProps).map(container => container.id));
  };

  const [values, setValues] = useState<Set<string>>(filterContainers());

  const handleChange = (selected: Set<string>) => {
    filterProps.container = selected;
    if (!filterProps.container?.size) filterProps.container = undefined;
    setFilterProps({ ...filterProps });
  };

  const handleRemove = () => {
    filterProps.container = undefined;
    setFilterProps({ ...filterProps });
  };

  useEffect(() => {
    setValues(filterContainers());
  }, [query]);

  return (
    <ScrollableFilterContent>
      <Grid container>
        <Grid item xs={12} className='flex py-2 px-3'>
          <Grid container>
            <Grid item>
              <p className='text-[13px] font-[500] text-ventory-grey-500'>{t().container.singular.label}</p>
            </Grid>
            <Grid item flexGrow={1} display={'flex'} justifyContent={'flex-end'} sx={{ color: VentoryColor.deleteRed }}>
              <Grid item onClick={handleRemove} className='cursor-pointer'>
                <DeleteIcon />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SearchBar onChange={i => setQuery(i)} className='pl-3 pr-4' />
        </Grid>
        <Grid item className='py-3' xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectionList
            selected={filterProps.container}
            values={values}
            onChange={handleChange}
            toText={id => `${containers.get(id)?.identifier}`}
            toSubText={id => `${stockLocations.get(containers.get(id)?.stockLocationId || '')?.name || ''}`}
          />
        </Grid>
      </Grid>
    </ScrollableFilterContent>
  );
}
