import React, { useContext } from 'react';
import { ProductExportConfigurationContext } from '../../../../../../context/ProductExportConfigurationContext';
import LoadingPackage from '../../../../Common/LoadingPackage';
import ProductExportConfigurationItem from './Export/ProductExportConfigurationItem';
import { Grid } from '@mui/material';
import Paper from '../../../../../../VentoryUI/components/common/Paper';

export default function ProductReportPane() {
  const { productExportConfigurations, setProductExportConfigurations, productExportConfigurationsLoading } =
    useContext(ProductExportConfigurationContext);

  if (productExportConfigurationsLoading) {
    return (
      <Grid container height={'100%'} alignContent={'center'} justifyContent={'center'}>
        <Grid item className='fill-gray-300'>
          <LoadingPackage />
        </Grid>
      </Grid>
    );
  }

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            {[...productExportConfigurations.values()].map(configuration => (
              <Grid key={configuration.id} item>
                <ProductExportConfigurationItem configuration={configuration} key={configuration.id} />
              </Grid>
            ))}
            <Grid item>
              <ProductExportConfigurationItem />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
