import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import Dropdown from '../../../Common/Dropdown';
import { t } from '../../../../../types/translation/Translator';
import { CreateTaskInput, Task, TaskType, taskTypeToString, validateTaskType } from '../../../../../types/task';
import { testIds } from '../../../../../util/identifiers/identifiers.util';
import { CompanyContext } from '../../../../../context/CompanyContext';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { NextButtonTemplate } from '../../../../../VentoryUI/components/common/Button/Templates/NextButton/NextButton';

interface CreateTaskTypePaneInputProps {
  task: CreateTaskInput;
  setTask: (task: Task) => void;
  next: () => void;
  back: () => void;
}

export function CreateTaskTypePane({ task, setTask, next, back }: CreateTaskTypePaneInputProps) {
  const { currentCompany } = useContext(CompanyContext);

  return (
    <ModalPane
      testId={testIds.createTaskTypePane}
      footerButtons={[
        {
          align: 'right',
          ...NextButtonTemplate(next),
        },
      ]}
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <Dropdown<TaskType>
              mandatory
              testId={testIds.taskType}
              label={t().taskType.singular.label}
              values={currentCompany.validTaskTypes}
              selectedValue={validateTaskType(currentCompany, task.type) ? task.type : currentCompany.validTaskTypes[0]}
              toText={type => taskTypeToString(type)}
              onChange={type => setTask(task.withType(type))}
            />
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
