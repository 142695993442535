import React, { useContext, useState } from 'react';
import { CompanyRole } from '../../../../types/companyRoleAssignment';
import { CompanyRoleAssignmentContext } from '../../../../context/CompanyRoleAssignmentContext';
import { UserContext } from '../../../../context/UserContext';
import { CompanyContext } from '../../../../context/CompanyContext';
import { SuperUserContext } from '../../../../context/SuperUserContext';
import { Route, Routes } from 'react-router-dom';
import CustomFieldPane from './Panes/CustomFieldPane';
import IntegrationsPane from './Panes/IntegrationsPane';
import AuthenticationCompanySettingsPane from './Panes/UserCompanySettingsPane';
import Pane from '../../../../VentoryUI/components/common/Pane';
import { t } from '../../../../types/translation/Translator';
import TagPane from './Panes/TagPane';
import ContainerTypePane from './Panes/ContainerTypes/ContainerTypePane';
import CustomTranslationPane from './Panes/CustomTranslations/CustomTranslationPane';
import { Company } from '../../../../types/company';

const getTabs = (role: CompanyRole, company: Company) =>
  [
    {
      text: t().integrations.singular.label,
      path: '/settings/advanced/integrations',
      key: 'integrations',
    },
    {
      text: t().customFields.singular.label,
      path: '/settings/advanced/custom_fields',
      key: 'custom_fields',
    },
    {
      text: t().authentication.singular.label,
      path: '/settings/advanced/authentication_settings',
      key: 'authentication_settings',
    },
    {
      text: 'Custom Translation',
      path: '/settings/advanced/custom_translation',
      key: 'custom_translations',
    },
    {
      text: t().tag.plural.label,
      path: '/settings/advanced/tags',
      key: 'tags',
    },
    {
      text: 'Container Types',
      path: '/settings/advanced/container_types',
      key: 'container_types',
    },
  ].filter(tab => {
    if (tab.key === 'container_types' && !company.settings.featureToggles.containers.containers) return false;

    if (role === CompanyRole.administrator) return true;
    return false;
  });

export default function AdvancedSettingsScreen() {
  const { companyRoles } = useContext(CompanyRoleAssignmentContext);
  const { currentUser } = useContext(UserContext);
  const { currentCompany } = useContext(CompanyContext);
  const { superUser } = useContext(SuperUserContext);

  const [error, setError] = useState<string>('');

  const role = companyRoles.get(currentUser?.userId || '')?.find(cr => cr.companyId === currentCompany.id);
  if (!role && !superUser) return null;

  return (
    <Pane error={error} tabs={getTabs(role?.role || CompanyRole.administrator, currentCompany)}>
      <Routes>
        <Route path='/custom_fields' element={<CustomFieldPane />} />
        <Route path='/integrations' element={<IntegrationsPane />} />
        <Route
          path='/authentication_settings'
          element={<AuthenticationCompanySettingsPane error={error} setError={setError} />}
        />
        <Route path='/custom_translation' element={<CustomTranslationPane setError={setError} />} />
        <Route path='/tags' element={<TagPane setError={setError} />} />
        {currentCompany.settings.featureToggles.containers.containers ? (
          <Route path='/container_types' element={<ContainerTypePane setError={setError} />} />
        ) : null}
      </Routes>
    </Pane>
  );
}
