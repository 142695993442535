import * as atlas from 'azure-maps-control';
import React, { useContext, useEffect, useState } from 'react';
import '../../../style/Map.scss';
import { StockLocationContext } from '../../../context/StockLocationContext';
import { CompanyContext } from '../../../context/CompanyContext';
import {
  MarkerImage,
  mobileSockLocationEventToMarkerImage,
  mobileStockLocationEventToMarkerStatus,
  StockLocationWithProductQuantityMarker,
} from '../../../util/marker.util';
import {
  CoordinatesArray,
  ZOOM_FOR_SINGLE_STOCK_LOCATION,
  getZoomByLargestDistanceFromCenter,
} from './Maps/CoordinatesUtil';
import { Coordinates } from '../../../types/common/address';
import { Grid } from '@mui/material';
import FactoryIcon from '@mui/icons-material/Factory';
import { t } from '../../../types/translation/Translator';
import {
  GetLastMobileStockLocationEventsResponse,
  GetLastMobileStockLocationEventsVariables,
  MobileStockLocationEvent,
  MobileStockLocationNotification,
  MobileStockLocationNotificationResponse,
  MobileStockLocationNotificationVariables,
  MobileStockLocationQueries,
} from '../../../graphql/mobileStockLocation.graphql';
import { useQuery, useSubscription } from '@apollo/client';
import { ProductMasterData } from '../../../types/productMasterData';
import { getSuffix } from '../../../types/unitOfMeasure';

enum AtlastMapSource {
  datasource = 'datasource',
}

interface StockLocationIdWithProductQuantity {
  id: string;
  quantity: number;
}

interface ProductMapProps {
  locations: StockLocationIdWithProductQuantity[];
  productMasterData: ProductMasterData;
}

export default function ProductMap({ locations, productMasterData }: ProductMapProps) {
  const { stockLocations } = useContext(StockLocationContext);
  const { currentCompany } = useContext(CompanyContext);

  if (!currentCompany) return null;

  const [events, setEvents] = useState<Map<string, MobileStockLocationEvent>>(new Map());
  const [map, setMap] = useState<atlas.Map>();
  let loaded = false;

  const { loading, refetch } = useQuery<
    GetLastMobileStockLocationEventsResponse,
    GetLastMobileStockLocationEventsVariables
  >(MobileStockLocationQueries.getLastEvents, {
    variables: { companyId: currentCompany.id },
    onCompleted: data => {
      setEvents(new Map(data.getLastMobileStockLocationEvents.map(event => [event.stockLocationId, event])));
    },
    onError: () => setEvents(new Map()),
  });

  const { data } = useSubscription<MobileStockLocationNotificationResponse, MobileStockLocationNotificationVariables>(
    MobileStockLocationNotification.subscribe,
    {
      variables: { companyId: currentCompany.id },
    },
  );

  useEffect(() => {
    if (data && data.mobileStockLocationNotification) handleNotification(data.mobileStockLocationNotification.entities);
  }, [data]);

  const handleNotification = (notifications: MobileStockLocationEvent[]) => {
    for (const notification of notifications) {
      const event = notification;
      if (!event) continue;

      const location = locations.find(location => location.id === notification.stockLocationId);
      const stockLocation = stockLocations.get(notification.stockLocationId);
      if (!location || !stockLocation || !map) return;
      const marker = StockLocationWithProductQuantityMarker.fromEvent(event, stockLocation, location.quantity);
      try {
        const dataSource = map.sources.getById(AtlastMapSource.datasource) as atlas.source.DataSource;
        if (dataSource) dataSource.removeById(stockLocation.id);
        dataSource.add(marker.createMapFeature());
      } catch (e) {
        // TODO: Don't think this should happen
      }
    }
  };

  const createMap = () => {
    if (loaded) return;
    setMap(
      new atlas.Map('map', {
        center: [0, -100],
        maxZoom: 18,
        minZoom: 1,
        zoom: 8,

        authOptions: {
          authType: atlas.AuthenticationType.subscriptionKey,
          subscriptionKey: process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY || 'no_key',
        },
      }),
    );
    loaded = true;
  };

  const createMarkerTemplates = async (map: atlas.Map) => {
    await map.imageSprite.createFromTemplate(MarkerImage.orange, 'marker', 'orange');
    await map.imageSprite.createFromTemplate(MarkerImage.green, 'marker', 'green');
  };

  const setupMapSourcesAndEvents = async () => {
    if (!map) return;

    await createMarkerTemplates(map);

    const markers: StockLocationWithProductQuantityMarker[] = [];

    for (const location of locations) {
      const stockLocation = stockLocations.get(location.id);
      if (!stockLocation) continue;

      // This should be initial events only
      const event = events.get(location.id);

      if (event) {
        markers.push(StockLocationWithProductQuantityMarker.fromEvent(event, stockLocation, location.quantity));
      } else {
        const marker = StockLocationWithProductQuantityMarker.fromStockLocation(stockLocation, location.quantity);
        if (marker) markers.push(marker);
      }
    }

    // Must always setup the map even if markers are empty

    let zoom: number = 1;
    let center: Coordinates | undefined;
    if (markers.length > 1) {
      const coordinates = new CoordinatesArray(
        ...markers.map(marker => marker.event?.coordinates ?? marker.stockLocation.address?.coordinates!),
      );

      center = coordinates.getMiddle();
      zoom = getZoomByLargestDistanceFromCenter(coordinates.largestDistanceFromCenter(center));
    } else if (markers.length === 1) {
      zoom = ZOOM_FOR_SINGLE_STOCK_LOCATION;
      center = markers[0].event?.coordinates ?? markers[0].stockLocation.address?.coordinates!;
    }

    map.setCamera({
      zoom: zoom,
      center: center ? [center.longitude, center.latitude] : undefined,
    });

    var popup = new atlas.Popup({
      pixelOffset: [0, -60],
      closeButton: false,
    });

    const dataSource = new atlas.source.DataSource(AtlastMapSource.datasource, {
      cluster: true,
      clusterProperties: {
        quantity: ['+', ['get', 'quantity']],
        name: [
          'concat',
          ['concat', ['get', 'name'], ': ', ['get', 'quantity'], ` ${getSuffix(productMasterData.unitOfMeasure)}`, ','],
        ],
      },
    });

    const symbolLayer = new atlas.layer.SymbolLayer(dataSource, undefined, {
      iconOptions: {
        size: 1.5,
      },
      textOptions: {
        textField: [
          'case',
          ['>', ['get', 'quantity'], 99],
          `99+`,
          ['concat', ['get', 'quantity'], ` ${getSuffix(productMasterData.unitOfMeasure)}`],
        ],
        offset: [0, -2.03],
        font: ['StandardFont-Bold'],
        color: 'white',
        size: 14,
      },
    });

    map.events.add('stylechanged', () => {
      map.sources.add(dataSource);
      map.layers.add(symbolLayer);
    });

    map.events.add('ready', () => {
      for (const marker of markers) {
        dataSource.add(
          new atlas.data.Feature(
            new atlas.data.Point([marker.coordinates.longitude, marker.coordinates.latitude]),
            {
              name: marker.stockLocation.name || '',
              description: `Quantity: ${marker.quantity} ${getSuffix(productMasterData.unitOfMeasure)}`,
              quantity: marker.quantity,
              image: marker.event ? mobileSockLocationEventToMarkerImage(marker.event) : 'marker-blue',
              status: marker.event ? mobileStockLocationEventToMarkerStatus(marker.event) : '',
            },
            marker.stockLocation.id,
          ),
        );
      }

      map.events.add('mouseenter', symbolLayer, e => {
        //Make sure that the point exists.
        map.getCanvasContainer().style.cursor = 'pointer';
        if (e.shapes && e.shapes.length > 0) {
          if (e.shapes[0] instanceof atlas.Shape) {
            const shape = e.shapes[0] as atlas.Shape;
            var properties = shape.getProperties();
            const coordinate = shape.getCoordinates() as atlas.data.Position;
            popup.setOptions({
              //Update the content of the popup.
              content: `<div class='map_popup'>
              <h1>${properties.name}</h1>
              <p>${properties.description}</p>
              <p>${properties.status}</p>
            </div>`,
              //Update the popup's position with the symbol's coordinate.
              position: coordinate,
            });
          } else {
            const cluster = e.shapes[0];
            const properties = cluster.properties;
            const pointCount = properties.point_count;
            const point = cluster.geometry.coordinates as atlas.data.Position;
            const stockLocations = properties.name.split(',');
            stockLocations.pop();
            popup.setOptions({
              //Update the content of the popup.
              content: `<div class='map_popup'>
              <h1>Quantity in ${pointCount} stock locations</h1>
              <p>${stockLocations.join('<br/>')}</p>
            </div>`,
              // Update the popup's position with the symbol's coordinate.
              position: point,
            });
          }
          //Open the popup.
          popup.open(map);
        }
      });

      map.events.add('mouseleave', symbolLayer, () => {
        popup.remove();
        map.getCanvasContainer().style.cursor = 'grab';
      });
    });
  };

  useEffect(() => {
    setupMapSourcesAndEvents();
  }, [map]);

  useEffect(() => {
    createMap();
  }, []);

  if (!locations.filter(loc => !!stockLocations.get(loc.id)?.address?.coordinates).length) {
    return (
      <Grid container height={'100%'} alignContent={'center'}>
        <Grid item xs={12} textAlign={'center'}>
          <FactoryIcon className='text-gray-300' style={{ fontSize: '5rem' }} />
          <p className='font-semibold text-sm text-gray-300'>{t().mapUnavailable.singular.label}</p>
        </Grid>
        <div id='map' className='hidden'></div>
      </Grid>
    );
  }

  return (
    <div
      id='map'
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '8px',
      }}
    ></div>
  );
}
