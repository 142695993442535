import { gql, useSubscription } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import React from 'react';
import { forNotification } from '../Common/forNotification';
import { ContainerFragment } from '../../graphql/container.graphql';
import { Container } from '../../types/container';
import { ContainerContext } from '../../context/ContainerContext';

const containerSubscription = gql`
  subscription containerNotification($companyId: String!) {
    containerNotification(companyId: $companyId) {
      companyId
      entities {
        ...Container
      }
      topic
      date
    }
  }
  ${ContainerFragment.container}
`;

export class ContainerNotificationResponse extends forNotification<Container>('containerNotification') {}

export default function ContainerNotification({}) {
  const { currentCompany } = useContext(CompanyContext);
  const { containers, setContainers } = useContext(ContainerContext);

  if (!currentCompany) return null;

  const { data: containerNotifaction } = useSubscription<ContainerNotificationResponse>(containerSubscription, {
    variables: { companyId: currentCompany.id },
  });

  const handleContainerNotification = (notification?: ContainerNotificationResponse) => {
    if (!notification?.containerNotification) return;
    const containerNotification = notification.containerNotification;

    switch (containerNotification.topic) {
      case 'CREATE':
      case 'UPDATE':
        containerNotification.entities.forEach(container => containers.set(container.id, new Container(container)));
        break;
      case 'DELETE':
        containerNotification.entities.forEach(container =>
          containers.has(container.id) ? containers.delete(container.id) : null,
        );
    }
    setContainers(new Map(containers));
  };

  useEffect(() => {
    handleContainerNotification(containerNotifaction);
  }, [containerNotifaction]);

  return <></>;
}
