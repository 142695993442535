import React from 'react';
import { CsvUploadConfiguration } from '../../../../../types/csvUploadConfiguration';
import { Grid } from '@mui/material';
import { t } from '../../../../../types/translation/Translator';
import ModalPane from '../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import Button from '../../../../../VentoryUI/components/common/Button';

interface CsvUploadConfigurationInspectProps {
  configuration?: CsvUploadConfiguration;
  headers: string[];
  onBack: () => void;
}

export default function CsvUploadConfigurationInspect({
  configuration,
  headers,
  onBack,
}: CsvUploadConfigurationInspectProps) {
  if (!configuration) return null;

  const existingHeaderNames = new Set(configuration.headerNames);
  const requestedHeaderNames = new Set(headers);

  const missingHeaders = configuration.headerNames.filter(config => !requestedHeaderNames.has(config));
  const additionalHeaders = headers.filter(config => !existingHeaderNames.has(config));

  return (
    <ModalPane
      footer={
        <Grid container columnSpacing={1} justifyContent={'flex-end'}>
          <Grid item marginTop={'auto'}>
            <Button onClick={onBack} text={t().back.singular.label} />
          </Grid>
        </Grid>
      }
    >
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12} overflow={'auto'} maxHeight={'500px'}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                {missingHeaders.length ? (
                  <Grid item xs={12} mb={1}>
                    <p className='font-semibold text-sm'>{`The following columns are missing from the file:`}</p>
                    <ul className='list-disc pl-8'>
                      {missingHeaders.map(header => (
                        <li>{header}</li>
                      ))}
                    </ul>
                  </Grid>
                ) : null}
                {additionalHeaders.length ? (
                  <Grid item xs={12}>
                    <p className='font-semibold text-sm'>{`The following columns are not defined by the config:`}</p>
                    <ul className='list-disc pl-8'>
                      {additionalHeaders.map(header => (
                        <li>{header}</li>
                      ))}
                    </ul>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalPane>
  );
}
