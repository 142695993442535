import React from 'react';
import { CompanyRelationService, IntegrationSettings } from '../../../../../../../types/integrationSettings';
import { Grid } from '@mui/material';
import IntegrationSAPSettingsInfo from './Common/IntegrationSAPSettingsInfo';
import IntegrationBusinessCentralSettingsInfo from './Common/IntegrationBusinessCentralSettingsInfo';
import ModalPane from '../../../../../../../VentoryUI/components/common/Modal/components/ModalPane';
import { BackButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import { NextButtonTemplate } from '../../../../../../../VentoryUI/components/common/Button/Templates/NextButton/NextButton';

interface CreateIntegrationSettingsInfoPaneProps {
  settings: IntegrationSettings;
  setSettings: (settings: IntegrationSettings) => void;
  handleNext: () => void;
  handleBack: () => void;
}

export default function CreateIntegrationSettingsInfoPane({
  settings,
  setSettings,
  handleNext,
  handleBack,
}: CreateIntegrationSettingsInfoPaneProps) {
  return (
    <ModalPane footerButtons={[BackButtonTemplate(handleBack), NextButtonTemplate(handleNext)]}>
      <Grid container height={'100%'} alignContent={'space-between'}>
        <Grid item xs={12}>
          {settings.type === CompanyRelationService.sap ? (
            <IntegrationSAPSettingsInfo
              settingsInput={settings}
              setSettingsInput={setSettings}
              disabled={false}
              setError={err => {}}
            />
          ) : (
            <IntegrationBusinessCentralSettingsInfo
              settingsInput={settings}
              setSettingsInput={setSettings}
              disabled={false}
            />
          )}
        </Grid>
      </Grid>
    </ModalPane>
  );
}
